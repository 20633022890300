//#region IMPORTS
import { connect } from 'react-redux';
import Wrapper, { DispatchProps, StateProps } from './Wrapper';
import { GetUser, SetSatoToken, SatoAuthFetch } from '../../../user/ActionUser';
import { AppState } from '../../../../bootstrap/ReduxStore';
import { ConsoleLog, GetDeviceTokenFetch, ReceivedActionFromNative, ToggleMutedAction } from '../../ActionWrapper';
import { SetDeviceToken, PushNotificationRegisterFetch, GetDevicePlatform } from '../../../user/ActionUser';

//#endregion REDUX BINDINGS
const mapStateToProps = (state: AppState): StateProps => ({
  isSignedIn: state.user.isSignedIn,
  userAction: state.user.action,
  deviceTokenFromWrapper: state.wrapper.deviceToken,
  wrapperAction: state.wrapper.action,
  user: state.user.user,
  deviceToken: state.user.deviceToken,
  devicePlatform: state.user.devicePlatform,
  satoAuthResponse: state.user.satoAuthResponse,
  isMuted: state.wrapper.isMuted,
});

const mapDispatchToProps: DispatchProps = {
  GetUser,
  GetDeviceTokenFetch,
  ReceivedActionFromNative,
  ConsoleLog,
  SetDeviceToken,
  PushNotificationRegisterFetch,
  GetDevicePlatform,
  SatoAuthFetch,
  SetSatoToken,
  ToggleMutedAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapper);
