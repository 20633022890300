//#region IMPORT
// LOCAL CONFIG
import {
  Area,
  AreaList,
  CONGESTION_LIST_FAILED,
  CONGESTION_LIST_FETCH,
  CONGESTION_LIST_SUCCESS,
  CONGESTION_SUBSCRIPTION_FAILED,
  CONGESTION_SUBSCRIPTION_SUCCESS,
  CONGESTION_UPDATE_FAILED,
  CONGESTION_UPDATE_FETCH,
  CONGESTION_UPDATE_SUCCESS,
  CongestionActionTypes,
  CongestionListParam,
  CongestionUpdateParam,
  FailureType,
} from './constants';

//#endregion

//#region ACTION
// CONGESTION UPDATE
export const congestionUpdateFetch = (payload: CongestionUpdateParam): CongestionActionTypes => ({
  type: CONGESTION_UPDATE_FETCH,
  payload,
});

export const congestionUpdateSuccess = (payload: Area): CongestionActionTypes => ({
  type: CONGESTION_UPDATE_SUCCESS,
  payload,
});

export const congestionUpdateFailed = (payload: FailureType): CongestionActionTypes => ({
  type: CONGESTION_UPDATE_FAILED,
  payload,
});

// CONGESTION LIST FETCH
export const congestionListFetch = (payload: CongestionListParam): CongestionActionTypes => ({
  type: CONGESTION_LIST_FETCH,
  payload,
});
export const congestionListSuccess = (payload: AreaList): CongestionActionTypes => ({
  type: CONGESTION_LIST_SUCCESS,
  payload,
});
export const congestionListFailed = (payload: FailureType): CongestionActionTypes => ({
  type: CONGESTION_LIST_FAILED,
  payload,
});
export const CongestionSubscriptionSuccess = (): CongestionActionTypes => ({
  type: CONGESTION_SUBSCRIPTION_SUCCESS,
});

export const CongestionSubscriptionFailed = (payload: FailureType): CongestionActionTypes => ({
  type: CONGESTION_SUBSCRIPTION_FAILED,
  payload,
});
//#endregion
