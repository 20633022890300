//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { Field, Formik, Form } from 'formik';
import * as Yup from 'yup';

// LOCAL CONFIG
import { Button, Input, Modal } from '../../../../components';
import {
  ShowerBooking,
  ShowerBookingCreateUpdateType,
  ShowerBookingStatus,
  ShowerRoom,
  StartForm,
} from '../../constants';

// LOCAL COMPONENT
import './ShowerAction.scss';
//#endregion

//#region INTERFACE
interface OwnProps {
  intl: IntlShape;
  data: ShowerBooking;
  totalReady: number;
  roomList: ShowerRoom[];
  onClickActionButton: (
    booking: ShowerBooking,
    updateType: ShowerBookingCreateUpdateType,
    shower_room?: string,
    memo?: string
  ) => void;
  onClickDetail?: (booking: ShowerBooking) => void;
  showDetail?: boolean;
  onChangeMemo?:(device_id:string, value:string) => void;
  memoData?: {[device_id:string]:string};
}

interface OwnState {
  cancelLoading: boolean;
  callLoading: boolean;
  notifyLoading: boolean;
  timeoutLoading: boolean;
  startLoading: boolean;
  completeLoading: boolean;
  disabledAllAction: boolean;
  modalVisible: boolean;
  modalContent: string;
  modalCallback: Function | undefined;
}

type Props = OwnProps;
//#endregion

//#region COMPONENT
class ShowerAction extends React.Component<Props, OwnState> {
  //#region CTOR & LIFECYCLES
  private startShowerValidation: Yup.ObjectSchema;
  private confirmationModalRef: React.RefObject<Modal>;

  constructor(props: Props) {
    super(props);

    this.state = {
      cancelLoading: false,
      callLoading: false,
      notifyLoading: false,
      timeoutLoading: false,
      startLoading: false,
      completeLoading: false,
      disabledAllAction: false,
      modalVisible: false,
      modalCallback: undefined,
      modalContent: '',
    };

    this.startShowerValidation = Yup.object().shape({
      shower_room: Yup.string().required('validationRequired'),
    });

    this.confirmationModalRef = React.createRef<Modal>();

    this.handleClickCancel = this.handleClickCancel.bind(this);
    this.handleClickCall = this.handleClickCall.bind(this);
    this.handleClickStart = this.handleClickStart.bind(this);
    this.handleClickNotify = this.handleClickNotify.bind(this);
    this.handleClickComplete = this.handleClickComplete.bind(this);
    this.handleClickTimeout = this.handleClickTimeout.bind(this);
    this.handleShowConfirmation = this.handleShowConfirmation.bind(this);
    this.handleClickDetail = this.handleClickDetail.bind(this);
    this.handleChangeMemo = this.handleChangeMemo.bind(this);
  }

  componentDidUpdate(prevProps: Props, prevState: OwnState): void {
    if (prevProps.data.status !== this.props.data.status) {
      this.setState({ disabledAllAction: false });
    }

    if (prevState.modalVisible !== this.state.modalVisible) {
      if (this.state.modalVisible) {
        this.confirmationModalRef.current && this.confirmationModalRef.current.openModal();
      } else {
        this.confirmationModalRef.current && this.confirmationModalRef.current.closeModal();
      }
    }
  }

  //#endregion

  //#region CLASS METHODS
  handleClickCancel(): void {
    const { onClickActionButton, data } = this.props;
    this.setState({ cancelLoading: true, disabledAllAction: true }, () =>
      onClickActionButton(data, ShowerBookingCreateUpdateType.CANCEL)
    );
    window.localStorage.removeItem(data.device_id);
  }

  handleClickCall(): void {
    const { onClickActionButton, data } = this.props;
    this.setState({ callLoading: true, disabledAllAction: true }, () =>
      onClickActionButton(data, ShowerBookingCreateUpdateType.CALL)
    );
  }

  handleClickNotify(): void {
    const { onClickActionButton, data } = this.props;
    this.setState({ notifyLoading: true, disabledAllAction: true }, () => {
      onClickActionButton(data, ShowerBookingCreateUpdateType.NOTIFY);
      setTimeout(() => this.setState({ notifyLoading: false, disabledAllAction: false }), 1000);
    });
  }

  handleClickTimeout(): void {
    const { onClickActionButton, data } = this.props;
    this.setState({ timeoutLoading: true, disabledAllAction: true }, () =>
      onClickActionButton(data, ShowerBookingCreateUpdateType.TIMEOUT)
    );
    window.localStorage.removeItem(data.device_id);
  }

  handleClickStart(values: StartForm): void {
    const { shower_room, memo } = values;
    const { onClickActionButton, data } = this.props;
    this.setState({ startLoading: true, disabledAllAction: true }, () =>
      onClickActionButton(data, ShowerBookingCreateUpdateType.START, shower_room, memo)
    );
}

  handleClickComplete(): void {
    const { onClickActionButton, data } = this.props;
    this.setState({ completeLoading: true, disabledAllAction: true }, () =>
      onClickActionButton(data, ShowerBookingCreateUpdateType.COMPLETE)
    );
    window.localStorage.removeItem(data.device_id);
  }

  handleShowConfirmation(content: string, callback: Function): void {
    this.setState({ modalVisible: true, modalContent: content, modalCallback: callback });
  }

  handleClickDetail(): void {
    const { onClickDetail, data } = this.props;
    if (typeof onClickDetail === 'function') onClickDetail(data);
  }

  handleChangeMemo(event: React.ChangeEvent<HTMLInputElement>): void {
    const { onChangeMemo, data } = this.props;
    if ( typeof onChangeMemo === 'function') onChangeMemo(data.device_id,event.target.value);
  }
  
  //#endregion

  //#region RENDER
  renderConfirmationModal(): React.ReactElement {
    if (!this.state.modalVisible && typeof this.state.modalCallback !== 'function') return <></>;
    return (
      <Modal
        mode={2}
        ref={this.confirmationModalRef}
        title="titleConfirmation"
        content={this.state.modalContent}
        onClosed={(): void => this.setState({ modalVisible: false, modalCallback: undefined })}
        onConfirmed={(): Function | undefined => this.state.modalCallback && this.state.modalCallback()}
      />
    );
  }

  renderActionGroup(): JSX.Element {
    const { roomList, totalReady, data } = this.props;
    const availableRoom = !roomList
      ? []
      : roomList
          .filter((room: ShowerRoom) => room.status === 'AVAILABLE')
          .map((room: ShowerRoom) => ({ label: room.name, value: room.status }));

    const showCall = availableRoom.length > 0 && totalReady < roomList.length;

    switch (data.status) {
      case ShowerBookingStatus.IN_QUEUE:
        return (
          <div className="shower-action__group">
            <div className="shower-action__cancel no-margin">
              <Button
                type="button"
                color="light-primary"
                label="showerCancel"
                showSpinner={this.state.cancelLoading}
                disabled={this.state.cancelLoading || this.state.disabledAllAction}
                onClick={(): void => this.handleShowConfirmation('contentCancelBooking', this.handleClickCancel)}
              />
            </div>
            {showCall && (
              <div className="shower-action__call">
                <Button
                  type="button"
                  color="secondary"
                  label="showerCall"
                  showSpinner={this.state.callLoading}
                  disabled={this.state.callLoading || this.state.disabledAllAction}
                  onClick={this.handleClickCall}
                />
              </div>
            )}
          </div>
        );
      case ShowerBookingStatus.READY:
        //let local_memo = window.localStorage.getItem(data.device_id);
        //if(!local_memo) {
        //  local_memo = '';
        //}
        return (
          <>
            <div className="shower-action__group">
              <Formik
                enableReinitialize
                isInitialValid
                initialValues={{
                  shower_room: availableRoom.length > 0 ? availableRoom[0].label : '',
                  memo: this.props.memoData ? this.props.memoData[data.device_id] : '',
                  airport_id: data.airport_id,
                  lounge_id: data.lounge_id, 
                }}
                validationSchema={this.startShowerValidation}
                onSubmit={this.handleClickStart}
                render={({ isValid }): JSX.Element => (
                  <Form>
                    <div className="shower-action__ref">
                      <Field
                        name="memo"
                        component={Input}
                        type="text"
                        id="memo"
                        maxLength={30}
                        value={(this.props.memoData && this.props.memoData[data.device_id]) ? this.props.memoData[data.device_id] : ''}
                        onChange={this.handleChangeMemo}
                        placeholder="placeholderMemo"
                      />
                    </div>
                    <div className="shower-action__room">
                      <Field
                        component="select"
                        name="shower_room"
                        className="jal-input--select"
                        disabled={availableRoom.length < 1 || this.state.startLoading || this.state.disabledAllAction}
                      >
                        {availableRoom.map((option: { label: string; value: string }, index: number) => (
                          <option key={index} value={option.label}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="shower-action__start">
                      <Button
                        type="submit"
                        color="primary"
                        label="showerStart"
                        showSpinner={this.state.startLoading}
                        disabled={
                          !isValid ||
                          availableRoom.length < 1 ||
                          this.state.startLoading ||
                          this.state.disabledAllAction
                        }
                      />
                    </div>
                  </Form>
                )}
              />
            </div>
            <div className="shower-action__group">
              <div className="shower-action__cancel no-margin">
                <Button
                  type="button"
                  color="light-primary"
                  label="showerTimeout"
                  showSpinner={this.state.timeoutLoading}
                  disabled={this.state.timeoutLoading || this.state.disabledAllAction}
                  onClick={(): void => this.handleShowConfirmation('contentTimeoutBooking', this.handleClickTimeout)}
                />
              </div>
              {this.props.data.use_app && (
                <div className="shower-action__notify">
                  <Button
                    type="button"
                    color="secondary"
                    label="showerNotify"
                    showSpinner={this.state.notifyLoading}
                    disabled={this.state.notifyLoading || this.state.disabledAllAction}
                    onClick={this.handleClickNotify}
                  />
                </div>
              )}
            </div>
            <div className="shower-action__group">
              <div className="shower-action__cancel no-margin">
                <Button
                  type="button"
                  color="light-primary"
                  label="showerCancel"
                  showSpinner={this.state.cancelLoading}
                  disabled={this.state.cancelLoading || this.state.disabledAllAction}
                  onClick={(): void => this.handleShowConfirmation('contentCancelBooking', this.handleClickCancel)}
                />
              </div>
            </div>
          </>
        );

      case ShowerBookingStatus.NOTIFIED:
        //let local_memo = window.localStorage.getItem(data.device_id);
        //if(!local_memo) {
        //  local_memo = '';
        //}
        return (
          <>
            <div className="shower-action__group">
              <Formik
                enableReinitialize
                isInitialValid
                initialValues={{
                  shower_room: availableRoom.length > 0 ? availableRoom[0].label : '',
                  memo: (this.props.memoData && this.props.memoData[data.device_id]) ? this.props.memoData[data.device_id] : '',
                  airport_id: data.airport_id,
                  lounge_id: data.lounge_id, 
                }}
                validationSchema={this.startShowerValidation}
                onSubmit={this.handleClickStart}
                render={({ isValid }): JSX.Element => (
                  <Form>
                    <div className="shower-action__ref">
                      <Field
                        name="memo"
                        component={Input}
                        type="text"
                        id="memo"
                        maxLength={30}
                        value={(this.props.memoData && this.props.memoData[data.device_id]) ? this.props.memoData[data.device_id] : ''}
                        onChange={this.handleChangeMemo}
                        placeholder="placeholderMemo"
                      />
                    </div>
                    <div className="shower-action__room">
                      <Field
                        component="select"
                        name="shower_room"
                        className="jal-input--select"
                        disabled={availableRoom.length < 1 || this.state.startLoading || this.state.disabledAllAction}
                      >
                        {availableRoom.map((option: { label: string; value: string }, index: number) => (
                          <option key={index} value={option.label}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="shower-action__start">
                      <Button
                        type="submit"
                        color="primary"
                        label="showerStart"
                        showSpinner={this.state.startLoading}
                        disabled={
                          !isValid ||
                          availableRoom.length < 1 ||
                          this.state.startLoading ||
                          this.state.disabledAllAction
                        }
                      />
                    </div>
                  </Form>
                )}
              />
            </div>
            <div className="shower-action__group">
              <div className="shower-action__cancel no-margin">
                <Button
                  type="button"
                  color="light-primary"
                  label="showerTimeout"
                  showSpinner={this.state.timeoutLoading}
                  disabled={this.state.timeoutLoading || this.state.disabledAllAction}
                  onClick={(): void => this.handleShowConfirmation('contentTimeoutBooking', this.handleClickTimeout)}
                />
              </div>
              {this.props.data.use_app && (
                <div className="shower-action__notify">
                  <Button
                    type="button"
                    color="secondary"
                    label="showerNotify"
                    showSpinner={this.state.notifyLoading}
                    disabled={this.state.notifyLoading || this.state.disabledAllAction}
                    onClick={this.handleClickNotify}
                  />
                </div>
              )}
            </div>
            <div className="shower-action__group">
              <div className="shower-action__cancel no-margin">
                <Button
                  type="button"
                  color="light-primary"
                  label="showerCancel"
                  showSpinner={this.state.cancelLoading}
                  disabled={this.state.cancelLoading || this.state.disabledAllAction}
                  onClick={(): void => this.handleShowConfirmation('contentCancelBooking', this.handleClickCancel)}
                />
              </div>
            </div>
          </>
        );

      case ShowerBookingStatus.ANNOUNCED:
        //let local_memo = window.localStorage.getItem(data.device_id);
        //if(!local_memo) {
        //  local_memo = '';
        //}
        return (
          <>
            <div className="shower-action__group">
              <Formik
                enableReinitialize
                isInitialValid
                initialValues={{
                  shower_room: availableRoom.length > 0 ? availableRoom[0].label : '',
                  memo: (this.props.memoData && this.props.memoData[data.device_id]) ? this.props.memoData[data.device_id] : '',
                  airport_id: data.airport_id,
                  lounge_id: data.lounge_id, 
                }}
                validationSchema={this.startShowerValidation}
                onSubmit={this.handleClickStart}
                render={({ isValid }): JSX.Element => (
                  <Form>
                    <div className="shower-action__ref">
                      <Field
                        name="memo"
                        component={Input}
                        type="text"
                        id="memo"
                        maxLength={30}
                        value={(this.props.memoData && this.props.memoData[data.device_id]) ? this.props.memoData[data.device_id] : ''}
                        onChange={this.handleChangeMemo}
                        placeholder="placeholderMemo"
                      />
                    </div>
                    <div className="shower-action__room">
                      <Field
                        component="select"
                        name="shower_room"
                        className="jal-input--select"
                        disabled={availableRoom.length < 1 || this.state.startLoading || this.state.disabledAllAction}
                      >
                        {availableRoom.map((option: { label: string; value: string }, index: number) => (
                          <option key={index} value={option.label}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="shower-action__start">
                      <Button
                        type="submit"
                        color="primary"
                        label="showerStart"
                        showSpinner={this.state.startLoading}
                        disabled={
                          !isValid ||
                          availableRoom.length < 1 ||
                          this.state.startLoading ||
                          this.state.disabledAllAction
                        }
                      />
                    </div>
                  </Form>
                )}
              />
            </div>
            <div className="shower-action__group">
              <div className="shower-action__cancel no-margin">
                <Button
                  type="button"
                  color="light-primary"
                  label="showerTimeout"
                  showSpinner={this.state.timeoutLoading}
                  disabled={this.state.timeoutLoading || this.state.disabledAllAction}
                  onClick={(): void => this.handleShowConfirmation('contentTimeoutBooking', this.handleClickTimeout)}
                />
              </div>
              {this.props.data.use_app && (
                <div className="shower-action__notify">
                  <Button
                    type="button"
                    color="secondary"
                    label="showerNotify"
                    showSpinner={this.state.notifyLoading}
                    disabled={this.state.notifyLoading || this.state.disabledAllAction}
                    onClick={this.handleClickNotify}
                  />
                </div>
              )}
            </div>
            <div className="shower-action__group">
              <div className="shower-action__cancel no-margin">
                <Button
                  type="button"
                  color="light-primary"
                  label="showerCancel"
                  showSpinner={this.state.cancelLoading}
                  disabled={this.state.cancelLoading || this.state.disabledAllAction}
                  onClick={(): void => this.handleShowConfirmation('contentCancelBooking', this.handleClickCancel)}
                />
              </div>
            </div>
          </>
        );
      case ShowerBookingStatus.IN_PROGRESS:
        return (
          <div className="shower-action__group">
            <div className="shower-action__complete">
              <Button
                type="button"
                color="primary"
                label="showerComplete"
                showSpinner={this.state.completeLoading}
                disabled={this.state.completeLoading || this.state.disabledAllAction}
                onClick={this.handleClickComplete}
              />
            </div>
          </div>
        );
      default:
        return <></>;
    }
  }

  render(): React.ReactNode {
    const { showDetail } = this.props;

    return (
      <div className="shower-action">
        {this.renderActionGroup()}
        {showDetail && (
          <div className="shower-action__group">
            <Button type="button" className="show-detail" label="buttonShowDetail" onClick={this.handleClickDetail} />
          </div>
        )}
        {this.renderConfirmationModal()}
      </div>
    );
  }

  //#endregion
}

export default injectIntl(ShowerAction);
