//#region IMPORT
// PACKAGE IMPORT
import React from 'react';
import { Formik, Field, Form } from 'formik';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import * as Yup from 'yup';

// LOCAL CONFIG

// LOCAL COMPONENT
import { Button, Input } from '../../../../components';
import './Login.scss';

//#endregion

//#region INTERFACE
export interface DispatchProps {
  Login: (username: string, password: string) => void;
}
export interface StateProps {
  isLoadingLogin: boolean;
}

type Props = StateProps & DispatchProps & WrappedComponentProps;

interface LoginForm {
  username: string;
  password: string;
}
//#endregion

//#region COMPONENT
export class Login extends React.Component<Props> {
  //#region CONSTRUCTOR
  private loginValidation: Yup.ObjectSchema;

  constructor(props: Props) {
    super(props);

    this.loginValidation = Yup.object().shape({
      username: Yup.string().required('validationRequired'),
      password: Yup.string().required('validationRequired'),
    });
  }
  //#endregion

  //#region RENDER
  render(): React.ReactElement {
    const { intl, isLoadingLogin, Login } = this.props;

    return (
      <div className="login">
        <div className="login__header">
          <div className="login__logo">
            <h4>{intl.formatMessage({ id: 'loginTitle', description: 'loginTitle' })}</h4>
          </div>
        </div>
        <div className="login__content">
          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            validationSchema={this.loginValidation}
            onSubmit={(values: LoginForm): void => Login(values.username, values.password)}
            render={({ isValid }): JSX.Element => {
              return (
                <Form>
                  <div className="login__username">
                    <Field
                      name="username"
                      component={Input}
                      type="username"
                      label="labelUsername"
                      id="username"
                      placeholder="placeholderUsername"
                      icon="person"
                      iconColor="secondary"
                      isRequired
                    />
                  </div>
                  <div className="login__password">
                    <Field
                      name="password"
                      component={Input}
                      type="password"
                      label="labelPassword"
                      id="password"
                      placeholder="placeholderPassword"
                      icon="lock"
                      iconColor="secondary"
                      isRequired
                    />
                  </div>
                  <div className="login__call-to-action">
                    <Button
                      color="primary"
                      label="buttonLogin"
                      type="submit"
                      disabled={!isValid || isLoadingLogin}
                      showSpinner={isLoadingLogin}
                    />
                  </div>
                </Form>
              );
            }}
          />
        </div>
      </div>
    );
  }
  //#endregion
}
//#endregion

export default injectIntl(Login);
