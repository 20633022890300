//#region IMPORTS
// LOCAL CONFIG
import {
  MEAL_ORDER_CREATE_CLEAR,
  MEAL_ORDER_CREATE_FAILED,
  MEAL_ORDER_CREATE_FETCH,
  MEAL_ORDER_CREATE_SUCCESS,
  MEAL_ORDER_LIST_CLEAR,
  MEAL_ORDER_LIST_FAILED,
  MEAL_ORDER_LIST_FETCH,
  MEAL_ORDER_LIST_SUCCESS,
  MEAL_ORDER_SUBSCRIPTION_CREATE_FAILED,
  MEAL_ORDER_SUBSCRIPTION_CREATE_FETCH,
  MEAL_ORDER_SUBSCRIPTION_CREATE_SUCCESS,
  MEAL_ORDER_SUBSCRIPTION_REMOVE_FAILED,
  MEAL_ORDER_SUBSCRIPTION_REMOVE_FETCH,
  MEAL_ORDER_SUBSCRIPTION_REMOVE_SUCCESS,
  MEAL_ORDER_UPDATE_CLEAR,
  MEAL_ORDER_UPDATE_FAILED,
  MEAL_ORDER_UPDATE_FETCH,
  MEAL_ORDER_UPDATE_SUCCESS,
  MENU_LIST_CLEAR,
  MENU_LIST_FAILED,
  MENU_LIST_FETCH,
  MENU_LIST_SUCCESS,
  ON_CREATE_UPDATE_MEAL_ORDER,
  ON_CREATE_UPDATE_MEAL_ORDER_FAILED,
  ON_CREATE_UPDATE_MEAL_ORDER_SUCCESS,
  SET_LATEST_ORDER_COUNTER,
  TOGGLE_SORTED_ACTION,
} from './StringMeals';
//#endregion

//#region GENERAL ENUMS
// Meal Order
export enum MealOrderFilterType {
  NONE = 'NONE',
  STATUS = 'STATUS',
  STATUS_DATE = 'STATUS_DATE',
  DEVICE_ID = 'DEVICE_ID',
}

export enum MealOrderStatusType {
  SUBMITTED = '201',
  IN_PROGRESS = '301',
  REJECTED = '302',
  COMPLETED = '401',
}

export enum MealOrderCreateUpdateType {
  REJECT = 'REJECT',
  START = 'START',
  COMPLETE = 'COMPLETE',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export enum SubscriptionRegistrationStatus {
  UNREGISTERED,
  UNREGISTERING,
  REGISTERING,
  REGISTERED,
  REGISTRATION_FAILED,
}

export type FailureType = Record<string, unknown> | string;

export type GraphQLSubscriptionResult = {
  provider: unknown;
  value: {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    data: any;
  };
};
//#endregion

//#region GENERAL INTERFACES
// MEAL ORDER
export type MealOrderItem = {
  name: string;
  qty: number;
  is_cooked: boolean;
  is_delivered: boolean;
};
export type MealOrderStatusHistory = {
  status: string;
  timestamp: string;
};
export type MealOrder = {
  device_id: string;
  items: string; // NOTE: This is a json string, don't forget to parse it first before using.
  status: MealOrderStatusType;
  timestamp: string;
  counter: number;
  use_app: boolean;
  status_history: MealOrderStatusHistory[];
  table_id: string;
  airport_id: string;
  lounge_id: string;
};
export type MealOrderList = {
  items?: MealOrder[];
  nextToken?: string;
};
export interface ListMealOrder {
  listMealOrder?: MealOrderList;
}
export interface ListMealOrderByStatus {
  listMealOrderByStatus?: MealOrderList;
}
export interface ListMealOrderByByStatusAndDate {
  listMealOrderByStatusAndDate?: MealOrderList;
}
export interface ListMealOrderByDeviceId {
  listMealOrderByDeviceId?: MealOrderList;
}

// ORDER FORM
export interface OrderForm {
  device_id: string;
  items: MealOrderItem[];
  table_id: string;
  airport_id: string;
  lounge_id: string;
}
export type MenuSelectItem = {
  label: string;
  value: string;
};

// MENU
export interface MenuItemImageInfo {
  id: number;
  name: string;
  hash: string;
  sha256: string;
  ext: string;
  mime: string;
  size: string;
  url: string;
  provider: string;
  public_id?: null;
  created_at?: string;
  updated_at?: string;
}
export interface MenuListItem {
  id: number;
  name?: string | null;
  description?: string | null;
  is_active: boolean;
  created_at?: string;
  updated_at?: string;
  name_en?: string | null;
  name_jp?: string | null;
  description_en?: string | null;
  description_jp?: string | null;
  name_ja: string;
  description_ja: string;
  image_url: MenuItemImageInfo;
  display_order?: number;
}
export interface MenuListResponse {
  items: MenuListItem[];
}
export interface MenuItemImageInfo {
  id: number;
  name: string;
  hash: string;
  sha256: string;
  ext: string;
  mime: string;
  size: string;
  url: string;
  provider: string;
  public_id?: null;
  created_at?: string;
  updated_at?: string;
}
export interface MenuListItem {
  id: number;
  name?: string | null;
  description?: string | null;
  is_active: boolean;
  created_at?: string;
  updated_at?: string;
  name_en?: string | null;
  name_jp?: string | null;
  description_en?: string | null;
  description_jp?: string | null;
  name_ja: string;
  description_ja: string;
  image_url: MenuItemImageInfo;
  is_staging: boolean;
  is_production: boolean;
  from: string;
  to: string;
}
export enum MenuCategoryType {
  SAKE = 1,
  SUSHI = 2,
  WINE = 3,
  MEAL = 4,
}
export interface MenuCategoryItemType {
  created_at: string;
  id: number;
  menus: MenuListItem[];
  name_en?: string | null;
  name_ja?: string | null;
  updated_at: string;
  type: number;
}
export type MenuListItemJa = Omit<MenuListItem, 'name_en' | 'description_en'>;
export type MenuListItemEn = Omit<MenuListItem, 'name_jp' | 'description_jp' | 'name_ja' | 'description_ja'>;
//#endregion

//#region ACTION TYPES
//#region MEAL ORDER LIST
// Fetch
export interface MealOrderListParam {
  limit?: number;
  nextToken?: string;
  filterType: MealOrderFilterType;
  airport_id: string;
  lounge_id: string;
}
interface MealOrderListByDeviceId extends MealOrderListParam {
  device_id: string;
}
export interface MealOrderListByStatusParam extends MealOrderListParam {
  statusTypes: MealOrderStatusType[];
}
export interface MealOrderListByStatusAndDate extends MealOrderListParam {
  statusTypes: MealOrderStatusType[];
  date: string;
}
export type MealOrderListPayload =
  | MealOrderListParam
  | MealOrderListByStatusParam
  | MealOrderListByStatusAndDate
  | MealOrderListByDeviceId;
export interface MealOrderListFetch {
  type: typeof MEAL_ORDER_LIST_FETCH;
  payload: MealOrderListPayload;
}

// Success
export type MealOrderListMultiStatusResponse = {
  items: {
    [MealOrderStatusType.COMPLETED]?: MealOrder[];
    [MealOrderStatusType.IN_PROGRESS]?: MealOrder[];
    [MealOrderStatusType.REJECTED]?: MealOrder[];
    [MealOrderStatusType.SUBMITTED]?: MealOrder[];
  };
};
export type MealOrderListByStatusResponse = {
  listMealOrderByStatus: MealOrderList;
};
export type MealOrderListByStatusAndDateResponse = {
  listMealOrderByStatusAndDate: MealOrderList;
};
export type MealOrderListByDeviceIdResponse = {
  listMealOrderByDeviceId: MealOrderList;
};
export type MealOrderListResponse = MealOrderList | MealOrderListMultiStatusResponse;
export interface MealOrderListSuccess {
  type: typeof MEAL_ORDER_LIST_SUCCESS;
  payload: MealOrderListResponse;
}

// Failed
export interface MealOrderListFailed {
  type: typeof MEAL_ORDER_LIST_FAILED;
  payload: FailureType;
}

// Clear
export interface MealOrderListClear {
  type: typeof MEAL_ORDER_LIST_CLEAR;
}

type MealOrderListActions = MealOrderListFetch | MealOrderListSuccess | MealOrderListFailed | MealOrderListClear;

//#endregion

//#region MEAL ORDER CREATE
// Fetch
export type MealOrderCreateParam = {
  device_id: string;
  items: MealOrderItem[];
  use_app: boolean;
  table_id: string;
  airport_id: string;
  lounge_id: string;
  locale: string;
};
export interface MealOrderCreateFetch {
  type: typeof MEAL_ORDER_CREATE_FETCH;
  payload: MealOrderCreateParam;
}

// Success
export type MealOrderCreateResponseType = {
  createMealOrder: MealOrder;
};
export interface MealOrderCreateSuccess {
  type: typeof MEAL_ORDER_CREATE_SUCCESS;
  payload: MealOrderCreateResponseType;
}

// Failed
export interface MealOrderCreateFailed {
  type: typeof MEAL_ORDER_CREATE_FAILED;
  payload: FailureType;
}

// Clear
export interface MealOrderCreateClear {
  type: typeof MEAL_ORDER_CREATE_CLEAR;
}

type MealOrderCreateActions =
  | MealOrderCreateFetch
  | MealOrderCreateSuccess
  | MealOrderCreateFailed
  | MealOrderCreateClear;

//#endregion

//#region MEAL ORDER UPDATE

// Fetch
export type MealOrderUpdateParam = {
  device_id: string;
  timestamp: string;
  updateType: MealOrderCreateUpdateType;
  airport_id: string;
  lounge_id: string;
  items?: string;
};
export interface MealOrderUpdateFetch {
  type: typeof MEAL_ORDER_UPDATE_FETCH;
  payload: MealOrderUpdateParam;
}

// Success
export interface MealOrderUpdateSuccess {
  type: typeof MEAL_ORDER_UPDATE_SUCCESS;
  payload: MealOrder;
}

// Failed
export interface MealOrderUpdateFailed {
  type: typeof MEAL_ORDER_UPDATE_FAILED;
  payload: FailureType;
}

// Clear
export interface MealOrderUpdateClear {
  type: typeof MEAL_ORDER_UPDATE_CLEAR;
}

type MealOrderUpdateActions =
  | MealOrderUpdateFetch
  | MealOrderUpdateSuccess
  | MealOrderUpdateFailed
  | MealOrderUpdateClear;

//#endregion

//#region MEAL ORDER SUBSCRIPTION

// Create Subscription
export interface MealOrderSubscriptionCreateFetch {
  type: typeof MEAL_ORDER_SUBSCRIPTION_CREATE_FETCH;
}
export interface MealOrderSubscriptionCreateSuccess {
  type: typeof MEAL_ORDER_SUBSCRIPTION_CREATE_SUCCESS;
}
export interface MealOrderSubscriptionCreateFailed {
  type: typeof MEAL_ORDER_SUBSCRIPTION_CREATE_FAILED;
  payload: FailureType;
}
export interface MealOrderSubscriptionRemoveFetch {
  type: typeof MEAL_ORDER_SUBSCRIPTION_REMOVE_FETCH;
}
export interface MealOrderSubscriptionRemoveSuccess {
  type: typeof MEAL_ORDER_SUBSCRIPTION_REMOVE_SUCCESS;
}
export interface MealOrderSubscriptionRemoveFailed {
  type: typeof MEAL_ORDER_SUBSCRIPTION_REMOVE_FAILED;
  payload: FailureType;
}

// On Subscription Fired
interface OnCreateUpdateMealOrderParam {
  mutationType: MealOrderCreateUpdateType;
}
export interface OnCreateMealOrderPayload extends OnCreateUpdateMealOrderParam {
  onCreateMealOrder: MealOrder;
}
export interface OnRejectMealOrderPayload extends OnCreateUpdateMealOrderParam {
  onRejectMealOrder: MealOrder;
}
export interface OnStartMealOrderPayload extends OnCreateUpdateMealOrderParam {
  onStartMealOrder: MealOrder;
}
export interface OnCompleteMealOrderPayload extends OnCreateUpdateMealOrderParam {
  onCompleteMealOrder: MealOrder;
}
export interface OnUpdateMealOrderItemsPayload extends OnCreateUpdateMealOrderParam {
  onUpdateMealOrderItems: MealOrder;
}

export type OnCreateUpdateMealOrderPayload =
  | OnCreateMealOrderPayload
  | OnRejectMealOrderPayload
  | OnStartMealOrderPayload
  | OnCompleteMealOrderPayload
  | OnUpdateMealOrderItemsPayload;

export interface OnCreateUpdateMealOrder {
  type: typeof ON_CREATE_UPDATE_MEAL_ORDER;
  payload: OnCreateUpdateMealOrderPayload;
  muted: boolean;
  airportId: string;
  loungeId: string;
}

export interface OnCreateUpdateMealOrderSuccess {
  type: typeof ON_CREATE_UPDATE_MEAL_ORDER_SUCCESS;
  payload: MealOrder;
}

export interface OnCreateUpdateMealOrderFailed {
  type: typeof ON_CREATE_UPDATE_MEAL_ORDER_FAILED;
  payload: FailureType;
}

export interface ToggleSortedAction {
  type: typeof TOGGLE_SORTED_ACTION;
  payload: boolean;
}

type MealOrderSubscriptionActions =
  | MealOrderSubscriptionCreateFetch
  | MealOrderSubscriptionCreateSuccess
  | MealOrderSubscriptionCreateFailed
  | MealOrderSubscriptionRemoveFetch
  | MealOrderSubscriptionRemoveSuccess
  | MealOrderSubscriptionRemoveFailed
  | OnCreateUpdateMealOrder
  | OnCreateUpdateMealOrderSuccess
  | OnCreateUpdateMealOrderFailed;

//#endregion

//#region MENU LIST
export type MenuListParam = {
  airportId: string;
  loungeId: string;
};

// Fetch
export interface MenuListFetch {
  type: typeof MENU_LIST_FETCH;
  payload: MenuListParam;
}

// Success
export interface MenuListSuccess {
  type: typeof MENU_LIST_SUCCESS;
  payload: MenuListResponse;
}

// FailedL
export interface MenuListFailed {
  type: typeof MENU_LIST_FAILED;
  payload: FailureType;
}

// Clear
export interface MenuListClear {
  type: typeof MENU_LIST_CLEAR;
}

type MenuListActions = MenuListFetch | MenuListSuccess | MenuListFailed | MenuListClear;

//#endregion

//#region OTHERS

export type LatestOrderCounter = {
  date: number;
  counter: number;
}

export interface SetLatestOrderCounter {
  type: typeof SET_LATEST_ORDER_COUNTER;
  payload: LatestOrderCounter;
}

//#endregion

export type MealOrderActionTypes =
  | MealOrderListActions
  | MealOrderCreateActions
  | MealOrderUpdateActions
  | MealOrderSubscriptionActions
  | MenuListActions
  | SetLatestOrderCounter
  | ToggleSortedAction;

//#endregion
