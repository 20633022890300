//#region IMPORT
// PACKAGE IMPORT
import gql from 'graphql-tag';
//#endregion

//#region QUERIES
const ListShowerRooms = gql`
  query ListShowerRooms($airport_id: String!, $lounge_id: String!, $limit: Int, $nextToken: String) {
    listShowerRooms(airport_id: $airport_id, lounge_id: $lounge_id, limit: $limit, nextToken: $nextToken) {
      items {
        name
        status
      }
      nextToken
    }
  }
`;
const ListShowerBookingByStatusAndDate = gql`
  query ListShowerBookingByStatusAndDate($status: String!, $date: AWSDate!, $airport_id: String!, $lounge_id: String!, $limit: Int, $nextToken: String) {
    listShowerBookingByStatusAndDate(status: $status, date: $date, airport_id: $airport_id, lounge_id: $lounge_id, limit: $limit, nextToken: $nextToken) {
      items {
        device_id
        status
        timestamp
        counter
        is_handicap
        shower_room
        memo
        use_app
        status_history {
          status
          timestamp
        }
        airport_id
        lounge_id
        table_id
        email_address
        pnr
        carrier
        flight_number
        date_of_flight
        seat_number 
        is_left_room
        is_cleaning_room
      }
      nextToken
    }
  }
`;
const ListShowerBookingByStatus = gql`
  query ListShowerBookingByStatus($status: String!, $airport_id: String!, $lounge_id: String!, $limit: Int, $nextToken: String) {
    listShowerBookingByStatus(status: $status, airport_id: $airport_id, lounge_id: $lounge_id, limit: $limit, nextToken: $nextToken) {
      items {
        device_id
        status
        timestamp
        is_handicap
        counter
        memo
        shower_room
        use_app
        status_history {
          status
          timestamp
        }
        airport_id
        lounge_id
        table_id
        email_address
        pnr
        carrier
        flight_number
        date_of_flight
        seat_number
        is_left_room
        is_cleaning_room
      }
      nextToken
    }
  }
`;
const ListShowerBookingByDeviceId = gql`
  query ListShowerBookingByDeviceId($device_id: String!, $airport_id: String!, $lounge_id: String!, $limit: Int, $nextToken: String) {
    listShowerBookingByDeviceId(device_id: $device_id, airport_id: $airport_id, lounge_id: $lounge_id, limit: $limit, nextToken: $nextToken) {
      items {
        device_id
        status
        timestamp
        counter
        is_handicap
        shower_room
        memo
        use_app
        status_history {
          status
          timestamp
        }
        airport_id
        lounge_id
        table_id
        email_address
        pnr
        carrier
        flight_number
        date_of_flight
        seat_number
        is_left_room
        is_cleaning_room
      }
      nextToken
    }
  }
`;
export const queries = {
  ListShowerRooms,
  ListShowerBookingByStatusAndDate,
  ListShowerBookingByStatus,
  ListShowerBookingByDeviceId,
};
//#endregion

//#region MUTATIONS
const NotifyShowerBooking = gql`
  mutation NotifyShowerBooking($input: NotifyShowerBookingInput!) {
    notifyShowerBooking(input: $input) {
      device_id
      status
      timestamp
      counter
      is_handicap
      shower_room
      use_app
      status_history {
        status
        timestamp
      }
      airport_id
      lounge_id
      table_id
      email_address
      pnr
      carrier
      flight_number
      date_of_flight
      seat_number
      locale
    }
  }
`;
const CreateShowerBooking = gql`
  mutation CreateShowerBooking($input: CreateShowerBookingInput!) {
    createShowerBooking(input: $input) {
      device_id
      status
      timestamp
      counter
      is_handicap
      memo
      shower_room
      use_app
      status_history {
        status
        timestamp
      }
      airport_id
      lounge_id
      table_id
      email_address
      pnr
      carrier
      flight_number
      date_of_flight
      seat_number 
      locale
    }
  }
`;
const CallShowerBooking = gql`
  mutation CallShowerBooking($input: CallShowerBookingInput!) {
    callShowerBooking(input: $input) {
      device_id
      status
      timestamp
      counter
      is_handicap
      shower_room
      memo
      use_app
      status_history {
        status
        timestamp
      }
      airport_id
      lounge_id
      table_id
      email_address
      pnr
      carrier
      flight_number
      date_of_flight
      seat_number 
      locale
    }
  }
`;
const StartShowerBooking = gql`
  mutation StartShowerBooking($input: StartShowerBookingInput!) {
    startShowerBooking(input: $input) {
      device_id
      status
      timestamp
      counter
      is_handicap
      memo
      shower_room
      use_app
      status_history {
        status
        timestamp
      }
      airport_id
      lounge_id
      table_id
      email_address
      pnr
      carrier
      flight_number
      date_of_flight
      seat_number 
      locale
    }
  }
`;
const TimeoutShowerBooking = gql`
  mutation TimeoutShowerBooking($input: TimeoutShowerBookingInput!) {
    timeoutShowerBooking(input: $input) {
      device_id
      status
      timestamp
      counter
      is_handicap
      memo
      shower_room
      use_app
      status_history {
        status
        timestamp
      }
      airport_id
      lounge_id
      table_id
      email_address
      pnr
      carrier
      flight_number
      date_of_flight
      seat_number 
      locale
    }
  }
`;
const CancelShowerBooking = gql`
  mutation CancelShowerBooking($input: CancelShowerBookingInput!) {
    cancelShowerBooking(input: $input) {
      device_id
      status
      timestamp
      memo
      is_handicap
      counter
      shower_room
      use_app
      status_history {
        status
        timestamp
      }
      airport_id
      lounge_id
      table_id
      email_address
      pnr
      carrier
      flight_number
      date_of_flight
      seat_number 
      locale
    }
  }
`;
const CompleteShowerBooking = gql`
  mutation CompleteShowerBooking($input: CompleteShowerBookingInput!) {
    completeShowerBooking(input: $input) {
      device_id
      status
      timestamp
      counter
      is_handicap
      memo
      shower_room
      use_app
      status_history {
        status
        timestamp
      }
      airport_id
      lounge_id
      table_id
      email_address
      pnr
      carrier
      flight_number
      date_of_flight
      seat_number 
      locale
    }
  }
`;
const UpdateShowerBooking = gql`
  mutation UpdateShowerBooking($input: UpdateShowerBookingInput!) {
    updateShowerBooking(input: $input) {
      device_id
      status
      timestamp
      counter
      is_handicap
      memo
      shower_room
      use_app
      status_history {
        status
        timestamp
      }
      airport_id
      lounge_id
      table_id
      email_address
      pnr
      carrier
      flight_number
      date_of_flight
      seat_number 
      locale
      is_left_room
      is_cleaning_room
    }
  }
`;
export const mutations = {
  CreateShowerBooking,
  CallShowerBooking,
  StartShowerBooking,
  TimeoutShowerBooking,
  CancelShowerBooking,
  CompleteShowerBooking,
  NotifyShowerBooking,
  UpdateShowerBooking,
};
//#endregion

//#region SUBSCRIPTIONS
const OnCallShowerBooking = gql`
  subscription OnCallShowerBooking {
    onCallShowerBooking {
      device_id
      status
      timestamp
      counter
      shower_room
      use_app
      status_history {
        status
        timestamp
      }
      airport_id
      lounge_id
      table_id
      email_address
      pnr
      carrier
      flight_number
      date_of_flight
      seat_number 
      locale
      is_left_room
      is_cleaning_room
    }
  }
`;
const OnCancelShowerBooking = gql`
  subscription OnCancelShowerBooking {
    onCancelShowerBooking {
      device_id
      status
      timestamp
      counter
      shower_room
      use_app
      status_history {
        status
        timestamp
      }
      airport_id
      lounge_id
      table_id
      email_address
      pnr
      carrier
      flight_number
      date_of_flight
      seat_number 
      locale
      is_left_room
      is_cleaning_room
    }
  }
`;
const OnCompleteShowerBooking = gql`
  subscription OnCompleteShowerBooking {
    onCompleteShowerBooking {
      device_id
      status
      timestamp
      counter
      shower_room
      use_app
      status_history {
        status
        timestamp
      }
      airport_id
      lounge_id
      table_id
      email_address
      pnr
      carrier
      flight_number
      date_of_flight
      seat_number 
      locale
      is_left_room
      is_cleaning_room
    }
  }
`;
const OnCreateShowerBooking = gql`
  subscription OnCreateShowerBooking {
    onCreateShowerBooking {
      device_id
      status
      timestamp
      counter
      shower_room
      use_app
      status_history {
        status
        timestamp
      }
      airport_id
      lounge_id
      table_id
      email_address
      memo
      pnr
      carrier
      flight_number
      date_of_flight
      seat_number 
      locale
      is_left_room
      is_cleaning_room
    }
  }
`;
const OnStartShowerBooking = gql`
  subscription OnStartShowerBooking {
    onStartShowerBooking {
      device_id
      status
      timestamp
      counter
      shower_room
      use_app
      status_history {
        status
        timestamp
      }
      airport_id
      lounge_id
      table_id
      email_address
      pnr
      carrier
      flight_number
      date_of_flight
      seat_number 
      locale
      is_left_room
      is_cleaning_room
    }
  }
`;
const OnNotifyShowerBooking = gql`
  subscription OnNotifyShowerBooking {
    onNotifyShowerBooking {
      device_id
      status
      timestamp
      counter
      shower_room
      use_app
      status_history {
        status
        timestamp
      }
      airport_id
      lounge_id
      table_id
      email_address
      pnr
      carrier
      flight_number
      date_of_flight
      seat_number 
      locale
      is_left_room
      is_cleaning_room
    }
  }
`;
const OnTimeoutShowerBooking = gql`
  subscription OnTimeoutShowerBooking {
    onTimeoutShowerBooking {
      device_id
      status
      timestamp
      counter
      shower_room
      use_app
      status_history {
        status
        timestamp
      }
      airport_id
      lounge_id
      table_id
      email_address
      pnr
      carrier
      flight_number
      date_of_flight
      seat_number 
      locale
      is_left_room
      is_cleaning_room
    }
  }
`;
const OnUpdateShowerBooking = gql`
subscription OnUpdateShowerBooking {
  onUpdateShowerBooking {
    device_id
    status
    timestamp
    counter
    shower_room
    use_app
    status_history {
      status
      timestamp
    }
    airport_id
    lounge_id
    table_id
    email_address
    pnr
    carrier
    flight_number
    date_of_flight
    seat_number 
    locale
    is_left_room
    is_cleaning_room
  }
}
`;
export const subscription = {
  OnCallShowerBooking,
  OnCancelShowerBooking,
  OnCompleteShowerBooking,
  OnCreateShowerBooking,
  OnStartShowerBooking,
  OnNotifyShowerBooking,
  OnTimeoutShowerBooking,
  OnUpdateShowerBooking,
};

//#endregion
