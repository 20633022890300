//#region IMPORTS
// LOCAL CONFIG
import {
  DevicePlatformTypes,
  PUSH_NOTIFICATION,
  RegisterPinpointOptions,
  User,
  USER,
  UserActionTypes,
  GET_AREA_USERS_CLEAR,
  GET_AREA_USERS_FAILED,
  GET_AREA_USERS_FETCH,
  GET_AREA_USERS_SUCCESS,
  GET_USER_LOCATION_CLEAR,
  GET_USER_LOCATION_FAILED,
  GET_USER_LOCATION_FETCH,
  GET_USER_LOCATION_SUCCESS,
  GetAreaUsersResponse,
  GetUserLocationResponse,
  SATO_AUTH_CLEAR,
  SATO_AUTH_FAILED,
  SATO_AUTH_FETCH,
  SATO_AUTH_SUCCESS,
  SatoAreaInfo,
  SatoAuthFailType,
  SatoAuthParam,
  SatoAuthResponse,
  SatoFailureType,
  SatoUserInfo,
  SatoTokens,
  FailureType,
} from './constants';

//#endregion

//#region ACTION TYPES

//#region ACTION: USER
export const GetUser = (): UserActionTypes => ({
  type: USER.GET_USER,
});

export const SetUser = (payload: User): UserActionTypes => ({
  type: USER.SET_USER,
  payload,
});

export const LoadingUser = (payload: boolean): UserActionTypes => ({
  type: USER.LOADING_USER,
  payload,
});

//#endregion

//#region ACTION: AUTH
export const Login = (username: string, password: string): UserActionTypes => ({
  type: USER.LOGIN,
  payload: {
    username,
    password,
  },
});

export const LoadingLogin = (payload: boolean): UserActionTypes => ({
  type: USER.LOADING_LOGIN,
  payload,
});

export const Logout = (): UserActionTypes => ({
  type: USER.LOGOUT,
});

export const LogoutSuccess = (): UserActionTypes => ({
  type: USER.LOGOUT_SUCCESS,
});
//#endregion

//#region ACTION: DEVICE TOKEN
export const SetDeviceToken = (payload: string): UserActionTypes => ({
  type: USER.SET_DEVICE_TOKEN,
  payload,
});

export const SetSatoToken = (payload: SatoTokens): UserActionTypes => ({
  type: USER.SET_SATO_TOKEN,
  payload,
});

//#endregion

//#region ACTION: PUSH NOTIFICATIONS
export const PushNotificationRegisterFetch = (payload: RegisterPinpointOptions): UserActionTypes => ({
  type: PUSH_NOTIFICATION.REGISTER.PUSH_NOTIFICATION_REGISTER_FETCH,
  payload,
});

export const PushNotificationRegisterSuccess = (payload: string): UserActionTypes => ({
  type: PUSH_NOTIFICATION.REGISTER.PUSH_NOTIFICATION_REGISTER_SUCCESS,
  payload,
});

export const PushNotificationRegisterFailed = (payload: FailureType): UserActionTypes => ({
  type: PUSH_NOTIFICATION.REGISTER.PUSH_NOTIFICATION_REGISTER_FAILED,
  payload,
});

export const PushNotificationRegisterClear = (): UserActionTypes => ({
  type: PUSH_NOTIFICATION.REGISTER.PUSH_NOTIFICATION_REGISTER_CLEAR,
});

//#endregion

//#region ACTION: DEVICE PLATFORM
export const GetDevicePlatform = (): UserActionTypes => ({
  type: USER.GET_DEVICE_PLATFORM,
});

export const SetDevicePlatform = (payload: DevicePlatformTypes): UserActionTypes => ({
  type: USER.SET_DEVICE_PLATFORM,
  payload,
});

//#endregion

//#region ACTION: SATO AREA
export const GetAreaUsersFetch = (payload: SatoUserInfo): UserActionTypes => ({
  type: GET_AREA_USERS_FETCH,
  payload,
});

export const GetAreaUsersSuccess = (payload: GetAreaUsersResponse): UserActionTypes => ({
  type: GET_AREA_USERS_SUCCESS,
  payload,
});

export const GetAreaUsersFailed = (payload: SatoFailureType | FailureType): UserActionTypes => ({
  type: GET_AREA_USERS_FAILED,
  payload,
});

export const GetAreaUsersClear = (): UserActionTypes => ({
  type: GET_AREA_USERS_CLEAR,
});

//#endregion

//#region ACTION: SATO LOCATION
export const GetUserLocationFetch = (payload: SatoAreaInfo): UserActionTypes => ({
  type: GET_USER_LOCATION_FETCH,
  payload,
});

export const GetUserLocationSuccess = (payload: GetUserLocationResponse): UserActionTypes => ({
  type: GET_USER_LOCATION_SUCCESS,
  payload,
});

export const GetUserLocationFailed = (payload: SatoFailureType | FailureType): UserActionTypes => ({
  type: GET_USER_LOCATION_FAILED,
  payload,
});

export const GetUserLocationClear = (): UserActionTypes => ({
  type: GET_USER_LOCATION_CLEAR,
});

//#endregion

//#region ACTION: SATO AUTH
export const SatoAuthFetch = (payload: SatoAuthParam): UserActionTypes => ({
  type: SATO_AUTH_FETCH,
  payload,
});

export const SatoAuthSuccess = (payload: SatoAuthResponse): UserActionTypes => ({
  type: SATO_AUTH_SUCCESS,
  payload,
});

export const SatoAuthFailed = (payload: SatoAuthFailType): UserActionTypes => ({
  type: SATO_AUTH_FAILED,
  payload,
});

export const SatoAuthClear = (): UserActionTypes => ({
  type: SATO_AUTH_CLEAR,
});

//#endregion

//#endregion
