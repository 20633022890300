//#region IMPORT
// PACKAGE IMPORT
import { connect } from 'react-redux';

// LOCAL CONFIG
import Congestion, { DispatchProps, StateProps } from './Congestion';
import {
  congestionListFetch,
  CongestionSubscriptionSuccess,
  CongestionSubscriptionFailed,
} from '../../ActionCongestion';
import { JalAppState } from '../../../../config/interface';
import { AreaList } from '../../constants';
//#endregion

//#region REDUX BINDING
const mapStateToProps = (state: JalAppState): StateProps => ({
  data: state.congestion.congestionListResponse ? (state.congestion.congestionListResponse as AreaList).items : [],
  airport_id: state.user.user.airportId
});

const mapDispatchToProps: DispatchProps = {
  congestionListFetch,
  CongestionSubscriptionSuccess,
  CongestionSubscriptionFailed,
};
//#endregion

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Congestion);
