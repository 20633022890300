//#region IMPORTS
import {
  CONSOLE_LOG,
  TOGGLE_MUTED_ACTION,
  GET_DEVICE_TOKEN_FETCH,
  GET_DEVICE_TOKEN_RECEIVED,
  NEW_DEVICE_TOKEN,
  RECEIVED_ACTION_FROM_NATIVE,
  RECEIVED_ACTION_FROM_NATIVE_CLEAR,
  RECEIVED_ACTION_FROM_NATIVE_ERROR,
  RECEIVED_ACTION_FROM_NATIVE_ERROR_CLEAR,
  SEND_ACTION_TO_NATIVE,
  SEND_ACTION_TO_NATIVE_CLEAR,
  SEND_ACTION_TO_NATIVE_ERROR,
  SEND_ACTION_TO_NATIVE_ERROR_CLEAR,
  UNKNOWN_ACTION,
  WrapperActionTypes,
} from './constants';
//#endregion

//#region ACTION CREATORS

//#region XAMARIN COMMUNICATION

// Send Action To Native
export const SendActionToNative = (payload: string): WrapperActionTypes => ({
  type: SEND_ACTION_TO_NATIVE,
  payload,
});

export const SendActionToNativeClear = (): WrapperActionTypes => ({
  type: SEND_ACTION_TO_NATIVE_CLEAR,
});

export const SendActionToNativeError = (payload: string): WrapperActionTypes => ({
  type: SEND_ACTION_TO_NATIVE_ERROR,
  payload,
});

export const SendActionToNativeErrorClear = (): WrapperActionTypes => ({
  type: SEND_ACTION_TO_NATIVE_ERROR_CLEAR,
});

// Received Action from Native
export const ReceivedActionFromNative = (payload: string): WrapperActionTypes => ({
  type: RECEIVED_ACTION_FROM_NATIVE,
  payload,
});

export const ReceiveActionFromNativeClear = (): WrapperActionTypes => ({
  type: RECEIVED_ACTION_FROM_NATIVE_CLEAR,
});

export const ReceiveActionFromNativeError = (payload: string): WrapperActionTypes => ({
  type: RECEIVED_ACTION_FROM_NATIVE_ERROR,
  payload,
});

export const ReceiveActionFromNativeErrorClear = (): WrapperActionTypes => ({
  type: RECEIVED_ACTION_FROM_NATIVE_ERROR_CLEAR,
});

// Device token
export const GetDeviceTokenFetch = (): WrapperActionTypes => ({
  type: GET_DEVICE_TOKEN_FETCH,
});

export const GetDeviceTokenReceived = (payload: string): WrapperActionTypes => ({
  type: GET_DEVICE_TOKEN_RECEIVED,
  payload,
});

export const NewDeviceToken = (payload: string): WrapperActionTypes => ({
  type: NEW_DEVICE_TOKEN,
  payload,
});

export const UnknownAction = (): WrapperActionTypes => ({
  type: UNKNOWN_ACTION,
});
//#endregion

//#region MISC

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ConsoleLog = (payload: any): WrapperActionTypes => ({
  type: CONSOLE_LOG,
  payload,
});

export const ToggleMutedAction = (payload: boolean): WrapperActionTypes => ({
  type: TOGGLE_MUTED_ACTION,
  payload,
});

//#endregion

//#endregion
