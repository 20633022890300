//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

// LOCAL CONFIG
import { StaffCallItem, StaffCallMutationTypes, StaffCallStatusTypes } from '../../constants';

// LOCAL COMPONENT
import { Button } from '../../../../components';
import './StaffCallAction.scss';
//#endregion

//#region INTERFACE
interface OwnProps extends WrappedComponentProps {
  data: StaffCallItem;
  onClickActionButton(data: StaffCallItem, updateType: StaffCallMutationTypes): void;
  onClickDetail?(data: StaffCallItem): void;
  showDetail?: boolean;
  hideAction?: boolean;
}

interface OwnState {
  answerLoading: boolean;
}

type Props = OwnProps;
//#endregion

//#region COMPONENT
class StaffCallAction extends React.Component<Props, OwnState> {
  //#region CTOR & LIFECYCLES
  constructor(props: Props) {
    super(props);

    this.state = {
      answerLoading: false,
    };

    this.handleClickAnswer = this.handleClickAnswer.bind(this);
    this.handleClickDetail = this.handleClickDetail.bind(this);
  }
  //#endregion

  //#region CLASS METHODS
  handleClickAnswer(): void {
    const { onClickActionButton, data } = this.props;
    this.setState({ answerLoading: true }, () => onClickActionButton(data, StaffCallMutationTypes.ANSWER));
  }

  handleClickDetail(): void {
    const { onClickDetail, data } = this.props;
    if (typeof onClickDetail === 'function') onClickDetail(data);
  }

  //#endregion

  //#region RENDER
  renderActionGroup(): JSX.Element {
    const { data } = this.props;

    switch (data.status) {
      case StaffCallStatusTypes.CALLING:
        return (
          <div className="staff-call-action__group">
            <div className="staff-call-action__answer">
              <Button
                type="button"
                color="primary"
                label="staffCallAnswer"
                showSpinner={this.state.answerLoading}
                disabled={this.state.answerLoading}
                onClick={this.handleClickAnswer}
              />
            </div>
          </div>
        );
      default:
        return <></>;
    }
  }

  render(): React.ReactNode {
    const { showDetail, hideAction } = this.props;

    return (
      <div className="staff-call-action">
        {!hideAction && this.renderActionGroup()}
        {showDetail && (
          <div className="staff-call-action__group">
            <Button type="button" className="show-detail" label="buttonShowDetail" onClick={this.handleClickDetail} />
          </div>
        )}
      </div>
    );
  }
  //#endregion
}
//#endregion

export default injectIntl(StaffCallAction);
