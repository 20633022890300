//#region IMPORTS

import {
  ON_STAFF_CALL_SUBSCRIPTION,
  STAFF_CALL_CREATE_CLEAR,
  STAFF_CALL_CREATE_FAILED,
  STAFF_CALL_CREATE_FETCH,
  STAFF_CALL_CREATE_SUCCESS,
  STAFF_CALL_ITEM_CLEAR,
  STAFF_CALL_ITEM_FAILED,
  STAFF_CALL_ITEM_FETCH,
  STAFF_CALL_ITEM_SUCCESS,
  STAFF_CALL_SUBSCRIPTION_CREATE_CLEAR,
  STAFF_CALL_SUBSCRIPTION_CREATE_FAILED,
  STAFF_CALL_SUBSCRIPTION_CREATE_FETCH,
  STAFF_CALL_SUBSCRIPTION_CREATE_SUCCESS,
  STAFF_CALL_UPDATE_CLEAR,
  STAFF_CALL_UPDATE_FAILED,
  STAFF_CALL_UPDATE_FETCH,
  STAFF_CALL_UPDATE_SUCCESS,
  StaffCallActions,
  StaffCallCreateFailed,
  StaffCallCreateFetch,
  StaffCallCreateParam,
  StaffCallCreateResponse,
  StaffCallCreateSuccess,
  StaffCallItemFailed,
  StaffCallItemFetch,
  StaffCallItemParam,
  StaffCallItemResponse,
  StaffCallItemSuccess,
  StaffCallSubscriptionCreateFailed,
  StaffCallUpdateFailed,
  StaffCallUpdateFetch,
  StaffCallUpdateParam,
  StaffCallUpdateResponse,
  StaffCallUpdateSuccess,
  STAFF_CALL_LIST_FETCH,
  STAFF_CALL_LIST_SUCCESS,
  STAFF_CALL_LIST_FAILED,
  STAFF_CALL_LIST_CLEAR,
  StaffCallListParam,
  StaffCallMultiStatusResponse,
  StaffCallListFetch,
  StaffCallListSuccess,
  StaffCallListFailed,
  OnCreateUpdateStaffCallPayload,
  SubscriptionStatus,
  FailureType,
} from './constants';
import { RESET } from '../../config/constant';

//#endregion

//#region INTERFACE
interface StaffCallListInitialState {
  staffCallListLoading: boolean;
  staffCallListParam?: StaffCallListParam;
  staffCallListResponse?: StaffCallMultiStatusResponse;
  staffCallListError?: FailureType;
}

interface StaffCallItemInitialState {
  staffCallItemLoading: boolean;
  staffCallItemParam?: StaffCallItemParam;
  staffCallItemResponse?: StaffCallItemResponse;
  staffCallItemError?: FailureType;
}

interface StaffCallCreateInitialState {
  staffCallCreateLoading: boolean;
  staffCallCreateParam?: StaffCallCreateParam;
  staffCallCreateResponse?: StaffCallCreateResponse;
  staffCallCreateError?: FailureType;
}

interface StaffCallUpdateInitialState {
  staffCallUpdateLoading: boolean;
  staffCallUpdateParam?: StaffCallUpdateParam;
  staffCallUpdateResponse?: StaffCallUpdateResponse;
  staffCallUpdateError?: FailureType;
}

export interface InitialState
  extends StaffCallListInitialState,
    StaffCallItemInitialState,
    StaffCallCreateInitialState,
    StaffCallUpdateInitialState {
  staffCallSubscriptionStatus: SubscriptionStatus;
  staffCallSubscriptionError?: FailureType;
  onCreateUpdateStaffCallParam?: OnCreateUpdateStaffCallPayload;

  action: string;
}

//#endregion

//#region INITIAL STATES

const staffCallListInitialState: StaffCallListInitialState = {
  staffCallListLoading: false,
  staffCallListParam: undefined,
  staffCallListResponse: undefined,
  staffCallListError: undefined,
};

const staffCallItemInitialState: StaffCallItemInitialState = {
  staffCallItemLoading: false,
  staffCallItemParam: undefined,
  staffCallItemResponse: undefined,
  staffCallItemError: undefined,
};

const staffCallCreateInitialState: StaffCallCreateInitialState = {
  staffCallCreateLoading: false,
  staffCallCreateParam: undefined,
  staffCallCreateResponse: undefined,
  staffCallCreateError: undefined,
};

const staffCallUpdateInitialState: StaffCallUpdateInitialState = {
  staffCallUpdateLoading: false,
  staffCallUpdateParam: undefined,
  staffCallUpdateResponse: undefined,
  staffCallUpdateError: undefined,
};

const initialState: InitialState = {
  ...staffCallListInitialState,
  ...staffCallItemInitialState,
  ...staffCallCreateInitialState,
  ...staffCallUpdateInitialState,
  staffCallSubscriptionStatus: SubscriptionStatus.UNREGISTERED,
  staffCallSubscriptionError: undefined,
  action: '',
};

//#endregion

//#region REDUCER

function staffCallReducer(state = initialState, action: StaffCallActions): InitialState {
  const actions = {
    // Staff Call List
    [STAFF_CALL_LIST_FETCH]: (): InitialState => ({
      ...state,
      action: action.type,
      staffCallListLoading: true,
      staffCallListParam: (action as StaffCallListFetch).payload,
    }),
    [STAFF_CALL_LIST_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
      staffCallListLoading: false,
      staffCallListResponse: (action as StaffCallListSuccess).payload,
    }),
    [STAFF_CALL_LIST_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
      staffCallListLoading: false,
      staffCallListError: (action as StaffCallListFailed).payload,
    }),
    [STAFF_CALL_LIST_CLEAR]: (): InitialState => ({
      ...state,
      ...staffCallListInitialState,
      action: action.type,
    }),
    // Staff Call Item
    [STAFF_CALL_ITEM_FETCH]: (): InitialState => ({
      ...state,
      action: action.type,
      staffCallItemLoading: true,
      staffCallItemParam: (action as StaffCallItemFetch).payload,
    }),
    [STAFF_CALL_ITEM_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
      staffCallItemLoading: false,
      staffCallItemResponse: (action as StaffCallItemSuccess).payload,
    }),
    [STAFF_CALL_ITEM_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
      staffCallItemLoading: false,
      staffCallItemError: (action as StaffCallItemFailed).payload,
    }),
    [STAFF_CALL_ITEM_CLEAR]: (): InitialState => ({
      ...state,
      ...staffCallItemInitialState,
      action: action.type,
    }),
    // Staff Call Create
    [STAFF_CALL_CREATE_FETCH]: (): InitialState => ({
      ...state,
      action: action.type,
      staffCallCreateLoading: true,
      staffCallCreateParam: (action as StaffCallCreateFetch).payload,
    }),
    [STAFF_CALL_CREATE_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
      staffCallCreateLoading: false,
      staffCallCreateResponse: (action as StaffCallCreateSuccess).payload,
    }),
    [STAFF_CALL_CREATE_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
      staffCallCreateLoading: false,
      staffCallCreateError: (action as StaffCallCreateFailed).payload,
    }),
    [STAFF_CALL_CREATE_CLEAR]: (): InitialState => ({
      ...state,
      ...staffCallCreateInitialState,
      action: action.type,
    }),
    [STAFF_CALL_UPDATE_FETCH]: (): InitialState => ({
      ...state,
      action: action.type,
      staffCallUpdateLoading: true,
      staffCallUpdateParam: (action as StaffCallUpdateFetch).payload,
    }),
    [STAFF_CALL_UPDATE_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
      staffCallUpdateLoading: false,
      staffCallUpdateResponse: (action as StaffCallUpdateSuccess).payload,
    }),
    [STAFF_CALL_UPDATE_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
      staffCallUpdateLoading: false,
      staffCallUpdateError: (action as StaffCallUpdateFailed).payload,
    }),
    [STAFF_CALL_UPDATE_CLEAR]: (): InitialState => ({
      ...state,
      ...staffCallUpdateInitialState,
      action: action.type,
    }),
    [STAFF_CALL_SUBSCRIPTION_CREATE_FETCH]: (): InitialState => ({
      ...state,
      action: action.type,
      staffCallSubscriptionStatus: SubscriptionStatus.REGISTERING,
    }),
    [STAFF_CALL_SUBSCRIPTION_CREATE_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
      staffCallSubscriptionStatus: SubscriptionStatus.REGISTERED,
    }),
    [STAFF_CALL_SUBSCRIPTION_CREATE_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
      staffCallSubscriptionStatus: SubscriptionStatus.REGISTRATION_FAILURE,
      staffCallSubscriptionError: (action as StaffCallSubscriptionCreateFailed).payload,
    }),
    [STAFF_CALL_SUBSCRIPTION_CREATE_CLEAR]: (): InitialState => ({
      ...state,
      action: action.type,
      staffCallSubscriptionStatus: SubscriptionStatus.UNREGISTERED,
    }),
    [ON_STAFF_CALL_SUBSCRIPTION]: (): InitialState => ({
      ...state,
      action: action.type,
    }),
    [RESET.ALL]: (): InitialState => ({
      ...initialState,
      action: action.type,
    }),
    DEFAULT: (): InitialState => state,
  };

  return (actions[action.type] || actions.DEFAULT)();
}

export default staffCallReducer;

//#endregion
