//#region ACTION NAMES

// Meal Order List
export const MEAL_ORDER_LIST_FETCH = 'MEAL_ORDER_LIST_FETCH';
export const MEAL_ORDER_LIST_SUCCESS = 'MEAL_ORDER_LIST_SUCCESS';
export const MEAL_ORDER_LIST_FAILED = 'MEAL_ORDER_LIST_FAILED';
export const MEAL_ORDER_LIST_CLEAR = 'MEAL_ORDER_LIST_CLEAR';

// Meal Order Create
export const MEAL_ORDER_CREATE_FETCH = 'MEAL_ORDER_CREATE_FETCH';
export const MEAL_ORDER_CREATE_SUCCESS = 'MEAL_ORDER_CREATE_SUCCESS';
export const MEAL_ORDER_CREATE_FAILED = 'MEAL_ORDER_CREATE_FAILED';
export const MEAL_ORDER_CREATE_CLEAR = 'MEAL_ORDER_CREATE_CLEAR';

// Meal Order Update
export const MEAL_ORDER_UPDATE_FETCH = 'MEAL_ORDER_UPDATE_FETCH';
export const MEAL_ORDER_UPDATE_SUCCESS = 'MEAL_ORDER_UPDATE_SUCCESS';
export const MEAL_ORDER_UPDATE_FAILED = 'MEAL_ORDER_UPDATE_FAILED';
export const MEAL_ORDER_UPDATE_CLEAR = 'MEAL_ORDER_UPDATE_CLEAR';

// Meal Order Update Items
export const MEAL_ORDER_UPDATE_ITEMS_FETCH = 'MEAL_ORDER_UPDATE_ITEMS_FETCH';
export const MEAL_ORDER_UPDATE_ITEMS_SUCCESS = 'MEAL_ORDER_UPDATE_ITEMS_SUCCESS';
export const MEAL_ORDER_UPDATE_ITEMS_FAILED = 'MEAL_ORDER_UPDATE_ITEMS_FAILED';
export const MEAL_ORDER_UPDATE_ITEMS_CLEAR = 'MEAL_ORDER_UPDATE_ITEMS_CLEAR';

// Meal Order Subscription
export const MEAL_ORDER_SUBSCRIPTION_CREATE_FETCH = 'MEAL_ORDER_SUBSCRIPTION_CREATE_FETCH';
export const MEAL_ORDER_SUBSCRIPTION_CREATE_SUCCESS = 'MEAL_ORDER_SUBSCRIPTION_CREATE_SUCCESS';
export const MEAL_ORDER_SUBSCRIPTION_CREATE_FAILED = 'MEAL_ORDER_SUBSCRIPTION_CREATE_FAILED';
export const MEAL_ORDER_SUBSCRIPTION_REMOVE_FETCH = 'MEAL_ORDER_SUBSCRIPTION_REMOVE_SUCCESS';
export const MEAL_ORDER_SUBSCRIPTION_REMOVE_SUCCESS = 'MEAL_ORDER_SUBSCRIPTION_REMOVE_SUCCESS';
export const MEAL_ORDER_SUBSCRIPTION_REMOVE_FAILED = 'MEAL_ORDER_SUBSCRIPTION_REMOVE_FAILED';
export const ON_CREATE_UPDATE_MEAL_ORDER = 'ON_CREATE_UPDATE_MEAL_ORDER';
export const ON_CREATE_UPDATE_MEAL_ORDER_SUCCESS = 'ON_CREATE_UPDATE_MEAL_ORDER_SUCCESS';
export const ON_CREATE_UPDATE_MEAL_ORDER_FAILED = 'ON_CREATE_UPDATE_MEAL_ORDER_FAILED';

// Menu Data List
export const MENU_LIST_FETCH = 'MENU_LIST_FETCH';
export const MENU_LIST_SUCCESS = 'MENU_LIST_SUCCESS';
export const MENU_LIST_FAILED = 'MENU_LIST_FAILED';
export const MENU_LIST_CLEAR = 'MENU_LIST_CLEAR';
export const DELETE_MENU_LIST_TOKEN = 'DELETE_MENU_LIST_TOKEN';

// Oters
export const SET_LATEST_ORDER_COUNTER = 'SET_LATEST_ORDER_COUNTER';
export const TOGGLE_SORTED_ACTION = 'TOGGLE_SORTED_ACTION';
//#endregion
