//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';

// LOCAL COMPONENT
import './ColumnBody.scss';

//#endregion

//#region INTERFACE
interface OwnProps {
  children: React.ReactNode;
  isActive: boolean;
}
//#endregion

//#region COMPONENT
function ColumnBody({ children, isActive }: OwnProps): React.ReactElement {
  return <div className={`column-content ${isActive ? 'column-content--active' : ''}`}>{children}</div>;
}
//#endregion

export default ColumnBody;
