//#region IMPORTS

// PACKAGE IMPORTS
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

// LOCAL CONFIGS
import {
  ReceiveActionFromNativeError,
  SendActionToNativeError,
  UnknownAction,
  SendActionToNative as SendActionToNativeAction,
} from './ActionWrapper';
import {
  GET_DEVICE_TOKEN_FETCH,
  GET_DEVICE_TOKEN_RECEIVED,
  GET_PLATFORM_RESPONSE,
  GetDeviceTokenFetch,
  NativeAction,
  NEW_DEVICE_TOKEN,
  RECEIVED_ACTION_FROM_NATIVE,
  ReceivedActionFromNative,
  REGISTER_ENDPOINTS_RESPONSE,
  UNREGISTER_ENDPOINTS,
  SEND_ACTION_TO_NATIVE,
  SendActionToNative,
  GET_USER_LOCATION_RESPONSE,
} from './constants';
import {
  PushNotificationRegisterFailed,
  PushNotificationRegisterSuccess,
  PushNotificationRegisterClear,
  SetDevicePlatform,
  SetDeviceToken,
  GetUserLocationSuccess,
} from '../user/ActionUser';
import { DevicePlatformTypes, GetUserLocationResponse } from '../user/constants';

//#endregion

//#region SAGA WORKERS

//#region XAMARIN
function* workerSagaSendToNative(action: SendActionToNative): SagaIterator {
  try {
    yield call(window.sendToNative, action.payload);
  } catch (e) {
    yield put(SendActionToNativeError(e.message));
  }
}

function* workerSageReceiveFromNative(action: ReceivedActionFromNative): SagaIterator {
  try {
    const nativeAction: NativeAction = JSON.parse(action.payload);
    // Mini reducer for xamarin actions.
    switch (nativeAction.type) {
      case GET_DEVICE_TOKEN_RECEIVED:
        yield put(SetDeviceToken(nativeAction.payload));
        break;
      case NEW_DEVICE_TOKEN:
        yield put(SetDeviceToken(nativeAction.payload));
        break;
      case REGISTER_ENDPOINTS_RESPONSE:
        if (nativeAction.payload === 'Accepted') {
          yield put(PushNotificationRegisterSuccess(nativeAction.payload));
        } else {
          yield put(PushNotificationRegisterFailed(nativeAction.payload));
        }
        break;
      case GET_PLATFORM_RESPONSE:
        yield put(SetDevicePlatform(nativeAction.payload as DevicePlatformTypes));
        break;
      case GET_USER_LOCATION_RESPONSE:
        yield put(GetUserLocationSuccess(JSON.parse(decodeURIComponent(nativeAction.payload)) as GetUserLocationResponse));
        break;
      case UNREGISTER_ENDPOINTS:
        yield put(PushNotificationRegisterClear());
        break;
      default:
        yield put(UnknownAction());
        break;
    }
  } catch (e) {
    yield put(ReceiveActionFromNativeError(e.message));
  }
}

function* workerSagaGetDeviceToken(action: GetDeviceTokenFetch): SagaIterator {
  yield put(SendActionToNativeAction(JSON.stringify(action)));
}

//#endregion

//#region SUBSCRIPTIONS

//#endregion

//#endregion

const wrapperWatcher = [
  takeLatest(GET_DEVICE_TOKEN_FETCH, workerSagaGetDeviceToken),
  takeEvery(SEND_ACTION_TO_NATIVE, workerSagaSendToNative),
  takeEvery(RECEIVED_ACTION_FROM_NATIVE, workerSageReceiveFromNative),
];

export default wrapperWatcher;
