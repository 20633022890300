/* eslint-disable @typescript-eslint/explicit-function-return-type */
//#region IMPORTS
// PACKAGE IMPORTS
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import API, { graphqlOperation } from '@aws-amplify/api';
import { SagaIterator } from 'redux-saga';

// LOCAL CONFIG
import {
  OnCreateUpdateShowerBookingFailed,
  OnCreateUpdateShowerBookingSuccess,
  ShowerBookingCreateFailed,
  ShowerBookingCreateSuccess,
  ShowerBookingListFailed,
  ShowerBookingListSuccess,
  ShowerBookingUpdateFailed,
  ShowerBookingUpdateSuccess,
  ShowerRoomListFailed,
  ShowerRoomListSuccess,
} from './ActionShower';
import {
  mutations,
  ON_CREATE_UPDATE_SHOWER_BOOKING,
  OnCallShowerBooking,
  OnCancelShowerBooking,
  OnCompleteShowerBooking,
  OnCreateShowerBooking,
  OnCreateUpdateShowerBooking,
  OnStartShowerBooking,
  OnNotifyShowerBooking,
  OnTimeoutShowerBooking,
  OnUpdateShowerBooking,
  queries,
  SHOWER_BOOKING_CREATE_FETCH,
  SHOWER_BOOKING_LIST_FETCH,
  SHOWER_BOOKING_UPDATE_FETCH,
  SHOWER_ROOM_LIST_FETCH,
  ShowerBooking,
  ShowerBookingByStatus,
  ShowerBookingByStatusAndDate,
  ShowerBookingCreateFetch,
  ShowerBookingCreateUpdateType,
  ShowerBookingFilterType,
  ShowerBookingList,
  ShowerBookingListByStatusParam,
  ShowerBookingListFetch,
  ShowerBookingListMultiStatusResponse,
  ShowerBookingStatus,
  ShowerBookingUpdateFetch,
  ShowerRoomListFetch,
} from './constants';
import { extractEnumValues } from '../../utils';
//#endregion

//#region SAGA WORKERS
// SHOWER_BOOKING_LIST_FETCH
function* workerSagaShowerBookingList(action: ShowerBookingListFetch) {
  try {
    const { filterType, airport_id, lounge_id, ...variables } = action.payload as ShowerBookingListByStatusParam;
    const typesArray: unknown[] =
      filterType === ShowerBookingFilterType.NONE
        ? extractEnumValues(ShowerBookingStatus)
        : extractEnumValues((action.payload as ShowerBookingListByStatusParam).statusTypes);

    let items = {};

    const { ListShowerBookingByStatus, ListShowerBookingByStatusAndDate } = queries;

    const query =
      filterType === ShowerBookingFilterType.STATUS ? ListShowerBookingByStatus : ListShowerBookingByStatusAndDate;

    for (let i = 0; i < typesArray.length; i++) {
      const status = typesArray[i];
      const response = yield API.graphql(graphqlOperation(query, ({ ...variables, status, airport_id, lounge_id} as unknown)as typeof variables));


      const newItems =
        filterType === ShowerBookingFilterType.STATUS
          ? (response.data as ShowerBookingByStatus).listShowerBookingByStatus.items
          : (response.data as ShowerBookingByStatusAndDate).listShowerBookingByStatusAndDate.items;

      items = {
        ...items,
        [status as ShowerBookingStatus]: newItems,
      };
    }

    const response: ShowerBookingList | ShowerBookingListMultiStatusResponse = {
      items,
      nextToken: '',
    };

    yield put(ShowerBookingListSuccess(response));
  } catch (e) {
    console.log("error: ", e);
    yield put(ShowerBookingListFailed(e));
  }
}

// SHOWER_BOOKING_CREATE_FETCH
function* workerSagaShowerBookingCreate(action: ShowerBookingCreateFetch) {
  const mutation = mutations.CreateShowerBooking;
  try {
    const response = yield API.graphql(graphqlOperation(mutation, { input: action.payload }));
    yield put(ShowerBookingCreateSuccess(response.data));
  } catch (e) {
    yield put(ShowerBookingCreateFailed(e));
  }
}

// SHOWER_BOOKING_UPDATE_FETCH
function* workerSagaShowerBookingUpdate(action: ShowerBookingUpdateFetch) {
  const mutationQueries = {
    [ShowerBookingCreateUpdateType.CALL]: (): string => mutations.CallShowerBooking,
    [ShowerBookingCreateUpdateType.CANCEL]: (): string => mutations.CancelShowerBooking,
    [ShowerBookingCreateUpdateType.COMPLETE]: (): string => mutations.CompleteShowerBooking,
    [ShowerBookingCreateUpdateType.START]: (): string => mutations.StartShowerBooking,
    [ShowerBookingCreateUpdateType.TIMEOUT]: (): string => mutations.TimeoutShowerBooking,
    [ShowerBookingCreateUpdateType.NOTIFY]: (): string => mutations.NotifyShowerBooking,
    [ShowerBookingCreateUpdateType.UPDATE]: (): string => mutations.UpdateShowerBooking,
    [ShowerBookingCreateUpdateType.CREATE]: (): string => 'Invalid Type',
    DEFAULT: (): string => 'Invalid Type',
  };

  const { updateType, ...variables } = action.payload;

  const mutation = (mutationQueries[updateType] || mutationQueries.DEFAULT)();
  try {
    const response = yield API.graphql(graphqlOperation(mutation, { input: variables }));
    yield put(ShowerBookingUpdateSuccess(response.data));
  } catch (e) {
    yield put(ShowerBookingUpdateFailed(e));
  }
}

// SHOWER_ROOM_LIST_FETCH
function* workerSagaShowerRoomList(action: ShowerRoomListFetch) {
  try {
    const response = yield API.graphql(graphqlOperation(queries.ListShowerRooms, action.payload));
    yield put(ShowerRoomListSuccess(response.data.listShowerRooms.items));
  } catch (e) {
    yield put(ShowerRoomListFailed(e));
  }
}

// ON_CREATE_UPDATE_SHOWER_BOOKING
export function* workerSagaOnShowerBookingSubscription(action: OnCreateUpdateShowerBooking): SagaIterator {
  const invalidUpdateError = 'Invalid Update Type';
  const { payload } = action;
  const { mutationType } = payload;

  const { CANCEL, CALL, CREATE, COMPLETE, NOTIFY, START, TIMEOUT, UPDATE } = ShowerBookingCreateUpdateType;

  const mutationTypes = {
    [CALL]: (): ShowerBooking => (payload as OnCallShowerBooking).onCallShowerBooking,
    [CANCEL]: (): ShowerBooking => (payload as OnCancelShowerBooking).onCancelShowerBooking,
    [COMPLETE]: (): ShowerBooking => (payload as OnCompleteShowerBooking).onCompleteShowerBooking,
    [CREATE]: (): ShowerBooking => (payload as OnCreateShowerBooking).onCreateShowerBooking,
    [START]: (): ShowerBooking => (payload as OnStartShowerBooking).onStartShowerBooking,
    [NOTIFY]: (): ShowerBooking => (payload as OnNotifyShowerBooking).onNotifyShowerBooking,
    [TIMEOUT]: (): ShowerBooking => (payload as OnTimeoutShowerBooking).onTimeoutShowerBooking,
    [UPDATE]: (): ShowerBooking => (payload as OnUpdateShowerBooking).onUpdateShowerBooking,
    [NOTIFY]: () => invalidUpdateError,
    DEFAULT: (): string => invalidUpdateError,
  };

  const extractedShowerBooking = (mutationTypes[mutationType] || mutationTypes.DEFAULT)();

  if (extractedShowerBooking === invalidUpdateError) {
    yield put(OnCreateUpdateShowerBookingFailed({ message: extractedShowerBooking }));
    return;
  }

  const newShowerBooking = extractedShowerBooking as ShowerBooking;

  yield put(OnCreateUpdateShowerBookingSuccess(newShowerBooking));
}

//#endregion

const showerWatcher = [
  takeLatest(SHOWER_BOOKING_CREATE_FETCH, workerSagaShowerBookingCreate),
  takeLatest(SHOWER_BOOKING_LIST_FETCH, workerSagaShowerBookingList),
  takeLatest(SHOWER_BOOKING_UPDATE_FETCH, workerSagaShowerBookingUpdate),
  takeLatest(SHOWER_ROOM_LIST_FETCH, workerSagaShowerRoomList),
  takeEvery(ON_CREATE_UPDATE_SHOWER_BOOKING, workerSagaOnShowerBookingSubscription),
];

export default showerWatcher;
