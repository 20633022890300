//#region IMPORT
// PACKAGE IMPORT
import axios, { AxiosResponse } from 'axios';
import qs from 'qs';

//#endregion

/**
 * Extracts the values from a string enum and returns the array of values of the enum.
 * @param {E} enumType
 * @returns {unknown[]}
 */
export function extractEnumValues<E>(enumType: E): E[] {
  return [...Object.values(enumType).filter(statusType => typeof statusType === 'string')];
}

/**
 * Call the url using GET
 * @param {string} url
 * @param {Record<string, unknown>} data
 * @param token
 * @returns {Promise<AxiosResponse>}
 */
export function get(url: string, data?: Record<string, unknown>): Promise<AxiosResponse> {
  if (data && data.token) {
    const { token, ...dataWithoutToken } = data;
    return axios.get(`${url}?${qs.stringify(dataWithoutToken)}`, { headers: { Authorization: `Bearer ${token}` } });
  }
  return axios.get(`${url}?${qs.stringify(data)}`);
}

/**
 * Call the url using POST
 * @param {string} url
 * @param data
 */
export function post<T, R = Record<string, unknown>>(url: string, data: T): Promise<AxiosResponse<R>> {
  return axios.post(`${url}`, data);
}

/**
 * Translate ddMMM to JulianDay
 * @param {string} dateOfFlight
 * @returns {string} JulianDay
 */
 const LEAPYEAR:number[] = [
  31,
  29,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31
]
const USUALYEAR: number[] = [
  31,
  28,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31
]
export function toGregorianDay(dateOfFlight: string):string{
  const thisYear = (new Date()).getFullYear();
  let month = 0;
  let julianDay:number = +dateOfFlight;
  if(thisYear%4 === 0){
    for(; month < 12; ++month ){
      if(julianDay - LEAPYEAR[month] < 1 ){
        break;
      }else{
        julianDay = julianDay - LEAPYEAR[month];
      }
    }
  }else{
    for(; month < 12; ++month ){
      if(julianDay - USUALYEAR[month] < 1 ){
        break;
      }else{
        julianDay = julianDay - USUALYEAR[month];
      }
    }
  }
  return (month+1).toString() + '/' + julianDay; 
}
