//#region IMPORTS
// LOCAL CONFIG
import {
  SHOWER_BOOKING_LIST_FETCH,
  SHOWER_BOOKING_CREATE_CLEAR,
  SHOWER_BOOKING_CREATE_FAILED,
  SHOWER_BOOKING_CREATE_FETCH,
  SHOWER_BOOKING_CREATE_SUCCESS,
  SHOWER_BOOKING_LIST_CLEAR,
  SHOWER_BOOKING_LIST_FAILED,
  SHOWER_BOOKING_LIST_SUCCESS,
  SHOWER_BOOKING_UPDATE_CLEAR,
  SHOWER_BOOKING_UPDATE_FAILED,
  SHOWER_BOOKING_UPDATE_FETCH,
  SHOWER_BOOKING_UPDATE_SUCCESS,
  SHOWER_ROOM_LIST_CLEAR,
  SHOWER_ROOM_LIST_FAILED,
  SHOWER_ROOM_LIST_FETCH,
  SHOWER_ROOM_LIST_SUCCESS,
  SHOWER_BOOKING_SUBSCRIPTION_CREATE_FAILED,
  SHOWER_BOOKING_SUBSCRIPTION_CREATE_SUCCESS,
  ShowerActionTypes,
  ShowerBooking,
  ShowerBookingCreateParam,
  ShowerBookingUpdateParam,
  ShowerRoomListParam,
  ShowerRoomResponse,
  StartShowerBookingParam,
  SHOWER_BOOKING_SUBSCRIPTION_REMOVE_SUCCESS,
  SHOWER_BOOKING_SUBSCRIPTION_UPDATE_SUCCESS,
  ON_CREATE_UPDATE_SHOWER_BOOKING_SUCCESS,
  ON_CREATE_UPDATE_SHOWER_BOOKING,
  SHOWER_BOOKING_SUBSCRIPTION_REMOVE_FAILED,
  SHOWER_BOOKING_SUBSCRIPTION_UPDATE_FAILED,
  ON_CREATE_UPDATE_SHOWER_BOOKING_FAILED,
  ShowerBookingListFetch,
  ShowerBookingListSuccess,
  ShowerBookingListFailed,
  ShowerBookingCreateFetch,
  ShowerBookingCreateSuccess,
  ShowerBookingCreateFailed,
  ShowerBookingUpdateFetch,
  ShowerBookingUpdateSuccess,
  ShowerBookingUpdateFailed,
  ShowerRoomListFetch,
  ShowerRoomListFailed,
  OnCreateUpdateShowerBookingPayload,
  ShowerBookingSubscriptionCreateFailed,
  ShowerBookingSubscriptionRemoveFailed,
  OnCreateUpdateShowerBooking,
  OnCreateUpdateShowerBookingSuccess,
  OnCreateUpdateShowerBookingFailed,
  SubscriptionRegistrationStatus,
  ShowerBookingListResponse,
  ShowerBookingListTypes,
  FailureType,
  SHOWER_BOOKING_SUBSCRIPTION_REMOVE_FETCH,
  SHOWER_BOOKING_SUBSCRIPTION_CREATE_FETCH,
  SHOWER_BOOKING_SUBSCRIPTION_UPDATE_FETCH,
} from './constants';
import { RESET } from '../../config/constant';

//#endregion

//#region INTERFACE
interface ShowerBookingListInitialState {
  showerBookingListParam: ShowerBookingListTypes | {};
  showerBookingListResponse: ShowerBookingListResponse | {};
  showerBookingListLoading: boolean;
  showerBookingListError: FailureType;
}
interface ShowerBookingCreateInitialState {
  showerBookingCreateParam: ShowerBookingCreateParam | {};
  showerBookingCreateResponse: ShowerBooking | {};
  showerBookingCreateLoading: boolean;
  showerBookingCreateError: FailureType;
}
interface ShowerBookingUpdateInitialState {
  showerBookingUpdateParam: ShowerBookingUpdateParam | StartShowerBookingParam | {};
  showerBookingUpdateResponse: ShowerBooking | {};
  showerBookingUpdateLoading: boolean;
  showerBookingUpdateError: FailureType;
}
interface ShowerRoomListInitialState {
  showerRoomListParam: ShowerRoomListParam | {};
  showerRoomListResponse: ShowerRoomResponse | {};
  showerRoomListLoading: boolean;
  showerRoomListError: FailureType;
}
interface ShowerBookingSubscriptionInitialState {
  onCreateUpdateShowerBookingParam?: OnCreateUpdateShowerBookingPayload;
  onCreateUpdateShowerBookingResponse?: ShowerBooking;
  subscriptionError?: FailureType;
  subscriptionRegistrationStatus: SubscriptionRegistrationStatus;
}
export interface InitialState
  extends ShowerBookingListInitialState,
    ShowerBookingCreateInitialState,
    ShowerBookingUpdateInitialState,
    ShowerRoomListInitialState,
    ShowerBookingSubscriptionInitialState {
  action: string;
}
//#endregion

//#region INITIAL STATE
const showerBookingListInitialState: ShowerBookingListInitialState = {
  showerBookingListParam: {},
  showerBookingListResponse: {},
  showerBookingListLoading: false,
  showerBookingListError: {},
};
const showerBookingCreateInitialState: ShowerBookingCreateInitialState = {
  showerBookingCreateParam: {},
  showerBookingCreateResponse: {},
  showerBookingCreateLoading: false,
  showerBookingCreateError: {},
};
const showerBookingUpdateInitialState: ShowerBookingUpdateInitialState = {
  showerBookingUpdateParam: {},
  showerBookingUpdateResponse: {},
  showerBookingUpdateLoading: false,
  showerBookingUpdateError: {},
};
const showerRoomListInitialState: ShowerRoomListInitialState = {
  showerRoomListParam: {},
  showerRoomListResponse: {},
  showerRoomListLoading: false,
  showerRoomListError: {},
};
const showerBookingSubscriptionInitialState: ShowerBookingSubscriptionInitialState = {
  onCreateUpdateShowerBookingParam: undefined,
  subscriptionError: undefined,
  subscriptionRegistrationStatus: SubscriptionRegistrationStatus.UNREGISTERED,
};
const initialState: InitialState = {
  ...showerBookingListInitialState,
  ...showerBookingCreateInitialState,
  ...showerBookingUpdateInitialState,
  ...showerRoomListInitialState,
  ...showerBookingSubscriptionInitialState,
  action: '',
};
//#endregion

//#region REDUCER

function showerReducer(state = initialState, action: ShowerActionTypes): InitialState {
  const actions = {
    // Shower Booking List
    [SHOWER_BOOKING_LIST_FETCH]: (): InitialState => ({
      ...state,
      showerBookingListParam: (action as ShowerBookingListFetch).payload,
      showerBookingListLoading: true,
      action: action.type,
    }),
    [SHOWER_BOOKING_LIST_SUCCESS]: (): InitialState => ({
      ...state,
      showerBookingListResponse: (action as ShowerBookingListSuccess).payload,
      showerBookingListLoading: false,
      action: action.type,
    }),
    [SHOWER_BOOKING_LIST_FAILED]: (): InitialState => ({
      ...state,
      showerBookingListError: (action as ShowerBookingListFailed).payload,
      action: action.type,
    }),
    [SHOWER_BOOKING_LIST_CLEAR]: (): InitialState => ({
      ...state,
      ...showerBookingListInitialState,
      action: action.type,
    }),
    // Shower Booking Create
    [SHOWER_BOOKING_CREATE_FETCH]: (): InitialState => ({
      ...state,
      showerBookingCreateParam: (action as ShowerBookingCreateFetch).payload,
      showerBookingCreateLoading: true,
      action: action.type,
    }),
    [SHOWER_BOOKING_CREATE_SUCCESS]: (): InitialState => ({
      ...state,
      showerBookingCreateResponse: (action as ShowerBookingCreateSuccess).payload,
      showerBookingCreateLoading: false,
      action: action.type,
    }),
    [SHOWER_BOOKING_CREATE_FAILED]: (): InitialState => ({
      ...state,
      showerBookingCreateError: (action as ShowerBookingCreateFailed).payload,
      showerBookingCreateLoading: false,
      action: action.type,
    }),
    [SHOWER_BOOKING_CREATE_CLEAR]: (): InitialState => ({
      ...state,
      ...showerBookingCreateInitialState,
      action: action.type,
    }),
    // Shower Booking Update
    [SHOWER_BOOKING_UPDATE_FETCH]: (): InitialState => ({
      ...state,
      showerBookingUpdateParam: (action as ShowerBookingUpdateFetch).payload,
      showerBookingUpdateLoading: true,
      action: action.type,
    }),
    [SHOWER_BOOKING_UPDATE_SUCCESS]: (): InitialState => ({
      ...state,
      showerBookingUpdateResponse: (action as ShowerBookingUpdateSuccess).payload,
      showerBookingUpdateLoading: false,
      action: action.type,
    }),
    [SHOWER_BOOKING_UPDATE_FAILED]: (): InitialState => ({
      ...state,
      showerBookingUpdateError: (action as ShowerBookingUpdateFailed).payload,
      showerBookingUpdateLoading: false,
      action: action.type,
    }),
    [SHOWER_BOOKING_UPDATE_CLEAR]: (): InitialState => ({
      ...state,
      ...showerBookingUpdateInitialState,
      action: action.type,
    }),
    // Shower Room List
    [SHOWER_ROOM_LIST_FETCH]: (): InitialState => ({
      ...state,
      showerRoomListParam: (action as ShowerRoomListFetch).payload,
      showerRoomListLoading: true,
      action: action.type,
    }),
    [SHOWER_ROOM_LIST_SUCCESS]: (): InitialState => ({
      ...state,
      showerRoomListResponse: (action as ShowerRoomListFetch).payload,
      showerRoomListLoading: false,
      action: action.type,
    }),
    [SHOWER_ROOM_LIST_FAILED]: (): InitialState => ({
      ...state,
      showerRoomListError: (action as ShowerRoomListFailed).payload,
      showerRoomListLoading: false,
      action: action.type,
    }),
    [SHOWER_ROOM_LIST_CLEAR]: (): InitialState => ({
      ...state,
      ...showerRoomListInitialState,
      action: action.type,
    }),
    // Shower Booking Subscription
    [SHOWER_BOOKING_SUBSCRIPTION_CREATE_FETCH]: (): InitialState => ({
      ...state,
      subscriptionRegistrationStatus: SubscriptionRegistrationStatus.REGISTERING,
      action: action.type,
    }),
    [SHOWER_BOOKING_SUBSCRIPTION_CREATE_SUCCESS]: (): InitialState => ({
      ...state,
      subscriptionRegistrationStatus: SubscriptionRegistrationStatus.REGISTERED,
      action: action.type,
    }),
    [SHOWER_BOOKING_SUBSCRIPTION_CREATE_FAILED]: (): InitialState => ({
      ...state,
      subscriptionRegistrationStatus: SubscriptionRegistrationStatus.REGISTRATION_FAILED,
      subscriptionError: (action as ShowerBookingSubscriptionCreateFailed).payload,
      action: action.type,
    }),
    [SHOWER_BOOKING_SUBSCRIPTION_REMOVE_FETCH]: (): InitialState => ({
      ...state,
      subscriptionRegistrationStatus: SubscriptionRegistrationStatus.UNREGISTERING,
      action: action.type,
    }),
    [SHOWER_BOOKING_SUBSCRIPTION_REMOVE_SUCCESS]: (): InitialState => ({
      ...state,
      subscriptionRegistrationStatus: SubscriptionRegistrationStatus.UNREGISTERED,
      action: action.type,
    }),
    [SHOWER_BOOKING_SUBSCRIPTION_REMOVE_FAILED]: (): InitialState => ({
      ...state,
      subscriptionError: (action as ShowerBookingSubscriptionRemoveFailed).payload,
      action: action.type,
    }),
    [SHOWER_BOOKING_SUBSCRIPTION_UPDATE_FETCH]: (): InitialState => ({
      ...state,
      subscriptionRegistrationStatus: SubscriptionRegistrationStatus.UNREGISTERING,
      action: action.type,
    }),
    [SHOWER_BOOKING_SUBSCRIPTION_UPDATE_SUCCESS]: (): InitialState => ({
      ...state,
      subscriptionRegistrationStatus: SubscriptionRegistrationStatus.UNREGISTERED,
      action: action.type,
    }),
    [SHOWER_BOOKING_SUBSCRIPTION_UPDATE_FAILED]: (): InitialState => ({
      ...state,
      subscriptionError: (action as ShowerBookingSubscriptionRemoveFailed).payload,
      action: action.type,
    }),
    [ON_CREATE_UPDATE_SHOWER_BOOKING]: (): InitialState => ({
      ...state,
      onCreateUpdateShowerBookingParam: (action as OnCreateUpdateShowerBooking).payload,
      action: action.type,
    }),
    [ON_CREATE_UPDATE_SHOWER_BOOKING_SUCCESS]: (): InitialState => ({
      ...state,
      onCreateUpdateShowerBookingResponse: (action as OnCreateUpdateShowerBookingSuccess).payload,
      action: action.type,
    }),
    [ON_CREATE_UPDATE_SHOWER_BOOKING_FAILED]: (): InitialState => ({
      ...state,
      subscriptionError: (action as OnCreateUpdateShowerBookingFailed).payload,
      action: action.type,
    }),
    [RESET.ALL]: (): InitialState => ({
      ...initialState,
      action: action.type,
    }),
    DEFAULT: (): InitialState => state,
  };

  return (actions[action.type] || actions.DEFAULT)();
}

//#endregion

export default showerReducer;
