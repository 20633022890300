//#region IMPORTS

import {
  SHOWER_BOOKING_LIST_FETCH,
  SHOWER_BOOKING_CREATE_CLEAR,
  SHOWER_BOOKING_CREATE_FAILED,
  SHOWER_BOOKING_CREATE_FETCH,
  SHOWER_BOOKING_CREATE_SUCCESS,
  SHOWER_BOOKING_LIST_CLEAR,
  SHOWER_BOOKING_LIST_FAILED,
  SHOWER_BOOKING_LIST_SUCCESS,
  SHOWER_BOOKING_UPDATE_CLEAR,
  SHOWER_BOOKING_UPDATE_FAILED,
  SHOWER_BOOKING_UPDATE_FETCH,
  SHOWER_BOOKING_UPDATE_SUCCESS,
  SHOWER_ROOM_LIST_CLEAR,
  SHOWER_ROOM_LIST_FAILED,
  SHOWER_ROOM_LIST_FETCH,
  SHOWER_ROOM_LIST_SUCCESS,
  SHOWER_BOOKING_SUBSCRIPTION_CREATE_FAILED,
  SHOWER_BOOKING_SUBSCRIPTION_CREATE_SUCCESS,
  ShowerActionTypes,
  ShowerBooking,
  ShowerBookingCreateParam,
  ShowerBookingListByStatusAndDate,
  ShowerBookingListByStatusParam,
  ShowerBookingListParam,
  ShowerBookingUpdateParam,
  ShowerRoomListParam,
  ShowerRoomResponse,
  StartShowerBookingParam,
  OnCreateUpdateShowerBookingPayload,
  ON_CREATE_UPDATE_SHOWER_BOOKING_SUCCESS,
  ON_CREATE_UPDATE_SHOWER_BOOKING,
  ON_CREATE_UPDATE_SHOWER_BOOKING_FAILED,
  SHOWER_BOOKING_SUBSCRIPTION_REMOVE_FAILED,
  SHOWER_BOOKING_SUBSCRIPTION_REMOVE_SUCCESS,
  ShowerBookingListResponse,
  FailureType,
  SHOWER_BOOKING_SUBSCRIPTION_UPDATE_FAILED,
  SHOWER_BOOKING_SUBSCRIPTION_UPDATE_SUCCESS,
  SHOWER_BOOKING_SUBSCRIPTION_CREATE_FETCH,
  SHOWER_BOOKING_SUBSCRIPTION_REMOVE_FETCH,
  SHOWER_BOOKING_SUBSCRIPTION_UPDATE_FETCH,
  UpdateShowerBookingParam,
} from './constants';

//#endregion

//#region ACTION CREATORS

// Shower Booking List
export const ShowerBookingListFetch = (
  payload: ShowerBookingListParam | ShowerBookingListByStatusParam | ShowerBookingListByStatusAndDate
): ShowerActionTypes => ({
  type: SHOWER_BOOKING_LIST_FETCH,
  payload,
});

export const ShowerBookingListSuccess = (payload: ShowerBookingListResponse): ShowerActionTypes => ({
  type: SHOWER_BOOKING_LIST_SUCCESS,
  payload,
});

export const ShowerBookingListFailed = (payload: Record<string, unknown>): ShowerActionTypes => ({
  type: SHOWER_BOOKING_LIST_FAILED,
  payload,
});

export const ShowerBookingListClear = (): ShowerActionTypes => ({
  type: SHOWER_BOOKING_LIST_CLEAR,
});

// Shower Booking Create
export const ShowerBookingCreateFetch = (payload: ShowerBookingCreateParam): ShowerActionTypes => ({
  type: SHOWER_BOOKING_CREATE_FETCH,
  payload,
});

export const ShowerBookingCreateSuccess = (payload: ShowerBooking): ShowerActionTypes => ({
  type: SHOWER_BOOKING_CREATE_SUCCESS,
  payload,
});

export const ShowerBookingCreateFailed = (payload: Record<string, unknown>): ShowerActionTypes => ({
  type: SHOWER_BOOKING_CREATE_FAILED,
  payload,
});

export const ShowerBookingCreateClear = (): ShowerActionTypes => ({
  type: SHOWER_BOOKING_CREATE_CLEAR,
});

// Shower Booking Update
export const ShowerBookingUpdateFetch = (
  payload: ShowerBookingUpdateParam | StartShowerBookingParam | UpdateShowerBookingParam
): ShowerActionTypes => ({
  type: SHOWER_BOOKING_UPDATE_FETCH,
  payload,
});

export const ShowerBookingUpdateSuccess = (payload: ShowerBooking): ShowerActionTypes => ({
  type: SHOWER_BOOKING_UPDATE_SUCCESS,
  payload,
});

export const ShowerBookingUpdateFailed = (payload: Record<string, unknown>): ShowerActionTypes => ({
  type: SHOWER_BOOKING_UPDATE_FAILED,
  payload,
});

export const ShowerBookingUpdateClear = (): ShowerActionTypes => ({
  type: SHOWER_BOOKING_UPDATE_CLEAR,
});

// Shower Room List
export const ShowerRoomListFetch = (payload: ShowerRoomListParam): ShowerActionTypes => ({
  type: SHOWER_ROOM_LIST_FETCH,
  payload,
});

export const ShowerRoomListSuccess = (payload: ShowerRoomResponse): ShowerActionTypes => ({
  type: SHOWER_ROOM_LIST_SUCCESS,
  payload,
});

export const ShowerRoomListFailed = (payload: Record<string, unknown>): ShowerActionTypes => ({
  type: SHOWER_ROOM_LIST_FAILED,
  payload,
});

export const ShowerRoomListClear = (): ShowerActionTypes => ({
  type: SHOWER_ROOM_LIST_CLEAR,
});

// Subscription Actions
export const OnCreateUpdateShowerBooking = (payload: OnCreateUpdateShowerBookingPayload): ShowerActionTypes => ({
  type: ON_CREATE_UPDATE_SHOWER_BOOKING,
  payload,
});
export const OnCreateUpdateShowerBookingSuccess = (payload: ShowerBooking): ShowerActionTypes => ({
  type: ON_CREATE_UPDATE_SHOWER_BOOKING_SUCCESS,
  payload,
});
export const OnCreateUpdateShowerBookingFailed = (payload: FailureType): ShowerActionTypes => ({
  type: ON_CREATE_UPDATE_SHOWER_BOOKING_FAILED,
  payload,
});
export const ShowerBookingSubscriptionCreateFetch = (): ShowerActionTypes => ({
  type: SHOWER_BOOKING_SUBSCRIPTION_CREATE_FETCH,
});
export const ShowerBookingSubscriptionCreateSuccess = (): ShowerActionTypes => ({
  type: SHOWER_BOOKING_SUBSCRIPTION_CREATE_SUCCESS,
});
export const ShowerBookingSubscriptionCreateFailed = (payload: FailureType): ShowerActionTypes => ({
  type: SHOWER_BOOKING_SUBSCRIPTION_CREATE_FAILED,
  payload,
});
export const ShowerBookingSubscriptionRemoveSuccess = (): ShowerActionTypes => ({
  type: SHOWER_BOOKING_SUBSCRIPTION_REMOVE_SUCCESS,
});
export const ShowerBookingSubscriptionRemoveFetch = (): ShowerActionTypes => ({
  type: SHOWER_BOOKING_SUBSCRIPTION_REMOVE_FETCH,
});
export const ShowerBookingSubscriptionRemoveFailed = (payload: FailureType): ShowerActionTypes => ({
  type: SHOWER_BOOKING_SUBSCRIPTION_REMOVE_FAILED,
  payload,
});
export const ShowerBookingSubscriptionUpdateFetch = (): ShowerActionTypes => ({
  type: SHOWER_BOOKING_SUBSCRIPTION_UPDATE_FETCH,
});
export const ShowerBookingSubscriptionUpdateSuccess = (): ShowerActionTypes => ({
  type: SHOWER_BOOKING_SUBSCRIPTION_UPDATE_SUCCESS,
});
export const ShowerBookingSubscriptionUpdateFailed = (payload: FailureType): ShowerActionTypes => ({
  type: SHOWER_BOOKING_SUBSCRIPTION_UPDATE_FAILED,
  payload,
});
//#endregion
