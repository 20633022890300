//#region
// PACKAGE IMPORT
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import moment from 'moment';

// LOCAL CONFIG
import { StaffCallStatusTypes, StaffCallingStatusText, StaffCallItem, StaffCallMutationTypes } from '../../constants';

// LOCAL COMPONENT
import { Icon } from '../../../../components';
import { IconBell } from '../../../../config/icon';
import { TimeCounter } from '../../components';
import StaffCallAction from '../StaffCallAction';
import './StaffCallListItem.scss';
//#endregion

//#region INTERFACE
export interface OwnProps extends WrappedComponentProps {
  data: StaffCallItem;
  onClickDetail(data: StaffCallItem): void;
  onClickActionButton(data: StaffCallItem, UpdateType: StaffCallMutationTypes): void;
}
//#endregion

//#region COMPONENT
class Index extends React.Component<OwnProps> {
  render(): React.ReactNode {
    const { data, intl } = this.props;

    const isAnswered = data.status === StaffCallStatusTypes.ANSWERED;

    return (
      <div className="staff-call-item">
        <div className="staff-call-item__header">
          <div className="staff-call-item__header__status">
            <span className={`staff-call-item__header__icon staff-call-item__header__icon--${data.status}`}>
              <Icon data={isAnswered ? 'check' : IconBell} />
            </span>

            {intl.formatMessage({
              id: StaffCallingStatusText(data.status as StaffCallStatusTypes),
              defaultMessage: StaffCallingStatusText(data.status as StaffCallStatusTypes),
            })}
          </div>
        </div>
        <div className="staff-call-item__content">
          <div className="staff-call-item__content__group">
            <div className="staff-call-item__content__item">
              <div className="staff-call-item__content__item__label">
                {intl.formatMessage({
                  id: 'labelCustomerId',
                  defaultMessage: 'labelCustomerId',
                })}
              </div>
              <div className="staff-call-item__content__item__value">{data.device_id}</div>
            </div>
            <div className="staff-call-item__content__item">
              <div className="staff-call-item__content__item__label">
                {intl.formatMessage({
                  id: 'labelCallTime',
                  defaultMessage: 'labelCallTime',
                })}
              </div>
              <div className="staff-call-item__content__item__value">
                {data.status === StaffCallStatusTypes.CALLING ? (
                  <>
                    <span className="icon-wrapper">
                      <Icon data="timer" />
                    </span>
                    <TimeCounter startTime={data.timestamp} />
                  </>
                ) : (
                  moment(data.timestamp).format('hh:mm a')
                )}
              </div>
            </div>
          </div>
        </div>
        <StaffCallAction {...this.props} hideAction showDetail />
      </div>
    );
  }
}
//#endregion

export default injectIntl(Index);
