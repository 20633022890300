//#region IMPORT
// PACKAGE IMPORT
import React, { ReactNode } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

// LOCAL CONFIG
import Map1 from '../../assets/map-1.png';
import './UserLocation.scss';
import './UserLocationMapping.scss';
//#endregion

//#region INTERFACE
export interface OwnProps extends WrappedComponentProps {
  tagId: string;
  tableId: string;
}

export interface StateProps {
  responsedTagId: string;
}

type Props = OwnProps & StateProps;
//#endregion

//#region COMPONENT
export class UserLocation extends React.Component<Props> {

  //#region METHOD
  renderPosition(): ReactNode {
    const location = this.props.tableId;
    if (!location) return <></>;
    return (
      <div
        className={`user-location__map__position Area-${location}`}
      ></div>
    );
  }
  //#endregion

  //#region RENDER
  render(): React.ReactElement {
    const { intl } = this.props;

    return (
      <div className="user-location">
          <p className="user-location__name">
            <strong>
              {intl.formatMessage({ id: 'labelAreaId', defaultMessage: 'labelAreaId' })}
              {' : '}
            </strong>
          </p>
          <div className="user-location__map">
            <img src={Map1} alt="location-map"/>
            {this.renderPosition()}
          </div>
      </div>
    );
  }
  //#endregion
}
//#endregion

export default injectIntl(UserLocation);
