//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import classnames from 'classnames';
import { Field, FieldArray, Form, Formik, FieldArrayRenderProps } from 'formik';
import { tagMaster, TagMaster, Tag } from '../../../user/views/UserLocationSearch/TagMaster';
import * as Yup from 'yup';

// LOCAL CONFIG
import {
  MealOrderItem,
  MenuSelectItem,
  OrderForm,
  MealOrderActionTypes,
  MenuListItemJa,
  MenuListItemEn,
  MenuListResponse,
  MENU_LIST_SUCCESS,
  MenuListParam
} from '../../constants';

// LOCAL COMPONENT
import { Button, Input, Select } from '../../../../components';
import './MealsForm.scss';
import moment from 'moment';

//#endregion

//#region INTERFACE
export interface OwnProps {
  intl: IntlShape;
  onSubmit(formData: OrderForm): void;
}

export interface StateProps {
  menuListResponse?: MenuListResponse;
  mealAction: string;
  airport_id: string;
  lounge_id: string;
}
export interface DispatchProps {
  MenuListFetch(action:MenuListParam): MealOrderActionTypes;
}

type Props = OwnProps & DispatchProps & StateProps;

interface State {
  formAction: string;
  isLoading: boolean;
  isLoadingMenu: boolean;
  menuItems: MenuSelectItem[];
}

const FORM_ACTION = {
  ADD_ITEM: 'ADD_ITEM',
  SUBMIT_FORM: 'SUBMIT_FORM',
};
//#endregion

//#region COMPONENT
class MealsForm extends React.Component<Props, State> {
  //#region CTOR
  private readonly orderValidation: Yup.ObjectSchema;

  constructor(props: Props) {
    super(props);

    this.state = {
      formAction: '',
      isLoading: false,
      menuItems: [],
      isLoadingMenu: false,
    };
    this.handleMenuSuccess = this.handleMenuSuccess.bind(this);

    this.orderValidation = Yup.object().shape({
      device_id: Yup.string().required('validationRequired'),
      items: Yup.array()
        .min(1)
        .of(
          Yup.object().shape({
            name: Yup.string().required('Items must not be empty'),
            qty: Yup.number().moreThan(0),
          })
        ),
    });
  }

  //#endregion

  //#region LC
  componentDidMount(): void {
    this.props.MenuListFetch({
      airportId: this.props.airport_id,
      loungeId: this.props.lounge_id
    });
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.props.mealAction !== prevProps.mealAction) {
      this.handleReduxActions(this.props.mealAction);
    }
  }
  //#endregion

  //#region METHOD
  handleReduxActions(action: string): void {
    const actions = {
      [MENU_LIST_SUCCESS as string]: (): void => {
        this.props.menuListResponse && this.handleMenuSuccess(this.props.menuListResponse);
      },
      DEFAULT: (): void => {},
    };

    return (actions[action] || actions.DEFAULT)();
  }

  handleMenuSuccess(menuListResponse: MenuListResponse): void {
    const { locale } = this.props.intl;
    const menuItems: MenuSelectItem[] = [];
    const isStaging = process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.trim() === 'stage';
    const items = menuListResponse.items.sort((a,b) => {
      if(a.display_order) {
        if(b.display_order) return a.display_order - b.display_order;
        else return 1;
      } else {
        if(b.display_order) return -1;
        else return 0;
      }
    });
    if (locale === 'ja') {
      items.forEach(item => {
        const { name_ja, is_active, is_production, is_staging, from, to } = item as MenuListItemJa;

        if(isStaging && !is_staging) return;
        else if(!isStaging && !is_production) return;
        if(from && moment(from).year() !== 1970 && moment(from).add("h", -9).isAfter(Date.now())) return;
        if(to && moment(to).year() !== 1970 && moment(to).add("h", -9).isBefore(Date.now())) return;

        if (name_ja) {
          menuItems.push({
            label: is_active
              ? name_ja
              : `${name_ja} - ${this.props.intl.formatMessage({
                  id: 'labelOutOfStock',
                  defaultMessage: 'labelOutOfStock',
                })}`,
            value: name_ja,
          });
        }
      });
    } else {
      items.forEach(item => {
        const { name_en, is_active, is_production, is_staging, from, to } = item as MenuListItemEn;

        if(isStaging && !is_staging) return;
        else if(!isStaging && !is_production) return;
        if(from && moment(from).year() !== 1970 && moment(from).add("h", -9).isAfter(Date.now())) return;
        if(to && moment(to).year() !== 1970 && moment(to).add("h", -9).isBefore(Date.now())) return;

        if (name_en) {
          menuItems.push({
            label: is_active
              ? name_en
              : `${name_en} - ${this.props.intl.formatMessage({
                  id: 'labelOutOfStock',
                  defaultMessage: 'labelOutOfStock',
                })}`,
            value: name_en,
          });
        }
      });
    }
    this.setState({ menuItems });
  }
  //#endregion

  //#region RENDER
  render(): JSX.Element {
    const classess = classnames('meals-form');
    return (
      <div className={classess}>
        <Formik
          initialValues={{
            device_id: '',
            items: [],
            table_id: '',
            airport_id: this.props.airport_id,
            lounge_id: this.props.lounge_id,
          }}
          validationSchema={this.orderValidation}
          onSubmit={(values: OrderForm): void => {
            if (this.state.formAction === FORM_ACTION.SUBMIT_FORM) {
              this.props.onSubmit(values);
            }
          }}
          render={({ isValid, values, setFieldValue, submitForm }): JSX.Element => {
            return (
              <Form>
                <div className="meals-form__section">
                  <Field
                    name="device_id"
                    component={Select}
                    label="labelSelectDevice"
                    id="device_id"
                    placeholder="placeholderSelectDevice"
                    options={
                      tagMaster.map(
                        (item: TagMaster, index: number): Tag => (
                          {
                            value: item.code,
                            label: item.id.toString(),
                          }
                        )
                      ) || [{ label: 'No Available Item', value: 'No Available Item' }]
                    }
                    isSearchable={false}
                    isRequired
                  />
                </div>
                <div className="meals-form__section">
                  <FieldArray
                    name="items"
                    render={(arrayHelper: FieldArrayRenderProps): React.ReactElement => (
                      <>
                        {values.items &&
                          values.items.length > 0 &&
                          values.items.map((item: MealOrderItem, index: number) => (
                            <div key={index} className="meals-form__section__item">
                              <div className="meals-form__section__item__menu">
                                <Field
                                  name={`items.${index}.name`}
                                  component={Select}
                                  label="labelSelectMenu"
                                  id={`items.${index}.name`}
                                  placeholder="placeholderSelectMenu"
                                  options={
                                    this.state.menuItems || [{ label: 'No Available Item', value: 'No Available Item' }]
                                  }
                                  isSearchable={false}
                                  isRequired
                                />
                              </div>
                              <div className="meals-form__section__item__qty">
                                <Button
                                  icon="remove"
                                  iconOnly
                                  onClick={(): void => {
                                    if (item.qty > 1) setFieldValue(`items.${index}.qty`, item.qty - 1);
                                  }}
                                />
                                <Field
                                  name={`items.${index}.qty`}
                                  component={Input}
                                  type="number"
                                  label="labelQty"
                                  id={`items.${index}.qty`}
                                  placeholder="placeholderQty"
                                  isRequired
                                  value={item.qty}
                                  disabled
                                />
                                <Button
                                  color="transparent-primary"
                                  icon="add"
                                  iconOnly
                                  onClick={(): void => setFieldValue(`items.${index}.qty`, item.qty + 1)}
                                />
                              </div>
                              <div className="meals-form__section__item__remove">
                                <Button
                                  color="transparent-primary"
                                  icon="delete"
                                  iconOnly
                                  onClick={(): void => arrayHelper.remove(index)}
                                />
                              </div>
                            </div>
                          ))}

                        <Button
                          onClick={(): void => {
                            this.setState({ formAction: FORM_ACTION.ADD_ITEM }, () =>
                              arrayHelper.push({ name: '', qty: 1 })
                            );
                          }}
                          color="transparent-primary"
                          label="buttonAddItem"
                          type="button"
                          icon="add"
                        />
                      </>
                    )}
                  />
                </div>
                <div className="meals-form__action">
                  <Button
                    disabled={!isValid || this.state.isLoading}
                    showSpinner={this.state.isLoading}
                    color="primary"
                    label="buttonCreateOrder"
                    type="submit"
                    onClick={(): void => {
                      this.setState({ formAction: FORM_ACTION.SUBMIT_FORM, isLoading: true }, () => submitForm());
                    }}
                  />
                </div>
              </Form>
            );
          }}
        />
      </div>
    );
  }
  //#endregion
}

//#endregion

export default injectIntl(MealsForm);
