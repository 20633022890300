/*eslint @typescript-eslint/camelcase: "off"*/
//#region IMPORTS
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import moment from 'moment';
import classnames from 'classnames';
import { IconBell, iconShower } from '../../../../config/icon';
import { Icon, Checkbox } from '../../../../components';
import ShowerAction from '../ShowerAction';
import {
  ShowerBooking,
  ShowerBookingStatus,
  ShowerBookingStatusHistory,
  ShowerBookingStatusText,
  ShowerBookingCreateUpdateType,
  ShowerRoom,
} from '../../constants';
import './ShowerListItem.scss';
//#endregion

//#region OWN INTERFACE
export interface OwnProps {
  intl: IntlShape;
  data: ShowerBooking;
  roomList: ShowerRoom[];
  totalReady: number;
  onClickDetail: (booking: ShowerBooking) => void;
  onClickActionButton: (
    booking: ShowerBooking,
    updateType: ShowerBookingCreateUpdateType,
    shower_room?: string,
    memo?: string,
    is_left_room?: boolean,
    is_cleaning_room?: boolean,
  ) => void;
  onChangeMemo?:(device_id:string, value:string) => void;
  memoData?: {[device_id:string]:string};
}

interface State {
  isCollapse: boolean;
  statusType: ShowerBookingStatus;
  memoData:{ [deviceId: string]: string };
}

//const InitialState = {
//  isCollapse: false,
//  statusType: ShowerBookingStatus.IN_QUEUE,
//};
//
//type State = typeof InitialState;

type Props = OwnProps;
//#endregion

//#region ShowerListItem CLASS
class ShowerListItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isCollapse: false,
      statusType: props.data.status ? props.data.status : ShowerBookingStatus.IN_QUEUE,
      memoData: {}
    };

    this.onToggleCollapse = this.onToggleCollapse.bind(this);
    this.handleLeftRoom = this.handleLeftRoom.bind(this);
    //this.handleCleaningRoom = this.handleCleaningRoom.bind(this);
  }

  //#region METHOD

  onToggleCollapse = (): void => this.setState({ isCollapse: !this.state.isCollapse });

  handleLeftRoom(is_left_room: boolean): void {
    const { data, onClickActionButton } = this.props;
    if (data.status !== ShowerBookingStatus.IN_PROGRESS) return;

    data.is_left_room = is_left_room;

    const newData = {
      ...data,
      is_left_room: is_left_room
    };

    onClickActionButton(newData, ShowerBookingCreateUpdateType.UPDATE, undefined, undefined, is_left_room, data.is_cleaning_room);
  }

  handleCleaningRoom(is_cleaning_room: boolean): void {
    const { data, onClickActionButton } = this.props;
    if (data.status !== ShowerBookingStatus.IN_PROGRESS) return;

    data.is_cleaning_room = is_cleaning_room;

    const newData = {
      ...data,
      is_cleaning_room: is_cleaning_room
    };

    onClickActionButton(newData, ShowerBookingCreateUpdateType.UPDATE, undefined, undefined, data.is_left_room, is_cleaning_room);
  }
  //#endregion

  //#region RENDER

  render(): JSX.Element {
    const { intl, data } = this.props;
    const { isCollapse } = this.state;

    let local_memo = window.localStorage.getItem(data.device_id);
    if(!local_memo) {
      local_memo = '';
    }
    
    const isQueue = data.status === ShowerBookingStatus.IN_QUEUE;
    const isReady = data.status === ShowerBookingStatus.READY;
    const isNotified = data.status === ShowerBookingStatus.NOTIFIED;
    const isAnnounced = data.status === ShowerBookingStatus.ANNOUNCED;
    const isComplete = data.status === ShowerBookingStatus.COMPLETED;
    const isInProgress = data.status === ShowerBookingStatus.IN_PROGRESS;
    const isTimeout = data.status === ShowerBookingStatus.TIMEOUT;
    const isCancelled = data.status === ShowerBookingStatus.CANCELLED;

    const hasHistory = data.status_history.length > 0;
    const classes = classnames('shower-list-item', `shower-list-item--${data.status}`);

    return (
      <div className={classes}>
        <div className="shower-list-item__header" onClick={this.onToggleCollapse}>
          <div className="shower-list-item__header__status">
            <span
              className={`shower-list-item__header__icon shower-list-item__header__icon--${
                isQueue || isReady || isNotified || isAnnounced ? 'in-queue' : 'in-progress'
              }`}
            >
              <Icon
                data={
                  /*eslint no-nested-ternary: "off"*/
                  isComplete
                    ? 'check'
                    : isReady || isNotified || isAnnounced
                    ? 'phone'
                    : isInProgress
                    ? iconShower
                    : isTimeout || isCancelled
                    ? 'priority_high'
                    : IconBell
                }
              />
            </span>
            {intl.formatMessage({
              id: ShowerBookingStatusText(data.status),
              defaultMessage: ShowerBookingStatusText(data.status),
            })}
          </div>
          <div className="shower-list-item__header__collapsable">
            <Icon data={isCollapse ? 'keyboard_arrow_down' : 'keyboard_arrow_up'} />
          </div>
        </div>
        {!isCollapse && (
          <>
            <div className="shower-list-item__content">
              {data.is_handicap && (
                <div className="shower-list-item__content__group">
                  <div className="shower-list-item__content__item">
                    <div className="shower-list-item__content__item__value">
                      <span className="icon-wrapper handicap">
                        <Icon data="accessible" />
                      </span>
                      {intl.formatMessage({
                        id: 'labelHandicap',
                        defaultMessage: 'labelHandicap',
                      })}
                    </div>
                  </div>
                </div>
              )}
              <div className="shower-list-item__content__group">
                <div className="shower-list-item__content__item">
                  <div className="shower-list-item__content__item__label">
                    {intl.formatMessage({
                      id: data.use_app ? 'labelReservedId' : 'labelBeeperId',
                      defaultMessage: data.use_app ? 'labelReservedId' : 'labelBeeperId',
                    })}
                  </div>
                  <div className="shower-list-item__content__item__value">
                    {!data.use_app && (
                      <span className="icon-wrapper">
                        <Icon data={IconBell} />
                      </span>
                    )}
                    {data.use_app ? data.airport_id + '-' + data.lounge_id + '-' + moment(data.timestamp).format("YYYYMMDD") + '-' + data.counter 
                      : ((data.device_id.split('.')[0] === "None" || data.device_id.split('.')[0] === "Announce") ? "ビーパー: " + data.device_id.split('.')[0] : "ビーパー: No." + data.device_id.split('.')[0])}
                  </div>
                </div>
              </div>
              <div className="shower-list-item__content">
                <div className="shower-list-item__content__group">
                  
                  <div className="shower-list-item__content__item">
                    <div className="shower-list-item__content__item__label">
                      {intl.formatMessage({
                        id: 'labelOrderTableId',
                        defaultMessage: 'labelOrderTableId',
                      })}
                    </div>
                    <div className="shower-list-item__content__item__value">{data.use_app ? data.table_id : "-------"}</div>
                  </div>
                {!isInProgress && (
                  <div className="shower-list-item__content__item book-date">
                    <div className="shower-list-item__content__item__label">
                      {intl.formatMessage({ id: 'labelBookTime', defaultMessage: 'labelBookTime' })}
                    </div>
                    <div className="shower-list-item__content__item__value">
                      {moment(data.timestamp).format('hh:mm a')}
                    </div>
                  </div>
                )}
                {isInProgress && hasHistory && (
                  <div className="shower-list-item__content__item start-date">
                    <div className="shower-list-item__content__item__label">
                      {intl.formatMessage({ id: 'labelStartTime', defaultMessage: 'labelStartTime' })}
                    </div>
                    <div className="shower-list-item__content__item__value">
                      {moment(
                        ((data.status_history[data.status_history.length - 1] as unknown) as ShowerBookingStatusHistory)
                          .timestamp
                      ).format('hh:mm a')}
                    </div>
                  </div>
                )}
                <div className="shower-list-item__content__item">
                  <div className="shower-list-item__content__item__label">
                    {intl.formatMessage({
                      id: 'labelPnr',
                      defaultMessage: 'labelPnr',
                    })}
                  </div>
                  <div className="shower-list-item__content__item__value">
                    {data.use_app ? (data.pnr === "XXXXXXX" ? "-------" : data.pnr) : "-------"}
                  </div>
                </div>
              </div>
            </div>

            <div className="shower-list-item__content">
              { data.shower_room && (
              <div className="shower-list-item__content__group">
                <div className="shower-list-item__content__item room">
                  <div className="shower-list-item__content__item__label">
                    {intl.formatMessage({ id: 'labelRoom', defaultMessage: 'labelRoom' })}
                  </div>
                  <div className="shower-list-item__content__item__value">{data.shower_room}</div>
                </div>
                <div className="shower-list-item__content__item">
                  <div className="shower-list-item__content__item__label">
                    {intl.formatMessage({ id: 'labelIsLeft', defaultMessage: 'labelIsLeft' })}
                  </div>
                  <div className="shower-list-item__content__item__value">
                    <Checkbox
                      id={`is-left-room-${data.shower_room}`}
                      checked={data.is_left_room}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                        this.handleLeftRoom(e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div className="shower-list-item__content__item">
                  <div className="shower-list-item__content__item__label">
                    {intl.formatMessage({ id: 'labelIsCleaning', defaultMessage: 'labelIsCleaning' })}
                  </div>
                  <div className="shower-list-item__content__item__value">
                    <Checkbox
                      id={`is-cleaning-room-${data.shower_room}`}
                      checked={data.is_cleaning_room}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                        this.handleCleaningRoom(e.target.checked)
                      }
                    />
                  </div>
                </div>
 
              </div>
              )}
            </div>
            <div className="shower-list-item__content">
              { data.memo && (
                <div className="shower-list-item__content__group">
                  <div className="shower-list-item__content__item ref">
                    <div className="shower-list-item__content__item__label">
                      {intl.formatMessage({ id: 'labelMemo', defaultMessage: 'labelMemo' })}
                    </div>
                    <div className="shower-list-item__content__item__value">{data.memo}</div>
                  </div>
                </div>
                )
              }
            </div>
            <ShowerAction {...this.props} showDetail memoData={this.props.memoData} onChangeMemo={this.props.onChangeMemo} />
            </div>
          </>
        )}
      </div>
    );
  }

  //#endregion
}

//#endregion

export default injectIntl(ShowerListItem);
