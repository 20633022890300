//#region IMPORTS

// PACKAGE IMPORT
import { combineReducers, Reducer, AnyAction } from 'redux';
import { History } from 'history';
import { LocationChangeAction } from 'connected-react-router';

// LOCAL CONFIG
import ReduxReducer from '../config/ReduxReducer';
import { JalAppState } from '../config/interface';

//#endregion

export default (history: History): Reducer<JalAppState, AnyAction & LocationChangeAction> =>
  combineReducers(ReduxReducer(history));
