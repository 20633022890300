//#region IMPORTS

import {
  PUSH_NOTIFICATION,
  USER,
  GET_AREA_USERS_FETCH,
  GET_AREA_USERS_SUCCESS,
  GET_AREA_USERS_FAILED,
  GET_AREA_USERS_CLEAR,
  GET_USER_LOCATION_FETCH,
  GET_USER_LOCATION_SUCCESS,
  GET_USER_LOCATION_FAILED,
  GET_USER_LOCATION_CLEAR,
  SATO_AUTH_FETCH,
  SATO_AUTH_SUCCESS,
  SATO_AUTH_FAILED,
  SATO_AUTH_CLEAR,
} from './StringsUser';

//#endregion

//#region GENERAL ENUM

export enum UserRoles {
  FLOOR_STAFF = 'floor-staff',
  KITCHEN_STAFF = 'kitchen-staff',
  SHOWER_STAFF = 'shower-staff',
}

export enum DevicePlatformTypes {
  android = 'Android',
  ios = 'iOS',
}
//#endregion

//#region SATO

export type SatoFailureDetailInfo = {
  errorMessage: string;
  errorCode: string;
  stackTrace: string;
};

export type SatoFailureType = {
  isError: string;
  processingCount: number;
  detailInfo: SatoFailureDetailInfo;
};

export type SatoUserInfo = {
  storeName: string;
  areaName: string;
  areaId: string;
  token: string;
};

export type SatoAreaInfo = {
  userName: string;
  tagId: string;
  token: string;
  areaId?: string;
};

export type FailureType = Record<string, unknown> | string;

//#endregion

//#region GET USERS INSIDE AN AREA

// Fetch
export interface GetAreaUsersFetch {
  type: typeof GET_AREA_USERS_FETCH;
  payload: SatoUserInfo;
}

// Success
export type GetAreaUsersResponse = {
  isError: string;
  processingCount?: number;
  areaInfo: SatoAreaInfo[];
};

export interface GetAreaUsersSuccess {
  type: typeof GET_AREA_USERS_SUCCESS;
  payload: GetAreaUsersResponse;
}

// Failed
export interface GetAreaUsersFailed {
  type: typeof GET_AREA_USERS_FAILED;
  payload: SatoFailureType | FailureType;
}

// Clear
export interface GetAreaUsersClear {
  type: typeof GET_AREA_USERS_CLEAR;
}

type GetAreaUserActions = GetAreaUsersFetch | GetAreaUsersSuccess | GetAreaUsersFailed | GetAreaUsersClear;

//#endregion

//#region GET USER'S CURRENT LOCATION

// Fetch
export interface GetUserLocationFetch {
  type: typeof GET_USER_LOCATION_FETCH;
  payload: SatoAreaInfo;
}

// Success
export type GetUserLocationResponse = {
  tagId: string;
  isError?: string;
  processingCount?: number;
  userInfo: SatoUserInfo[];
};

export interface GetUserLocationSuccess {
  type: typeof GET_USER_LOCATION_SUCCESS;
  payload: GetUserLocationResponse;
}

// Failed
export interface GetUserLocationFailed {
  type: typeof GET_USER_LOCATION_FAILED;
  payload: SatoFailureType | FailureType;
}

// Clear
export interface GetUserLocationClear {
  type: typeof GET_USER_LOCATION_CLEAR;
}

type GetUserLocationActions =
  | GetUserLocationFetch
  | GetUserLocationSuccess
  | GetUserLocationFailed
  | GetUserLocationClear;

//#endregion

//#region SATO AUTH

// Fetch
export type SatoAuthParam = {
  client_id: string;
  username: string;
  password: string;
  grant_type: string;
};

export interface SatoAuthFetch {
  type: typeof SATO_AUTH_FETCH;
  payload: SatoAuthParam;
}

// Success
export type SatoAuthResponse = {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  'not-before-policy': number;
  session_state: string;
  scope: string;
};

export interface SatoAuthSuccess {
  type: typeof SATO_AUTH_SUCCESS;
  payload: SatoAuthResponse;
}

// Failed
export type SatoAuthFailType =
  | FailureType
  | {
      error: string;
      error_description: string;
    };

export interface SatoAuthFailed {
  type: typeof SATO_AUTH_FAILED;
  payload: SatoAuthFailType;
}

// Clear
export interface SatoAuthClear {
  type: typeof SATO_AUTH_CLEAR;
}

type SatoAuthActions = SatoAuthFetch | SatoAuthSuccess | SatoAuthClear | SatoAuthFailed;

//#endregion

//#region GENERAL INTERFACE

export interface User {
  username: string;
  roles: UserRoles[];
  airportId: string;
  loungeId: string;
}

interface CognitoIdToken {
  getJwtToken(): string;
  getExpiration(): number;
  getIssuedAt(): number;
  // Eslint rule disabled because we followed the interface shape from the Amplify Package.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  decodePayload(): { [id: string]: any };
}

interface CognitoRefreshToken {
  getToken(): string;
}

interface CognitoAccessToken {
  getJwtToken(): string;
  getExpiration(): number;
  getIssuedAt(): number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  decodePayload(): { [id: string]: any };
}

export interface CognitoUserSession {
  getIdToken(): CognitoIdToken;
  getRefreshToken(): CognitoRefreshToken;
  getAccessToken(): CognitoAccessToken;
  isValid(): boolean;
}

export interface RegisterPinpointOptions {
  DeviceToken: string;
  EndpointId: string;
  UserName: string;
}

//#endregion

//#region ACTION TYPES

//#region GENERAL USER

export interface GetUser {
  type: typeof USER.GET_USER;
}

export interface LoadingUser {
  type: typeof USER.LOADING_USER;
  payload: boolean;
}

export interface SetUser {
  type: typeof USER.SET_USER;
  payload: User;
}

type UserActions = GetUser | LoadingUser | SetUser;

//#endregion

//#region AUTH

export interface Login {
  type: typeof USER.LOGIN;
  payload: {
    username: string;
    password: string;
  };
}

export interface LoadingLogin {
  type: typeof USER.LOADING_LOGIN;
  payload: boolean;
}

export interface Logout {
  type: typeof USER.LOGOUT;
}

export interface LogoutSuccess {
  type: typeof USER.LOGOUT_SUCCESS;
}

type AuthActions = Login | LoadingLogin | Logout | LogoutSuccess;

//#endregion

//#region TOKEN

export interface SetDeviceToken {
  type: typeof USER.SET_DEVICE_TOKEN;
  payload: string;
}

export type SatoTokens = {
  token: string;
  refreshToken: string;
};

export interface SetSatoToken {
  type: typeof USER.SET_SATO_TOKEN;
  payload: SatoTokens;
}

type DeviceTokenActions = SetDeviceToken | SetSatoToken;

//#endregion

//#region PUSH NOTIFICATION REGISTER

export interface PushNotificationRegisterFetch {
  type: typeof PUSH_NOTIFICATION.REGISTER.PUSH_NOTIFICATION_REGISTER_FETCH;
  payload: RegisterPinpointOptions;
}

export interface PushNotificationRegisterSuccess {
  type: typeof PUSH_NOTIFICATION.REGISTER.PUSH_NOTIFICATION_REGISTER_SUCCESS;
  payload: string;
}

export interface PushNotificationRegisterFailed {
  type: typeof PUSH_NOTIFICATION.REGISTER.PUSH_NOTIFICATION_REGISTER_FAILED;
  payload: FailureType;
}

export interface PushNotificationRegisterClear {
  type: typeof PUSH_NOTIFICATION.REGISTER.PUSH_NOTIFICATION_REGISTER_CLEAR;
}

type PushNotificationActions =
  | PushNotificationRegisterFetch
  | PushNotificationRegisterSuccess
  | PushNotificationRegisterFailed
  | PushNotificationRegisterClear;

//#endregion

//#region DEVICE PLATFORM

export interface GetDevicePlatform {
  type: typeof USER.GET_DEVICE_PLATFORM;
}

export interface SetDevicePlatform {
  type: typeof USER.SET_DEVICE_PLATFORM;
  payload: DevicePlatformTypes;
}

type DevicePlatformActions = GetDevicePlatform | SetDevicePlatform;
//#endregion

//#endregion

export type UserActionTypes =
  | UserActions
  | AuthActions
  | DeviceTokenActions
  | PushNotificationActions
  | DevicePlatformActions
  | GetAreaUserActions
  | GetUserLocationActions
  | GetAreaUserActions
  | GetUserLocationActions
  | SatoAuthActions;
