//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// LOCAL CONFIG
import { PATH } from '../../constants';
import { UserRoles } from '../../../user/constants';

// LOCAL COMPONENT
import { Modal } from '../../../../components';
import { Navigation, NavigationItem } from '../../components';
import { iconMeals, iconShower, iconLogout, IconCongestion } from '../../../../config/icon';
import './NavigationBar.scss';

//#endregion

//#region INTERFACE
export interface DispatchProps {
  Logout: () => void;
}

export interface StateProps {
  roles: UserRoles[];
}

type Props = StateProps & DispatchProps & RouteComponentProps;

export interface State {
  modalVisible: boolean;
}
//#endregion

//#region COMPONENT
class NavigationBar extends React.Component<Props, State> {
  //#region CTOR
  private logoutModalRef: React.RefObject<Modal>;

  constructor(props: Props) {
    super(props);

    this.state = {
      modalVisible: false,
    };

    this.logoutModalRef = React.createRef<Modal>();
    this.handleShowConfirm = this.handleShowConfirm.bind(this);
  }
  //#endregion

  //#region LC
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (prevState.modalVisible !== this.state.modalVisible) {
      if (this.state.modalVisible) {
        this.logoutModalRef.current && this.logoutModalRef.current.openModal();
      } else {
        this.logoutModalRef.current && this.logoutModalRef.current.closeModal();
      }
    }
  }
  //#endregion

  //#region METHOD
  handleShowConfirm(e: React.MouseEvent<HTMLDivElement>): void {
    e.preventDefault();
    this.setState({ modalVisible: true });
  }

  renderAddModal(): React.ReactElement {
    if (!this.state.modalVisible) return <></>;

    return (
      <Modal
        mode={2}
        content="contentLogout"
        title="titleConfirmLogout"
        ref={this.logoutModalRef}
        onClosed={(): void => this.setState({ modalVisible: false })}
        onConfirmed={(): void => this.props.Logout()}
      />
    );
  }
  //#endregion

  //#region RENDER
  render(): React.ReactElement {
    const { roles } = this.props;
    return (
      <>
        <Navigation className="navigation-bar" isSidebarOnDesktop isCollapse={true}>
          {roles.includes(UserRoles.KITCHEN_STAFF) && (
            <NavigationItem icon={iconMeals} isSidebarOnDesktop target={PATH.MEALS_ORDER} />
          )}
          {roles.includes(UserRoles.SHOWER_STAFF) && (
            <NavigationItem icon={iconShower} isSidebarOnDesktop target={PATH.SHOWER_BOOKING} />
          )}
          {roles.includes(UserRoles.FLOOR_STAFF) && (
            <>
              <NavigationItem icon={IconCongestion} isSidebarOnDesktop target={PATH.CONGESTION} />
            </>
          )}
          <NavigationItem
            icon={iconLogout}
            isSidebarOnDesktop
            className="navigation-item__logout"
            onClick={this.handleShowConfirm}
          />
        </Navigation>

        {this.renderAddModal()}
      </>
    );
  }
  //#endregion
}
//#endregion

export default withRouter(NavigationBar);
