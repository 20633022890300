import React from 'react';
import { Button, Input, Header, Select } from '../../../../components';
import { Formik, Field, Form  } from 'formik';
import './UserLocationSearch.scss';
import UserLocation from '../../../user/views/UserLocation';
import { tagMaster, TagMaster, Tag } from './TagMaster';


export interface OwnProps {
}
export interface OwnState {
  tagId: string;
  selectedTag: string;
}
  
export class UserLocationSearch extends React.Component<OwnProps, OwnState> {
  constructor(props: OwnProps) {
    super(props);
    this.state = {
      tagId: '',
      selectedTag: '0'
    }
    this.handleSelectedTagChange = this.handleSelectedTagChange.bind(this);
  }

  componentDidMount(): void {
  }

  componentWillUnmount(): void {
  }

  handleSelectedTagChange(event: { target: { value: any; }; }): void {
    this.setState({
      tagId: event.target.value,
      selectedTag: event.target.value
    });
  }

  renderTagItems(): React.ReactElement {
    return (
      <>
        <div>
          <select className="userlocationsearch-select" value={this.state.selectedTag} onChange={this.handleSelectedTagChange}>
          <option key="0" disabled value="0">選択してください</option>
            {tagMaster.map(
              (item: TagMaster, index: number): React.ReactElement => (
                <option key={item.id} value={item.code}>{item.id}</option>
              )
            )}
          </select>
        </div>
      </>
    );
  }

  render(): React.ReactElement {
    return (
      <div>
        <Header title="UserLocationSearch" />
        <div className="userlocationsearch">
          <div className="userlocationsearch-form">
            <Formik
              initialValues={{ tagId: '' }}
              onSubmit={(values) => {
                this.setState({ tagId: values.tagId })
              }}
              render={({ values }): JSX.Element => (
                <Form>
                  <div className="userlocationsearch-select">
                    <Field
                      name="tagId"
                      component={Select}
                      id="tagId"
                      placeholder="placeholderSelectDevice"
                      options={
                        tagMaster.map(
                          (item: TagMaster, index: number): Tag => (
                            {
                              value: item.code,
                              label: item.id.toString(),
                            }
                          )
                        ) || [{ label: 'No Available Item', value: 'No Available Item' }]
                      }
                      isSearchable={false}
                      isRequired
                    />
                  </div>
                  <div className="userlocationsearch-tagid">
                    <Field
                      name="tagId"
                      component={Input}
                      type="text"
                      id="tagId"
                      maxLength={12}
                      placeholder="placeholderBeaconId"
                      isRequired
                    />
                  </div>
                  <div className="userlocationsearch-button">
                    <Button
                      type="submit"
                      color="primary"
                      label="searchStart"
                      disabled={ values.tagId.length === 0 }
                    />
                  </div>
                </Form>
              )}
            />
          </div>
          {this.state.tagId && (
          <>
            <div className="userlocationsearch-map">
              {`タグID:${this.state.tagId}`}
              <UserLocation tagId={this.state.tagId} tableId={"41-01-001"} />
            </div>
          </>
          )}
        </div>
      </div>
    );
  }
}

export default UserLocationSearch;
