//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { injectIntl, IntlShape } from 'react-intl';

// LOCAL COMPONENT
import { Icon } from '../../../../components';
import './_index.scss';
//#endregion

//#region INTERFACE
interface OwnProps extends React.DOMAttributes<HTMLAnchorElement | HTMLDivElement> {
  target?: string;
  icon: React.ReactElement<SVGElement> | string;
  label?: string;
  className?: string;
  counter?: number;
  showCounter?: boolean;
  withLine?: boolean;
  isSidebarOnDesktop?: boolean;
  isCollapseButton?: boolean;
  intl: IntlShape;
}
//#endregion

//#region COMPONENT
function NavigationItem({
  target,
  icon,
  label,
  className,
  counter,
  showCounter,
  withLine,
  isSidebarOnDesktop,
  isCollapseButton,
  intl,
  ...others
}: OwnProps): React.ReactElement {
  const classes = classnames(
    'navigation-item',
    { 'navigation-item--with-line': withLine },
    { 'navigation-item--sidebar': isSidebarOnDesktop },
    { 'navigation-item--is-collapse-button': isCollapseButton },
    className
  );

  return isCollapseButton ? (
    <div className={classes} {...others}>
      <div className="navigation-item__icon">
        <Icon data={icon} />
      </div>

      {label && (
        <div className="navigation-item__label">
          <span>{intl.formatMessage({ id: label, defaultMessage: label })}</span>
        </div>
      )}

      {showCounter && <span className="navigation-item__counter">{counter}</span>}
    </div>
  ) : (
    <NavLink to={target || '/'} className={classes} activeClassName="active" {...others}>
      <div className="navigation-item__icon">
        <Icon data={icon} />
      </div>

      {label && (
        <div className="navigation-item__label">
          <span>{intl.formatMessage({ id: label, defaultMessage: label })}</span>
        </div>
      )}

      {showCounter && <span className="navigation-item__counter">{counter}</span>}
    </NavLink>
  );
}
//#endregion

export default injectIntl(NavigationItem);
