//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import classnames from 'classnames';

// LOCAL COMPONENT
import './_index.scss';
//#endregion

//#region INTERFACE
interface OwnProps {
  children: React.ReactNode;
  className?: string;
  isCollapse?: boolean;
  isSidebarOnDesktop: boolean;
}
//#endregion

//#region COMPONENT
function Navigation({ children, isSidebarOnDesktop, className, isCollapse }: OwnProps): React.ReactElement {
  const classes = classnames(
    'navigation',
    { 'navigation--sidebar': isSidebarOnDesktop },
    { 'navigation--is-collapse': isCollapse },
    className
  );

  return <div className={classes}>{children}</div>;
}
//#endregion

export default Navigation;
