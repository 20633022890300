//#region IMPORTS
// LOCAL CONFIG
import {
  ON_STAFF_CALL_SUBSCRIPTION,
  STAFF_CALL_CREATE_CLEAR,
  STAFF_CALL_CREATE_FAILED,
  STAFF_CALL_CREATE_FETCH,
  STAFF_CALL_CREATE_SUCCESS,
  STAFF_CALL_ITEM_CLEAR,
  STAFF_CALL_ITEM_FAILED,
  STAFF_CALL_ITEM_FETCH,
  STAFF_CALL_ITEM_SUCCESS,
  STAFF_CALL_SUBSCRIPTION_CREATE_CLEAR,
  STAFF_CALL_SUBSCRIPTION_CREATE_FAILED,
  STAFF_CALL_SUBSCRIPTION_CREATE_FETCH,
  STAFF_CALL_SUBSCRIPTION_CREATE_SUCCESS,
  STAFF_CALL_UPDATE_CLEAR,
  STAFF_CALL_UPDATE_FAILED,
  STAFF_CALL_UPDATE_FETCH,
  STAFF_CALL_UPDATE_SUCCESS,
  StaffCallActions,
  StaffCallCreateParam,
  StaffCallCreateResponse,
  StaffCallItemParam,
  StaffCallItemResponse,
  StaffCallUpdateParam,
  StaffCallUpdateResponse,
  STAFF_CALL_LIST_FETCH,
  StaffCallListParam,
  STAFF_CALL_LIST_SUCCESS,
  STAFF_CALL_LIST_FAILED,
  STAFF_CALL_LIST_CLEAR,
  StaffCallMultiStatusResponse,
  FailureType,
} from './constants';

//#endregion

//#region ACTION CREATORS

//#region STAFF CALL LIST
export const StaffCallListFetch = (payload: StaffCallListParam): StaffCallActions => ({
  type: STAFF_CALL_LIST_FETCH,
  payload,
});

export const StaffCallListSuccess = (payload: StaffCallMultiStatusResponse): StaffCallActions => ({
  type: STAFF_CALL_LIST_SUCCESS,
  payload,
});

export const StaffCallListFailed = (payload: FailureType): StaffCallActions => ({
  type: STAFF_CALL_LIST_FAILED,
  payload,
});

export const StaffCallListClear = (): StaffCallActions => ({
  type: STAFF_CALL_LIST_CLEAR,
});

//#endregion

//#region STAFF CALL ITEM
export const StaffCallItemFetch = (payload: StaffCallItemParam): StaffCallActions => ({
  type: STAFF_CALL_ITEM_FETCH,
  payload,
});

export const StaffCallItemSuccess = (payload: StaffCallItemResponse): StaffCallActions => ({
  type: STAFF_CALL_ITEM_SUCCESS,
  payload,
});

export const StaffCallItemFailed = (payload: FailureType): StaffCallActions => ({
  type: STAFF_CALL_ITEM_FAILED,
  payload,
});

export const StaffCallItemClear = (): StaffCallActions => ({
  type: STAFF_CALL_ITEM_CLEAR,
});

//#endregion

//#region STAFF CALL CREATE
export const StaffCallCreateFetch = (payload: StaffCallCreateParam): StaffCallActions => ({
  type: STAFF_CALL_CREATE_FETCH,
  payload,
});

export const StaffCallCreateSuccess = (payload: StaffCallCreateResponse): StaffCallActions => ({
  type: STAFF_CALL_CREATE_SUCCESS,
  payload,
});

export const StaffCallCreateFailed = (payload: FailureType): StaffCallActions => ({
  type: STAFF_CALL_CREATE_FAILED,
  payload,
});

export const StaffCallCreateClear = (): StaffCallActions => ({
  type: STAFF_CALL_CREATE_CLEAR,
});

//#endregion

//#region STAFF CALL UPDATE

export const StaffCallUpdateFetch = (payload: StaffCallUpdateParam): StaffCallActions => ({
  type: STAFF_CALL_UPDATE_FETCH,
  payload,
});

export const StaffCallUpdateSuccess = (payload: StaffCallUpdateResponse): StaffCallActions => ({
  type: STAFF_CALL_UPDATE_SUCCESS,
  payload,
});

export const StaffCallUpdateFailed = (payload: FailureType): StaffCallActions => ({
  type: STAFF_CALL_UPDATE_FAILED,
  payload,
});

export const StaffCallUpdateClear = (): StaffCallActions => ({
  type: STAFF_CALL_UPDATE_CLEAR,
});

//#endregion

//#region STAFF CALL SUBSCRIPTION
export const StaffCallSubscriptionCreateFetch = (): StaffCallActions => ({
  type: STAFF_CALL_SUBSCRIPTION_CREATE_FETCH,
});

export const StaffCallSubscriptionCreateSuccess = (): StaffCallActions => ({
  type: STAFF_CALL_SUBSCRIPTION_CREATE_SUCCESS,
});

export const StaffCallSubscriptionCreateFailed = (payload: FailureType): StaffCallActions => ({
  type: STAFF_CALL_SUBSCRIPTION_CREATE_FAILED,
  payload,
});

export const StaffCallSubscriptionCreateClear = (): StaffCallActions => ({
  type: STAFF_CALL_SUBSCRIPTION_CREATE_CLEAR,
});

export const OnStaffCallSubscription = (): StaffCallActions => ({
  type: ON_STAFF_CALL_SUBSCRIPTION,
});

//#endregion

//#endregion
