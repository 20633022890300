//#region IMPORT
// PACKAGE IMPORT
import React from 'react';
import classnames from 'classnames';
import ReactModal from 'react-modal';
import { FormattedMessage } from 'react-intl';

// LOCAL COMPONENT
import Button from '../Button';
import './_index.scss';
//#endregion

//#region INTERFACE
interface OwnProps {
  mode?: 1 | 2 | 3 | 4;
  title?: string;
  onClosed?: () => void;
  onConfirmed?: () => void;
  content?: React.ReactElement | string;
  cancelTranslation?: string;
  confirmTranslation?: string;
  className?: string;
  overlayClassname?: string;
  hideClose?: boolean;
  isNotFullOnDesktop?: boolean;
}

interface OwnState {
  isOpen: boolean;
  offsetY: number;
  touchStartY: number | null;
  windowWidth: number;
}
//#endregion

//#region COMPONENT
ReactModal.setAppElement('#root');
class Modal extends React.Component<OwnProps, OwnState> {
  //#region CONSTRUCTOR & LIFECYCLE
  private modal: React.RefObject<ReactModal>;

  constructor(props: OwnProps) {
    super(props);

    this.modal = React.createRef<ReactModal>();
    this.state = {
      isOpen: false,
      offsetY: 0,
      touchStartY: null,
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount(): void {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.onResize);
  }

  //#endregion

  //#region METHOD
  onResize = (e: UIEvent): void => {
    const currentTarget = e.currentTarget as Window;
    this.setState({ windowWidth: currentTarget && currentTarget.innerWidth });
  };

  onTouchStart = (event: React.TouchEvent): void => {
    if (event.touches[0]) {
      const touchStartY = event.touches[0].clientY;
      this.setState({ touchStartY });
    }
  };

  onTouchMove = (event: React.TouchEvent): void => {
    if (event.touches[0]) {
      const currentY = event.touches[0].clientY;
      const startY = this.state.touchStartY || 0;
      const offsetY = currentY - startY;

      if (offsetY > 0) {
        event.preventDefault();
        this.setState({ offsetY });
      }
    }
  };

  onTouchEnd = (): void => {
    /**
     * this.state.offsetY > 250
     * Close window if top position more than 250px
     *
     * this.state.offsetY < window.outerHeight
     * to make sure state offsetY is correct value
     * because sometime clientY return same as outerHeight
     */
    if (this.state.offsetY > 200 && this.state.offsetY < window.outerHeight) {
      this.closeModal();
    } else {
      this.setState({
        offsetY: 0,
        touchStartY: null,
      });
    }
  };

  openModal = (): void => this.setState({ isOpen: true });

  closeModal = (): void => {
    if (typeof this.props.onClosed === 'function') this.props.onClosed();

    this.setState(
      {
        isOpen: false,
        offsetY: window.outerHeight,
        touchStartY: null,
      },
      () => {
        setTimeout(() => this.setState({ offsetY: 0 }), 500);
      }
    );
  };

  confirm = (): void => {
    this.closeModal();
    if (typeof this.props.onConfirmed === 'function') this.props.onConfirmed();
  };
  //#endregion

  //#region RENDER
  render(): React.ReactElement {
    const {
      title,
      content,
      cancelTranslation,
      confirmTranslation,
      className,
      overlayClassname,
      hideClose,
      isNotFullOnDesktop,
      mode = 1, // 1 popup, 2 confirm, 3 element, 4 card, 5 card-transparent
    } = this.props;

    const { offsetY, windowWidth } = this.state;

    const classes = classnames(
      'modal',
      { 'modal--el': mode === 3 },
      { 'modal--card': mode === 4 },
      { 'modal--card-smaller': mode === 4 && isNotFullOnDesktop && windowWidth >= 768 },
      className
    );

    const overlayClasses = classnames('modal-overlay', overlayClassname || '');

    return (
      <ReactModal
        isOpen={this.state.isOpen}
        onRequestClose={this.closeModal}
        closeTimeoutMS={150}
        className={classes}
        overlayClassName={overlayClasses}
        ref={this.modal}
        style={{
          content: {
            top: mode === 4 ? `${offsetY + 90}px` : '50%',
          },
        }}
      >
        {mode === 4 && !hideClose && (
          <div
            onTouchStart={this.onTouchStart}
            onTouchMove={this.onTouchMove}
            onTouchEnd={this.onTouchEnd}
            className="modal__drag-header"
          >
            <div className="modal__drag-header__close">
              <Button type="button-close" icon="clear" onClick={this.closeModal} />
            </div>
            <div className="modal__drag-header__content">
              <span className="modal__drag-header__content__draggable-icon"></span>
              {typeof title === 'string' && (
                <h3>
                  <FormattedMessage id={title} defaultMessage={title} />
                </h3>
              )}
            </div>
          </div>
        )}

        {mode < 4 && (
          <div className="modal__header">
            <div className="modal__header__text">
              {typeof title === 'string' && <FormattedMessage id={title} defaultMessage={title} />}
            </div>
            <Button type="button-close" icon="clear" onClick={this.closeModal} />
          </div>
        )}

        {mode < 3 && (
          <div className="modal__body">
            <div className="modal__body__content">
              {typeof content === 'string' && <FormattedMessage id={content} defaultMessage={content} />}
            </div>
          </div>
        )}

        {(mode === 3 || mode === 4) && this.props.content}
        
        {1===mode && (
          <div className="modal__action">
            <Button
              color="transparent-danger"
              label={cancelTranslation || 'buttonConfirm'}
              onClick={this.closeModal}
            />
          </div>
        )}

        {1< mode && mode < 3 && (
          <div className="modal__action">
            {mode < 3 && (
              <Button
                color="transparent-danger"
                label={cancelTranslation || 'buttonCancel'}
                onClick={this.closeModal}
              />
            )}

            {mode === 2 && (
              <Button color="transparent" label={confirmTranslation || 'buttonConfirm'} onClick={this.confirm} />
            )}
          </div>
        )}
      </ReactModal>
    );
  }
  //#endregion
}
//#endregion

export default Modal;
