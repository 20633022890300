//#region IMPORT
// PACKAGE IMPORT
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Formik, Field, Form } from 'formik';
import classnames from 'classnames';
import * as Yup from 'yup';

// LOCAL CONFIG
import { BookingForm } from '../../constants';

// LOCAL COMPONENT
import { Button, Select, Input, Checkbox } from '../../../../components';
import moment from 'moment';
import './ShowerForm.scss';

//#endregion

//#region INTERFACE
export interface OwnProps extends WrappedComponentProps {
  airport_id: string;
  lounge_id: string;
  onSubmit(formData: BookingForm): void;
}

export interface StateProps {
  isLoading: boolean;
}
//#endregion

//#region COMPONENT
export class ShowerForm extends React.Component<OwnProps & StateProps> {
  private addQueueValidation: Yup.ObjectSchema;

  constructor(props: OwnProps & StateProps) {
    super(props);

    this.addQueueValidation = Yup.object().shape({
      device_id: Yup.string().required('validationRequired'),
    });
  }

  //#region RENDER
  render(): React.ReactNode {
    const classess = classnames('shower-form');
    const { isLoading } = this.props;

    return (
      <div className={classess}>
        <Formik
          initialValues={{
            device_id: '',
            is_handicap: false,
            memo: '',
            table_id: '-------',
            airport_id: this.props.airport_id,
            lounge_id: this.props.lounge_id,
          }}
          validationSchema={this.addQueueValidation}
          onSubmit={(values: BookingForm): void => this.props.onSubmit(values)}
          render={({ isValid }): JSX.Element => (
            <Form>
              <div className="shower-form__section">
                <Field
                  name="device_id"
                  component={Select}
                  id="device_id"
                  placeholder="placeholderBeeperId"
                  options={[
                    { value: "None._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "None"},
                    { value: "Announce._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "Announce"},
                    { value: "1._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.1"},
                    { value: "2._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.2"},
                    { value: "3._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.3"},
                    { value: "4._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.4"},
                    { value: "5._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.5"},
                    { value: "6._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.6"},
                    { value: "7._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.7"},
                    { value: "8._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.8"},
                    { value: "9._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.9"},
                    { value: "10._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.10"},
                    { value: "11._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.11"},
                    { value: "12._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.12"},
                    { value: "13._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.13"},
                    { value: "14._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.14"},
                    { value: "15._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.15"},
                    { value: "16._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.16"},
                    { value: "17._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.17"},
                    { value: "18._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.18"},
                    { value: "19._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.19"},
                    { value: "20._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.20"},
                    { value: "21._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.21"},
                    { value: "22._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.22"},
                    { value: "23._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.23"},
                    { value: "24._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.24"},
                    { value: "25._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.25"},
                    { value: "26._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.26"},
                    { value: "27._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.27"},
                    { value: "28._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.28"},
                    { value: "29._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.29"},
                    { value: "30._" + moment().locale('ja').format('YYMMDDhhmmss'), label: "No.30"},
                  ]}
                  isSearchable={false}
                  isRequired
                />
              </div>
              <Field
                name="memo"
                component={Input}
                type="text"
                id="memo"
                maxLength={30}
                placeholder="placeholderMemo"
              />
              <div className="shower-form__section">
                <Field name="is_handicap" component={Checkbox} id="is_handicap" label="labelIsHandicap" />
              </div>
              <div className="shower-form__action">
                <Button
                  label="showerFormAddButton"
                  color="primary"
                  type="submit"
                  disabled={!isValid || isLoading}
                  showSpinner={isLoading}
                />
              </div>
            </Form>
          )}
        />
      </div>
    );
  }
}
//#endregion

export default injectIntl(ShowerForm);
