//#region IMPORTS
// LOCAL CONFIG
import { RESET } from '../../config/constant';
import {
  DevicePlatformTypes,
  LoadingLogin,
  LoadingUser,
  PUSH_NOTIFICATION,
  PushNotificationRegisterFailed,
  PushNotificationRegisterFetch,
  PushNotificationRegisterSuccess,
  RegisterPinpointOptions,
  SetDevicePlatform,
  SetDeviceToken,
  SetUser,
  User,
  USER,
  UserActionTypes,
  SatoFailureType,
  SatoUserInfo,
  GetAreaUsersResponse,
  SatoAreaInfo,
  GetUserLocationResponse,
  GET_AREA_USERS_FETCH,
  GET_AREA_USERS_SUCCESS,
  GET_AREA_USERS_FAILED,
  GET_AREA_USERS_CLEAR,
  GET_USER_LOCATION_FETCH,
  GET_USER_LOCATION_SUCCESS,
  GET_USER_LOCATION_FAILED,
  GET_USER_LOCATION_CLEAR,
  GetAreaUsersFetch,
  GetAreaUsersSuccess,
  GetAreaUsersFailed,
  GetUserLocationFetch,
  GetUserLocationSuccess,
  GetUserLocationFailed,
  SatoAuthParam,
  SatoAuthResponse,
  SatoAuthFailType,
  SATO_AUTH_FETCH,
  SATO_AUTH_FAILED,
  SATO_AUTH_CLEAR,
  SATO_AUTH_SUCCESS,
  SatoAuthFetch,
  SatoAuthSuccess,
  SatoAuthFailed,
  FailureType,
} from './constants';

//#endregion

//#region INTERFACE
interface UserInitialState {
  isLoadingUser: boolean;
  isLoadingLogin: boolean;
  isSignedIn: boolean;
  user: User;
}

interface PushNotificationInitialState {
  pushNotificationRegisterLoading: boolean;
  pushNotificationRegisterParam: RegisterPinpointOptions;
  pushNotificationRegisterResponse: string;
  pushNotificationRegisterError: FailureType;
}

interface GetAreaUsersInitialState {
  getAreaUsersParam: SatoUserInfo;
  getAreaUsersResponse: GetAreaUsersResponse;
  getAreaUsersError: SatoFailureType | FailureType;
  getAreaUsersLoading: boolean;
}

interface GetUserLocationInitialState {
  getUserLocationParam: SatoAreaInfo;
  getUserLocationResponse: GetUserLocationResponse;
  getUserLocationError: SatoFailureType | FailureType;
  getUserLocationLoading: boolean;
}

interface SatoAuthInitialState {
  satoAuthParam?: SatoAuthParam;
  satoAuthResponse?: SatoAuthResponse;
  satoAuthError: SatoAuthFailType;
  satoAuthLoading: boolean;
}

export interface InitialState
  extends UserInitialState,
    PushNotificationInitialState,
    GetAreaUsersInitialState,
    GetUserLocationInitialState,
    SatoAuthInitialState {
  action: string;
  deviceToken: string;
  devicePlatform: DevicePlatformTypes;
}

//#endregion

//#region INITIAL STATE
const userInitialState: UserInitialState = {
  isLoadingUser: false,
  isLoadingLogin: false,
  isSignedIn: false,
  user: {
    username: '',
    roles: [],
    airportId: '',
    loungeId: '',
  },
};

const pushNotificationInitialState: PushNotificationInitialState = {
  pushNotificationRegisterError: '',
  pushNotificationRegisterLoading: false,
  pushNotificationRegisterParam: {
    DeviceToken: '',
    EndpointId: '',
    UserName: '',
  },
  pushNotificationRegisterResponse: '',
};

const getAreaUsersInitialState: GetAreaUsersInitialState = {
  getAreaUsersError: '',
  getAreaUsersLoading: false,
  getAreaUsersParam: {
    areaName: '',
    token: '',
    areaId: '',
    storeName: '',
  },
  getAreaUsersResponse: {
    areaInfo: [],
    isError: '',
    processingCount: undefined,
  },
};

const getUserLocationInitialState: GetUserLocationInitialState = {
  getUserLocationError: '',
  getUserLocationLoading: false,
  getUserLocationParam: {
    token: '',
    userName: '',
    tagId: '',
  },
  getUserLocationResponse: {
    tagId: '',
    isError: '',
    processingCount: undefined,
    userInfo: [],
  },
};

const satoAuthInitialState: SatoAuthInitialState = {
  satoAuthError: '',
  satoAuthLoading: false,
  satoAuthParam: undefined,
  satoAuthResponse: undefined,
};

const initialState: InitialState = {
  ...userInitialState,
  ...pushNotificationInitialState,
  ...getAreaUsersInitialState,
  ...getUserLocationInitialState,
  ...satoAuthInitialState,
  action: '',
  deviceToken: '',
  devicePlatform: DevicePlatformTypes.ios,
};

//#endregion

//#region REDUCER
function userReducer(state = initialState, action: UserActionTypes): InitialState {
  const actions = {
    [USER.SET_USER]: (): InitialState => ({
      ...state,
      action: action.type,
      isSignedIn: true,
      user: (action as SetUser).payload,
    }),
    [USER.LOADING_USER]: (): InitialState => ({
      ...state,
      action: action.type,
      isLoadingUser: (action as LoadingUser).payload,
    }),
    [USER.LOADING_LOGIN]: (): InitialState => ({
      ...state,
      action: action.type,
      isLoadingLogin: (action as LoadingLogin).payload,
    }),
    [USER.SET_DEVICE_TOKEN]: (): InitialState => ({
      ...state,
      action: action.type,
      deviceToken: (action as SetDeviceToken).payload,
    }),
    [RESET.ALL]: (): InitialState => ({
      ...initialState,
      action: action.type,
    }),
    [USER.LOGOUT]: (): InitialState => ({
      ...state,
      action: action.type,
    }),
    [USER.LOGOUT_SUCCESS]: (): InitialState => ({
      ...state,
      ...userInitialState,
      action: action.type,
    }),
    [PUSH_NOTIFICATION.REGISTER.PUSH_NOTIFICATION_REGISTER_FETCH]: (): InitialState => ({
      ...state,
      action: action.type,
      pushNotificationRegisterParam: (action as PushNotificationRegisterFetch).payload,
    }),
    [PUSH_NOTIFICATION.REGISTER.PUSH_NOTIFICATION_REGISTER_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
      pushNotificationRegisterResponse: (action as PushNotificationRegisterSuccess).payload,
    }),
    [PUSH_NOTIFICATION.REGISTER.PUSH_NOTIFICATION_REGISTER_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
      pushNotificationRegisterError: (action as PushNotificationRegisterFailed).payload,
    }),
    [PUSH_NOTIFICATION.REGISTER.PUSH_NOTIFICATION_REGISTER_CLEAR]: (): InitialState => ({
      ...state,
      ...pushNotificationInitialState,
      action: action.type,
    }),
    [USER.SET_DEVICE_PLATFORM]: (): InitialState => ({
      ...state,
      action: action.type,
      devicePlatform: (action as SetDevicePlatform).payload,
    }),
    [GET_AREA_USERS_FETCH]: (): InitialState => ({
      ...state,
      action: action.type,
      getAreaUsersLoading: true,
      getAreaUsersParam: (action as GetAreaUsersFetch).payload,
    }),
    [GET_AREA_USERS_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
      getAreaUsersLoading: false,
      getAreaUsersResponse: (action as GetAreaUsersSuccess).payload,
    }),
    [GET_AREA_USERS_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
      getAreaUsersError: (action as GetAreaUsersFailed).payload,
    }),
    [GET_AREA_USERS_CLEAR]: (): InitialState => ({
      ...state,
      ...getAreaUsersInitialState,
      action: action.type,
    }),
    [GET_USER_LOCATION_FETCH]: (): InitialState => ({
      ...state,
      action: action.type,
      getUserLocationLoading: true,
      getUserLocationParam: (action as GetUserLocationFetch).payload,
    }),
    [GET_USER_LOCATION_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
      getUserLocationLoading: false,
      getUserLocationResponse: (action as GetUserLocationSuccess).payload,
    }),
    [GET_USER_LOCATION_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
      getUserLocationLoading: false,
      getUserLocationError: (action as GetUserLocationFailed).payload,
    }),
    [GET_USER_LOCATION_CLEAR]: (): InitialState => ({
      ...state,
      ...getUserLocationInitialState,
      action: action.type,
    }),
    [SATO_AUTH_FETCH]: (): InitialState => ({
      ...state,
      action: action.type,
      satoAuthLoading: true,
      satoAuthParam: (action as SatoAuthFetch).payload,
    }),
    [SATO_AUTH_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
      satoAuthLoading: false,
      satoAuthResponse: (action as SatoAuthSuccess).payload,
    }),
    [SATO_AUTH_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
      satoAuthLoading: false,
      satoAuthError: (action as SatoAuthFailed).payload,
    }),
    [SATO_AUTH_CLEAR]: (): InitialState => ({
      ...state,
      ...satoAuthInitialState,
      action: action.type,
    }),
    DEFAULT: (): InitialState => state,
  };

  return (actions[action.type] || actions.DEFAULT)();
}

export default userReducer;

//#endregion
