//#region IMPORT
// PACKAGE IMPORT
import gql from 'graphql-tag';

//#endregion

//#region QUERIES
// List Area
const ListArea = gql`
  query ListArea($airport_id: String!, $limit: Int, $nextToken: String) {
    listArea(airport_id: $airport_id, limit: $limit, nextToken: $nextToken) {
      items {
        code
        congestion_level
        updated_by
        updated_at
      }
      nextToken
    }
  }
`;

export const queries = {
  ListArea,
};

//#endregion

//#region MUTATIONS
// Update Area
const UpdateArea = gql`
  mutation UpdateArea($input: UpdateAreaInput!) {
    updateArea(input: $input) {
      code
      congestion_level
      updated_by
      updated_at
    }
  }
`;

export const mutations = {
  UpdateArea,
};

//#endregion

//#region SUBSCRIPTION
// On Update Area
const OnUpdateArea = gql`
  subscription OnUpdateAreaSubscription {
    onUpdateArea {
      code
      congestion_level
      updated_by
      updated_at
    }
  }
`;

export const subscription = {
  OnUpdateArea,
};
//#endregion
