//#region IMPORT
//LOCAL CONFIG
import { ALERT, ALERTTYPE, AlertActionTypes } from './constants';

//#endregion

//#region ACTION
// SHOW & HIDE
export const ShowAlert = (type: ALERTTYPE, message: string): AlertActionTypes => ({
  type: ALERT.SHOW,
  payload: {
    message,
    type,
  },
});

export const HideAlert = (): AlertActionTypes => ({
  type: ALERT.HIDE,
});

//#endregion
