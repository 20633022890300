//#region IMPORT
// LOCAL CONFIG
import { ALERT, ALERTTYPE, AlertActionTypes, ShowAlert } from './constants';
import { RESET } from '../../config/constant';

//#endregion

//#region INTERFACE
export interface AlertInitialState {
  isShowAlert: boolean;
  message: string;
  type: ALERTTYPE;
}

export interface InitialState extends AlertInitialState {
  action: string;
}
//#endregion

//#region INITIAL STATE
const alertInitialState: AlertInitialState = {
  isShowAlert: false,
  message: '',
  type: ALERTTYPE.WARNING,
};

const initialState: InitialState = {
  ...alertInitialState,
  action: '',
};
//#endregion

//#region REDUCER
export default (state = initialState, action: AlertActionTypes): InitialState => {
  const actions = {
    [ALERT.SHOW]: (): InitialState => ({
      ...state,
      isShowAlert: true,
      message: (action as ShowAlert).payload.message,
      type: (action as ShowAlert).payload.type,
      action: (action as ShowAlert).type,
    }),
    [ALERT.HIDE]: (): InitialState => initialState,
    [RESET.ALL]: (): InitialState => initialState,
    DEFAULT: (): InitialState => state,
  };
  return (actions[action.type] || actions.DEFAULT)();
};
//#endregion
