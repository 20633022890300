/* eslint-disable @typescript-eslint/explicit-function-return-type */
//#region IMPORT
// PACKAGE IMPORT
import { takeLatest, put } from 'redux-saga/effects';
import API, { graphqlOperation } from '@aws-amplify/api';

// LOCAL CONFIG
import {
  CONGESTION_UPDATE_FETCH,
  CongestionUpdateFetch,
  CongestionUpdateResponse,
  CongestionListFetch,
  CONGESTION_LIST_FETCH,
  CongestionListResponse,
  queries,
  mutations,
} from './constants';
import { congestionListSuccess, congestionListFailed, congestionUpdateSuccess } from './ActionCongestion';

//#endregion

//#region WORKER
// CONGESTION_LIST_FETCH
function* workerCongestionList(action: CongestionListFetch) {
  const invalidFilterError = 'Invalid Filter Type';

  try {
    const { ...variables } = action.payload;
    const query = queries.ListArea;
    const response = yield API.graphql(graphqlOperation(query, variables));
    const data = (response.data as CongestionListResponse).listArea;
    if (typeof data === 'string') {
      yield put(congestionListFailed({ message: invalidFilterError }));
      return;
    }

    yield put(congestionListSuccess(data));
  } catch (e) {
    yield put(congestionListFailed(e));
  }
}

// CONGESTION_UPDATE_FETCH
function* workerCongestionUpdate(action: CongestionUpdateFetch) {
  try {
    const { airport_id, code, congestionLevel } = action.payload;
    const mutation = mutations.UpdateArea;
    const response = yield API.graphql(
      // eslint-disable-next-line @typescript-eslint/camelcase
      graphqlOperation(mutation, { input: { airport_id, code, congestion_level: congestionLevel } })
    );
    const data = (response.data as CongestionUpdateResponse).updateArea;

    yield put(congestionUpdateSuccess(data));
  } catch (error) {}
}
//#endregion

export default [
  takeLatest(CONGESTION_UPDATE_FETCH, workerCongestionUpdate),
  takeLatest(CONGESTION_LIST_FETCH, workerCongestionList),
];
