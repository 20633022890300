//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';

// LOCAL CONFIG
import './_index.scss';
//#endregion

//#region INTERFACE
interface OwnProps {
  className?: string;
  data: React.ReactElement<SVGElement> | string;
}
//#endregion

//#region COMPONENT
function Icon({ className, data }: OwnProps): React.ReactElement {
  return <>{typeof data === 'string' ? <i className={`material-icons ${className}`}>{data}</i> : data}</>;
}
//#endregion

export default Icon;
