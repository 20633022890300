import React from 'react';
import Auth from '@aws-amplify/auth';
import { connect } from 'react-redux';
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { AppState } from '../../../../bootstrap/ReduxStore';
import { PATH } from '../../../navigation/constants';
import { UserRoles } from '../../../user/constants';

interface OwnProps extends RouteComponentProps {
  history: History;
}

interface StateProps {
  roles: UserRoles[];
}

type Props = OwnProps & StateProps;

const isNotAuthenticated = (WrappedComponent: any): any => {
  class Index extends React.Component<Props> {
    async UNSAFE_componentWillMount(): Promise<void> {
      try {
        const auth: any = await Auth.currentSession();
        if (auth) {
          const roles = auth.accessToken && auth.accessToken.payload && auth.accessToken.payload['cognito:groups'];
          if (roles && roles.length > 0 && roles.includes(UserRoles.KITCHEN_STAFF)) {
            this.props.history.push(PATH.MEALS_ORDER);
          } else if (roles && roles.length > 0 && roles.includes(UserRoles.SHOWER_STAFF)) {
            this.props.history.push(PATH.SHOWER_BOOKING);
          } else if (roles && roles.length > 0 && roles.includes(UserRoles.FLOOR_STAFF)) {
            this.props.history.push(PATH.CONGESTION);
          } else {
            this.props.history.push(PATH.UNAUTHORIZED);
          }
        }
      } catch (err) {
        //
      }
    }

    render(): React.ReactElement {
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state: AppState): StateProps => ({
    roles: state.user && state.user.user ? state.user.user.roles : [],
  });

  return connect(mapStateToProps)(Index);
};

export default isNotAuthenticated;
