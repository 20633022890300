//#region IMPORTS
// PACKAGE IMPORTS
import { connect } from 'react-redux';

// LOCAL CONFIG
import StaffCall, { StateProps, DispatchProps } from './StaffCall';
import { AppState } from '../../../../bootstrap/ReduxStore';
import {
  OnStaffCallSubscription,
  StaffCallSubscriptionCreateClear,
  StaffCallSubscriptionCreateFailed,
  StaffCallSubscriptionCreateFetch,
  StaffCallSubscriptionCreateSuccess,
  StaffCallListFetch,
  StaffCallUpdateFetch,
} from '../../ActionStaffCall';
import { ShowAlert } from '../../../alert/ActionAlert';
//#endregion

//#region REDUX BINDINGS
const mapStateToProps = (state: AppState): StateProps => ({
  staffCallSubscriptionStatus: state.staffCall.staffCallSubscriptionStatus,
  staffCallListResponse: state.staffCall.staffCallListResponse,
  action: state.staffCall.action,
  staffCallUpdateResponse: state.staffCall.staffCallUpdateResponse,
  staffCallUpdateParam: state.staffCall.staffCallUpdateParam,
  staffCallCreateUpdateError: state.staffCall.staffCallUpdateError,
});
const mapDispatchToProps: DispatchProps = {
  OnStaffCallSubscription,
  StaffCallSubscriptionCreateClear,
  StaffCallSubscriptionCreateFailed,
  StaffCallSubscriptionCreateFetch,
  StaffCallSubscriptionCreateSuccess,
  StaffCallListFetch,
  StaffCallUpdateFetch,
  ShowAlert,
};
//#endregion

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StaffCall);
