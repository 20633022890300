//#region IMPORTS
// PACKAGE IMPORT
import { connect } from 'react-redux';

// LOCAL CONFIG
import {
  MealOrderSubscriptionCreateFailed,
  MealOrderSubscriptionCreateSuccess,
  MealOrderSubscriptionRemoveFailed,
  MealOrderSubscriptionRemoveSuccess,
  OnCreateUpdateMealOrder,
  MealOrderListFetch,
  MealOrderCreateFetch,
  MealOrderUpdateFetch,
  MealOrderSubscriptionRemoveFetch,
  MealOrderSubscriptionCreateFetch,
  SetLatestOrderCounter,
  ToggleSortedAction,
} from '../../ActionMeals';
import Meals, { DispatchProps, StateProps } from './Meals';
import { AppState } from '../../../../bootstrap/ReduxStore';

//#endregion

//#region REDUX BINDINGS
const mapStateToProps = (state: AppState): StateProps => ({
  mealOrderListResponse: state.meals.mealOrderListResponse,
  mealOrderListParam: state.meals.mealOrderListParam,
  action: state.meals.action,
  onCreateUpdateMealOrderBookingResponse: state.meals.onCreateUpdateMealOrderResponse,
  onCreateUpdateSubscriptionParam: state.meals.onCreateUpdateMealOrderParam,
  mealOrderCreateParam: state.meals.mealOrderCreateParam,
  subscriptionRegistrationStatus: state.meals.subscriptionRegistrationStatus,
  mealOrderListLoading: state.meals.mealOrderListLoading,
  airportId: state.user.user.airportId,
  loungeId: state.user.user.loungeId,
  isMuted: state.wrapper.isMuted,
  latestOrderCounter: state.meals.latestOrderCounter,
  isAscSorted: state.meals.isAscSorted,
});

const mapDispatchToProps: DispatchProps = {
  MealOrderSubscriptionCreateSuccess,
  MealOrderSubscriptionCreateFailed,
  MealOrderSubscriptionRemoveSuccess,
  MealOrderSubscriptionRemoveFailed,
  OnCreateUpdateMealOrder,
  MealOrderListFetch,
  MealOrderCreateFetch,
  MealOrderUpdateFetch,
  MealOrderSubscriptionCreateFetch,
  MealOrderSubscriptionRemoveFetch,
  SetLatestOrderCounter,
  ToggleSortedAction,
};

//#endregion

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Meals);
