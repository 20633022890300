//#region IMPORTS

// PACKAGE IMPORTS
import React from 'react';
import API, { graphqlOperation } from '@aws-amplify/api';
import { RouteComponentProps, withRouter } from 'react-router';
import { injectIntl, IntlShape } from 'react-intl';
import Observable from 'zen-observable';
import lodash from 'lodash';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';

// LOCAL CONFIG IMPORTS
import {
  ON_CREATE_UPDATE_SHOWER_BOOKING_SUCCESS,
  OnCreateUpdateShowerBookingPayload,
  SHOWER_BOOKING_LIST_SUCCESS,
  ShowerActionTypes,
  ShowerBooking,
  ShowerBookingCreateUpdateType,
  ShowerBookingFilterType,
  ShowerBookingListByStatusAndDate,
  ShowerBookingListByStatusParam,
  ShowerBookingListMultiStatusResponse,
  ShowerBookingListParam,
  ShowerBookingListResponse,
  ShowerBookingListTypes,
  ShowerBookingStatus,
  ShowerRoomListParam,
  subscription,
  BookingForm,
  ShowerBookingCreateParam,
  SHOWER_BOOKING_CREATE_SUCCESS,
  SHOWER_BOOKING_CREATE_FAILED,
  ShowerBookingUpdateParam,
  StartShowerBookingParam,
  CancelShowerBookingParam,
  UpdateShowerBookingParam,
  ShowerRoom,
  OnCallShowerBooking,
  OnCancelShowerBooking,
  OnTimeoutShowerBooking,
  OnCompleteShowerBooking,
  OnStartShowerBooking,
  OnNotifyShowerBooking,
  OnUpdateShowerBooking,
  FailureType,
  GraphQLSubscriptionResult,
  SubscriptionRegistrationStatus,
  ShowerLoungeId,
} from '../../constants';
import { ALERTTYPE } from '../../../alert/constants';
import { PATH } from '../../../navigation/constants';

// COMPONENT IMPORTS
import { Button, Header, Modal } from '../../../../components';
import ShowerForm from '../ShowerForm';
import ShowerDetail from '../ShowerDetail';
import ShowerListItem from '../ShowerListItem';
import Column, { ColumnColor, ColumnProps } from '../../../../components/Column';
import './Shower.scss';

//#endregion

//#region INTERFACE
export interface OwnProps extends RouteComponentProps {
  intl: IntlShape;
  isArchive?: boolean;
}

export interface DispatchProps {
  OnCreateUpdateShowerBooking(payload: OnCreateUpdateShowerBookingPayload): ShowerActionTypes;
  ShowerBookingSubscriptionRemoveFailed(payload: FailureType): ShowerActionTypes;
  ShowerBookingSubscriptionCreateFailed(payload: FailureType): ShowerActionTypes;
  ShowerBookingSubscriptionCreateSuccess(): ShowerActionTypes;
  ShowerBookingSubscriptionRemoveSuccess(): ShowerActionTypes;
  ShowerBookingSubscriptionCreateFetch(): ShowerActionTypes;
  ShowerBookingSubscriptionRemoveFetch(): ShowerActionTypes;
  ShowerBookingListFetch(
    payload: ShowerBookingListParam | ShowerBookingListByStatusParam | ShowerBookingListByStatusAndDate
  ): ShowerActionTypes;
  ShowerRoomListFetch(payload: ShowerRoomListParam): ShowerActionTypes;
  ShowerBookingCreateFetch(payload: ShowerBookingCreateParam): ShowerActionTypes;
  ShowAlert: (type: ALERTTYPE, message: string) => void;
  ShowerBookingUpdateFetch(payload: ShowerBookingUpdateParam): ShowerActionTypes;
}

export interface StateProps {
  data: ShowerBooking[];
  onCreateUpdateSubscriptionParam?: OnCreateUpdateShowerBookingPayload;
  action: string;
  showerBookingListResponse: ShowerBookingListResponse | {};
  showerBookingListParam: ShowerBookingListTypes | {};
  onCreateUpdateShowerBookingResponse?: ShowerBooking;
  showerBookingUpdateRespons: ShowerBooking | {};
  roomList: ShowerRoom[];
  subscriptionRegistrationStatus: SubscriptionRegistrationStatus;
  airport_id: string;
  lounge_id: string;
}

type Props = OwnProps & DispatchProps & StateProps;

interface State {
  showerData: ShowerBookingListMultiStatusResponse;
  showerBookingDetail?: ShowerBooking;
  modalVisible: boolean;
  modalAddVisible: boolean;
  selectedDate?: Date | null;
  memoData:{ [reservedId: string]: string };
}
//#endregion

//#region COMPONENT
class Shower extends React.Component<Props, State> {
  //#region CLASS PROPERTIES
  private interval: number | undefined;
  private readonly addQueueModalRef: React.RefObject<Modal>;
  private readonly detailModalRef: React.RefObject<Modal>;

  private OnCallShowerBooking?: ZenObservable.Subscription;
  private OnCancelShowerBooking?: ZenObservable.Subscription;
  private OnCompleteShowerBooking?: ZenObservable.Subscription;
  private OnCreateShowerBooking?: ZenObservable.Subscription;
  private OnStartShowerBooking?: ZenObservable.Subscription;
  private OnNotifyShowerBooking?: ZenObservable.Subscription;
  private OnTimeoutShowerBooking?: ZenObservable.Subscription;
  private OnUpdateShowerBooking?: ZenObservable.Subscription;
  //#endregion

  //#region CONSTRUCTOR & LIFE CYCLE METHODS
  constructor(props: Props) {
    super(props);
    this.state = {
      showerData: {
        items: {
          [ShowerBookingStatus.IN_PROGRESS]: [],
          [ShowerBookingStatus.READY]: [],
          [ShowerBookingStatus.NOTIFIED]: [],
          [ShowerBookingStatus.ANNOUNCED]: [],
          [ShowerBookingStatus.IN_QUEUE]: [],
          [ShowerBookingStatus.COMPLETED]: [],
        },
      },
      modalVisible: false,
      modalAddVisible: false,
      showerBookingDetail: undefined,
      selectedDate: moment().toDate(),
      memoData: {},
    };

    this.addQueueModalRef = React.createRef<Modal>();
    this.detailModalRef = React.createRef<Modal>();

    this.createSubscription = this.createSubscription.bind(this);
    this.unsubscribeSubscriptions = this.unsubscribeSubscriptions.bind(this);
    this.handleSubscriptionUpdate = this.handleSubscriptionUpdate.bind(this);
    this.handleShowerBookingListSuccess = this.handleShowerBookingListSuccess.bind(this);
    this.fetchShowerBookingAndRoomList = this.fetchShowerBookingAndRoomList.bind(this);
    this.handleShowDetail = this.handleShowDetail.bind(this);
    this.handleShowAdd = this.handleShowAdd.bind(this);
    this.handleBookingSubmit = this.handleBookingSubmit.bind(this);
    this.handleBookingUpdate = this.handleBookingUpdate.bind(this);
    this.handleReload = this.handleReload.bind(this);
    this.handleChangeMemo = this.handleChangeMemo.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.createSubscription();
    //this.interval = window.setInterval(() => {
    //  //this.unsubscribeSubscription();
    //  //this.createSubscription();
    //  console.log("called shower interval")
    //  if(!this.props.isArchive){
    //    this.fetchShowerBookingAndRoomList(this.props.isArchive ? moment().format('YYYY-MM-DD') : undefined);
    //  }
    //}, 1 * 7 * 1000);
    this.fetchShowerBookingAndRoomList(this.props.isArchive ? moment().format('YYYY-MM-DD') : undefined);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (prevProps.action !== this.props.action) {
      this.handleReduxActions(this.props.action);
    }

    if (prevState.modalVisible !== this.state.modalVisible) {
      if (this.state.modalVisible) {
        this.detailModalRef.current && this.detailModalRef.current.openModal();
      } else {
        this.detailModalRef.current && this.detailModalRef.current.closeModal();
      }
    }

    if (prevState.modalAddVisible !== this.state.modalAddVisible) {
      if (this.state.modalAddVisible) {
        this.addQueueModalRef.current && this.addQueueModalRef.current.openModal();
      } else {
        this.addQueueModalRef.current && this.addQueueModalRef.current.closeModal();
      }
    }
    
  }

  componentWillUnmount(): void {
    this.unsubscribeSubscriptions();
  }
  //#endregion

  //#region CLASS METHODS

  handleReduxActions(action: string): void {
    const actions = {
      [SHOWER_BOOKING_LIST_SUCCESS as string]: (): void => {
        this.handleShowerBookingListSuccess();
      },
      [ON_CREATE_UPDATE_SHOWER_BOOKING_SUCCESS as string]: (): void => {
        this.props.ShowerRoomListFetch({airport_id: this.props.airport_id, lounge_id: ShowerLoungeId[this.props.airport_id][this.props.lounge_id]});
      },
      [SHOWER_BOOKING_CREATE_SUCCESS as string]: (): void => {
        this.addQueueModalRef.current && this.addQueueModalRef.current.closeModal();
      },
      [SHOWER_BOOKING_CREATE_FAILED]: (): void => {
        this.props.ShowAlert(ALERTTYPE.WARNING, 'alert.addQueueFailed');
      },
      DEFAULT: (): void => {},
    };

    return (actions[action] || actions.DEFAULT)();
  }

  async createSubscription(): Promise<void> {
    if (
      this.props.subscriptionRegistrationStatus === SubscriptionRegistrationStatus.UNREGISTERED ||
      this.props.subscriptionRegistrationStatus === SubscriptionRegistrationStatus.REGISTRATION_FAILED
    ) {
      try {
        this.OnCallShowerBooking = ((await API.graphql(
          graphqlOperation(subscription.OnCallShowerBooking)
        )) as Observable<GraphQLSubscriptionResult>).subscribe({
          next: (data: GraphQLSubscriptionResult) =>
            this.handleSubscriptionUpdate({
              onCallShowerBooking: data.value.data.onCallShowerBooking,
              mutationType: ShowerBookingCreateUpdateType.CALL,
            }),
        });

        this.OnTimeoutShowerBooking = ((await API.graphql(
          graphqlOperation(subscription.OnTimeoutShowerBooking)
        )) as Observable<GraphQLSubscriptionResult>).subscribe({
          next: (data: GraphQLSubscriptionResult) =>
            this.handleSubscriptionUpdate({
              onTimeoutShowerBooking: data.value.data.onTimeoutShowerBooking,
              mutationType: ShowerBookingCreateUpdateType.TIMEOUT,
            }),
        });

        this.OnCancelShowerBooking = ((await API.graphql(
          graphqlOperation(subscription.OnCancelShowerBooking)
        )) as Observable<GraphQLSubscriptionResult>).subscribe({
          next: (data: GraphQLSubscriptionResult) =>
            this.handleSubscriptionUpdate({
              onCancelShowerBooking: data.value.data.onCancelShowerBooking,
              mutationType: ShowerBookingCreateUpdateType.CANCEL,
            }),
        });

        this.OnCompleteShowerBooking = ((await API.graphql(
          graphqlOperation(subscription.OnCompleteShowerBooking)
        )) as Observable<GraphQLSubscriptionResult>).subscribe({
          next: (data: GraphQLSubscriptionResult) =>
            this.handleSubscriptionUpdate({
              onCompleteShowerBooking: data.value.data.onCompleteShowerBooking,
              mutationType: ShowerBookingCreateUpdateType.COMPLETE,
            }),
        });

        this.OnCreateShowerBooking = ((await API.graphql(
          graphqlOperation(subscription.OnCreateShowerBooking)
        )) as Observable<GraphQLSubscriptionResult>).subscribe({
          next: (data: GraphQLSubscriptionResult) =>
            this.handleSubscriptionUpdate({
              onCreateShowerBooking: data.value.data.onCreateShowerBooking,
              mutationType: ShowerBookingCreateUpdateType.CREATE,
            }),
        });

        this.OnStartShowerBooking = ((await API.graphql(
          graphqlOperation(subscription.OnStartShowerBooking)
        )) as Observable<GraphQLSubscriptionResult>).subscribe({
          next: (data: GraphQLSubscriptionResult) =>
            this.handleSubscriptionUpdate({
              onStartShowerBooking: data.value.data.onStartShowerBooking,
              mutationType: ShowerBookingCreateUpdateType.START,
            }),
        });

        this.OnNotifyShowerBooking = ((await API.graphql(
          graphqlOperation(subscription.OnNotifyShowerBooking)
        )) as Observable<GraphQLSubscriptionResult>).subscribe({
          next: (data: GraphQLSubscriptionResult) =>
            this.handleSubscriptionUpdate({
              onNotifyShowerBooking: data.value.data.onNotifyShowerBooking,
              mutationType: ShowerBookingCreateUpdateType.NOTIFY,
            }),
        });

        this.OnUpdateShowerBooking = ((await API.graphql(
          graphqlOperation(subscription.OnUpdateShowerBooking)
        )) as Observable<GraphQLSubscriptionResult>).subscribe({
          next: (data: GraphQLSubscriptionResult) =>
            this.handleSubscriptionUpdate({
              onUpdateShowerBooking: data.value.data.onUpdateShowerBooking,
              mutationType: ShowerBookingCreateUpdateType.UPDATE,
            }),
        });

        this.props.ShowerBookingSubscriptionCreateSuccess();
      } catch (e) {
        this.props.ShowerBookingSubscriptionCreateFailed(e);
        this.unsubscribeSubscriptions();
      }
    }
  }

  unsubscribeSubscriptions(): void {
    try {
      this.props.ShowerBookingSubscriptionRemoveFetch();

      this.OnCallShowerBooking && this.OnCallShowerBooking.unsubscribe();
      this.OnCancelShowerBooking && this.OnCancelShowerBooking.unsubscribe();
      this.OnCompleteShowerBooking && this.OnCompleteShowerBooking.unsubscribe();
      this.OnCreateShowerBooking && this.OnCreateShowerBooking.unsubscribe();
      this.OnStartShowerBooking && this.OnStartShowerBooking.unsubscribe();
      this.OnNotifyShowerBooking && this.OnNotifyShowerBooking.unsubscribe();
      this.OnTimeoutShowerBooking && this.OnTimeoutShowerBooking.unsubscribe();
      this.OnUpdateShowerBooking && this.OnUpdateShowerBooking.unsubscribe();

      this.props.ShowerBookingSubscriptionRemoveSuccess();
    } catch (e) {
      this.props.ShowerBookingSubscriptionRemoveFailed(e);
    }
  }

  fetchShowerBookingAndRoomList(date?: string): void {
    const { isArchive, airport_id, lounge_id } = this.props;
    this.props.ShowerBookingListFetch({
      filterType: isArchive ? ShowerBookingFilterType.STATUS_DATE : ShowerBookingFilterType.STATUS,
      statusTypes: isArchive
        ? [ShowerBookingStatus.COMPLETED, ShowerBookingStatus.TIMEOUT, ShowerBookingStatus.CANCELLED]
        : [ShowerBookingStatus.IN_QUEUE, ShowerBookingStatus.READY, ShowerBookingStatus.NOTIFIED, ShowerBookingStatus.ANNOUNCED, ShowerBookingStatus.IN_PROGRESS],
      limit: 1000,
      date,
      airport_id,
      lounge_id:ShowerLoungeId[airport_id][lounge_id],
    } as ShowerBookingListByStatusAndDate);
    this.props.ShowerRoomListFetch({airport_id: airport_id, lounge_id: ShowerLoungeId[airport_id][lounge_id]});
  }

  checkIfBookingListFilterByStatus(response: ShowerBookingListTypes): boolean {
    return 'statusTypes' in response;
  }

  handleShowerBookingListSuccess(): void {
    const { showerBookingListResponse, showerBookingListParam } = this.props;
    if (!this.checkIfBookingListFilterByStatus(showerBookingListParam as ShowerBookingListTypes)) {
      return;
    }

    this.setState({
      showerData: showerBookingListResponse as ShowerBookingListMultiStatusResponse,
    });

    // copy memo data to state
    if(showerBookingListResponse !== {}){
      const data = (showerBookingListResponse as ShowerBookingListMultiStatusResponse).items;
      let bookings: ShowerBooking[] = [];
      if(data[ShowerBookingStatus.IN_QUEUE] !== undefined || data[ShowerBookingStatus.IN_QUEUE] !== []){
        Array.prototype.push.apply(bookings, data[ShowerBookingStatus.IN_QUEUE] as ShowerBooking[])
      }
      if(data[ShowerBookingStatus.READY] !== undefined || data[ShowerBookingStatus.READY] !== []){
        Array.prototype.push.apply(bookings, data[ShowerBookingStatus.READY] as ShowerBooking[])
      }
      if(data[ShowerBookingStatus.NOTIFIED] !== undefined || data[ShowerBookingStatus.NOTIFIED] !== []){
        Array.prototype.push.apply(bookings, data[ShowerBookingStatus.NOTIFIED] as ShowerBooking[])
      }
      if(data[ShowerBookingStatus.ANNOUNCED] !== undefined || data[ShowerBookingStatus.ANNOUNCED] !== []){
        Array.prototype.push.apply(bookings, data[ShowerBookingStatus.ANNOUNCED] as ShowerBooking[])
      }
      let newMemoData: { [reservedId: string]: string } = {};
      for(let d = 0; d < bookings.length; ++d){
        newMemoData[bookings[d].device_id] = bookings[d].memo;
      }
      this.setState({memoData: newMemoData});
    }
  }

  handleSubscriptionUpdate(payload: OnCreateUpdateShowerBookingPayload): void {
    const prevPayload = this.props.onCreateUpdateSubscriptionParam;

    const updates = {
      [ShowerBookingCreateUpdateType.CALL as string]: (): ShowerBooking => {
        return (payload as OnCallShowerBooking).onCallShowerBooking;
      },
      [ShowerBookingCreateUpdateType.CANCEL]: (): ShowerBooking => {
        return (payload as OnCancelShowerBooking).onCancelShowerBooking;
      },
      [ShowerBookingCreateUpdateType.TIMEOUT]: (): ShowerBooking => {
        return (payload as OnTimeoutShowerBooking).onTimeoutShowerBooking;
      },
      [ShowerBookingCreateUpdateType.COMPLETE]: (): ShowerBooking => {
        return (payload as OnCompleteShowerBooking).onCompleteShowerBooking;
      },
      [ShowerBookingCreateUpdateType.START]: (): ShowerBooking => {
        return (payload as OnStartShowerBooking).onStartShowerBooking;
      },
      [ShowerBookingCreateUpdateType.NOTIFY]: (): ShowerBooking => {
        return (payload as OnNotifyShowerBooking).onNotifyShowerBooking;
      },
      [ShowerBookingCreateUpdateType.UPDATE]: (): ShowerBooking => {
        return (payload as OnUpdateShowerBooking).onUpdateShowerBooking;
      },
      DEFAULT: (): string => 'invalid',
    };

    const currentUpdate = (updates[payload.mutationType] || updates.DEFAULT)();

    if (
      this.state.showerBookingDetail &&
      typeof currentUpdate !== 'string' &&
      this.state.showerBookingDetail.device_id === currentUpdate.device_id
    ) {
      this.setState({ showerBookingDetail: currentUpdate });
    }

    if (lodash.isEqual(prevPayload, payload)) return;
    this.fetchShowerBookingAndRoomList();
  }

  handleBookingSubmit(booking: BookingForm): void {
    // TODO 暫定実装
    window.localStorage.setItem(booking.device_id, booking.memo);
    this.props.ShowerBookingCreateFetch({
      device_id: booking.device_id,
      is_handicap: booking.is_handicap,
      use_app: false,
      airport_id: booking.airport_id,
      lounge_id: booking.lounge_id,
      table_id: booking.table_id,
      memo: booking.memo,
      email_address: "",
      pnr: "-------",
      carrier: "---",
      flight_number: "--",
      date_of_flight: "--/--",
      seat_number: "----",
      locale: "ja",
    });
  }

  handleBookingUpdate(
    booking: ShowerBooking,
    updateType: ShowerBookingCreateUpdateType,
    shower_room?: string,
    memo?: string,
    is_left_room?: boolean,
    is_cleaning_room?: boolean
  ): void {
    const { device_id, airport_id, lounge_id } = booking;
    if (updateType === ShowerBookingCreateUpdateType.START) {
      if (typeof shower_room !== 'string' ) {
        this.props.ShowAlert(ALERTTYPE.WARNING, 'alert.startShowerFailed');
      } else {
        if(!memo){
          this.props.ShowerBookingUpdateFetch({
            updateType,
            device_id,
            airport_id,
            lounge_id,
            shower_room,
            memo:"",
          } as StartShowerBookingParam);
        }else{
          this.props.ShowerBookingUpdateFetch({
            updateType,
            device_id,
            airport_id,
            lounge_id,
            shower_room,
            memo,
          } as StartShowerBookingParam);
        }
      }
    }else if(updateType === ShowerBookingCreateUpdateType.CANCEL){
      this.props.ShowerBookingUpdateFetch({
          updateType,
          device_id,
          airport_id,
          lounge_id,
          memo,
          use_app: false,
        } as CancelShowerBookingParam);
    }else if(updateType === ShowerBookingCreateUpdateType.UPDATE){
      this.props.ShowerBookingUpdateFetch({
        updateType,
        device_id,
        airport_id,
        lounge_id,
        is_left_room,
        is_cleaning_room
      } as UpdateShowerBookingParam);
    } else {
      this.props.ShowerBookingUpdateFetch({
        updateType,
        device_id,
        airport_id,
        lounge_id,
      });
    }
  }

  handleShowDetail(data: ShowerBooking): void {
    this.setState({ showerBookingDetail: data, modalVisible: true });
  }

  handleShowAdd(): void {
    this.setState({ modalAddVisible: true });
  }

  handleReload(): void {
    this.unsubscribeSubscriptions();
    this.createSubscription();
    this.fetchShowerBookingAndRoomList(this.props.isArchive ? moment().format('YYYY-MM-DD') : undefined);
  }

  handleChangeMemo(device_id: string, value:string): void {
    let newMemoData = Object.assign({}, this.state.memoData);
    newMemoData[device_id] = value;
    this.setState({memoData:newMemoData});
  }
  //#endregion

  //#region RENDER

  renderDetailModal(): React.ReactElement {
    if (!this.state.modalVisible || this.state.showerBookingDetail == null) return <></>;
    const { roomList } = this.props;
    const { showerData } = this.state;
    const dataReady = showerData.items[ShowerBookingStatus.READY]|| [];
    const dataNotified = showerData.items[ShowerBookingStatus.NOTIFIED] || [];
    const dataAnnounced = showerData.items[ShowerBookingStatus.ANNOUNCED] || [];
    const dataReadyNotifiedAnnounced = dataReady.concat(dataNotified).concat(dataAnnounced)
    const dataInProgress = lodash.uniqBy(showerData.items[ShowerBookingStatus.IN_PROGRESS], 'device_id') || [];

    return (
      <Modal
        mode={4}
        ref={this.detailModalRef}
        title="labelBookingDetail"
        content={
          <ShowerDetail
            data={this.state.showerBookingDetail}
            totalReady={dataReadyNotifiedAnnounced.length + dataInProgress.length}
            roomList={roomList}
            onClickActionButton={this.handleBookingUpdate}
            onChangeMemo={this.handleChangeMemo}
            memoData={this.state.memoData}
          />
        }
        onClosed={(): void => this.setState({ modalVisible: false })}
      />
    );
  }

  renderModalAdd(): React.ReactElement {
    if (!this.state.modalAddVisible) return <></>;

    return (
      <Modal
        mode={4}
        title="showerFormAddTitle"
        isNotFullOnDesktop
        content={<ShowerForm airport_id={this.props.airport_id} lounge_id={ShowerLoungeId[this.props.airport_id][this.props.lounge_id]} onSubmit={this.handleBookingSubmit} />}
        ref={this.addQueueModalRef}
        onClosed={(): void => this.setState({ modalAddVisible: false })}
      />
    );
  }

  renderColumn(): ColumnProps[] {
    const { isArchive, roomList } = this.props;
    const { showerData } = this.state;
    const dataReady = showerData.items[ShowerBookingStatus.READY] || [];
    const dataNotified = showerData.items[ShowerBookingStatus.NOTIFIED] || [];
    const dataAnnounced = showerData.items[ShowerBookingStatus.ANNOUNCED] || [];
    const dataReadyNotifiedAnnounced = dataReady.concat(dataNotified).concat(dataAnnounced)
    const dataInQueue = showerData.items[ShowerBookingStatus.IN_QUEUE] || [];
    const dataCompleted = showerData.items[ShowerBookingStatus.COMPLETED] || [];
    const dataCancelled = showerData.items[ShowerBookingStatus.CANCELLED] || [];
    const dataTimeout = showerData.items[ShowerBookingStatus.TIMEOUT] || [];
    const queueGroup = [...dataNotified, ...dataAnnounced, ...dataReady, ...dataInQueue];
    const dataInProgress = lodash.uniqBy(showerData.items[ShowerBookingStatus.IN_PROGRESS], 'device_id') || [];
    if (isArchive) {
      return [
        {
          title: 'showerGroupCompleted',
          count: dataCompleted.length,
          color: ColumnColor.SECONDARY,
          content: dataCompleted.map((data: ShowerBooking) => (
            <ShowerListItem
              data={data}
              key={`${data.airport_id}#${data.lounge_id}#shower-booking#${data.status}#${data.timestamp}`}
              onClickActionButton={this.handleBookingUpdate}
              onClickDetail={this.handleShowDetail}
              totalReady={dataCompleted.length}
              roomList={roomList}
            />
          )),
        },
        {
          title: 'showerGroupTimeout',
          count: dataTimeout.length,
          color: ColumnColor.PRIMARY,
          content: dataTimeout.map((data: ShowerBooking) => (
            <ShowerListItem
              data={data}
              key={`${data.airport_id}#${data.lounge_id}#shower-booking#${data.status}#${data.timestamp}`}
              onClickActionButton={this.handleBookingUpdate}
              onClickDetail={this.handleShowDetail}
              totalReady={dataTimeout.length}
              roomList={roomList}
            />
          )),
        },
        {
          title: 'showerGroupCancelled',
          count: dataCancelled.length,
          color: ColumnColor.PRIMARY,
          content: dataCancelled.map((data: ShowerBooking) => (
            <ShowerListItem
              data={data}
              key={`${data.airport_id}#${data.lounge_id}#shower-booking#${data.status}#${data.timestamp}`}
              onClickActionButton={this.handleBookingUpdate}
              onClickDetail={this.handleShowDetail}
              totalReady={dataTimeout.length}
              roomList={roomList}
            />
          )),
        },
      ];
    }

    return [
      {
        title: 'showerGroupQueue',
        count: queueGroup.length,
        color: ColumnColor.SECONDARY,
        content: queueGroup.map((data: ShowerBooking) => (
          <ShowerListItem
            data={data}
            key={`${data.airport_id}#${data.lounge_id}#shower-booking#${data.status}#${data.timestamp}`}
            onClickActionButton={this.handleBookingUpdate}
            onClickDetail={this.handleShowDetail}
            totalReady={dataReadyNotifiedAnnounced.length + dataInProgress.length}
            roomList={roomList}
            onChangeMemo={this.handleChangeMemo}
            memoData={this.state.memoData}
          />
        )),
      },
      {
        title: 'showerGroupInUse',
        count: dataInProgress.length,
        color: ColumnColor.PRIMARY,
        content: dataInProgress.map((data: ShowerBooking) => (
          <ShowerListItem
            data={data}
            key={`${data.airport_id}#${data.lounge_id}#shower-booking#${data.status}#${data.timestamp}`}
            onClickActionButton={this.handleBookingUpdate}
            onClickDetail={this.handleShowDetail}
            totalReady={dataReadyNotifiedAnnounced.length + dataInProgress.length}
            roomList={roomList}
          />
        )),
      },
    ];
  }

  render(): React.ReactNode {
    const { isArchive, history } = this.props;
    return (
      <div className="shower">
        <Header
          title={isArchive ? 'showerArchiveTitle' : 'showerTitle'}
          backButton={isArchive}
          action={
            !isArchive
              ? [
                  <Button key="add" label="showerActionAdd" color="primary" icon="add" onClick={this.handleShowAdd} />,
                  <Button
                    key="reload"
                    label="mealsActionReload"
                    color="secondary"
                    icon="loop"
                    className="meals__header__reloadButton"
                    onClick={this.handleReload}
                  />,
                  <Button
                    key="archive"
                    iconOnly
                    icon="history"
                    className="archive"
                    onClick={(): void => {
                      history.push(PATH.SHOWER_BOOKING_ARCHIVE);
                    }}
                  />,
                ]
              : [
                  <ReactDatePicker
                    dateFormat="dd MMM yyyy"
                    key="date-picker-key"
                    selected={this.state.selectedDate}
                    locale="ja"
                    popperPlacement="top-end"
                    showPopperArrow={false}
                    onChange={(date: Date): void => {
                      this.fetchShowerBookingAndRoomList(moment(date).format('YYYY-MM-DD'));
                      this.setState({ selectedDate: date });
                    }}
                    className="staff-call__archive__input"
                  />,
                ]
          }
        />
        <div className="shower__content">
          <div className="shower__content__list-group">
            <Column data={this.renderColumn()} />
          </div>
        </div>

        {this.renderModalAdd()}
        {this.renderDetailModal()}
      </div>
    );
  }

  //#endregion
}

export default withRouter(injectIntl(Shower));

//#endregion
