//#region IMPORTS

import {
  CONSOLE_LOG,
  ConsoleLog,
  GET_DEVICE_TOKEN_FETCH,
  GET_DEVICE_TOKEN_RECEIVED,
  GetDeviceTokenReceived,
  NEW_DEVICE_TOKEN,
  NewDeviceToken,
  RECEIVED_ACTION_FROM_NATIVE,
  RECEIVED_ACTION_FROM_NATIVE_CLEAR,
  RECEIVED_ACTION_FROM_NATIVE_ERROR,
  RECEIVED_ACTION_FROM_NATIVE_ERROR_CLEAR,
  ReceivedActionFromNative,
  ReceivedActionFromNativeError,
  SEND_ACTION_TO_NATIVE,
  SEND_ACTION_TO_NATIVE_CLEAR,
  SEND_ACTION_TO_NATIVE_ERROR,
  SEND_ACTION_TO_NATIVE_ERROR_CLEAR,
  SendActionToNative,
  SendActionToNativeError,
  UNKNOWN_ACTION,
  WrapperActionTypes,
  FailureType,
  TOGGLE_MUTED_ACTION,
  ToggleMutedAction,
} from './constants';

//#endregion

//#region INITIAL STATE

interface WrapperInitialState {
  receivedParams: string;
  sentParams: string;
  receivedError: string;
  sentError: FailureType;
  deviceToken: string;
  isLoading: boolean;
}

export interface InitialState extends WrapperInitialState {
  action: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  consoleLog: any;
  isMuted: boolean;
}

const wrapperInitialState: WrapperInitialState = {
  receivedParams: '',
  sentParams: '',
  receivedError: '',
  sentError: '',
  deviceToken: '',
  isLoading: false,
};

const initialState: InitialState = {
  ...wrapperInitialState,
  action: '',
  consoleLog: '',
  isMuted: true,
};

//#endregion

//#region REDUCER

function wrapperReducer(state = initialState, action: WrapperActionTypes): InitialState {
  const actions = {
    [SEND_ACTION_TO_NATIVE]: (): InitialState => ({
      ...state,
      action: action.type,
      sentParams: (action as SendActionToNative).payload,
    }),
    [RECEIVED_ACTION_FROM_NATIVE]: (): InitialState => ({
      ...state,
      action: action.type,
      receivedParams: (action as ReceivedActionFromNative).payload,
    }),
    [SEND_ACTION_TO_NATIVE_ERROR]: (): InitialState => ({
      ...state,
      action: action.type,
      sentError: (action as SendActionToNativeError).payload,
    }),
    [RECEIVED_ACTION_FROM_NATIVE_ERROR]: (): InitialState => ({
      ...state,
      action: action.type,
      receivedError: (action as ReceivedActionFromNativeError).payload,
    }),
    [RECEIVED_ACTION_FROM_NATIVE_CLEAR]: (): InitialState => ({
      ...state,
      action: action.type,
      receivedParams: initialState.receivedParams,
    }),
    [SEND_ACTION_TO_NATIVE_CLEAR]: (): InitialState => ({
      ...state,
      sentParams: initialState.sentParams,
      action: action.type,
    }),
    [RECEIVED_ACTION_FROM_NATIVE_ERROR_CLEAR]: (): InitialState => ({
      ...state,
      receivedError: initialState.receivedError,
      action: action.type,
    }),
    [SEND_ACTION_TO_NATIVE_ERROR_CLEAR]: (): InitialState => ({
      ...state,
      action: action.type,
      sentError: initialState.sentError,
    }),
    [GET_DEVICE_TOKEN_FETCH]: (): InitialState => ({
      ...state,
      action: action.type,
    }),
    [GET_DEVICE_TOKEN_RECEIVED]: (): InitialState => ({
      ...state,
      action: action.type,
      deviceToken: (action as GetDeviceTokenReceived).payload,
    }),
    [NEW_DEVICE_TOKEN]: (): InitialState => ({
      ...state,
      action: action.type,
      deviceToken: (action as NewDeviceToken).payload,
    }),
    [UNKNOWN_ACTION]: (): InitialState => ({
      ...state,
      action: action.type,
    }),
    [CONSOLE_LOG]: (): InitialState => ({
      ...state,
      action: action.type,
      consoleLog: (action as ConsoleLog).payload,
    }),
    [TOGGLE_MUTED_ACTION]: (): InitialState =>({
      ...state,
      action: action.type,
      isMuted: (action as ToggleMutedAction).payload
    }),
    DEFAULT: (): InitialState => state,
  };

  return (actions[action.type] || actions.DEFAULT)();
}

//#endregion

export default wrapperReducer;
