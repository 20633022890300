//#region IMPORTS
// PACKAGE IMPORT
import { connect } from 'react-redux';

// LOCAL CONFIG
import UserLocation, { StateProps } from './UserLocation';
import { AppState } from '../../../../bootstrap/ReduxStore';
//#endregion

//#region REDUX BINDINGS
const mapStateToProps = (state: AppState): StateProps => ({
  responsedTagId: state.user.getUserLocationResponse
  ? (state.user.getUserLocationResponse.tagId)
  : '',
});

//#endregion

export default connect(
  mapStateToProps
)(UserLocation);
