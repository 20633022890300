//#region IMPORTS
// PACKAGE IMPORT
import { connect } from 'react-redux';

// LOCAL CONFIG
import NotifyView, { StateProps } from './Notify';
import { HideAlert } from '../../ActionAlert';
import { AppState } from '../../../../bootstrap/ReduxStore';

//#endregion

//#region REDUX BINDINGS
const mapStateToProps = (state: AppState): StateProps => ({
  message: state.alert.message,
  type: state.alert.type,
  isShowAlert: state.alert.isShowAlert,
  isSignedIn: state.user.isSignedIn,
});

const mapDispatchToProps = {
  HideAlert,
};
//#endregion

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotifyView);
