//#region IMPORTS
// PACKAGE IMPORT
import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import moment from 'moment';

// LOCAL CONFIG
import {
  ShowerBooking,
  ShowerBookingCreateUpdateType,
  ShowerBookingStatus,
  ShowerBookingStatusHistory,
  ShowerBookingStatusText,
  ShowerRoom,
} from '../../constants';

// LOCAL COMPONENT
import { toGregorianDay } from '../../../../utils';
import { IconBell } from '../../../../config/icon';
import { Icon } from '../../../../components';
import ShowerAction from '../ShowerAction';
import './ShowerDetail.scss';
//#endregion

//#region INTERFACE
interface OwnProps extends WrappedComponentProps {
  data: ShowerBooking;
  totalReady: number;
  roomList: ShowerRoom[];
  onClickActionButton: (
    booking: ShowerBooking,
    updateType: ShowerBookingCreateUpdateType,
    shower_room?: string,
    memo?: string
  ) => void;
  onChangeMemo:(device_id:string, value:string) => void;
  memoData?: {[device_id:string]:string};
}

type Props = OwnProps;
//#endregion

//#region COMPONENT
class ShowerDetail extends Component<Props> {
  //#region RENDER
  render(): React.ReactNode {
    const { intl, data } = this.props;
    return (
      <div className="shower-detail">
        <div className="shower-detail__content">
          <div className="shower-detail__content__section section-order">
            <div className="shower-detail__content__section__item">
              <div className="shower-detail__content__section__item__header">
                {intl.formatMessage({
                  id: data.use_app ? 'labelReservedId' : 'labelBeeperId',
                  defaultMessage: data.use_app ? 'labelReservedId' : 'labelBeeperId',
                })}
              </div>
              <div className="shower-detail__content__section__item__content">
                {!data.use_app && (
                  <span className="icon-wrapper">
                    <Icon data={IconBell} />
                  </span>
                )}
                {data.use_app ? data.airport_id + '-' + data.lounge_id + '-' + moment(data.timestamp).format("YYYYMMDD") + '-' + data.counter 
                  : ((data.device_id.split('.')[0] === "None" || data.device_id.split('.')[0] === "Announce") ? "ビーパー: " + data.device_id.split('.')[0] : "ビーパー: No." + data.device_id.split('.')[0])}
              </div>
            </div>
          </div>
          <div className="shower-detail__content__section section-order">
            <div className="shower-detail__content__section__item">
              <div className="shower-detail__content__section__item__header">
                {intl.formatMessage({
                  id: 'labelOrderTableId',
                  defaultMessage: 'labelOrderTableId',
                })}
              </div>
              <div className="shower-detail__content__section__item__content">
                {data.use_app ? data.table_id : "-------"}
              </div>
            </div>
          </div>
          <div className="shower-detail__content__section section-order">
            <div className="shower-detail__content__section__item">
              <div className="shower-detail__content__section__item__header">
                {intl.formatMessage({
                  id: 'labelEmailAddress',
                  defaultMessage: 'labelEmailAddress',
                })}
              </div>
              <div className="shower-detail__content__section__item__content">
                {data.use_app ? data.email_address : "-------"}
              </div>
            </div>
          </div>
        </div>
        <div className="shower-detail__content">
          <div className="shower-detail__content__section section-order">
            <div className="shower-detail__content__section__item">
              <div className="shower-detail__content__section__item__header">
                {intl.formatMessage({
                  id: 'labelPnr',
                  defaultMessage: 'labelPnr',
                })}
              </div>
              <div className="shower-detail__content__section__item__content">
                {data.use_app ? (data.pnr === "XXXXXXX" ? "-------" : data.pnr) : "-------"}
              </div>
            </div>
          </div>
          <div className="shower-detail__content__section section-order">
            <div className="shower-detail__content__section__item">
              <div className="shower-detail__content__section__item__header">
                {intl.formatMessage({
                  id: 'labelDateOfFlight',
                  defaultMessage: 'labelDateOfFlight',
                })}
              </div>
              <div className="shower-detail__content__section__item__content">
                {data.use_app ? toGregorianDay(data.date_of_flight) : "--/--"}
              </div>
            </div>
          </div>
          <div className="shower-detail__content__section section-order">
            <div className="shower-detail__content__section__item">
              <div className="shower-detail__content__section__item__header">
                {intl.formatMessage({
                  id: 'labelFlightNumber',
                  defaultMessage: 'labelFlightNumber',
                })}
              </div>
              <div className="shower-detail__content__section__item__content">
                {data.carrier + data.flight_number}
              </div>
            </div>
          </div>
          <div className="shower-detail__content__section section-order">
            <div className="shower-detail__content__section__item">
              <div className="shower-detail__content__section__item__header">
                {intl.formatMessage({
                  id: 'labelSeatNumber',
                  defaultMessage: 'labelSeatNumber',
                })}
              </div>
              <div className="shower-detail__content__section__item__content">
                {data.seat_number}
              </div>
            </div>
          </div>
          
        </div>
        <div className="shower-detail__content">
          {data.shower_room && (<div className="shower-detail__content__section section-order">
            <div className="shower-detail__content__section__item">
              <div className="shower-detail__content__section__item__header">
                {intl.formatMessage({ id: 'labelRoom', defaultMessage: 'labelRoom' })}
              </div>
              <div className="shower-detail__content__section__item__content">{data.shower_room}</div>
            </div>
          </div>
          )}
          {data.memo && (
            <div className="shower-detail__content__section section-order">
              <div className="shower-detail__content__section__item">
                <div className="shower-detail__content__section__item__header">
                  {intl.formatMessage({
                    id: 'labelMemo',
                    defaultMessage: 'labelMemo',
                  })}
                </div>
                <div className="shower-detail__content__section__item__content">{data.memo}</div>
              </div>
            </div>
          )}
        </div>
        <div className="shower-detail__content">
          <div className="shower-detail__content__section__item">
            <div className="shower-detail__content__section__item__header">
              {intl.formatMessage({ id: 'labelHistory', defaultMessage: 'labelHistory' })}
            </div>
            <div className="shower-detail__content__section__item__content">
              {data.status_history.map((booking: ShowerBookingStatusHistory, index: number) => {
                return (
                  <div className="shower-detail__content__section__item__content__status" key={index}>
                    <div className="shower-detail__content__section__item__content__status__time">
                      {moment(booking.timestamp).format('lll')}
                    </div>
                    <div className="shower-detail__content__section__item__content__status__value">
                      {intl.formatMessage({
                        id: ShowerBookingStatusText(booking.status as ShowerBookingStatus),
                        defaultMessage: ShowerBookingStatusText(booking.status as ShowerBookingStatus),
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <ShowerAction {...this.props} memoData={this.props.memoData} onChangeMemo={this.props.onChangeMemo} />
      </div>
    );
  }
  //#endregion
}
//#endregion

export default injectIntl(ShowerDetail);
