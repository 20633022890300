//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import classnames from 'classnames';

// LOCAL CONFIG
import './_index.scss';

//#endregion

//#region INTERFACE
interface OwnProps {
  children: React.ReactChild;
  isFull?: boolean;
  className?: string;
  isSidebarOnDesktop?: boolean;
}
//#endregion

//#region COMPONENT
function Container({ children, isFull, className, isSidebarOnDesktop }: OwnProps): React.ReactElement {
  const classes = classnames(
    'container',
    { 'container--full': isFull },
    { 'container--desktop-sidebar': isSidebarOnDesktop },
    className
  );

  return <div className={classes}>{children}</div>;
}
//#endregion

export default Container;
