//#region IMPORTS
// LOCAL CONFIG
import {
  ON_STAFF_CALL_SUBSCRIPTION,
  STAFF_CALL_CREATE_CLEAR,
  STAFF_CALL_CREATE_FAILED,
  STAFF_CALL_CREATE_FETCH,
  STAFF_CALL_CREATE_SUCCESS,
  STAFF_CALL_ITEM_CLEAR,
  STAFF_CALL_ITEM_FAILED,
  STAFF_CALL_ITEM_FETCH,
  STAFF_CALL_ITEM_SUCCESS,
  STAFF_CALL_SUBSCRIPTION_CREATE_CLEAR,
  STAFF_CALL_SUBSCRIPTION_CREATE_FAILED,
  STAFF_CALL_SUBSCRIPTION_CREATE_FETCH,
  STAFF_CALL_SUBSCRIPTION_CREATE_SUCCESS,
  STAFF_CALL_UPDATE_CLEAR,
  STAFF_CALL_UPDATE_FAILED,
  STAFF_CALL_UPDATE_FETCH,
  STAFF_CALL_UPDATE_SUCCESS,
  STAFF_CALL_LIST_FETCH,
  STAFF_CALL_LIST_SUCCESS,
  STAFF_CALL_LIST_FAILED,
  STAFF_CALL_LIST_CLEAR,
  ON_CREATE_UPDATE_STAFF_CALL,
} from './StringStaffCall';

//#endregion

//#region GENERAL ENUMS

export enum StaffCallMutationTypes {
  CREATE = 'CREATE',
  CANCEL = 'CANCEL',
  ANSWER = 'ANSWER',
}

export enum StaffCallStatusTypes {
  CALLING = '101',
  ANSWERED = '201',
  CANCELLED = '202',
}

export const StaffCallingStatusText = (status: StaffCallStatusTypes): string => {
  const statuses = {
    [StaffCallStatusTypes.CALLING]: (): string => 'staffCallStatusCall',
    [StaffCallStatusTypes.ANSWERED]: (): string => 'staffCallStatusAnswer',
    [StaffCallStatusTypes.CANCELLED]: (): string => 'staffCallStatusCancel',
  };

  return statuses[status]();
};

export const StaffCallClassIdentifier = (status: StaffCallStatusTypes): string => {
  const statuses = {
    [StaffCallStatusTypes.CALLING]: (): string => 'call',
    [StaffCallStatusTypes.ANSWERED]: (): string => 'answer',
    [StaffCallStatusTypes.CANCELLED]: (): string => 'cancel',
  };

  return statuses[status]();
};

//#endregion

//#region GENERAL TYPES

export type StaffCall = {
  device_id: string;
  status: string;
  timestamp: string;
  answered_by?: string;
  answered_at?: string;
};

export interface StaffCallItem extends StaffCall {
  timeleft?: number;
}

export type StaffCallList = {
  items: StaffCall[];
  nextToken: string;
};

type StaffCallInput = {
  input: { device_id: string };
};

//#endregion

//#region ACTION TYPES

//#region STAFF CALL LIST

// Fetch
export type StaffCallListParam = {
  statusTypes: StaffCallStatusTypes[];
  limit?: number;
  nextToken?: string;
  date?: string;
};

export interface StaffCallListFetch {
  type: typeof STAFF_CALL_LIST_FETCH;
  payload: StaffCallListParam;
}

// Success
export type StaffCallListResponse = {
  listStaffCallByStatus: StaffCallList;
};

export type StaffCallMultiStatusResponse = {
  items: {
    [StaffCallStatusTypes.CALLING]?: StaffCall[];
    [StaffCallStatusTypes.CANCELLED]?: StaffCall[];
    [StaffCallStatusTypes.ANSWERED]?: StaffCall[];
  };
};

export interface StaffCallListSuccess {
  type: typeof STAFF_CALL_LIST_SUCCESS;
  payload: StaffCallMultiStatusResponse;
}

// Failed
export interface StaffCallListFailed {
  type: typeof STAFF_CALL_LIST_FAILED;
  payload: FailureType;
}

// Clear
export interface StaffCallListClear {
  type: typeof STAFF_CALL_LIST_CLEAR;
}

type StaffCallListActions = StaffCallListFetch | StaffCallListSuccess | StaffCallListFailed | StaffCallListClear;

//#endregion

//#region STAFF CALL ITEM

// Fetch
export type StaffCallItemParam = {
  device_id: string;
  status: string;
  timestamp: string;
};

export interface StaffCallItemFetch {
  type: typeof STAFF_CALL_ITEM_FETCH;
  payload: StaffCallItemParam;
}

// Success
export type StaffCallItemResponse = {
  getStaffCall: StaffCall;
};

export interface StaffCallItemSuccess {
  type: typeof STAFF_CALL_ITEM_SUCCESS;
  payload: StaffCallItemResponse;
}

// Failed
export interface StaffCallItemFailed {
  type: typeof STAFF_CALL_ITEM_FAILED;
  payload: FailureType;
}

// Clear
export interface StaffCallItemClear {
  type: typeof STAFF_CALL_ITEM_CLEAR;
}

type StaffCallItemActions = StaffCallItemFetch | StaffCallItemSuccess | StaffCallItemFailed | StaffCallItemClear;

//#endregion

//#region STAFF CALL CREATE

// Fetch
export type StaffCallCreateParam = StaffCallInput;

export interface StaffCallCreateFetch {
  type: typeof STAFF_CALL_CREATE_FETCH;
  payload: StaffCallCreateParam;
}

// Success
export type StaffCallCreateResponse = {
  createStaffCall: StaffCall;
};

export interface StaffCallCreateSuccess {
  type: typeof STAFF_CALL_CREATE_SUCCESS;
  payload: StaffCallCreateResponse;
}

// Failed
export interface StaffCallCreateFailed {
  type: typeof STAFF_CALL_CREATE_FAILED;
  payload: FailureType;
}

// Clear
export interface StaffCallCreateClear {
  type: typeof STAFF_CALL_CREATE_CLEAR;
}

type StaffCallCreateActions =
  | StaffCallCreateFetch
  | StaffCallCreateSuccess
  | StaffCallCreateFailed
  | StaffCallCreateClear;

//#endregion

//#region STAFF CALL UPDATE

// Fetch
export interface StaffCallUpdateParam extends StaffCallInput {
  updateType: StaffCallMutationTypes;
}

export interface StaffCallUpdateFetch {
  type: typeof STAFF_CALL_UPDATE_FETCH;
  payload: StaffCallUpdateParam;
}

// Success
type CancelStaffCallResponse = {
  onCancelStaffCall: StaffCall;
};

type AnswerStaffCallResponse = {
  onAnswerStaffCall: StaffCall;
};

export type StaffCallUpdateResponse = CancelStaffCallResponse | AnswerStaffCallResponse;

export interface StaffCallUpdateSuccess {
  type: typeof STAFF_CALL_UPDATE_SUCCESS;
  payload: StaffCallUpdateResponse;
}

// Failed
export interface StaffCallUpdateFailed {
  type: typeof STAFF_CALL_UPDATE_FAILED;
  payload: FailureType;
}

// Clear
export interface StaffCallUpdateClear {
  type: typeof STAFF_CALL_UPDATE_CLEAR;
}

type StaffCallUpdateActions =
  | StaffCallUpdateFetch
  | StaffCallUpdateSuccess
  | StaffCallUpdateFailed
  | StaffCallUpdateClear;

//#endregion

//#region STAFF CALL SUBSCRIPTION

// Fetch
export interface StaffCallSubscriptionCreateFetch {
  type: typeof STAFF_CALL_SUBSCRIPTION_CREATE_FETCH;
}

// Success
export interface StaffCallSubscriptionCreateSuccess {
  type: typeof STAFF_CALL_SUBSCRIPTION_CREATE_SUCCESS;
}

// Failed
export interface StaffCallSubscriptionCreateFailed {
  type: typeof STAFF_CALL_SUBSCRIPTION_CREATE_FAILED;
  payload: FailureType;
}

// Clear
export interface StaffCallSubscriptionCreateClear {
  type: typeof STAFF_CALL_SUBSCRIPTION_CREATE_CLEAR;
}

// On Subscription Action
export interface OnStaffCallSubscription {
  type: typeof ON_STAFF_CALL_SUBSCRIPTION;
}

type StaffCallSubscriptionActions =
  | StaffCallSubscriptionCreateFetch
  | StaffCallSubscriptionCreateSuccess
  | StaffCallSubscriptionCreateFailed
  | StaffCallSubscriptionCreateClear
  | OnStaffCallSubscription;

//#region ON subscription fired

export interface OnCreateUpdateStaffCallParam {
  mutationType: StaffCallMutationTypes;
  data?: StaffCall;
}
export interface OnAnswerStaffCall extends OnCreateUpdateStaffCallParam {
  onAnswerStaffCall: StaffCall;
}

export type OnCreateUpdateStaffCallPayload = OnAnswerStaffCall;

export interface OnCreateUpdateStaffCall {
  type: typeof ON_CREATE_UPDATE_STAFF_CALL;
  payload: OnCreateUpdateStaffCallPayload;
}
//#endregion

export type FailureType = Record<string, unknown> | string;

export enum SubscriptionStatus {
  UNREGISTERED,
  REGISTERING,
  REGISTERED,
  REGISTRATION_FAILURE,
}

export type GraphQLSubscriptionResult = {
  provider: unknown;
  value: {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    data: any;
  };
};

//#endregion

export type StaffCallActions =
  | StaffCallListActions
  | StaffCallItemActions
  | StaffCallCreateActions
  | StaffCallUpdateActions
  | StaffCallSubscriptionActions;

//#endregion
