//#region IMPORT
// PACKAGE IMPORT
import { connect } from 'react-redux';

// LOCAL CONFIG
import ShowerForm, { StateProps } from './ShowerForm';
import { JalAppState } from '../../../../config/interface';
//#endregion

//#region REDUX BINDING
const mapStateToProps = (state: JalAppState): StateProps => ({
  isLoading: state.shower.showerBookingCreateLoading,
});
//#endregion

export default connect(mapStateToProps)(ShowerForm);
