/* eslint-disable @typescript-eslint/explicit-function-return-type */
//#region IMPORTS

// PACKAGE IMPORTS
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import Auth from '@aws-amplify/auth';
import { AxiosResponse } from 'axios';
import qs from 'qs';

// LOCAL CONFIG IMPORTS
import {
  GetUser,
  LoadingLogin,
  LoadingUser,
  SetUser,
  GetAreaUsersSuccess,
  GetAreaUsersFailed,
  GetUserLocationSuccess,
  GetUserLocationFailed,
  SatoAuthSuccess,
  SatoAuthFailed,
  LogoutSuccess,
} from './ActionUser';
import { ShowAlert } from '../alert/ActionAlert';
import { ALERTTYPE } from '../alert/constants';
import { RESET } from '../../config/constant';
import { replace } from 'connected-react-router';
import { PATH } from '../navigation/constants';
import {
  CognitoUserSession,
  PUSH_NOTIFICATION,
  PushNotificationRegisterFetch,
  User,
  USER,
  Login,
  GetAreaUsersFetch,
  SATO_API_URI,
  GET_AREA_USERS_API,
  GetAreaUsersResponse,
  SatoFailureType,
  GET_AREA_USERS_FETCH,
  GetUserLocationFetch,
  GET_USER_LOCATION_API,
  GetUserLocationResponse,
  GET_USER_LOCATION_FETCH,
  SatoAuthFetch,
  SATO_API_AUTH_URI,
  SatoAuthFailType,
  SATO_AUTH_FETCH,
} from './constants';
import { SendActionToNative } from '../wrapper/ActionWrapper';
import { GET_PLATFORM_FETCH, REGISTER_ENDPOINTS_FETCH } from '../wrapper/constants';
import { get, post } from '../../utils';

//#endregion

//#region SAGA WORKERS
// USER.GET_USER
function* workerGetUser() {
  yield put(LoadingUser(true));
  try {
    const session: CognitoUserSession = yield Auth.currentSession();
    const user = session.getAccessToken().decodePayload();
    const attributes = yield Auth.currentUserInfo();
    const userData: User = { username: user.username, roles: user['cognito:groups'], airportId: attributes['attributes']['custom:airportId'], loungeId: attributes['attributes']['custom:loungeId'] };

    yield put(SetUser(userData));
    yield put(LoadingUser(false));
  } catch (err) {
    yield put(LoadingUser(false));
  }
}

// USER.LOGIN
function* workerLogin(params: Login) {
  const {
    payload: { username, password },
  } = params;

  yield put(LoadingLogin(true));
  try {
    const user = yield Auth.signIn((username as string).toLowerCase(), password);

    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      yield Auth.completeNewPassword(user, password, {});
    }

    yield put(GetUser());
    yield put(LoadingLogin(false));
  } catch (err) {
    yield put(LoadingLogin(false));
    yield put(ShowAlert(ALERTTYPE.WARNING, 'alert.authFailed'));
  }
}

// USER.LOGOUT
function* workerLogout() {
  try {
    yield Auth.signOut();
    yield put(LogoutSuccess());
    yield put({ type: RESET.ALL });
    yield put(replace(PATH.LOGIN));
  } catch {
    yield put(ShowAlert(ALERTTYPE.WARNING, 'alert.somethingWrong'));
  }
}

// PUSH_NOTIFICATION.REGISTER
function* workerRegisterPushNotification(action: PushNotificationRegisterFetch) {
  const newPayload = {
    type: REGISTER_ENDPOINTS_FETCH,
    payload: JSON.stringify(action.payload),
  };
  yield put(SendActionToNative(JSON.stringify(newPayload)));
}

// GET_DEVICE_PLATFORM
function* workerRegisterGetDeviceToken() {
  const newPayload = {
    type: GET_PLATFORM_FETCH,
  };
  yield put(SendActionToNative(JSON.stringify(newPayload)));
}

// GET_AREA_USERS_FETCH
function* workerSagaGetAreaUsers(action: GetAreaUsersFetch): SagaIterator {
  const url = `${SATO_API_URI}${GET_AREA_USERS_API}`;
  try {
    const response: AxiosResponse<GetAreaUsersResponse | SatoFailureType> = yield call(get, url, action.payload);
    // TODO: Check SATO isError code convention.
    if (response.status === 200) {
      yield put(GetAreaUsersSuccess(response.data as GetAreaUsersResponse));
    } else {
      yield put(GetAreaUsersFailed(response.data as SatoFailureType));
    }
  } catch (e) {
    yield put(GetAreaUsersFailed(e));
  }
}

// GET_USER_LOCATION_FETCH
function* workerSagaGetUserLocation(action: GetUserLocationFetch): SagaIterator {
  const url = `${SATO_API_URI}${GET_USER_LOCATION_API}`;
  try {
    const response: AxiosResponse<GetUserLocationResponse | SatoFailureType> = yield call(get, url, action.payload);
    if (response.status === 200) {
      yield put(GetUserLocationSuccess(response.data as GetUserLocationResponse));
    } else {
      yield put(GetUserLocationFailed(response.data as SatoFailureType));
    }
  } catch (e) {
    yield put(GetUserLocationFailed(e));
  }
}

// SATO_AUTH_FETCH
function* workerSagaSatoAuth(action: SatoAuthFetch): SagaIterator {
  const url = `${SATO_API_AUTH_URI}`;
  try {
    const response = yield call(post, url, qs.stringify(action.payload));

    if (response.status === 200) {
      yield put(SatoAuthSuccess(response.data));
    } else {
      yield put(SatoAuthFailed(response.data as SatoAuthFailType));
    }
  } catch (e) {
    yield put(SatoAuthFailed(e));
  }
}

//#endregion

const userWatcher = [
  takeLatest(USER.GET_USER, workerGetUser),
  takeLatest(USER.LOGIN, workerLogin),
  takeLatest(USER.LOGOUT, workerLogout),
  takeEvery(PUSH_NOTIFICATION.REGISTER.PUSH_NOTIFICATION_REGISTER_FETCH, workerRegisterPushNotification),
  takeEvery(USER.GET_DEVICE_PLATFORM, workerRegisterGetDeviceToken),
  takeLatest(GET_AREA_USERS_FETCH, workerSagaGetAreaUsers),
  takeLatest(GET_USER_LOCATION_FETCH, workerSagaGetUserLocation),
  takeLatest(SATO_AUTH_FETCH, workerSagaSatoAuth),
];

export default userWatcher;
