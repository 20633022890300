//#region IMPORTS

// PACKAGE IMPORTS
import React, { useMemo } from 'react';
import { BrowserRouter as Router, Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import PubSub from '@aws-amplify/pubsub';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import 'moment/locale/ja';
import moment from 'moment';

// LOCAL CONFIG
import { PATH } from '../../../navigation/constants';
import { IsAuthenticated, IsFloorStaff, IsKitchenStaff, IsNotAuthenticated, IsShowerStaff } from '../../components';
import { amplifyConfig, settings } from '../../../../config/config';
import { messages } from '../../../../lang/locales';
import { ConsoleLogPayload, WrapperActionTypes, XamarinEvent } from '../../constants';
import {
  DevicePlatformTypes,
  RegisterPinpointOptions,
  SatoTokens,
  USER,
  User,
  UserActionTypes,
  SATO_AUTH_PAYLOAD,
  SATO_AUTH_SUCCESS,
  SatoAuthParam,
  SatoAuthResponse,
  GET_USER_LOCATION_FETCH,
  GET_USER_LOCATION_SUCCESS,
} from '../../../user/constants';

// LOCAL COMPONENTS
import NavigationBar from '../../../navigation/views/NavigationBar';
import Login from '../../../user/views/Login';
import Notify from '../../../alert/views/Notify';
import Shower from '../../../shower/views/Shower';
import Meals from '../../../meals/views/Meals';
import Congestion from '../../../congestion/views/Congestion';
import StaffCall from '../../../staffcall/views/StaffCall';
import UserLocationSearch from '../../../user/views/UserLocationSearch';
import Unauthorized from '../Unauthorized/Unauthorized';
import { Container, Modal } from '../../../../components';
import StaffCallArchive from '../../../staffcall/views/StaffCallArchive';
import MealsArchive from '../../../meals/views/MealsArchive';
import ShowerArchive from '../../../shower/views/ShowerArchive';

// IMPORT STYLING
import './Wrapper.scss';
//#endregion

//#region PROPS & STATE
export interface DispatchProps {
  GetUser: () => void;
  GetDeviceTokenFetch(): WrapperActionTypes;
  ReceivedActionFromNative(payload: string): WrapperActionTypes;
  ConsoleLog(payload: ConsoleLogPayload): WrapperActionTypes;
  SetDeviceToken(payload: string): UserActionTypes;
  PushNotificationRegisterFetch(payload: RegisterPinpointOptions): UserActionTypes;
  GetDevicePlatform(): UserActionTypes;
  SatoAuthFetch(payload: SatoAuthParam): UserActionTypes;
  SetSatoToken(payload: SatoTokens): UserActionTypes;
  ToggleMutedAction(payload:boolean): WrapperActionTypes;
}

export interface StateProps {
  isSignedIn: boolean;
  userAction: string;
  wrapperAction: string;
  deviceTokenFromWrapper: string;
  user: User;
  deviceToken: string;
  devicePlatform: DevicePlatformTypes;
  satoAuthResponse?: SatoAuthResponse;
  isMuted: boolean;
}

type Props = DispatchProps & StateProps;

interface State {
  modalAudioVisible: boolean;
}

//#endregion

//#region MAIN CLASS

class Wrapper extends React.Component<Props, State> {
  //#region CONSTRUCTOR & LIFE CYCLE METHODS
  private readonly audioModalRef: React.RefObject<Modal>;

  constructor(props: Props) {
    super(props);
    registerLocale('ja', ja);
    setDefaultLocale('ja');
    moment.locale('ja');
    amplifyConfig();
    PubSub.configure({});

    this.handleReduxActions = this.handleReduxActions.bind(this);
    this.handleNativeAction = this.handleNativeAction.bind(this);
    this.registerForPushNotifications = this.registerForPushNotifications.bind(this);
    this.audioModalRef = React.createRef<Modal>();
    this.state ={
      modalAudioVisible: true,
    }
  }

  componentDidMount(): void {
    this.props.SatoAuthFetch(SATO_AUTH_PAYLOAD);
    this.props.GetUser();
    window.addEventListener('csharp', this.handleNativeAction);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (
      prevProps.userAction !== this.props.userAction &&
      this.props.userAction !== GET_USER_LOCATION_FETCH &&
      this.props.userAction !== GET_USER_LOCATION_SUCCESS
    ) {
      this.handleReduxActions(this.props.userAction);
    }

    if (prevProps.wrapperAction !== this.props.wrapperAction) {
      this.handleReduxActions(this.props.wrapperAction);
    }

    if (this.props.isMuted) {
      this.audioModalRef.current && this.audioModalRef.current.openModal();
    } else {
      this.audioModalRef.current && this.audioModalRef.current.closeModal();
    }
  }

  //#endregion

  //#region METHODS

  handleReduxActions(action: string): void {
    const actions = {
      [USER.SET_USER]: (): void => {
        this.registerForPushNotifications();
      },
      [USER.SET_DEVICE_TOKEN]: (): void => {
        this.registerForPushNotifications();
      },
      [SATO_AUTH_SUCCESS]: (): void => {
        this.props.satoAuthResponse &&
          this.props.SetSatoToken({
            token: this.props.satoAuthResponse.access_token,
            refreshToken: this.props.satoAuthResponse.refresh_token,
          });
      },
      DEFAULT: (): void => {},
    };

    return (actions[action] || actions.DEFAULT)();
  }

  handleNativeAction(event: Event): void {
    this.props.ReceivedActionFromNative((event as XamarinEvent).detail.parameters);
  }

  registerForPushNotifications(): void {
    const { user, PushNotificationRegisterFetch, deviceToken } = this.props;
    if (deviceToken !== '' && user.username !== '') {
      PushNotificationRegisterFetch({
        UserName: user.username,
        DeviceToken: deviceToken,
        EndpointId: user.username,
      });
    } else {
      this.props.GetDeviceTokenFetch();
    }
  }

  renderAudioModal(): React.ReactElement {
    if (!this.state.modalAudioVisible) return <></>;
    return (
      <Modal
        mode={1}
        title="titleConfirmAudio"
        content="contentConfirmAudio"
        onClosed={(): void => {
          const audio = document.getElementById('notify-se') as HTMLAudioElement;
          //if(audio){
          audio.muted = true;
          this.props.ToggleMutedAction(false);
          audio.play();
          this.setState({ modalAudioVisible: false });
        }}
        ref={this.audioModalRef}
      />
    );
  }

  //#endregion

  //#region MAIN RENDER

  render(): React.ReactElement {
    const { isSignedIn } = this.props;
    return (
      <IntlProvider locale={settings.lang} messages={messages[settings.lang]}>
        <>
          <Container isSidebarOnDesktop className="wrapper" isFull={!isSignedIn}>
            <Router>
              <>
                {isSignedIn && <NavigationBar />}
                <Switch>
                  <Route exact path={PATH.LOGIN} component={IsNotAuthenticated(Login)} />
                  <Route exact path={PATH.SHOWER_BOOKING} component={IsAuthenticated(IsShowerStaff(Shower))} />
                  <Route exact path={PATH.MEALS_ORDER} component={IsAuthenticated(IsKitchenStaff(Meals))} />
                  <Route exact path={PATH.CONGESTION} component={IsAuthenticated(IsFloorStaff(Congestion))} />
                  <Route exact path={PATH.STAFF_CALLING} component={IsAuthenticated(IsFloorStaff(StaffCall))} />
                  <Route exact path={PATH.USER_SEACH} component={IsAuthenticated(IsFloorStaff(UserLocationSearch))} />
                  <Route exact path={PATH.UNAUTHORIZED} component={IsAuthenticated(Unauthorized)} />
                  <Route
                    path={PATH.STAFF_CALLING_ARCHIVE}
                    component={IsAuthenticated(IsFloorStaff(StaffCallArchive))}
                  />
                  <Route path={PATH.MEALS_ORDER_ARCHIVE} component={IsAuthenticated(IsKitchenStaff(MealsArchive))} />
                  <Route path={PATH.SHOWER_BOOKING_ARCHIVE} component={IsAuthenticated(IsShowerStaff(ShowerArchive))} />
                  <Redirect to={PATH.LOGIN} />
                </Switch>
              </>
            </Router>
          </Container>

          <Notify />
          {this.renderAudioModal()}
        </>
      </IntlProvider>
    );
  }

  //#endregion
}

const MemoizedWrapper = (props: Props): React.ReactElement => {
  // 'props.isSignedIn' and 'props.user.roles' was defined as second argument.
  const wrapper = useMemo(() => <Wrapper {...props} />, [props]);
  return <>{wrapper}</>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default withRouter<RouteComponentProps, any>(MemoizedWrapper);
