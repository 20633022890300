//#region IMPORT
// PACKAGE IMPORT
import { connect } from 'react-redux';

// LOCAL CONFIG
import { CongestionUpdateParam } from '../../constants';
import { congestionUpdateFetch } from '../../ActionCongestion';
import { JalAppState } from '../../../../config/interface';
import CongestionListRow, { StateProps } from './CongestionListRow';
//#endregion

//#region REDUX BINDING
const mapStateToProps = (state: JalAppState): StateProps => ({
  congestionUpdateLoading: state.congestion.congestionUpdateLoading,
  congestionUpdateParam: state.congestion.congestionUpdateParam as CongestionUpdateParam,
  airport_id: state.user.user.airportId
});
const mapDispatchToProps = {
  congestionUpdateFetch,
};
//#endregion

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CongestionListRow);
