//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import ColumnHeader from './ColumnHeader';
import ColumnBody from './ColumnBody';

// LOCAL
import './_index.scss';

//#endregion

//#region INTERFACE
export enum ColumnColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

interface OwnProps {
  data: ColumnProps[];
  showHeader?: boolean;
}

export interface ColumnProps {
  title: string;
  count: number;
  color: ColumnColor;
  content: React.ReactNode;
}

interface OwnState {
  activeIndex: number;
}

//#endregion

//#region COMPONENT
class Column extends React.Component<OwnProps, OwnState> {
  constructor(props: OwnProps) {
    super(props);

    this.state = {
      activeIndex: 0,
    };
  }

  render(): React.ReactElement {
    const { data, showHeader } = this.props;
    const { activeIndex } = this.state;
    const shouldShowHeader = typeof showHeader === 'undefined' || (typeof showHeader !== 'undefined' && showHeader);
    return (
      <>
        {shouldShowHeader && (
          <div className="column-header-wrapper">
            {data.length > 0 &&
              data.map((col: ColumnProps, index: number) => (
                <ColumnHeader
                  key={index}
                  onClick={(): void => this.setState({ activeIndex: index })}
                  isActive={index === activeIndex}
                  title={col.title}
                  color={col.color}
                  count={col.count}
                />
              ))}
          </div>
        )}
        <div className="column-body-wrapper">
          {data.length > 0 &&
            data.map((col: ColumnProps, index: number) => (
              <ColumnBody key={index} isActive={index === activeIndex}>
                {col.content}
              </ColumnBody>
            ))}
        </div>
      </>
    );
  }
}

//#endregion

export default Column;
