//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import classnames from 'classnames';

// LOCAL COMPONENT
import { Icon } from '../index';
import './_index.scss';

//#endregion

//#region INTERFACE
interface OwnProps {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  field: any;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  form: any;
  icon?: React.ReactElement<SVGElement> | string;
  iconColor?: string;
  className?: string;
  label?: string;
  id: string;
  placeholder?: string;
  isRequired?: boolean;
  type?: string;
  intl: IntlShape;
  memo?: string;
  handleChange?: ()=>void;
}

type Props = OwnProps;
//#endregion

//#region COMPONENT
function Input({
  field,
  form: { touched, errors },
  icon,
  iconColor,
  className,
  label,
  id,
  isRequired,
  placeholder,
  type,
  intl,
  memo,
  ...props
}: Props): React.ReactElement {
  const classes = classnames('input', { 'input--with-icon': icon }, `input--with-icon--${iconColor}`, className);
  //const [value, setValue] = useState(memo);

  return (
    <div className={classes}>
      {label && (
        <label className="input__label" htmlFor={id}>
          {intl.formatMessage({ id: label, defaultMessage: label })}
          {isRequired && <span className="input__label__required">*</span>}
        </label>
      )}

      <div className="input__wrapper">
        {icon && (
          <div className="input__wrapper__icon">
            <Icon data={icon} />
          </div>
        )}

        <input
          className="input__wrapper__input"
          id={id}
          placeholder={placeholder ? intl.formatMessage({ id: placeholder, defaultMessage: placeholder }) : ''}
          {...field}
          {...props}
          type={type}
        />
      </div>
      <div className="input__validation">
        {touched[field.name] && errors[field.name] && (
          <span className="input__error">
            {intl.formatMessage({ id: errors[field.name], defaultMessage: errors[field.name] })}
          </span>
        )}
      </div>
    </div>
  );
}
//#endregion

export default injectIntl(Input);
