//#region IMPORTS
// LOCAL CONFIG
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';

// LOCAL COMPONENT
import { Button, Modal } from '../../../../components';
import { MealOrder, MealOrderCreateUpdateType, MealOrderStatusType } from '../../constants';
import './MealsAction.scss';

//#endregion

//#region INTERFACE
interface OwnProps {
  intl: IntlShape;
  data: MealOrder;
  onClickActionButton(mealOrder: MealOrder, updateType: MealOrderCreateUpdateType): void;
  onClickDetail?(mealOrder: MealOrder): void;
  showDetail?: boolean;
}

interface OwnState {
  rejectLoading: boolean;
  startLoading: boolean;
  deliveryLoading: boolean;
  disabledAllAction: boolean;
  modalVisible: boolean;
  modalContent: string;
  modalCallback: Function | undefined;
}

type Props = OwnProps;
//#endregion

//#region COMPONENT
class ShowerDetail extends React.Component<Props, OwnState> {
  //#region CTOR & LIFECYCLES
  private confirmationModalRef: React.RefObject<Modal>;

  constructor(props: Props) {
    super(props);

    this.state = {
      rejectLoading: false,
      startLoading: false,
      deliveryLoading: false,
      disabledAllAction: false,
      modalVisible: false,
      modalCallback: undefined,
      modalContent: '',
    };

    this.confirmationModalRef = React.createRef<Modal>();

    this.handleClickStart = this.handleClickStart.bind(this);
    this.handleClickDeliver = this.handleClickDeliver.bind(this);
    this.handleClickReject = this.handleClickReject.bind(this);
    this.handleClickDetail = this.handleClickDetail.bind(this);
    this.handleShowConfirmation = this.handleShowConfirmation.bind(this);
  }
  //#endregion

  //#region LIFECYCLE
  componentDidUpdate(prevProps: Props, prevState: OwnState): void {
    if (prevProps.data.status !== this.props.data.status) {
      this.setState({ disabledAllAction: false });
    }

    if (prevState.modalVisible !== this.state.modalVisible) {
      if (this.state.modalVisible) {
        this.confirmationModalRef.current && this.confirmationModalRef.current.openModal();
      } else {
        this.confirmationModalRef.current && this.confirmationModalRef.current.closeModal();
      }
    }
  }

  //#endregion

  //#region CLASS METHODS
  handleClickStart(): void {
    const { onClickActionButton, data } = this.props;
    this.setState({ startLoading: true, disabledAllAction: true }, () =>
      onClickActionButton(data, MealOrderCreateUpdateType.START)
    );
  }

  handleClickReject(): void {
    const { onClickActionButton, data } = this.props;
    this.setState({ rejectLoading: true, disabledAllAction: true }, () =>
      onClickActionButton(data, MealOrderCreateUpdateType.REJECT)
    );
  }

  handleClickDeliver(): void {
    const { onClickActionButton, data } = this.props;
    this.setState({ deliveryLoading: true, disabledAllAction: true }, () =>
      onClickActionButton(data, MealOrderCreateUpdateType.COMPLETE)
    );
  }

  handleShowConfirmation(content: string, callback: Function): void {
    this.setState({ modalVisible: true, modalContent: content, modalCallback: callback });
  }

  handleClickDetail(): void {
    const { onClickDetail, data } = this.props;
    if (typeof onClickDetail === 'function') onClickDetail(data);
  }
  //#endregion

  //#region RENDER
  renderConfirmationModal(): React.ReactElement {
    if (!this.state.modalVisible && typeof this.state.modalCallback !== 'function') return <></>;

    return (
      <Modal
        mode={2}
        ref={this.confirmationModalRef}
        title="titleConfirmation"
        content={this.state.modalContent}
        onClosed={(): void => this.setState({ modalVisible: false, modalCallback: undefined })}
        onConfirmed={(): Function | undefined => this.state.modalCallback && this.state.modalCallback()}
      />
    );
  }

  renderActionGroup(): JSX.Element {
    const { data } = this.props;
    switch (data.status) {
      case MealOrderStatusType.SUBMITTED:
        return (
          <div className="meals-action__group">
            <div className="meals-action__reject">
              <Button
                type="button"
                color="light-primary"
                label="mealsReject"
                showSpinner={this.state.rejectLoading}
                disabled={this.state.rejectLoading || this.state.disabledAllAction}
                onClick={(): void => this.handleShowConfirmation('contentRejectOrder', this.handleClickReject)}
              />
            </div>
            <div className="meals-action__accept">
              <Button
                type="button"
                color="primary"
                label="mealsAccept"
                showSpinner={this.state.startLoading}
                disabled={this.state.startLoading || this.state.disabledAllAction}
                onClick={this.handleClickStart}
              />
            </div>
          </div>
        );
      case MealOrderStatusType.IN_PROGRESS:
        return (
          <div className="meals-action__group">
            <div className="meals-action__delivery">
              <Button
                type="button"
                color="primary"
                label="mealsComplete"
                showSpinner={this.state.deliveryLoading}
                disabled={this.state.deliveryLoading || this.state.disabledAllAction}
                onClick={this.handleClickDeliver}
              />
            </div>
          </div>
        );
      default:
        return <></>;
    }
  }

  render(): React.ReactNode {
    const { showDetail } = this.props;

    return (
      <div className="meals-action">
        {this.renderActionGroup()}
        {showDetail && (
          <div className="meals-action__group">
            <Button type="button" className="show-detail" label="buttonShowDetail" onClick={this.handleClickDetail} />
          </div>
        )}
        {this.renderConfirmationModal()}
      </div>
    );
  }

  //#endregion
}

//#endregion

export default injectIntl(ShowerDetail);
