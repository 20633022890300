//#region IMPORTS
// PACKAGE IMPORT
import { connect } from 'react-redux';

// LOCAL CONFIG
import NavigationBar, { StateProps } from './NavigationBar';
import { AppState } from '../../../../bootstrap/ReduxStore';
import { Logout } from '../../../user/ActionUser';

//#endregion

//#region REDUX BINDINGS
const mapStateToProps = (state: AppState): StateProps => ({
  roles: state.user.user.roles || [],
});

const mapDispatchToProps = {
  Logout,
};
//#endregion

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationBar);
