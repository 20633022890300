//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import classnames from 'classnames';
import { injectIntl, IntlShape } from 'react-intl';

// LOCAL COMPONENT
import Spinner from '../Spinner';
import Icon from '../Icon';
import './_index.scss';

//#endregion

//#region INTERFACE
interface OwnProps extends React.DOMAttributes<HTMLButtonElement | HTMLAnchorElement> {
  className?: string;
  type?: string;
  label?: string;
  icon?: React.ReactElement<SVGElement> | string;
  color?: string;
  showSpinner?: boolean;
  href?: string;
  size?: string;
  disabled?: boolean;
  iconOnly?: boolean;
  intl: IntlShape;
  isSubmit?: boolean;
  id?: string;
}
//#endregion

//#region BUTTON COMPONENT
function Button({
  className,
  type,
  label,
  icon,
  intl,
  color,
  href,
  showSpinner,
  size,
  iconOnly,
  isSubmit,
  ...others
}: OwnProps): React.ReactElement {
  const classes = classnames(
    'button',
    `button--${color}`,
    `button--${type}`,
    `button--${size}`,
    { 'button--icon-only': iconOnly },
    { 'button--disabled': showSpinner },
    className
  );

  return (
    <>
      {href ? (
        <a className={classes} href={href} {...others}>
          <div className="button__content">
            {icon && <Icon data={icon} />}
            {label && intl.formatMessage({ id: label, defaultMessage: label })}
          </div>
        </a>
      ) : (
        <button disabled={showSpinner} className={classes} {...others} {...(isSubmit && { type: 'submit' })}>
          <div className="button__content">
            {icon && !showSpinner && <Icon data={icon} />}
            {label && !showSpinner && intl.formatMessage({ id: label, defaultMessage: label })}
            {showSpinner && <Spinner />}
          </div>
        </button>
      )}
    </>
  );
}
//#endregion

export default injectIntl(Button);
