//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import classnames from 'classnames';

// LOCAL CONFIG
import './ColumnHeader.scss';

//#endregion

//#region INTERFACE
export enum ColumnColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

interface OwnProps {
  title: string;
  count: number;
  color: ColumnColor;
  intl: IntlShape;
  isActive?: boolean;
  onClick?: () => void;
}
//#endregion

//#region HEADER COMPONENT
function ColumnHeader({ intl, title, count, onClick, isActive, color }: OwnProps): React.ReactElement {
  const classess = classnames('column-header', `column-header--${color}`, { 'column-header--active': isActive });
  return (
    <div
      className={classess}
      onClick={(): void => {
        if (typeof onClick === 'function') onClick();
      }}
    >
      <div className="column-header__counter">{count}</div>
      <div className="column-header__title">{intl.formatMessage({ id: title, defaultMessage: title })}</div>
    </div>
  );
}
//#endregion

export default injectIntl(ColumnHeader);
