//#region IMPORT
// PACKAGE IMPORT
import React, { useState, useEffect } from 'react';
import moment from 'moment';
//#endregion

//#region Interface
interface OwnProps {
  startTime: string;
}

type Props = OwnProps;
//#endregion

//#region METHOD
const timeDifference = (startTime: string): string => {
  const beginTime = moment(startTime);
  const currentTime = moment(Date.now());
  return moment(currentTime.diff(beginTime)).format('mm:ss');
};
//#endregion

//#region COMPONENT
function TimeCounter(props: Props): React.FunctionComponentElement<Props> {
  const [count, setCount] = useState(timeDifference(props.startTime));

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(timeDifference(props.startTime));
    }, 1000);

    return function cleanup(): void {
      clearInterval(interval);
    };
  });

  return <>{count}</>;
}
//#endregion

export default TimeCounter;
