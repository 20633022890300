//#region IMPORTS
// LOCAL CONFIG
import {
  MEAL_ORDER_CREATE_CLEAR,
  MEAL_ORDER_CREATE_FAILED,
  MEAL_ORDER_CREATE_FETCH,
  MEAL_ORDER_CREATE_SUCCESS,
  MEAL_ORDER_LIST_CLEAR,
  MEAL_ORDER_LIST_FAILED,
  MEAL_ORDER_LIST_FETCH,
  MEAL_ORDER_LIST_SUCCESS,
  MEAL_ORDER_SUBSCRIPTION_CREATE_FAILED,
  MEAL_ORDER_SUBSCRIPTION_CREATE_SUCCESS,
  MEAL_ORDER_SUBSCRIPTION_REMOVE_FAILED,
  MEAL_ORDER_SUBSCRIPTION_REMOVE_SUCCESS,
  MEAL_ORDER_UPDATE_CLEAR,
  MEAL_ORDER_UPDATE_FAILED,
  MEAL_ORDER_UPDATE_FETCH,
  MEAL_ORDER_UPDATE_SUCCESS,
  MealOrder,
  MealOrderActionTypes,
  MealOrderCreateFailed,
  MealOrderCreateFetch,
  MealOrderCreateParam,
  MealOrderCreateResponseType,
  MealOrderCreateSuccess,
  MealOrderListFailed,
  MealOrderListFetch,
  MealOrderListPayload,
  MealOrderListResponse,
  MealOrderListSuccess,
  MealOrderSubscriptionCreateFailed,
  MealOrderSubscriptionRemoveFailed,
  MealOrderUpdateFailed,
  MealOrderUpdateFetch,
  MealOrderUpdateParam,
  MealOrderUpdateSuccess,
  MENU_LIST_CLEAR,
  MENU_LIST_FAILED,
  MENU_LIST_FETCH,
  MENU_LIST_SUCCESS,
  MenuListFailed,
  MenuListParam,
  MenuListSuccess,
  ON_CREATE_UPDATE_MEAL_ORDER,
  ON_CREATE_UPDATE_MEAL_ORDER_FAILED,
  ON_CREATE_UPDATE_MEAL_ORDER_SUCCESS,
  OnCreateUpdateMealOrder,
  OnCreateUpdateMealOrderFailed,
  OnCreateUpdateMealOrderPayload,
  OnCreateUpdateMealOrderSuccess,
  SubscriptionRegistrationStatus,
  MenuListResponse,
  FailureType,
  MEAL_ORDER_SUBSCRIPTION_CREATE_FETCH,
  MEAL_ORDER_SUBSCRIPTION_REMOVE_FETCH,
  SET_LATEST_ORDER_COUNTER,
  SetLatestOrderCounter,
  LatestOrderCounter,
  TOGGLE_SORTED_ACTION,
  ToggleSortedAction,
} from './constants';
import { RESET } from '../../config/constant';

//#endregion

//#region INTERFACE
interface MealOrderListInitialState {
  mealOrderListParam?: MealOrderListPayload;
  mealOrderListResponse?: MealOrderListResponse;
  mealOrderListError?: FailureType;
  mealOrderListLoading: boolean;
}
interface MealOrderCreateInitialState {
  mealOrderCreateParam?: MealOrderCreateParam;
  mealOrderCreateResponse?: MealOrderCreateResponseType;
  mealOrderCreateError?: FailureType;
  mealOrderCreateLoading: boolean;
}
interface MealOrderUpdateInitialState {
  mealOrderUpdateParam?: MealOrderUpdateParam;
  mealOrderUpdateResponse?: MealOrder;
  mealOrderUpdateError?: FailureType;
  mealOrderUpdateLoading: boolean;
}
interface MealOrderSubscriptionInitialState {
  onCreateUpdateMealOrderParam?: OnCreateUpdateMealOrderPayload;
  onCreateUpdateMealOrderResponse?: OnCreateUpdateMealOrderPayload | MealOrder;
  subscriptionError?: FailureType;
  subscriptionRegistrationStatus: SubscriptionRegistrationStatus;
}
interface MenuListInitialState {
  menuListParam?: MenuListParam;
  menuListResponse?: MenuListResponse;
  menuListError?: FailureType;
  menuListLoading: boolean;
}
export interface InitialState
  extends MealOrderListInitialState,
    MealOrderCreateInitialState,
    MealOrderUpdateInitialState,
    MealOrderSubscriptionInitialState,
    MenuListInitialState {
  action: string;
  isMuted: boolean;
  latestOrderCounter: LatestOrderCounter;
  isAscSorted: boolean;
}
//#endregion

//#region INITIAL STATE
const mealOrderListInitialState: MealOrderListInitialState = {
  mealOrderListParam: undefined,
  mealOrderListResponse: undefined,
  mealOrderListError: undefined,
  mealOrderListLoading: false,
};
const mealOrderCreateInitialState: MealOrderCreateInitialState = {
  mealOrderCreateParam: undefined,
  mealOrderCreateResponse: undefined,
  mealOrderCreateError: undefined,
  mealOrderCreateLoading: false,
};
const mealOrderUpdateInitialState: MealOrderUpdateInitialState = {
  mealOrderUpdateParam: undefined,
  mealOrderUpdateResponse: undefined,
  mealOrderUpdateError: undefined,
  mealOrderUpdateLoading: false,
};
const mealOrderSubscriptionInitialState: MealOrderSubscriptionInitialState = {
  onCreateUpdateMealOrderParam: undefined,
  subscriptionError: undefined,
  subscriptionRegistrationStatus: SubscriptionRegistrationStatus.UNREGISTERED,
};
const menuListInitialState: MenuListInitialState = {
  menuListError: undefined,
  menuListLoading: false,
  menuListParam: undefined,
  menuListResponse: undefined,
};
const initialState: InitialState = {
  ...mealOrderListInitialState,
  ...mealOrderCreateInitialState,
  ...mealOrderUpdateInitialState,
  ...mealOrderSubscriptionInitialState,
  ...menuListInitialState,
  action: '',
  isMuted: true,
  latestOrderCounter: {
    date: (new Date()).getDate(),
    counter: -1,
  },
  isAscSorted: false
};
//#endregion

//#region REDUCERS
function mealsReducer(state = initialState, action: MealOrderActionTypes): InitialState {
  const actions = {
    [MEAL_ORDER_LIST_FETCH]: (): InitialState => ({
      ...state,
      mealOrderListParam: (action as MealOrderListFetch).payload,
      mealOrderListLoading: true,
      action: action.type,
    }),
    [MEAL_ORDER_LIST_SUCCESS]: (): InitialState => ({
      ...state,
      mealOrderListResponse: (action as MealOrderListSuccess).payload,
      mealOrderListLoading: false,
      action: action.type,
    }),
    [MEAL_ORDER_LIST_FAILED]: (): InitialState => ({
      ...state,
      mealOrderListError: (action as MealOrderListFailed).payload,
      mealOrderListLoading: false,
      action: action.type,
    }),
    [MEAL_ORDER_LIST_CLEAR]: (): InitialState => ({
      ...state,
      ...mealOrderListInitialState,
    }),
    [MEAL_ORDER_CREATE_FETCH]: (): InitialState => ({
      ...state,
      mealOrderCreateParam: (action as MealOrderCreateFetch).payload,
      mealOrderCreateLoading: true,
      action: action.type,
    }),
    [MEAL_ORDER_CREATE_SUCCESS]: (): InitialState => ({
      ...state,
      mealOrderCreateResponse: (action as MealOrderCreateSuccess).payload,
      mealOrderCreateLoading: false,
      action: action.type,
    }),
    [MEAL_ORDER_CREATE_FAILED]: (): InitialState => ({
      ...state,
      mealOrderCreateError: (action as MealOrderCreateFailed).payload,
      mealOrderCreateLoading: false,
      action: action.type,
    }),
    [MEAL_ORDER_CREATE_CLEAR]: (): InitialState => ({
      ...state,
      ...mealOrderCreateInitialState,
    }),
    [MEAL_ORDER_UPDATE_FETCH]: (): InitialState => ({
      ...state,
      mealOrderUpdateParam: (action as MealOrderUpdateFetch).payload,
      mealOrderUpdateLoading: true,
      action: action.type,
    }),
    [MEAL_ORDER_UPDATE_SUCCESS]: (): InitialState => ({
      ...state,
      mealOrderUpdateResponse: (action as MealOrderUpdateSuccess).payload,
      mealOrderUpdateLoading: false,
      action: action.type,
    }),
    [MEAL_ORDER_UPDATE_FAILED]: (): InitialState => ({
      ...state,
      mealOrderUpdateError: (action as MealOrderUpdateFailed).payload,
      mealOrderUpdateLoading: false,
      action: action.type,
    }),
    [MEAL_ORDER_UPDATE_CLEAR]: (): InitialState => ({
      ...state,
      ...mealOrderUpdateInitialState,
    }),
    [MEAL_ORDER_SUBSCRIPTION_CREATE_FETCH]: (): InitialState => ({
      ...state,
      subscriptionRegistrationStatus: SubscriptionRegistrationStatus.REGISTERING,
      action: action.type,
    }),
    [MEAL_ORDER_SUBSCRIPTION_CREATE_SUCCESS]: (): InitialState => ({
      ...state,
      subscriptionRegistrationStatus: SubscriptionRegistrationStatus.REGISTERED,
      action: action.type,
    }),
    [MEAL_ORDER_SUBSCRIPTION_CREATE_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
      subscriptionRegistrationStatus: SubscriptionRegistrationStatus.REGISTRATION_FAILED,
      subscriptionError: (action as MealOrderSubscriptionCreateFailed).payload,
    }),
    [MEAL_ORDER_SUBSCRIPTION_REMOVE_FETCH]: (): InitialState => ({
      ...state,
      subscriptionRegistrationStatus: SubscriptionRegistrationStatus.UNREGISTERING,
      action: action.type,
    }),
    [MEAL_ORDER_SUBSCRIPTION_REMOVE_SUCCESS]: (): InitialState => ({
      ...state,
      subscriptionRegistrationStatus: SubscriptionRegistrationStatus.UNREGISTERED,
      action: action.type,
    }),
    [MEAL_ORDER_SUBSCRIPTION_REMOVE_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
      subscriptionError: (action as MealOrderSubscriptionRemoveFailed).payload,
    }),
    [ON_CREATE_UPDATE_MEAL_ORDER]: (): InitialState => ({
      ...state,
      action: action.type,
      onCreateUpdateMealOrderParam: (action as OnCreateUpdateMealOrder).payload,
    }),
    [ON_CREATE_UPDATE_MEAL_ORDER_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
      onCreateUpdateMealOrderResponse: (action as OnCreateUpdateMealOrderSuccess).payload,
    }),
    [ON_CREATE_UPDATE_MEAL_ORDER_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
      subscriptionError: (action as OnCreateUpdateMealOrderFailed).payload,
    }),
    [MENU_LIST_FETCH]: (): InitialState => ({
      ...state,
      action: action.type,
      menuListLoading: true,
    }),
    [MENU_LIST_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
      menuListResponse: (action as MenuListSuccess).payload,
      menuListLoading: false,
    }),
    [MENU_LIST_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
      menuListError: (action as MenuListFailed).payload,
      menuListLoading: false,
    }),
    [MENU_LIST_CLEAR]: (): InitialState => ({
      ...state,
      ...menuListInitialState,
      action: action.type,
    }),
    [SET_LATEST_ORDER_COUNTER]:(): InitialState => ({
      ...state,
      latestOrderCounter: (action as SetLatestOrderCounter).payload,
      action: action.type,
    }),
    [TOGGLE_SORTED_ACTION]: (): InitialState => ({
      ...state,
      action:action.type,
      isAscSorted: (action as ToggleSortedAction).payload
    }),
    [RESET.ALL]: (): InitialState => ({
      ...initialState,
      action: action.type,
    }),

    DEFAULT: (): InitialState => state,
  };
  return (actions[action.type] || actions.DEFAULT)();
}

export default mealsReducer;
//#endregion
