//#region IMPORT
// PACKAGE IMPORT
import gql from 'graphql-tag';
//#endregion

//#region QUERIES
const GetStaffCall = gql`
  query GetStaffCall($device_id: ID!, $status: String!, $timestamp: String!) {
    getStaffCall(device_id: $device_id, status: $status, timestamp: $timestamp) {
      answered_at
      answered_by
      device_id
      status
      timestamp
    }
  }
`;

const ListStaffCallByStatus = gql`
  query ListStaffCallByStatus($status: String!, $date: AWSDate, $limit: Int, $nextToken: String) {
    listStaffCallByStatus(status: $status, date: $date, limit: $limit, nextToken: $nextToken) {
      items {
        device_id
        status
        timestamp
        answered_at
        answered_by
      }
      nextToken
    }
  }
`;

const ListStaffCallByStatusUnanswered = gql`
  query ListStaffCallByStatus($status: String!, $date: AWSDate, $limit: Int, $nextToken: String) {
    listStaffCallByStatus(status: $status, date: $date, limit: $limit, nextToken: $nextToken) {
      items {
        device_id
        status
        timestamp
      }
      nextToken
    }
  }
`;

export const queries = { GetStaffCall, ListStaffCallByStatus, ListStaffCallByStatusUnanswered };

//#endregion

//#region MUTATIONS

const CreateStaffCall = gql`
  mutation CreateStaffCall($input: CreateStaffCallInput!) {
    createStaffCall(input: $input) {
      answered_at
      answered_by
      device_id
      status
      timestamp
    }
  }
`;

const CancelStaffCall = gql`
  mutation CancelStaffCall($input: CancelStaffCallInput!) {
    cancelStaffCall(input: $input) {
      answered_at
      answered_by
      device_id
      status
      timestamp
    }
  }
`;

const AnswerStaffCall = gql`
  mutation AnswerStaffCall($input: AnswerStaffCallInput!) {
    answerStaffCall(input: $input) {
      answered_at
      answered_by
      device_id
      status
      timestamp
    }
  }
`;

export const mutations = {
  CreateStaffCall,
  CancelStaffCall,
  AnswerStaffCall,
};

//#endregion

//#region SUBSCRIPTION

const OnCreateStaffCall = gql`
  subscription OnCreateStaffCall {
    onCreateStaffCall {
      device_id
      status
      timestamp
    }
  }
`;

const OnCancelStaffCall = gql`
  subscription OnCancelStaffCall {
    onCancelStaffCall {
      answered_at
      answered_by
      device_id
      status
      timestamp
    }
  }
`;

const OnAnswerStaffCall = gql`
  subscription OnAnswerStaffCall {
    onAnswerStaffCall {
      answered_at
      answered_by
      device_id
      status
      timestamp
    }
  }
`;

export const subscription = {
  OnCreateStaffCall,
  OnCancelStaffCall,
  OnAnswerStaffCall,
};

//#endregion
