//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import classnames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// LOCAL COMPONENT
import Button from '../Button';
import './_index.scss';

//#endregion

//#region INTERFACE
interface OwnProps extends RouteComponentProps {
  icon?: React.ReactElement<SVGElement> | string;
  title: string;
  action?: React.ReactElement[] | React.ReactElement;
  intl: IntlShape;
  className?: string;
  backButton?: boolean;
  actionClassName?: string;
}

type Props = OwnProps;

//#endregion

//#region COMPONENT
function Header({ title, action, intl, backButton, actionClassName, history }: Props): React.ReactElement {
  const classes = classnames(`header`);
  const actionClasses = classnames(`header__action-list ${actionClassName || ''}`);
  return (
    <div className={classes}>
      {backButton && (
        <div className="header__back">
          <Button
            key="link-to-staffcall"
            type="button"
            color="transparent-primary"
            className="header__back--color-light"
            icon="keyboard_arrow_left"
            onClick={history.goBack}
            iconOnly
          />
        </div>
      )}
      <div className="header__title">
        <h3>{intl.formatMessage({ id: title, defaultMessage: title })}</h3>
      </div>

      {action && <div className={actionClasses}>{action}</div>}
    </div>
  );
}
//#endregion

export default withRouter(injectIntl(Header));
