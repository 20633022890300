//#region
// LOCAL IMPORT
import React, { SFC } from 'react';

// LOCAL COMPONENT
import Meals from '../Meals';

//#endregion

//#region COMPONENT
const MealsArchive: SFC = () => {
  return <Meals isArchive={true} />;
};
//#endregion

export default MealsArchive;
