//#region IMPORT
// LOCAL CONFIG
import {
  CONGESTION_UPDATE_FETCH,
  CongestionUpdateFetch,
  CongestionActionTypes,
  CONGESTION_UPDATE_SUCCESS,
  CongestionUpdateSuccess,
  CONGESTION_LIST_SUCCESS,
  CongestionListSuccess,
  CONGESTION_LIST_FETCH,
  CongestionListFetch,
  CONGESTION_UPDATE_FAILED,
  CONGESTION_UPDATE_CLEAR,
  CONGESTION_LIST_FAILED,
  CONGESTION_LIST_CLEAR,
  CongestionUpdateFailed,
  CongestionListFailed,
  CONGESTION_SUBSCRIPTION_FAILED,
  CONGESTION_SUBSCRIPTION_SUCCESS,
  AreaList,
} from './constants';
import { RESET } from '../../config/constant';
//#endregion

//#region INTERFACE
export interface CongestionListInitialState {
  congestionListParam?: object;
  congestionListResponse?: AreaList;
  congestionListError?: object;
  congestionListLoading: boolean;
}

export interface CongestionUpdateInitialState {
  congestionUpdateParam?: object;
  congestionUpdateResponse?: object;
  congestionUpdateError?: object;
  congestionUpdateLoading: boolean;
}

export interface InitialState extends CongestionUpdateInitialState, CongestionListInitialState {
  action: string;
}
//#endregion

//#region INITIAL STATE
const congestionUpdateInitialState: CongestionUpdateInitialState = {
  congestionUpdateError: undefined,
  congestionUpdateLoading: false,
  congestionUpdateParam: undefined,
  congestionUpdateResponse: undefined,
};

const congestionListInitialState: CongestionListInitialState = {
  congestionListError: undefined,
  congestionListLoading: false,
  congestionListParam: undefined,
  congestionListResponse: undefined,
};

const initialState: InitialState = {
  ...congestionUpdateInitialState,
  ...congestionListInitialState,
  action: '',
};
//#endregion

//#region SWITCH
export default (state = initialState, action: CongestionActionTypes): InitialState => {
  const actions = {
    [CONGESTION_UPDATE_FETCH]: (): InitialState => ({
      ...state,
      congestionUpdateParam: (action as CongestionUpdateFetch).payload,
      congestionUpdateLoading: true,
      action: action.type,
    }),
    [CONGESTION_UPDATE_SUCCESS]: (): InitialState => ({
      ...state,
      congestionUpdateResponse: (action as CongestionUpdateSuccess).payload,
      congestionUpdateLoading: false,
      action: action.type,
    }),
    [CONGESTION_UPDATE_FAILED]: (): InitialState => ({
      ...state,
      congestionUpdateError: (action as CongestionUpdateFailed).payload,
      congestionUpdateLoading: false,
      action: action.type,
    }),
    [CONGESTION_UPDATE_CLEAR]: (): InitialState => ({
      ...state,
      ...congestionUpdateInitialState,
    }),
    [CONGESTION_LIST_FETCH]: (): InitialState => ({
      ...state,
      congestionListParam: (action as CongestionListFetch).payload,
      congestionListLoading: true,
      action: action.type,
    }),
    [CONGESTION_LIST_SUCCESS]: (): InitialState => ({
      ...state,
      congestionListResponse: (action as CongestionListSuccess).payload,
      congestionListLoading: false,
      action: action.type,
    }),
    [CONGESTION_LIST_FAILED]: (): InitialState => ({
      ...state,
      congestionListError: (action as CongestionListFailed).payload,
      congestionListLoading: false,
      action: action.type,
    }),
    [CONGESTION_LIST_CLEAR]: (): InitialState => ({
      ...state,
      ...congestionListInitialState,
    }),
    [CONGESTION_SUBSCRIPTION_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
    }),
    [CONGESTION_SUBSCRIPTION_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
    }),
    [RESET.ALL]: (): InitialState => ({
      ...initialState,
      action: action.type,
    }),
    DEFAULT: (): InitialState => state,
  };
  return (actions[action.type] || actions.DEFAULT)();
};
//#endregion
