//#region IMPORT
// PACKAGE IMPORT
import gql from 'graphql-tag';
//#endregion

//#region QUERIES
const ListMealOrderByStatus = gql`
  query ListMealOrderByStatus($status: String!, $airport_id: String!, $lounge_id: String!, $limit: Int, $nextToken: String) {
    listMealOrderByStatus(status: $status, airport_id: $airport_id, lounge_id: $lounge_id, nextToken: $nextToken, limit: $limit) {
      items {
        counter
        device_id
        items
        status
        status_history {
          status
          timestamp
        }
        timestamp
        use_app
        table_id
        airport_id
        lounge_id
      }
      nextToken
    }
  }
`;

const ListMealOrderByStatusAndDate = gql`
  query ListMealOrderByStatusAndDate($status: String!, $date: AWSDate!, $airport_id: String!, $lounge_id: String!, $limit: Int, $nextToken: String) {
    listMealOrderByStatusAndDate(status: $status, date: $date, airport_id: $airport_id, lounge_id: $lounge_id, limit: $limit, nextToken: $nextToken) {
      items {
        counter
        device_id
        items
        status
        status_history {
          status
          timestamp
        }
        timestamp
        use_app
        table_id
        airport_id
        lounge_id
      }
      nextToken
    }
  }
`;

const ListMealOrderByDeviceId = gql`
  query ListMealOrderByDeviceId($device_id: ID!, $airport_id: String!, $lounge_id: String!, $limit: Int, $nextToken: String) {
    listMealOrderByDeviceId(device_id: $device_id, airport_id: $airport_id, lounge_id: $lounge_id, limit: $limit, nextToken: $nextToken) {
      items {
        counter
        device_id
        items
        status
        status_history {
          status
          timestamp
        }
        timestamp
        use_app
        table_id
        airport_id
        lounge_id
      }
      nextToken
    }
  }
`;

export const queries = {
  ListMealOrderByStatus,
  ListMealOrderByStatusAndDate,
  ListMealOrderByDeviceId,
};
//#endregion

//#region MUTATIONS
const CreateMealOrder = gql`
  mutation CreateMealOrder($input: CreateMealOrderInput!) {
    createMealOrder(input: $input) {
      counter
      device_id
      items
      status
      status_history {
        status
        timestamp
      }
      timestamp
      use_app
      table_id
      airport_id
      lounge_id
    }
  }
`;

const RejectMealOrder = gql`
  mutation RejectMealOrder($input: RejectMealOrderInput!) {
    rejectMealOrder(input: $input) {
      counter
      device_id
      items
      status
      status_history {
        status
        timestamp
      }
      timestamp
      use_app
      table_id
      airport_id
      lounge_id
    }
  }
`;

const StartMealOrder = gql`
  mutation StartMealOrder($input: StartMealOrderInput!) {
    startMealOrder(input: $input) {
      counter
      device_id
      items
      status
      status_history {
        status
        timestamp
      }
      timestamp
      use_app
      table_id
      airport_id
      lounge_id
    }
  }
`;

const CompleteMealOrder = gql`
  mutation CompleteMealOrder($input: CompleteMealOrderInput!) {
    completeMealOrder(input: $input) {
      counter
      device_id
      items
      status
      status_history {
        status
        timestamp
      }
      timestamp
      use_app
      table_id
      airport_id
      lounge_id
    }
  }
`;

const UpdateMealOrderItems = gql`
  mutation UpdateMealOrderItems($input: UpdateMealOrderItemsInput!) {
    updateMealOrderItems(input: $input) {
      device_id
      items
      timestamp
      table_id
      airport_id
      lounge_id
    }
  }
`;

export const mutations = {
  CreateMealOrder,
  RejectMealOrder,
  StartMealOrder,
  CompleteMealOrder,
  UpdateMealOrderItems,
};
//#endregion

//#region SUBSCRIPTIONS
const OnCreateMealOrder = gql`
  subscription OnCreateMealOrder {
    onCreateMealOrder {
      counter
      device_id
      items
      status
      status_history {
        status
        timestamp
      }
      timestamp
      use_app
      table_id
      airport_id
      lounge_id
    }
  }
`;

const OnRejectMealOrder = gql`
  subscription OnRejectMealOrder {
    onRejectMealOrder {
      counter
      device_id
      items
      status
      status_history {
        status
        timestamp
      }
      timestamp
      use_app
      table_id
      airport_id
      lounge_id
    }
  }
`;

const OnStartMealOrder = gql`
  subscription OnStartMealOrder {
    onStartMealOrder {
      counter
      device_id
      items
      status
      status_history {
        status
        timestamp
      }
      timestamp
      use_app
      table_id
      airport_id
      lounge_id
    }
  }
`;

const OnCompleteMealOrder = gql`
  subscription OnCompleteMealOrder {
    onCompleteMealOrder {
      counter
      device_id
      items
      status
      status_history {
        status
        timestamp
      }
      timestamp
      use_app
      table_id
      airport_id
      lounge_id
    }
  }
`;

const OnUpdateMealOrderItems = gql`
  subscription OnUpdateMealOrderItems {
    onUpdateMealOrderItems {
      counter
      device_id
      items
      status
      status_history {
        status
        timestamp
      }
      timestamp
      use_app
      table_id
      airport_id
      lounge_id
    }
  }
`;

export const subscription = {
  OnCreateMealOrder,
  OnRejectMealOrder,
  OnStartMealOrder,
  OnCompleteMealOrder,
  OnUpdateMealOrderItems,
};
//#endregion
