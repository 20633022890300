//#region IMPORT
// PACKAGE IMPORT
import { connectRouter, LocationChangeAction } from 'connected-react-router';
import { History } from 'history';
import { ReducersMapObject, AnyAction } from 'redux';

// LOCAL CONFIG
import user from '../modules/user/ReducerUser';
import alert from '../modules/alert/ReducerAlert';
import shower from '../modules/shower/ReducerShower';
import wrapper from '../modules/wrapper/ReducerWrapper';
import meals from '../modules/meals/ReducerMeals';
import congestion from '../modules/congestion/ReducerCongestion';
import staffCall from '../modules/staffcall/ReducerStaffCall';
import { JalAppState } from './interface';
//#endregion

export default (history: History): ReducersMapObject<JalAppState, AnyAction & LocationChangeAction> => ({
  router: connectRouter(history),
  user,
  alert,
  shower,
  wrapper,
  meals,
  congestion,
  staffCall,
});
