//#region IMPORT
// PACKAGE IMPORT
import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// LOCAL CONFIG
import { store, history, persistor } from './bootstrap/ReduxStore';

// LOCAL COMPONENT
import Wrapper from './modules/wrapper/views/Wrapper';

//#endregion

//#region RENDER
ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <ConnectedRouter history={history}>
        <Wrapper />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

//#endregion
