//#region IMPORT
// PACKAGE IMPORT
import React, { SFC } from 'react';
// LOCAL COMPONENT
import Shower from '../Shower';
//#endregion

//region COMPONENT
const ShowerArchive: SFC = () => {
  return <Shower isArchive />;
};
//#endregion

export default ShowerArchive;
