//#region IMPORT
// PACKAGE IMPORT
import React, { Component } from 'react';
import classnames from 'classnames';
import { Formik, FormikProps, Form } from 'formik';

// LOCAL CONFIG
import { CongestionLevel, CongestionUpdateParam } from '../../constants';

// LOCAL COMPONENT
import { Button } from '../../../../components';
import { CongestionListRowItem } from '../../components';
import './CongestionListRow.scss';

//#endregion

//#region INTERFACE
export interface StateProps {
  congestionUpdateLoading: boolean;
  congestionUpdateParam: CongestionUpdateParam;
  airport_id: string;
}

export interface DispatchProps {
  congestionUpdateFetch(payload: CongestionUpdateParam): void;
}

interface OwnProps {
  congestionLevel?: number;
  areaCode?: string;
  isHeader?: boolean;
  children: React.ReactNode;
}

interface OwnFormProps {
  code: string;
  congestionLevel: string;
}

interface OwnState {
  isUpdating: boolean;
  initialValues: OwnFormProps;
}

type Props = OwnProps & StateProps & DispatchProps;
//#endregion

//#region COMPONENT
class CongestionListRow extends Component<Props, OwnState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isUpdating: false,
      initialValues: {
        code: typeof props.areaCode !== 'undefined' ? props.areaCode : '',
        congestionLevel: typeof props.congestionLevel !== 'undefined' ? props.congestionLevel.toString() : '0',
      },
    };
    this.onChangeClick = this.onChangeClick.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.congestionUpdateLoading !== this.props.congestionUpdateLoading) {
      if (!this.props.congestionUpdateLoading && this.props.congestionUpdateParam.code === this.props.areaCode) {
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

  onChangeClick(e: React.MouseEvent<HTMLButtonElement>): void {
    e.preventDefault();
    this.setState({
      isUpdating: !this.state.isUpdating,
    });
  }

  onSubmitClick(values: OwnFormProps): void {
    const { code, congestionLevel } = values;
    this.props.congestionUpdateFetch({ airport_id: this.props.airport_id, code, congestionLevel: parseInt(congestionLevel) });
    this.setState({
      initialValues: {
        code,
        congestionLevel,
      },
    });
  }

  render(): React.ReactNode {
    const { isUpdating, initialValues } = this.state;
    const { congestionLevel, isHeader, congestionUpdateLoading, congestionUpdateParam, areaCode } = this.props;
    const classes = classnames(
      `congestion-list-row`,
      { 'congestion-list-row--updating': isUpdating },
      { 'congestion-list-row__header': typeof isHeader !== 'undefined' }
    );

    const loadUpdating = congestionUpdateLoading && congestionUpdateParam.code === areaCode;

    return (
      <>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={this.onSubmitClick}
          render={(formikProps: FormikProps<OwnFormProps>): React.ReactNode => (
            <Form className={classes}>
              {isHeader && this.props.children}
              {!isHeader && (
                <>
                  {!isUpdating && (
                    <>
                      {this.props.children}
                      {congestionLevel && (
                        <CongestionListRowItem congestionStatus={congestionLevel}>
                          {CongestionLevel[congestionLevel] === "Closed" && "一時閉鎖中"}
                          {CongestionLevel[congestionLevel] === "Vacant" && "快適"}
                          {CongestionLevel[congestionLevel] === "Crowded" &&  "混雑"}
                        </CongestionListRowItem>
                      )}
                      <CongestionListRowItem className="flex-end">
                        <Button onClick={this.onChangeClick} color="secondary" label="labelChange" type="button" />
                      </CongestionListRowItem>
                    </>
                  )}
                  {isUpdating && (
                    <>
                      {this.props.children}
                      <CongestionListRowItem>
                        <div className="congestion-slider">
                          <div className="congestion-slider__input">
                            {formikProps.status}
                            <input
                              name="congestionLevel"
                              type="range"
                              step="1"
                              min="1"
                              max="3"
                              value={formikProps.values.congestionLevel}
                              onChange={(e: React.FormEvent<HTMLInputElement>): void => {
                                const newLevelValue = e.currentTarget.value;
                                formikProps.setFieldValue('congestionLevel', newLevelValue);
                              }}
                            />
                          </div>
                          <div className="congestion-slider__caption">
                            <span className="closed">一時閉鎖中</span>
                            <span className="vacant">快適</span>
                            <span className="crowd">混雑</span>
                          </div>
                        </div>
                      </CongestionListRowItem>
                      <CongestionListRowItem className="flex-end">
                        <Button
                          color="primary"
                          label="labelUpdate"
                          isSubmit
                          disabled={loadUpdating}
                          showSpinner={loadUpdating}
                        />
                      </CongestionListRowItem>
                    </>
                  )}
                </>
              )}
            </Form>
          )}
        />
      </>
    );
  }
}
//#endregion
export default CongestionListRow;
