//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import classnames from 'classnames';

//LOCAL CONFIG
import './_index.scss';

//#endregion

//#region INTERFACE
interface OwnProps {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  field?: any;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  form?: any;
  className?: string;
  label?: string;
  checked?: boolean;
  id: string;
  intl: IntlShape;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

type Props = OwnProps;

//#endregion

//#region COMPONENT
function Checkbox({ field, className, label, id, intl, checked, onChange, ...props }: Props): React.ReactElement {
  const classes = classnames('checkbox', className);
  return (
    <div className={classes}>
      <input
        {...props}
        type="checkbox"
        id={id}
        className="checkbox__check"
        onChange={onChange && onChange}
        {...(typeof checked === 'boolean' && { checked })}
        {...(field && { ...field })}
      />
      <label htmlFor={id}>
        {typeof label === 'string' && intl.formatMessage({ id: label, defaultMessage: label })}
      </label>
    </div>
  );
}

//#endregion

export default injectIntl(Checkbox);
