//#region
// IMPORT PACKAGE
import React, { FunctionComponent } from 'react';

// LOCAL COMPONENT
import StaffCall from '../StaffCall';
//#endregion

//#region COMPONENT
const Index: FunctionComponent = () => {
  return <StaffCall isArchive />;
};
//#endregion

export default Index;
