//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import classnames from 'classnames';
import moment from 'moment';
import lodash from 'lodash';

// LOCAL CONFIG
import {
  MealOrder,
  MealOrderStatusHistory,
  MealOrderItem,
  MealOrderStatusType,
  MealOrderCreateUpdateType,
} from '../../constants';

// LOCAL COMPONENT
import { Icon } from '../../../../components';
import MealsAction from '../MealsAction';
import UserLocation from '../../../user/views/UserLocation';
import { tagMaster } from '../../../user/views/UserLocationSearch/TagMaster';
import './MealsDetail.scss';

//#endregion

//#region INTERFACE
export interface OwnProps {
  intl: IntlShape;
  data: MealOrder;
  onClickActionButton(mealOrder: MealOrder, updateType: MealOrderCreateUpdateType): void;
}

type Props = OwnProps;

const InitialState = {
  items: {},
  itemIsEmpty: true,
};

type State = typeof InitialState;

//#endregion

//#region COMPONENT
class MealsDetail extends React.Component<Props, State> {
  //#region CTOR
  constructor(props: OwnProps) {
    super(props);

    this.state = InitialState;

    this.getMealsOrderStatusLabel = this.getMealsOrderStatusLabel.bind(this);
    this.parseItems = this.parseItems.bind(this);
  }
  //#endregion

  //#region LC
  componentDidMount(): void {
    this.parseItems();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!lodash.isEqual(this.props.data, prevProps.data)) {
      this.parseItems();
    }
  }
  //#endregion

  //#region METHOD
  parseItems(): void {
    const items = JSON.parse(this.props.data.items);
    if (!lodash.isEmpty(items)) {
      this.setState({ items, itemIsEmpty: false });
    }
  }

  getMealsOrderStatusLabel(status: MealOrderStatusType): string {
    const labels = {
      [MealOrderStatusType.SUBMITTED as string]: 'mealsStatusSubmitted',
      [MealOrderStatusType.REJECTED as string]: 'mealsStatusRejected',
      [MealOrderStatusType.IN_PROGRESS as string]: 'mealsStatusInProgress',
      [MealOrderStatusType.COMPLETED as string]: 'mealsStatusCompleted',
    };
    const label = labels[status as string];

    return this.props.intl.formatMessage({ id: label, defaultMessage: label });
  }
  //#endregion

  //#region RENDER
  renderMealOrderItems(): JSX.Element[] | null {
    if (this.state.itemIsEmpty) {
      return null;
    }
    return (this.state.items as MealOrderItem[]).map((item: MealOrderItem, index: number) => (
      <div key={index} className="meals-detail__content__section__item__content__item">
        <div className="meals-detail__content__section__item__content__item__qty">{item.qty}</div>
        <div className="meals-detail__content__section__item__content__item__name">{item.name}</div>
      </div>
    ));
  }

  render(): JSX.Element {
    const { intl, data } = this.props;
    const classess = classnames('meals-detail');

    return (
      <div className={classess}>
        <div className="meals-detail__content">
          <div className="meals-detail__content__section section-order">
            <div className="meals-detail__content__section__item">
              {!data.status_history && (
                <>
                <div className="meals-detail__content__section__item__annouce">
                  {intl.formatMessage({
                    id: 'labelReloadAnnounce',
                    defaultMessage: 'labelReloadAnnounce',
                  })}
                </div>
                </>
              )}
              <div className="meals-detail__content__section__item__header">
                {intl.formatMessage({
                  id: data.use_app ? 'labelCustomerId' : 'labelBeaconId',
                  defaultMessage: data.use_app ? 'labelCustomerId' : 'labelBeaconId',
                })}
              </div>
              <div className="meals-detail__content__section__item__content">
                {!data.use_app && (
                  <span className="icon-wrapper">
                    <Icon data="label" />
                  </span>
                )}
                {data.use_app ? data.device_id : tagMaster.filter(tag => tag.code === data.device_id)[0].id}
              </div>
            </div>
            <div className="meals-detail__content__section__item">
              <div className="meals-detail__content__section__item__header">
                {intl.formatMessage({ id: 'labelOrderId', defaultMessage: 'labelOrderId' })}
              </div>
              <div className="meals-detail__content__section__item__content">{data.counter}</div>
            </div>
            <div className="meals-detail__content__section__item">
              <div className="meals-detail__content__section__item__header">
                {intl.formatMessage({ id: 'mealsOrderItems', defaultMessage: 'mealsOrderItems' })}
              </div>
              <div className="meals-detail__content__section__item__content">{this.renderMealOrderItems()}</div>
            </div>
            <div className="meals-detail__content__section__item">
              <div className="meals-detail__content__section__item__header">
                {intl.formatMessage({ id: 'labelHistory', defaultMessage: 'labelHistory' })}
              </div>
              <div className="meals-detail__content__section__item__content">
                {data.status_history && data.status_history.map((history: MealOrderStatusHistory, index: number) => {
                  return (
                    <div className="meals-detail__content__section__item__content__status" key={index}>
                      <div className="meals-detail__content__section__item__content__status__time">
                        {moment(history.timestamp).format('lll')}
                      </div>
                      <div className="meals-detail__content__section__item__content__status__value">
                        {this.getMealsOrderStatusLabel(history.status as MealOrderStatusType)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="meals-detail__content__section section-map">
            <div className="meals-detail__content__section__item">
              <div className="meals-detail__content__section__item__header">
                {intl.formatMessage({ id: 'labelLocation', defaultMessage: 'labelLocation' })}
              </div>
              <div className="meals-detail__content__section__item__content">
                <UserLocation tagId={data.device_id} tableId={data.table_id} />
              </div>
            </div>
          </div>
        </div>
        <MealsAction {...this.props} />
      </div>
    );
  }
  //#endregion
}

//#endregion

export default injectIntl(MealsDetail);
