//#region IMPORTS
// PACKAGE IMPORT
import Amplify from '@aws-amplify/core';

// LOCAL CONFIG
import env from './environment';
import { LANG } from './constant';
//#endregion

//#region AMPLIFY CONFIG
export const amplifyConfig = (): {} =>
  Amplify.configure({
    Auth: {
      identityPoolId: env.poolId,
      region: env.region,
      identityPoolRegion: env.identityPoolRegion,
      userPoolId: env.userPoolId,
      userPoolWebClientId: env.userPoolWebClientId,
      mandatorySignIn: env.mandatorySignIn,
    },
    aws_appsync_graphqlEndpoint: env.appsyncEndpoint,
    aws_appsync_region: env.appsyncRegion,
    aws_appsync_authenticationType: env.appsyncAuthenticationType,
  });
//#endregion

//#region SETTING
export const settings = {
  lang: LANG.JA,
};
//#endregion
