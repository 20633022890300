//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import moment from 'moment';

// LOCAL CONFIG
import { StaffCallItem, StaffCallStatusTypes, StaffCallMutationTypes } from '../../constants';
import StaffCallAction from '../StaffCallAction';

// LOCAL COMPONENTS
import { TimeCounter } from '../../components';
import { Icon } from '../../../../components';
import UserLocation from '../../../user/views/UserLocation';
import './StaffCallDetail.scss';
//#endregion

//#region INTERFACE
interface OwnProps extends WrappedComponentProps {
  data: StaffCallItem;
  onClickActionButton(data: StaffCallItem, UpdateType: StaffCallMutationTypes): void;
}
//#endregion

//#region COMPONENT
class StaffCallDetail extends React.Component<OwnProps> {
  //#region METHOD
  getStaffCallStatusLabel(status: StaffCallStatusTypes): string {
    const labels = {
      [StaffCallStatusTypes.ANSWERED as string]: 'staffCallStatusAnswer',
      [StaffCallStatusTypes.CALLING as string]: 'staffCallStatusCall',
      [StaffCallStatusTypes.CANCELLED as string]: 'staffCallStatusCancel',
    };
    const label = labels[status as string];

    return this.props.intl.formatMessage({ id: label, defaultMessage: label });
  }

  render(): React.ReactElement {
    const { intl, data } = this.props;
    const callReceiver = data.answered_by && data.answered_by.substr(data.answered_by.indexOf(':') + 1);
    const isNotCallingStatus =
      data.status === StaffCallStatusTypes.ANSWERED || data.status === StaffCallStatusTypes.CANCELLED;
    const isCancelled = data.status === StaffCallStatusTypes.CANCELLED;
    const isAnswered = data.status === StaffCallStatusTypes.ANSWERED;

    return (
      <div className="staff-call-detail">
        <div className="staff-call-detail__content">
          <div className="staff-call-detail__content__section section-order">
            <div className="staff-call-detail__content__section__item">
              <div className="staff-call-detail__content__section__item__header">
                {intl.formatMessage({
                  id: 'labelCustomerId',
                  defaultMessage: 'labelCustomerId',
                })}
              </div>
              <div className="staff-call-detail__content__section__item__content__status">{data.device_id}</div>
            </div>
            {data.status === StaffCallStatusTypes.CALLING && (
              <div className="staff-call-detail__content__section__item">
                <div className="staff-call-detail__content__section__item__header">
                  {intl.formatMessage({
                    id: 'labelCallTime',
                    defaultMessage: 'labelCallTime',
                  })}
                </div>
                <div className="staff-call-detail__content__section__item__content">
                  <span className="icon-wrapper">
                    <Icon data="timer" />
                  </span>
                  <TimeCounter startTime={data.timestamp} />
                </div>
              </div>
            )}

            {isNotCallingStatus && (
              <div className="staff-call-detail__content__section__item">
                <div className="staff-call-detail__content__section__item__header">
                  {intl.formatMessage({
                    id: 'labelHistory',
                    defaultMessage: 'labelHistory',
                  })}
                </div>

                <div className="staff-call-detail__content__section__item__content">
                  <div className="staff-call-detail__content__section__item__content__status">
                    <div className="staff-call-detail__content__section__item__content__status__time">
                      {moment(data.timestamp).format('lll')}
                    </div>
                    <div className="staff-call-detail__content__section__item__content__status__value">
                      {this.getStaffCallStatusLabel(StaffCallStatusTypes.CALLING)}
                    </div>
                  </div>
                  <div className="staff-call-detail__content__section__item__content__status">
                    <div className="staff-call-detail__content__section__item__content__status__time">
                      {isAnswered && moment(data.answered_at).format('lll')}
                      {isCancelled && moment(data.timestamp).format('lll')}
                    </div>
                    <div className="staff-call-detail__content__section__item__content__status__value">
                      {this.getStaffCallStatusLabel(data.status as StaffCallStatusTypes)}
                      {isAnswered && ` - ${callReceiver}`}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="staff-call-detail__content__section section-map">
            <div className="staff-call-detail__content__section__item">
              <div className="staff-call-detail__content__section__item__header">
                {intl.formatMessage({ id: 'labelLocation', defaultMessage: 'labelLocation' })}
              </div>
              <div className="staff-call-detail__content__section__item__content__status">
                <UserLocation tagId={data.device_id} tableId={"41-01-001"} />
              </div>
            </div>
          </div>
        </div>
        <StaffCallAction {...this.props} />
      </div>
    );
  }
  //#endregion
}
//#endregion

export default injectIntl(StaffCallDetail);
