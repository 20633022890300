export interface TagMaster {
    code: string,
    id: number
}
export interface Tag {
    label: string;
    value: string;
}
export const tagMaster: TagMaster[] = [{
    "code": "a4da22e0ab07",
    "id": 1
},
{
    "code": "a4da22e0bc10",
    "id": 2
},
{
    "code": "a4da22e0aaf9",
    "id": 3
},
{
    "code": "a4da22e0aaf6",
    "id": 4
},
{
    "code": "a4da22e0c0be",
    "id": 5
},
{
    "code": "a4da22e0ab80",
    "id": 6
},
{
    "code": "a4da22e0aafa",
    "id": 7
},
{
    "code": "a4da22e0ac30",
    "id": 8
},
{
    "code": "a4da22e0ac2d",
    "id": 9
},
{
    "code": "a4da22e0bc19",
    "id": 10
},
{
    "code": "a4da22e0ac14",
    "id": 11
},
{
    "code": "a4da22e0ac0c",
    "id": 12
},
{
    "code": "a4da22e0c01b",
    "id": 13
},
{
    "code": "a4da22e0c0c9",
    "id": 14
},
{
    "code": "a4da22e0aac6",
    "id": 15
},
{
    "code": "a4da22e0aa84",
    "id": 16
},
{
    "code": "a4da22e0c0e4",
    "id": 17
},
{
    "code": "a4da22e0bbf0",
    "id": 18
},
{
    "code": "a4da22e0bbfa",
    "id": 19
},
{
    "code": "a4da22e0bc20",
    "id": 20
},
{
    "code": "a4da22e0aafc",
    "id": 21
},
{
    "code": "a4da22e0bc25",
    "id": 22
},
{
    "code": "a4da22e0ab83",
    "id": 23
},
{
    "code": "a4da22e0bc29",
    "id": 24
},
{
    "code": "a4da22e0aa87",
    "id": 25
},
{
    "code": "a4da22e0ace2",
    "id": 26
},
{
    "code": "a4da22e0bc5b",
    "id": 27
},
{
    "code": "a4da22e0c382",
    "id": 28
},
{
    "code": "a4da22e0aa78",
    "id": 29
},
{
    "code": "a4da22e0c3b1",
    "id": 30
}
]