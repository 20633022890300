//#region IMPORT
// PACKAGE IMPORT
import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Observable from 'zen-observable';
import API, { graphqlOperation } from '@aws-amplify/api';

// LOCAL CONFIG
import {
  ListArea,
  CongestionListParam,
  Area,
  subscription,
  FailureType,
  GraphQLSubscriptionResult,
} from '../../constants';

// LOCAL COMPONENT
import { Header } from '../../../../components';
import CongestionListRow from '../CongestionListRow';
import { CongestionListRowItem } from '../../components';
import './Congestion.scss';
//#endregion

//#region INTERFACE
interface OwnState {
  isUpdating: boolean;
}

export interface DispatchProps {
  congestionListFetch(payload: CongestionListParam): void;
  CongestionSubscriptionSuccess(): void;
  CongestionSubscriptionFailed(payload: FailureType): void;
}

export interface StateProps {
  data: Area[] | undefined;
  airport_id: string;
}

type Props = DispatchProps & StateProps & WrappedComponentProps;
//#endregion

//#region COMPONENT
class Congestion extends Component<Props, OwnState> {
  private OnCongestionUpdate?: ZenObservable.Subscription;

  constructor(props: Props) {
    super(props);

    this.state = { isUpdating: false };
    this.onChangeClick = this.onChangeClick.bind(this);
    this.fetchCongestionList = this.fetchCongestionList.bind(this);
  }

  onChangeClick(): void {
    this.setState({
      isUpdating: !this.state.isUpdating,
    });
  }

  componentWillUnmount(): void {
    this.unsubscribeSubscription();
  }

  componentDidMount(): void {
    this.createSubscription();
    this.fetchCongestionList();
  }

  fetchCongestionList(): void {
    this.props.congestionListFetch({airport_id: this.props.airport_id});
  }

  handleSubscriptionUpdate(): void {
    this.fetchCongestionList();
  }

  async createSubscription(): Promise<void> {
    try {
      this.OnCongestionUpdate = ((await API.graphql(graphqlOperation(subscription.OnUpdateArea))) as Observable<
        GraphQLSubscriptionResult
      >).subscribe({
        next: () => this.handleSubscriptionUpdate(),
      });

      this.props.CongestionSubscriptionSuccess();
    } catch (e) {
      this.props.CongestionSubscriptionFailed(e);
    }
  }

  unsubscribeSubscription(): void {
    this.OnCongestionUpdate && this.OnCongestionUpdate.unsubscribe();
  }

  render(): React.ReactNode {
    const { intl, data } = this.props;
    if (!data) {
      return <></>;
    }
    return (
      <div className="congestion">
        <Header title="congestionTitle" />

        <div className="congestion__content">
          <div className="congestion__list">
            <div className="congestion__list__header">
              <CongestionListRow isHeader={true}>
                <CongestionListRowItem>
                  {intl.formatMessage({ id: 'congestionLIHeadArea', defaultMessage: 'congestionLIHeadArea' })}
                </CongestionListRowItem>
                <CongestionListRowItem>
                  {intl.formatMessage({ id: 'congestionLIHeadStatus', defaultMessage: 'congestionLIHeadStatus' })}
                </CongestionListRowItem>
                <CongestionListRowItem>{'  '}</CongestionListRowItem>
              </CongestionListRow>
            </div>
            {data.map((area: Area) => (
              <CongestionListRow key={area.code} congestionLevel={area.congestion_level} areaCode={area.code}>
                <CongestionListRowItem>{ListArea[area.code].name}</CongestionListRowItem>
              </CongestionListRow>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
//#endregion

export default injectIntl(Congestion);
