import React from 'react';
import Auth from '@aws-amplify/auth';
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { PATH } from '../../../navigation/constants';

interface OwnProps extends RouteComponentProps {
  history: History;
}

interface StateProps {
  isSignedIn: boolean;
}

type Props = OwnProps & StateProps;

const IsAuthenticated = (WrappedComponent: any): any => {
  return class Index extends React.Component<Props> {
    async UNSAFE_componentWillMount(): Promise<void> {
      try {
        await Auth.currentSession();
      } catch {
        this.props.history.push(PATH.LOGIN);
      }
    }

    render(): React.ReactElement {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default IsAuthenticated;
