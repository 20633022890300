//#region IMPORT
// PACKAGE IMPORT
import React from 'react';
import classnames from 'classnames';

// LOCAL CONFIG
import { CongestionLevel } from '../../constants';
import './CongestionListRowItem.scss';

//#endregion

//#region INTERFACE
interface OwnProps {
  congestionStatus?: number;
  className?: string;
}
//#endregion

//#region COMPONENT
class CongestionListRowItem extends React.Component<OwnProps> {
  render(): React.ReactNode {
    const { congestionStatus, className } = this.props;
    const level = congestionStatus && CongestionLevel[congestionStatus].toLowerCase();
    const classes = classnames(
      'congestion-list-item',
      { [`congestion-list-item--${level}`]: congestionStatus },
      { [`${className}`]: typeof className !== 'undefined' }
    );
    return <div className={classes}>{this.props.children}</div>;
  }
}
//#endregion

export default CongestionListRowItem;
