//#region IMPORTS
// PACKAGE IMPORTS
import { connect } from 'react-redux';

// LOCAL CONFIG
import Shower, { StateProps, DispatchProps } from './Shower';
import { AppState } from '../../../../bootstrap/ReduxStore';
import { ShowAlert } from '../../../alert/ActionAlert';
import {
  ShowerBookingListFetch,
  ShowerRoomListFetch,
  ShowerBookingUpdateFetch,
  OnCreateUpdateShowerBooking,
  ShowerBookingCreateFetch,
  ShowerBookingSubscriptionRemoveFailed,
  ShowerBookingSubscriptionCreateFailed,
  ShowerBookingSubscriptionRemoveSuccess,
  ShowerBookingSubscriptionCreateSuccess,
  ShowerBookingSubscriptionCreateFetch,
  ShowerBookingSubscriptionRemoveFetch,
} from '../../ActionShower';
import { ShowerBookingList, ShowerRoom } from '../../constants';
//#endregion

//#region REDUX BINDINGS
const mapStateToProps = (state: AppState): StateProps => ({
  data: (state.shower.showerBookingListResponse as ShowerBookingList).items
    ? (state.shower.showerBookingListResponse as ShowerBookingList).items
    : [],
  onCreateUpdateSubscriptionParam: state.shower.onCreateUpdateShowerBookingParam,
  showerBookingListResponse: state.shower.showerBookingListResponse,
  showerBookingUpdateRespons: state.shower.showerBookingUpdateResponse,
  action: state.shower.action,
  showerBookingListParam: state.shower.showerBookingListParam,
  roomList: state.shower.showerRoomListResponse as ShowerRoom[],
  onCreateUpdateShowerBookingResponse: state.shower.onCreateUpdateShowerBookingResponse,
  subscriptionRegistrationStatus: state.shower.subscriptionRegistrationStatus,
  airport_id: state.user.user.airportId,
  lounge_id: state.user.user.loungeId,
});

const mapDispatchToProps: DispatchProps = {
  ShowerBookingListFetch,
  ShowerRoomListFetch,
  ShowerBookingCreateFetch,
  ShowerBookingUpdateFetch,
  OnCreateUpdateShowerBooking,
  ShowerBookingSubscriptionRemoveFailed,
  ShowerBookingSubscriptionCreateFailed,
  ShowerBookingSubscriptionCreateSuccess,
  ShowerBookingSubscriptionRemoveSuccess,
  ShowerBookingSubscriptionCreateFetch,
  ShowerBookingSubscriptionRemoveFetch,
  ShowAlert,
};
//#endregion

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Shower);
