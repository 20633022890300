/* eslint-disable @typescript-eslint/explicit-function-return-type */
//#region IMPORTS

import { put, takeLatest } from 'redux-saga/effects';
import API, { graphqlOperation } from '@aws-amplify/api';
import {
  mutations,
  queries,
  STAFF_CALL_CREATE_FETCH,
  STAFF_CALL_ITEM_FETCH,
  STAFF_CALL_UPDATE_FETCH,
  StaffCallCreateFetch,
  StaffCallItemFetch,
  StaffCallMutationTypes,
  StaffCallUpdateFetch,
  StaffCallListFetch,
  StaffCallListResponse,
  StaffCallStatusTypes,
  StaffCallMultiStatusResponse,
  STAFF_CALL_LIST_FETCH,
  INVALID_CREATE_UPDATE_TYPE_ERROR,
} from './constants';
import {
  StaffCallCreateFailed,
  StaffCallCreateSuccess,
  StaffCallItemFailed,
  StaffCallItemSuccess,
  StaffCallUpdateFailed,
  StaffCallUpdateSuccess,
  StaffCallListSuccess,
  StaffCallListFailed,
} from './ActionStaffCall';
import { extractEnumValues } from '../../utils';

//#endregion

//#region SAGA WORKERS

function* workerSagaStaffCallList(action: StaffCallListFetch) {
  const { statusTypes, ...variables } = action.payload;

  const typeArray: unknown[] = extractEnumValues(statusTypes);

  let items = {};

  const { ListStaffCallByStatus, ListStaffCallByStatusUnanswered } = queries;

  try {
    for (let i = 0; i < typeArray.length; i++) {
      const status = typeArray[i] as StaffCallStatusTypes;
      const query = status === StaffCallStatusTypes.CALLING ? ListStaffCallByStatusUnanswered : ListStaffCallByStatus;
      const response = yield API.graphql(graphqlOperation(query, { ...variables, status }));
      const newItems = (response.data as StaffCallListResponse).listStaffCallByStatus.items;
      items = {
        ...items,
        [status as StaffCallStatusTypes]: newItems,
      };
    }
    const response: StaffCallMultiStatusResponse = {
      items,
    };

    yield put(StaffCallListSuccess(response));
  } catch (e) {
    yield put(StaffCallListFailed(e));
  }
}

function* workerStaffCallItem(action: StaffCallItemFetch) {
  const query = queries.GetStaffCall;
  try {
    const response = yield API.graphql(graphqlOperation(query, action.payload));
    yield put(StaffCallItemSuccess(response.data));
  } catch (e) {
    yield put(StaffCallItemFailed(e));
  }
}

function* workerStaffCallCreate(action: StaffCallCreateFetch) {
  const mutation = mutations.CreateStaffCall;
  try {
    const response = yield API.graphql(graphqlOperation(mutation, action.payload));
    yield put(StaffCallCreateSuccess(response.data));
  } catch (e) {
    yield put(StaffCallCreateFailed(e));
  }
}

function* workerStaffCallUpdate(action: StaffCallUpdateFetch) {
  const { ANSWER, CANCEL, CREATE } = StaffCallMutationTypes;
  const mutationQueries = {
    [ANSWER]: (): string => mutations.AnswerStaffCall,
    [CANCEL]: (): string => mutations.CancelStaffCall,
    [CREATE]: (): string => INVALID_CREATE_UPDATE_TYPE_ERROR,
    DEFAULT: (): string => INVALID_CREATE_UPDATE_TYPE_ERROR,
  };

  const { updateType, ...variables } = action.payload;

  const mutation = (mutationQueries[updateType] || mutationQueries.DEFAULT)();
  if (mutation === INVALID_CREATE_UPDATE_TYPE_ERROR) {
    yield put(StaffCallUpdateFailed({ message: mutation }));
    return;
  }

  try {
    const response = yield API.graphql(graphqlOperation(mutation, variables));
    yield put(StaffCallUpdateSuccess(response.data));
  } catch (e) {
    yield put(StaffCallUpdateFailed(e));
  }
}

//#endregion

// Watcher
const staffCallWatcher = [
  takeLatest(STAFF_CALL_LIST_FETCH, workerSagaStaffCallList),
  takeLatest(STAFF_CALL_ITEM_FETCH, workerStaffCallItem),
  takeLatest(STAFF_CALL_CREATE_FETCH, workerStaffCallCreate),
  takeLatest(STAFF_CALL_UPDATE_FETCH, workerStaffCallUpdate),
];

export default staffCallWatcher;
