//#region ENUM & GENERAL
export enum PATH {
  LOGIN = '/login',
  MEALS_ORDER = '/meals-order',
  MEALS_ORDER_ARCHIVE = '/meals-order/archive',
  SHOWER_BOOKING = '/shower-booking',
  SHOWER_BOOKING_ARCHIVE = '/shower-booking/archive',
  STAFF_CALLING = '/staff-calling',
  STAFF_CALLING_ARCHIVE = '/staff-calling/archive',
  CONGESTION = '/congestion',
  USER_SEACH = '/user-search',
  UNAUTHORIZED = '/unauthorized',
}
//#endregion
