//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import classnames from 'classnames';

// LOCAL CONFIG
import './_index.scss';

//#endregion

//#region INTERFACE
interface OwnProps {
  className?: string;
  color?: string;
}
//#endregion

//#region COMPONENT
function Spinner({ className, color }: OwnProps): React.ReactElement {
  const classes = classnames('spinner', `spinner--${color}`, className);
  return (
    <div className={classes}>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
}
//#endregion

export default Spinner;
