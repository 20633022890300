//#region IMPORT
// LOCAL CONFIG
import { Environment } from './interface';

//#endregion

//#region ENVIRONMENT
const dev_fpt: Environment = {
  poolId: 'ap-northeast-1:36b0f33e-69b8-45b0-9b8e-bd9e380a99ac',
  region: 'ap-northeast-1',
  identityPoolRegion: 'ap-northeast-1',
  userPoolId: 'ap-northeast-1_YChWWUKkU',
  mandatorySignIn: false,
  userPoolWebClientId: '2lepeobu8mp6r7ibmidr978vdf',
  appsyncEndpoint: 'https://qtgfh4wm4zcltci7n4abmpup7u.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  appsyncRegion: 'ap-northeast-1',
  appsyncAuthenticationType: 'AMAZON_COGNITO_USER_POOLS',
  mealsMenuUrl: 'https://dev-fpt-jal-content-641143465932-ap-northeast-1.s3-ap-northeast-1.amazonaws.com/',
  satoApiUrl: 'https://api.wellvill.com/d/roboten/JAL/v100',
  satoAuthUrl: 'https://auth.wellvill.com/auth/realms/JAL/protocol/openid-connect/token',
};

const dev: Environment = {
  poolId: 'ap-northeast-1:3f586941-1c11-4a6d-bfcb-f4ca5bc4ce58',
  region: 'ap-northeast-1',
  identityPoolRegion: 'ap-northeast-1',
  userPoolId: 'ap-northeast-1_lUmhKKfEU',
  mandatorySignIn: false,
  userPoolWebClientId: '6ha4a030hv84fmn1lrd9ne8mj4',
  appsyncEndpoint: 'https://wot6pkwnpzg3vmz6pljlljdbqe.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  appsyncRegion: 'ap-northeast-1',
  appsyncAuthenticationType: 'AMAZON_COGNITO_USER_POOLS',
  mealsMenuUrl: 'https://dev-jal-content-641143465932-ap-northeast-1.s3-ap-northeast-1.amazonaws.com/',
  satoApiUrl: 'https://api.wellvill.com/d/roboten/JAL/v100',
  satoAuthUrl: 'https://auth.wellvill.com/auth/realms/JAL/protocol/openid-connect/token',
};

const stage: Environment = {
  poolId: 'ap-northeast-1:35f62995-c7e4-483f-9868-82a7514fee0d',
  region: 'ap-northeast-1',
  identityPoolRegion: 'ap-northeast-1',
  userPoolId: 'ap-northeast-1_qTOobcsz2',
  mandatorySignIn: false,
  userPoolWebClientId: '1crvoa6iontu0ip1j4ic1heru5',
  appsyncEndpoint: 'https://4dnppm4kzng2vdlup5jouxbwoq.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  appsyncRegion: 'ap-northeast-1',
  appsyncAuthenticationType: 'AMAZON_COGNITO_USER_POOLS',
  satoApiUrl: 'https://api.wellvill.com/d/roboten/JAL/v100',
  satoAuthUrl: 'https://auth.wellvill.com/auth/realms/JAL/protocol/openid-connect/token',
  mealsMenuUrl: 'https://stage-jal-content-641143465932-ap-northeast-1.s3-ap-northeast-1.amazonaws.com/',
};

const prod: Environment = {
  poolId: 'ap-northeast-1:1f0d8f92-53a5-4dbf-bdcd-e59d4ffdcd13',
  region: 'ap-northeast-1',
  identityPoolRegion: 'ap-northeast-1',
  userPoolId: 'ap-northeast-1_3JKLYERJF',
  mandatorySignIn: false,
  userPoolWebClientId: '2u9474qfq3frs0c81bi2iqkcku',
  appsyncEndpoint: 'https://xsxqytot5zdkrnugms7m7b7k3m.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  appsyncRegion: 'ap-northeast-1',
  appsyncAuthenticationType: 'AMAZON_COGNITO_USER_POOLS',
  satoApiUrl: 'https://api.wellvill.com/d/roboten/JAL/v100',
  satoAuthUrl: 'https://auth.wellvill.com/auth/realms/JAL/protocol/openid-connect/token',
  mealsMenuUrl: 'https://prod-jal-content-348982232738-ap-northeast-1.s3-ap-northeast-1.amazonaws.com/',
};
//#endregion

let env: Environment = dev_fpt;

if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.trim() === 'production') {
  env = prod;
} else if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.trim() === 'stage') {
  env = stage;
} else if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.trim() === 'development') {
  env = dev_fpt;
}

export default env;
