//#region STRING
// LIST FETCH
export const CONGESTION_LIST_FETCH = 'CONGESTION_LIST_FETCH';
export const CONGESTION_LIST_SUCCESS = 'CONGESTION_LIST_SUCCESS';
export const CONGESTION_LIST_FAILED = 'CONGESTION_LIST_FAILED';
export const CONGESTION_LIST_CLEAR = 'CONGESTION_LIST_CLEAR';

// UPDATE FETCH
export const CONGESTION_UPDATE_FETCH = 'CONGESTION_UPDATE_FETCH';
export const CONGESTION_UPDATE_SUCCESS = 'CONGESTION_UPDATE_SUCCESS';
export const CONGESTION_UPDATE_FAILED = 'CONGESTION_UPDATE_FAILED';
export const CONGESTION_UPDATE_CLEAR = 'CONGESTION_UPDATE_CLEAR';

// SUBSCRIPTION
export const ON_CONGESTION_UPDATE = 'ON_CONGESTION_UPDATE';
export const CONGESTION_SUBSCRIPTION_SUCCESS = 'CONGESTION_SUBSCRIPTION_SUCCESS';
export const CONGESTION_SUBSCRIPTION_FAILED = 'CONGESTION_SUBSCRIPTION_FAILED';

//#endregion
