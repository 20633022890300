/* eslint-disable @typescript-eslint/explicit-function-return-type */
//#region IMPORTS
// PACKAGE IMPORT
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import API, { graphqlOperation } from '@aws-amplify/api';
import Axios, { AxiosResponse } from 'axios';

// LOCAL CONFIG
import {
  MEAL_ORDER_CREATE_FETCH,
  MEAL_ORDER_LIST_FETCH,
  MEAL_ORDER_UPDATE_FETCH,
  MealOrder,
  MealOrderCreateFetch,
  MealOrderCreateUpdateType,
  MealOrderFilterType,
  MealOrderListByDeviceIdResponse,
  MealOrderListByStatusAndDate,
  MealOrderListByStatusAndDateResponse,
  MealOrderListByStatusParam,
  MealOrderListByStatusResponse,
  MealOrderListFetch,
  MealOrderListPayload,
  MealOrderListResponse,
  MealOrderStatusType,
  MealOrderUpdateFetch,
  MenuListFetch,
  MENU_LIST_FETCH,
  mutations,
  ON_CREATE_UPDATE_MEAL_ORDER,
  OnCompleteMealOrderPayload,
  OnCreateMealOrderPayload,
  OnCreateUpdateMealOrder,
  OnRejectMealOrderPayload,
  OnStartMealOrderPayload,
  OnUpdateMealOrderItemsPayload,
  queries,
  MenuListItem,
  MenuCategoryItemType,
  MenuCategoryType,
} from './constants';
import {
  MealOrderCreateFailed,
  MealOrderCreateSuccess,
  MealOrderListFailed,
  MealOrderListSuccess,
  MealOrderUpdateFailed,
  MealOrderUpdateSuccess,
  MenuListFailed,
  MenuListSuccess,
  OnCreateUpdateMealOrderFailed,
  OnCreateUpdateMealOrderSuccess,
} from './ActionMeals';
import { extractEnumValues } from '../../utils';
import env from '../../config/environment';

//#endregion

//#region SAGA WORKERS
// MEAL_ORDER_LIST_FETCH
function* workerSagaMealOrderList(action: MealOrderListFetch) {
  const invalidFilterError = 'Invalid Filter Type';
  const { DEVICE_ID, STATUS, STATUS_DATE } = MealOrderFilterType;
  try {
    if (action.payload.filterType === STATUS || action.payload.filterType === STATUS_DATE) {
      const { filterType, airport_id, lounge_id, ...variables } = action.payload as MealOrderListPayload;

      const typesArray: unknown[] = extractEnumValues(
        (action.payload as MealOrderListByStatusParam | MealOrderListByStatusAndDate).statusTypes
      );

      let items = {};

      const { ListMealOrderByStatus, ListMealOrderByStatusAndDate } = queries;

      const query = filterType === MealOrderFilterType.STATUS ? ListMealOrderByStatus : ListMealOrderByStatusAndDate;

      for (let i = 0; i < typesArray.length; i++) {
        const status = typesArray[i];
        const response = yield API.graphql(
          graphqlOperation(query, ({ ...variables, airport_id, lounge_id, status } as unknown) as typeof variables)
        );

        const newItems =
          filterType === MealOrderFilterType.STATUS
            ? (response.data as MealOrderListByStatusResponse).listMealOrderByStatus.items
            : (response.data as MealOrderListByStatusAndDateResponse).listMealOrderByStatusAndDate.items;

        items = {
          ...items,
          [status as MealOrderStatusType]: newItems,
        };
      }

      const response: MealOrderListResponse = {
        items,
        nextToken: '',
      };

      yield put(MealOrderListSuccess(response));
    } else {
      const { filterType, airport_id, lounge_id, ...variables } = action.payload;
      const gqlQueries = {
        [DEVICE_ID as string]: () => queries.ListMealOrderByDeviceId,
        DEFAULT: (): string => invalidFilterError,
      };

      const query = (gqlQueries[filterType] || gqlQueries.DEFAULT)();

      if (query === invalidFilterError) {
        yield put(MealOrderListFailed({ message: invalidFilterError }));
        return;
      }

      const response = yield API.graphql(graphqlOperation(query, {airport_id, lounge_id, ...variables}));

      const responseObject = {
        [MealOrderFilterType.DEVICE_ID as string]: () =>
          (response.data as MealOrderListByDeviceIdResponse).listMealOrderByDeviceId,
        DEFAULT: (): string => invalidFilterError,
      };

      const data = (responseObject[filterType] || responseObject.DEFAULT)();

      if (typeof data === 'string') {
        yield put(MealOrderListFailed({ message: invalidFilterError }));
        return;
      }

      yield put(MealOrderListSuccess(data));
    }
  } catch (e) {
    yield put(MealOrderListFailed(e));
  }
}

// MEAL_ORDER_CREATE_FETCH
function* workerSagaMealOrderCreate(action: MealOrderCreateFetch) {
  const mutation = mutations.CreateMealOrder;

  try {
    const response = yield API.graphql(
      graphqlOperation(mutation, { input: { ...action.payload, items: JSON.stringify(action.payload.items) } })
    );
    yield put(MealOrderCreateSuccess(response.data.createShowerBooking));
  } catch (e) {
    yield put(MealOrderCreateFailed(e));
  }
}

// MEAL_ORDER_UPDATE_FETCH
function* workerSagaMealOrderUpdate(action: MealOrderUpdateFetch) {
  const invalidUpdateError = 'Invalid Update Type';

  const gqlMutations = {
    [MealOrderCreateUpdateType.COMPLETE]: () => mutations.CompleteMealOrder,
    [MealOrderCreateUpdateType.REJECT]: () => mutations.RejectMealOrder,
    [MealOrderCreateUpdateType.START]: () => mutations.StartMealOrder,
    [MealOrderCreateUpdateType.UPDATE]: () => mutations.UpdateMealOrderItems,
    [MealOrderCreateUpdateType.CREATE]: () => invalidUpdateError,
    DEFAULT: () => invalidUpdateError,
  };

  const { updateType, ...variables } = action.payload;

  const mutation = (gqlMutations[updateType] || gqlMutations.DEFAULT)();

  if (mutation === invalidUpdateError) {
    yield put(MealOrderUpdateFailed({ message: invalidUpdateError }));
    return;
  }

  try {
    const response = yield API.graphql(graphqlOperation(mutation, { input: { ...variables } }));
    yield put(MealOrderUpdateSuccess(response.data));
  } catch (e) {
    yield put(MealOrderUpdateFailed(e));
  }
}

// ON_CREATE_UPDATE_MEAL_ORDER
function* workerSagaOnCreateUpdateMealOrder(action: OnCreateUpdateMealOrder) {
  const invalidUpdateError = 'Invalid Update Type';
  const { COMPLETE, CREATE, REJECT, START, UPDATE } = MealOrderCreateUpdateType;
  const { payload, muted, airportId, loungeId } = action;

  const mutationTypes = {
    [COMPLETE]: (): MealOrder => (payload as OnCompleteMealOrderPayload).onCompleteMealOrder,
    [REJECT]: (): MealOrder => (payload as OnRejectMealOrderPayload).onRejectMealOrder,
    [START]: (): MealOrder => (payload as OnStartMealOrderPayload).onStartMealOrder,
    [CREATE]: (): MealOrder => (payload as OnCreateMealOrderPayload).onCreateMealOrder,
    [UPDATE]: (): MealOrder => (payload as OnUpdateMealOrderItemsPayload).onUpdateMealOrderItems,
    DEFAULT: (): string => invalidUpdateError,
  };

  const extractedMealOrder = (mutationTypes[payload.mutationType] || mutationTypes.DEFAULT)();

  if (((extractedMealOrder as unknown) as string) === invalidUpdateError) {
    yield put(OnCreateUpdateMealOrderFailed({ message: invalidUpdateError }));
    return;
  }

  if( MealOrderCreateUpdateType.CREATE === payload.mutationType 
    && (payload as OnCreateMealOrderPayload).onCreateMealOrder.airport_id === airportId  
    && (payload as OnCreateMealOrderPayload).onCreateMealOrder.lounge_id === loungeId 
    && !muted ){
    //const audio = document.getElementById('notify-se') as HTMLAudioElement;
    //if(audio){
    //  audio.muted = false;
    //  audio.play();
    //}
  }

  const newMealOrder = extractedMealOrder as MealOrder;

  yield put(OnCreateUpdateMealOrderSuccess(newMealOrder));
}

// MENU_LIST_FETCH
function* workerSagaMenuListFetch(action: MenuListFetch) {
  try {
    const mealsMenuUrl = env.mealsMenuUrl + action.payload.airportId + '_' + action.payload.loungeId + '_categories.json';
    const response: AxiosResponse<MenuListItem[] | {}> = yield call(Axios.get, mealsMenuUrl);
    if (response.status === 200) {
      const items = ((response.data as MenuCategoryItemType[])
        .filter((item: MenuCategoryItemType) => item.type === MenuCategoryType.MEAL)
        .shift() as MenuCategoryItemType).menus;
      yield put(MenuListSuccess({ items }));
    }
  } catch (e) {
    yield put(MenuListFailed(e));
  }
}

//#endregion

const mealsWatcher = [
  takeLatest(MEAL_ORDER_LIST_FETCH, workerSagaMealOrderList),
  takeLatest(MEAL_ORDER_CREATE_FETCH, workerSagaMealOrderCreate),
  takeLatest(MEAL_ORDER_UPDATE_FETCH, workerSagaMealOrderUpdate),
  takeEvery(ON_CREATE_UPDATE_MEAL_ORDER, workerSagaOnCreateUpdateMealOrder),
  takeLatest(MENU_LIST_FETCH, workerSagaMenuListFetch),
];

export default mealsWatcher;
