//#region IMPORTS

// PACKAGE IMPORT
import { all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

// LOCAL CONFIG
import { ReduxSaga } from '../config';

//#endregion

export default function* reduxSaga(): SagaIterator {
  yield all(ReduxSaga);
}
