//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import classnames from 'classnames';
import ReactSelect from 'react-select';

//LOCAL CONFIG
import './_index.scss';

//#endregion

//#region INTERFACE
interface OwnProps {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  field: any;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  form: any;
  className?: string;
  label?: string;
  id: string;
  placeholder?: string;
  isRequired?: boolean;
  options: Option[];
  intl: IntlShape;
  isMulti?: boolean;
}

interface Option {
  label: string;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  value: any;
  isTranslate?: boolean;
}

type Props = OwnProps;

//#endregion

//#region COMPONENT
function Select({
  field,
  form,
  form: { touched, errors },
  className,
  label,
  id,
  isRequired,
  placeholder,
  intl,
  options,
  isMulti,
  ...props
}: Props): React.ReactElement {
  const classes = classnames(
    'select',
    {
      'select--is-multi': isMulti,
    },
    className
  );

  return (
    <div className={classes}>
      {label && (
        <label className="select__label" htmlFor={id}>
          {intl.formatMessage({ id: label, defaultMessage: label })}
          {isRequired && <span className="select__label__required">*</span>}
        </label>
      )}

      <div className="select__wrapper">
        <ReactSelect
          {...props}
          id={id}
          className="select__wrapper__select"
          classNamePrefix="select__wrapper__select"
          /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
          onChange={(option: any): void => form.setFieldValue(field.name, option.value)}
          options={
            options &&
            options.map((option: Option) => ({
              value: option.value,
              label: option.isTranslate
                ? intl.formatMessage({ id: option.label, defaultMessage: option.label })
                : option.label,
            }))
          }
          value={options ? options.find((option: Option) => option.value === field.value) : undefined}
          placeholder={placeholder ? intl.formatMessage({ id: placeholder, defaultMessage: placeholder }) : ''}
          noOptionsMessage={(): string =>
            intl.formatMessage({ id: 'labelNoOptions', defaultMessage: 'labelNoOptions' })
          }
          onBlur={field.onB}
        />
      </div>
      <div className="select__validation">
        {touched[field.name] && errors[field.name] && (
          <span className="select__error">
            {intl.formatMessage({ id: errors[field.name], defaultMessage: errors[field.name] })}
          </span>
        )}
      </div>
    </div>
  );
}

//#endregion

export default injectIntl(Select);
