//#region IMPORT
// LOCAL CONFIG
import { ALERT } from './StringAlert';

//#endregion

//#region ENUM & GENERAL
export enum ALERTTYPE {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}
//#endregion

//#region ACTION TYPE
// SHOW
export interface ShowAlert {
  type: typeof ALERT.SHOW;
  payload: {
    message: string;
    type: ALERTTYPE;
  };
}

// HIDE
export interface HideAlert {
  type: typeof ALERT.HIDE;
}

// ACTIONS
export type AlertActionTypes = ShowAlert | HideAlert;

//#endregion
