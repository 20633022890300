//#region IMPORTS
// PACKAGE IMPORT
import { connect } from 'react-redux';

// LOCAL CONFIG
import MealsForm, { DispatchProps, StateProps } from './MealsForm';
import { MenuListFetch } from '../../ActionMeals';
import { AppState } from '../../../../bootstrap/ReduxStore';

//#endregion

//#region REDUX BINDING
const mapStateToProps = (state: AppState): StateProps => ({
  menuListResponse: state.meals.menuListResponse,
  mealAction: state.meals.action,
  airport_id: state.user.user.airportId,
  lounge_id: state.user.user.loungeId,
});

const mapDispatchToProps: DispatchProps = {
  MenuListFetch,
};
//#endregion

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MealsForm);
