//#region IMPORTS

import {
  SHOWER_BOOKING_CREATE_CLEAR,
  SHOWER_BOOKING_CREATE_FAILED,
  SHOWER_BOOKING_CREATE_FETCH,
  SHOWER_BOOKING_CREATE_SUCCESS,
  SHOWER_BOOKING_LIST_CLEAR,
  SHOWER_BOOKING_LIST_FAILED,
  SHOWER_BOOKING_LIST_FETCH,
  SHOWER_BOOKING_LIST_SUCCESS,
  SHOWER_BOOKING_UPDATE_CLEAR,
  SHOWER_BOOKING_UPDATE_FAILED,
  SHOWER_BOOKING_UPDATE_FETCH,
  SHOWER_BOOKING_UPDATE_SUCCESS,
  SHOWER_ROOM_LIST_CLEAR,
  SHOWER_ROOM_LIST_FAILED,
  SHOWER_ROOM_LIST_FETCH,
  SHOWER_ROOM_LIST_SUCCESS,
  SHOWER_BOOKING_SUBSCRIPTION_CREATE_FAILED,
  SHOWER_BOOKING_SUBSCRIPTION_CREATE_SUCCESS,
  SHOWER_BOOKING_SUBSCRIPTION_REMOVE_SUCCESS,
  SHOWER_BOOKING_SUBSCRIPTION_REMOVE_FAILED,
  SHOWER_BOOKING_SUBSCRIPTION_UPDATE_SUCCESS,
  SHOWER_BOOKING_SUBSCRIPTION_UPDATE_FAILED,
  ON_CREATE_UPDATE_SHOWER_BOOKING,
  ON_CREATE_UPDATE_SHOWER_BOOKING_SUCCESS,
  ON_CREATE_UPDATE_SHOWER_BOOKING_FAILED,
  SHOWER_BOOKING_SUBSCRIPTION_REMOVE_FETCH,
  SHOWER_BOOKING_SUBSCRIPTION_CREATE_FETCH,
  SHOWER_BOOKING_SUBSCRIPTION_UPDATE_FETCH,
} from './StringShower';

//#endregion

//#region GENERAL ENUMS

export enum ShowerBookingFilterType {
  NONE,
  STATUS,
  STATUS_DATE,
}

export enum ShowerBookingCreateUpdateType {
  NOTIFY = 'NOTIFY',
  TIMEOUT = 'TIMEOUT',
  CANCEL = 'CANCEL',
  COMPLETE = 'COMPLETE',
  START = 'START',
  CALL = 'CALL',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export enum ShowerBookingStatus {
  IN_QUEUE = '101',
  READY = '201',
  NOTIFIED = '202',
  ANNOUNCED = '203',
  IN_PROGRESS = '301',
  CANCELLED = '302',
  TIMEOUT = '303',
  COMPLETED = '401',
}

export enum SubscriptionRegistrationStatus {
  UNREGISTERED,
  UNREGISTERING,
  REGISTERING,
  REGISTERED,
  REGISTRATION_FAILED,
}

export const ShowerLoungeId: {[id:string]:{[id:string]:string}} = {
  'hnd': {
    "firstclass1": "firstclass",
    "firstclass2": "firstclass",
    "sakura1": "sakura",
  },
  'nrt': {
    "firstclass1": "firstclass",
    "firstclass2": "fsatellite",
    "sakura1": "sakura",
    "sakura2": "ssatellite",
  }
}

export const ShowerBookingStatusText = (status: ShowerBookingStatus): string => {
  const statuses = {
    [ShowerBookingStatus.IN_QUEUE]: (): string => 'showerStatusQueue',
    [ShowerBookingStatus.READY]: (): string => 'showerStatusReady',
    [ShowerBookingStatus.NOTIFIED]: (): string => 'showerStatusNotified',
    [ShowerBookingStatus.ANNOUNCED]: (): string => 'showerStatusAnnounced',
    [ShowerBookingStatus.IN_PROGRESS]: (): string => 'showerStatusInProgress',
    [ShowerBookingStatus.CANCELLED]: (): string => 'showerStatusCancelled',
    [ShowerBookingStatus.TIMEOUT]: (): string => 'showerStatusTimeout',
    [ShowerBookingStatus.COMPLETED]: (): string => 'showerStatusCompleted',
  };

  return statuses[status]();
};

export type FailureType = Record<string, unknown> | string;

export type GraphQLSubscriptionResult = {
  provider: unknown;
  value: {
    data: any;
  };
};
//#endregion

//#region General Types

export type ShowerBookingList = {
  items: ShowerBooking[];
  nextToken: string;
};
export type ShowerBooking = {
  device_id: string;
  status: ShowerBookingStatus;
  timestamp: string;
  is_handicap: boolean;
  counter: number;
  shower_room: string;
  memo: string;
  use_app: boolean;
  status_history: ShowerBookingStatusHistory[];
  airport_id: string;
  lounge_id: string;
  table_id: string;
  email_address?: string;
  pnr: string;
  carrier:string;
  flight_number: string;
  date_of_flight: string;
  seat_number: string;
  is_left_room: boolean;
  is_cleaning_room: boolean;
};
export type ShowerBookingStatusHistory = {
  airport_id: string;
  lounge_id: string;
  status: string;
  timestamp: string;
};
export interface BookingForm {
  device_id: string;
  is_handicap: boolean;
  memo: string;
  airport_id: string;
  lounge_id: string;
  table_id: string;
}
export interface StartForm {
  shower_room: string;
  memo: string;
  airport_id: string;
  lounge_id: string;
}

//#endregion

//#region ACTION TYPES

//#region SHOWER BOOKING LIST

// Fetch
export interface ShowerBookingListParam {
  airport_id: string;
  lounge_id: string;
  table_id: string;
  limit?: number;
  nextToken?: string;
  filterType: ShowerBookingFilterType;
}

export interface ShowerBookingListByStatusParam extends ShowerBookingListParam {
  statusTypes: ShowerBookingStatus[];
}

export interface ShowerBookingListByStatusAndDate extends ShowerBookingListByStatusParam {
  date: string;
}

export type ShowerBookingListTypes =
  | ShowerBookingListParam
  | ShowerBookingListByStatusParam
  | ShowerBookingListByStatusAndDate;

export type ShowerBookingByStatus = {
  listShowerBookingByStatus: ShowerBookingList;
};

export type ShowerBookingByStatusAndDate = {
  listShowerBookingByStatusAndDate: ShowerBookingList;
};

export interface ShowerBookingListFetch {
  type: typeof SHOWER_BOOKING_LIST_FETCH;
  payload: ShowerBookingListParam | ShowerBookingListByStatusParam | ShowerBookingListByStatusAndDate;
}

// Success
export type ShowerBookingListMultiStatusResponse = {
  items: {
    [ShowerBookingStatus.IN_PROGRESS]?: ShowerBooking[];
    [ShowerBookingStatus.IN_QUEUE]?: ShowerBooking[];
    [ShowerBookingStatus.READY]?: ShowerBooking[];
    [ShowerBookingStatus.NOTIFIED]?: ShowerBooking[];
    [ShowerBookingStatus.ANNOUNCED]?: ShowerBooking[];
    [ShowerBookingStatus.TIMEOUT]?: ShowerBooking[];
    [ShowerBookingStatus.CANCELLED]?: ShowerBooking[];
    [ShowerBookingStatus.COMPLETED]?: ShowerBooking[];
  };
};

export type ShowerBookingListResponse = ShowerBookingList | ShowerBookingListMultiStatusResponse;

export interface ShowerBookingListSuccess {
  type: typeof SHOWER_BOOKING_LIST_SUCCESS;
  payload: ShowerBookingListResponse;
}

// Failed
export interface ShowerBookingListFailed {
  type: typeof SHOWER_BOOKING_LIST_FAILED;
  payload: Record<string, unknown>;
}

// Clear
export interface ShowerBookingListClear {
  type: typeof SHOWER_BOOKING_LIST_CLEAR;
}

//#endregion

//#region SHOWER BOOKING CREATE

// Fetch
export interface ShowerBookingCreateParam {
  device_id: string;
  is_handicap: boolean;
  use_app: boolean;
  airport_id: string;
  lounge_id: string;
  table_id?: string;
  email_address?: string;
  memo?: string;
  pnr: string;
  carrier: string;
  flight_number: string;
  date_of_flight: string;
  seat_number: string;
  locale?: string;
}

export interface ShowerBookingCreateFetch {
  type: typeof SHOWER_BOOKING_CREATE_FETCH;
  payload: ShowerBookingCreateParam;
}

// Success
export interface ShowerBookingCreateSuccess {
  type: typeof SHOWER_BOOKING_CREATE_SUCCESS;
  payload: ShowerBooking;
}

// Failed
export interface ShowerBookingCreateFailed {
  type: typeof SHOWER_BOOKING_CREATE_FAILED;
  payload: Record<string, unknown>;
}

// Clear
export interface ShowerBookingCreateClear {
  type: typeof SHOWER_BOOKING_CREATE_CLEAR;
}

//#endregion

//#region SHOWER BOOKING UPDATE

// Fetch
export interface ShowerBookingUpdateParam {
  device_id: string;
  updateType: ShowerBookingCreateUpdateType;
  airport_id: string;
  lounge_id: string;
}

export interface StartShowerBookingParam extends ShowerBookingUpdateParam {
  shower_room: string;
  memo: string;
}

export interface NotifyShowerBookingParam extends ShowerBookingUpdateParam {
}

export interface CancelShowerBookingParam extends ShowerBookingUpdateParam {
  use_app: boolean;
}

export interface UpdateShowerBookingParam extends ShowerBookingUpdateParam {
  is_left_room: boolean;
  is_cleaning_room: boolean;
}

export interface ShowerBookingUpdateFetch {
  type: typeof SHOWER_BOOKING_UPDATE_FETCH;
  payload: ShowerBookingUpdateParam | StartShowerBookingParam | NotifyShowerBookingParam | UpdateShowerBookingParam;
}

// Success
export interface ShowerBookingUpdateSuccess {
  type: typeof SHOWER_BOOKING_UPDATE_SUCCESS;
  payload: ShowerBooking;
}

// Failed
export interface ShowerBookingUpdateFailed {
  type: typeof SHOWER_BOOKING_UPDATE_FAILED;
  payload: Record<string, unknown>;
}

// Clear
export interface ShowerBookingUpdateClear {
  type: typeof SHOWER_BOOKING_UPDATE_CLEAR;
}

//#endregion

//#region SHOWER ROOM LIST

// Fetch
export type ShowerRoomListParam = {
  airport_id: string;
  lounge_id: string;
  limit?: number;
  nextToken?: string;
};
export type ShowerRoomResponse = {
  items: ShowerRoom[];
  nextToken: string;
};
export type ShowerRoom = {
  name: string;
  status: string;
};

export interface ShowerRoomListFetch {
  type: typeof SHOWER_ROOM_LIST_FETCH;
  payload: ShowerRoomListParam;
}

// Success
export interface ShowerRoomListSuccess {
  type: typeof SHOWER_ROOM_LIST_SUCCESS;
  payload: ShowerRoomResponse;
}

// Failed
export interface ShowerRoomListFailed {
  type: typeof SHOWER_ROOM_LIST_FAILED;
  payload: Record<string, unknown>;
}

// Clear
export interface ShowerRoomListClear {
  type: typeof SHOWER_ROOM_LIST_CLEAR;
}

//#endregion

//#region SUBSCRIPTION

// Create Subscription
export interface ShowerBookingSubcriptionCreateFetch {
  type: typeof SHOWER_BOOKING_SUBSCRIPTION_CREATE_FETCH;
}
export interface ShowerBookingSubcriptionCreateSuccess {
  type: typeof SHOWER_BOOKING_SUBSCRIPTION_CREATE_SUCCESS;
}
export interface ShowerBookingSubscriptionCreateFailed {
  type: typeof SHOWER_BOOKING_SUBSCRIPTION_CREATE_FAILED;
  payload: FailureType;
}
export interface ShowerBookingSubscriptionRemoveFetch {
  type: typeof SHOWER_BOOKING_SUBSCRIPTION_REMOVE_FETCH;
}
export interface ShowerBookingSubscriptionRemoveSuccess {
  type: typeof SHOWER_BOOKING_SUBSCRIPTION_REMOVE_SUCCESS;
}
export interface ShowerBookingSubscriptionRemoveFailed {
  type: typeof SHOWER_BOOKING_SUBSCRIPTION_REMOVE_FAILED;
  payload: FailureType;
}
export interface ShowerBookingSubscriptionUpdateFetch {
  type: typeof SHOWER_BOOKING_SUBSCRIPTION_UPDATE_FETCH;
}
export interface ShowerBookingSubscriptionUpdateSuccess {
  type: typeof SHOWER_BOOKING_SUBSCRIPTION_UPDATE_SUCCESS;
}
export interface ShowerBookingSubscriptionUpdateFailed {
  type: typeof SHOWER_BOOKING_SUBSCRIPTION_UPDATE_FAILED;
  payload: FailureType;
}
// On Subscription Fired
export interface OnCreateUpdateShowerBookingParam {
  mutationType: ShowerBookingCreateUpdateType;
  data?: ShowerBooking;
}

export interface OnCallShowerBooking extends OnCreateUpdateShowerBookingParam {
  onCallShowerBooking: ShowerBooking;
}
export interface OnCancelShowerBooking extends OnCreateUpdateShowerBookingParam {
  onCancelShowerBooking: ShowerBooking;
}
export interface OnCompleteShowerBooking extends OnCreateUpdateShowerBookingParam {
  onCompleteShowerBooking: ShowerBooking;
}
export interface OnCreateShowerBooking extends OnCreateUpdateShowerBookingParam {
  onCreateShowerBooking: ShowerBooking;
}
export interface OnStartShowerBooking extends OnCreateUpdateShowerBookingParam {
  onStartShowerBooking: ShowerBooking;
}
export interface OnNotifyShowerBooking extends OnCreateUpdateShowerBookingParam {
  onNotifyShowerBooking: ShowerBooking;
}
export interface OnTimeoutShowerBooking extends OnCreateUpdateShowerBookingParam {
  onTimeoutShowerBooking: ShowerBooking;
}
export interface OnUpdateShowerBooking extends OnCreateUpdateShowerBookingParam {
  onUpdateShowerBooking: ShowerBooking;
}

export type OnCreateUpdateShowerBookingPayload =
  | OnCallShowerBooking
  | OnCancelShowerBooking
  | OnCompleteShowerBooking
  | OnCreateShowerBooking
  | OnStartShowerBooking
  | OnNotifyShowerBooking
  | OnTimeoutShowerBooking
  | OnUpdateShowerBooking;

export interface OnCreateUpdateShowerBooking {
  type: typeof ON_CREATE_UPDATE_SHOWER_BOOKING;
  payload: OnCreateUpdateShowerBookingPayload;
}

export interface OnCreateUpdateShowerBookingSuccess {
  type: typeof ON_CREATE_UPDATE_SHOWER_BOOKING_SUCCESS;
  payload: ShowerBooking;
}

export interface OnCreateUpdateShowerBookingFailed {
  type: typeof ON_CREATE_UPDATE_SHOWER_BOOKING_FAILED;
  payload: FailureType;
}

//#endregion


//#endregion

export type ShowerActionTypes =
  | ShowerBookingListFetch
  | ShowerBookingListSuccess
  | ShowerBookingListFailed
  | ShowerBookingListClear
  | ShowerBookingCreateFetch
  | ShowerBookingCreateSuccess
  | ShowerBookingCreateFailed
  | ShowerBookingCreateClear
  | ShowerBookingUpdateFetch
  | ShowerBookingUpdateSuccess
  | ShowerBookingUpdateFailed
  | ShowerBookingUpdateClear
  | ShowerRoomListFetch
  | ShowerRoomListSuccess
  | ShowerRoomListFailed
  | ShowerRoomListClear
  | OnCreateUpdateShowerBooking
  | OnCreateUpdateShowerBookingSuccess
  | OnCreateUpdateShowerBookingFailed
  | ShowerBookingSubcriptionCreateFetch
  | ShowerBookingSubcriptionCreateSuccess
  | ShowerBookingSubscriptionCreateFailed
  | ShowerBookingSubscriptionRemoveFetch
  | ShowerBookingSubscriptionRemoveSuccess
  | ShowerBookingSubscriptionRemoveFailed
  | ShowerBookingSubscriptionUpdateFetch
  | ShowerBookingSubscriptionUpdateSuccess
  | ShowerBookingSubscriptionUpdateFailed;
