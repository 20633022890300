//#region ACTION TYPES

// Staff Call List
export const STAFF_CALL_LIST_FETCH = 'STAFF_CALL_LIST_FETCH';
export const STAFF_CALL_LIST_SUCCESS = 'STAFF_CALL_LIST_SUCCESS';
export const STAFF_CALL_LIST_FAILED = 'STAFF_CALL_LIST_FAILED';
export const STAFF_CALL_LIST_CLEAR = 'STAFF_CALL_LIST_CLEAR';

// Staff Call Item
export const STAFF_CALL_ITEM_FETCH = 'STAFF_CALL_ITEM_FETCH';
export const STAFF_CALL_ITEM_SUCCESS = 'STAFF_CALL_ITEM_SUCCESS';
export const STAFF_CALL_ITEM_FAILED = 'STAFF_CALL_ITEM_FAILED';
export const STAFF_CALL_ITEM_CLEAR = 'STAFF_CALL_ITEM_CLEAR';

// Staff Call Create
export const STAFF_CALL_CREATE_FETCH = 'STAFF_CALL_CREATE_FETCH';
export const STAFF_CALL_CREATE_SUCCESS = 'STAFF_CALL_CREATE_SUCCESS';
export const STAFF_CALL_CREATE_FAILED = 'STAFF_CALL_CREATE_FAILED';
export const STAFF_CALL_CREATE_CLEAR = 'STAFF_CALL_CREATE_CLEAR';

// Staff Call Update
export const STAFF_CALL_UPDATE_FETCH = 'STAFF_CALL_UPDATE_FETCH';
export const STAFF_CALL_UPDATE_SUCCESS = 'STAFF_CALL_UPDATE_SUCCESS';
export const STAFF_CALL_UPDATE_FAILED = 'STAFF_CALL_UPDATE_FAILED';
export const STAFF_CALL_UPDATE_CLEAR = 'STAFF_CALL_UPDATE_CLEAR';

// Staff Call Subscription Create
export const STAFF_CALL_SUBSCRIPTION_CREATE_FETCH = 'STAFF_CALL_SUBSCRIPTION_CREATE_FETCH';
export const STAFF_CALL_SUBSCRIPTION_CREATE_SUCCESS = 'STAFF_CALL_SUBSCRIPTION_CREATE_SUCCESS';
export const STAFF_CALL_SUBSCRIPTION_CREATE_FAILED = 'STAFF_CALL_SUBSCRIPTION_CREATE_FAILED';
export const STAFF_CALL_SUBSCRIPTION_CREATE_CLEAR = 'STAFF_CALL_SUBSCRIPTION_CREATE_CLEAR';

// Staff Call Subscription Action
export const ON_STAFF_CALL_SUBSCRIPTION = 'ON_STAFF_CALL_SUBSCRIPTION';
export const ON_CREATE_UPDATE_STAFF_CALL = 'ON_CREATE_UPDATE_STAFF_CALL';

// Failed
export const INVALID_CREATE_UPDATE_TYPE_ERROR = 'INVALID_CREATE_UPDATE_TYPE_ERROR';

//#endregion
