//#region IMPORTS
// LOCAL CONFIG
import {
  MEAL_ORDER_CREATE_CLEAR,
  MEAL_ORDER_CREATE_FAILED,
  MEAL_ORDER_CREATE_FETCH,
  MEAL_ORDER_CREATE_SUCCESS,
  MEAL_ORDER_LIST_CLEAR,
  MEAL_ORDER_LIST_FAILED,
  MEAL_ORDER_LIST_FETCH,
  MEAL_ORDER_LIST_SUCCESS,
  MEAL_ORDER_SUBSCRIPTION_CREATE_FAILED,
  MEAL_ORDER_SUBSCRIPTION_CREATE_SUCCESS,
  MEAL_ORDER_SUBSCRIPTION_REMOVE_FAILED,
  MEAL_ORDER_SUBSCRIPTION_REMOVE_SUCCESS,
  MEAL_ORDER_UPDATE_CLEAR,
  MEAL_ORDER_UPDATE_FAILED,
  MEAL_ORDER_UPDATE_FETCH,
  MEAL_ORDER_UPDATE_SUCCESS,
  MealOrder,
  MealOrderActionTypes,
  MealOrderCreateParam,
  MealOrderCreateResponseType,
  MealOrderListPayload,
  MealOrderListResponse,
  MealOrderUpdateParam,
  MENU_LIST_CLEAR,
  MENU_LIST_FAILED,
  MENU_LIST_FETCH,
  MENU_LIST_SUCCESS,
  ON_CREATE_UPDATE_MEAL_ORDER,
  ON_CREATE_UPDATE_MEAL_ORDER_FAILED,
  ON_CREATE_UPDATE_MEAL_ORDER_SUCCESS,
  OnCreateMealOrderPayload,
  MenuListParam,
  MenuListResponse,
  FailureType,
  MEAL_ORDER_SUBSCRIPTION_CREATE_FETCH,
  MEAL_ORDER_SUBSCRIPTION_REMOVE_FETCH,
  SET_LATEST_ORDER_COUNTER,
  LatestOrderCounter,
  TOGGLE_SORTED_ACTION,
} from './constants';
//#endregion

//#region ACTION CREATORS
//#region MEAL
// Meal Order List
export const MealOrderListFetch = (payload: MealOrderListPayload): MealOrderActionTypes => ({
  type: MEAL_ORDER_LIST_FETCH,
  payload,
});
export const MealOrderListSuccess = (payload: MealOrderListResponse): MealOrderActionTypes => ({
  type: MEAL_ORDER_LIST_SUCCESS,
  payload,
});
export const MealOrderListFailed = (payload: FailureType): MealOrderActionTypes => ({
  type: MEAL_ORDER_LIST_FAILED,
  payload,
});
export const MealOrderListClear = (): MealOrderActionTypes => ({
  type: MEAL_ORDER_LIST_CLEAR,
});

// Meal Order Create
export const MealOrderCreateFetch = (payload: MealOrderCreateParam): MealOrderActionTypes => ({
  type: MEAL_ORDER_CREATE_FETCH,
  payload,
});
export const MealOrderCreateSuccess = (payload: MealOrderCreateResponseType): MealOrderActionTypes => ({
  type: MEAL_ORDER_CREATE_SUCCESS,
  payload,
});
export const MealOrderCreateFailed = (payload: FailureType): MealOrderActionTypes => ({
  type: MEAL_ORDER_CREATE_FAILED,
  payload,
});
export const MealOrderCreateClear = (): MealOrderActionTypes => ({
  type: MEAL_ORDER_CREATE_CLEAR,
});

// Meal Order Update
export const MealOrderUpdateFetch = (payload: MealOrderUpdateParam): MealOrderActionTypes => ({
  type: MEAL_ORDER_UPDATE_FETCH,
  payload,
});
export const MealOrderUpdateSuccess = (payload: MealOrder): MealOrderActionTypes => ({
  type: MEAL_ORDER_UPDATE_SUCCESS,
  payload,
});
export const MealOrderUpdateFailed = (payload: FailureType): MealOrderActionTypes => ({
  type: MEAL_ORDER_UPDATE_FAILED,
  payload,
});
export const MealOrderUpdateClear = (): MealOrderActionTypes => ({
  type: MEAL_ORDER_UPDATE_CLEAR,
});

// Subscription Actions
export const MealOrderSubscriptionCreateFetch = (): MealOrderActionTypes => ({
  type: MEAL_ORDER_SUBSCRIPTION_CREATE_FETCH,
});
export const MealOrderSubscriptionCreateSuccess = (): MealOrderActionTypes => ({
  type: MEAL_ORDER_SUBSCRIPTION_CREATE_SUCCESS,
});
export const MealOrderSubscriptionCreateFailed = (payload: FailureType): MealOrderActionTypes => ({
  type: MEAL_ORDER_SUBSCRIPTION_CREATE_FAILED,
  payload,
});
export const MealOrderSubscriptionRemoveFetch = (): MealOrderActionTypes => ({
  type: MEAL_ORDER_SUBSCRIPTION_REMOVE_FETCH,
});
export const MealOrderSubscriptionRemoveSuccess = (): MealOrderActionTypes => ({
  type: MEAL_ORDER_SUBSCRIPTION_REMOVE_SUCCESS,
});
export const MealOrderSubscriptionRemoveFailed = (payload: FailureType): MealOrderActionTypes => ({
  type: MEAL_ORDER_SUBSCRIPTION_REMOVE_FAILED,
  payload,
});
export const OnCreateUpdateMealOrder = (payload: OnCreateMealOrderPayload, muted: boolean, airportId: string, loungeId: string): MealOrderActionTypes => ({
  type: ON_CREATE_UPDATE_MEAL_ORDER,
  payload,
  muted,
  airportId,
  loungeId
});
export const OnCreateUpdateMealOrderSuccess = (payload: MealOrder): MealOrderActionTypes => ({
  type: ON_CREATE_UPDATE_MEAL_ORDER_SUCCESS,
  payload,
});
export const OnCreateUpdateMealOrderFailed = (payload: FailureType): MealOrderActionTypes => ({
  type: ON_CREATE_UPDATE_MEAL_ORDER_FAILED,
  payload,
});
//#endregion

//#region MENU
export const MenuListFetch = (payload: MenuListParam): MealOrderActionTypes => ({
  type: MENU_LIST_FETCH,
  payload,
});
export const MenuListSuccess = (payload: MenuListResponse): MealOrderActionTypes => ({
  type: MENU_LIST_SUCCESS,
  payload,
});
export const MenuListFailed = (payload: FailureType): MealOrderActionTypes => ({
  type: MENU_LIST_FAILED,
  payload,
});
export const MenuListClear = (): MealOrderActionTypes => ({
  type: MENU_LIST_CLEAR,
});
export const SetLatestOrderCounter = (payload: LatestOrderCounter): MealOrderActionTypes => ({
  type: SET_LATEST_ORDER_COUNTER,
  payload,
})

export const ToggleSortedAction = (payload: boolean): MealOrderActionTypes => ({
  type: TOGGLE_SORTED_ACTION,
  payload,
})
//#endregion
//#endregion
