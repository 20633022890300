//#region IMPORTS
// LOCAL CONFIG
import {
  CONGESTION_UPDATE_FETCH,
  CONGESTION_UPDATE_SUCCESS,
  CONGESTION_LIST_FETCH,
  CONGESTION_LIST_SUCCESS,
  CONGESTION_UPDATE_FAILED,
  CONGESTION_LIST_FAILED,
  ON_CONGESTION_UPDATE,
  CONGESTION_SUBSCRIPTION_SUCCESS,
  CONGESTION_SUBSCRIPTION_FAILED,
} from './StringCongestion';

//#endregion

//#region ENUM and GENERAL
export enum CongestionLevel {
  Closed = 1,
  Vacant = 2,
  Crowded = 3,
}

export enum AreaCode {
  Main = '1',
  Sub = '2',
}

export type FailureType = Record<string, unknown> | string;

export type GraphQLSubscriptionResult = {
  provider: unknown;
  value: {
    data: Area;
  };
};
//#endregion

//#region TYPES
// AREA
export interface Area {
  code: string;
  congestion_level: number;
  updated_by: string;
  updated_at: string;
}

interface ListAreaObject {
  code: string;
  name: string;
  level?: number;
}

export const ListArea: Record<string, ListAreaObject> = {
  'hnd#firstclass': {
    code: 'hnd#firstclass',
    name: 'First Class Lounge',
  },
  'hnd#sakura': {
    code: "hnd#sakura",
    name: 'Sakura Lounge',
  },
  'nrt#firstclass': {
    code: 'nrt#firstclass',
    name: 'First Class Lounge',
  },
  'nrt#sakura': {
    code: "nrt#sakura",
    name: 'Sakura Lounge',
  },
  'nrt#firstclass_satellite': {
    code: 'nrt#firstclass_satellite',
    name: 'First Class Lounge Satellite',
  },
  'nrt#sakura_satellite': {
    code: "nrt#sakura_satellite",
    name: 'Sakura Lounge Satellite',
  },
};

export interface AreaList {
  items?: Area[];
  nextToken?: string;
}

// CONGESTION
export interface Congestion {
  code: string;
  congestionLevel: string;
}
//#endregion

//#region ACTION TYPE
// LIST
export type CongestionListResponse = {
  listArea: AreaList;
};
export interface CongestionListParam {
  airport_id: string;
  limit?: number;
  nextToken?: string;
}
export interface CongestionListFetch {
  type: typeof CONGESTION_LIST_FETCH;
  payload: CongestionListParam;
}
export interface CongestionListSuccess {
  type: typeof CONGESTION_LIST_SUCCESS;
  payload: AreaList;
}
export interface CongestionListFailed {
  type: typeof CONGESTION_LIST_FAILED;
  payload: {};
}

// UPDATE
export type CongestionUpdateResponse = {
  updateArea: Area;
};
export interface CongestionUpdateParam {
  airport_id: string;
  code: string;
  congestionLevel: number;
}
export interface CongestionUpdateFetch {
  type: typeof CONGESTION_UPDATE_FETCH;
  payload: CongestionUpdateParam;
}
export interface CongestionUpdateSuccess {
  type: typeof CONGESTION_UPDATE_SUCCESS;
  payload: Area;
}
export interface CongestionUpdateFailed {
  type: typeof CONGESTION_UPDATE_FAILED;
  payload: {};
}

// SUBSCRIBE
export interface OnCongestionUpdate {
  type: typeof ON_CONGESTION_UPDATE;
  payload: Area;
}
export interface CongestionSubscriptionSuccess {
  type: typeof CONGESTION_SUBSCRIPTION_SUCCESS;
}
export interface CongestionSubscriptionFailed {
  type: typeof CONGESTION_SUBSCRIPTION_FAILED;
  payload: FailureType;
}
export interface OnUpdateCongestion {
  onUpdateArea: Area;
}

// ACTIONS
export type CongestionActionTypes =
  | CongestionUpdateFetch
  | CongestionUpdateSuccess
  | CongestionUpdateFailed
  | CongestionListFetch
  | CongestionListSuccess
  | CongestionListFailed
  | CongestionSubscriptionSuccess
  | CongestionSubscriptionFailed;

//#endregion
