//#region IMPORT
// PACKAGE IMPORT
import React from 'react';
import classnames from 'classnames';
import { injectIntl, WrappedComponentProps } from 'react-intl';

// LOCAL CONFIG
import { ALERTTYPE } from '../../constants';

// LOCAL COMPONENT
import { Button } from '../../../../components';
import './Notify.scss';

//#endregion

//#region INTERFACE
export interface DispatchProps {
  HideAlert: () => void;
}

export interface StateProps {
  message: string;
  type: ALERTTYPE;
  isSignedIn: boolean;
  isShowAlert: boolean;
}

type Props = StateProps & DispatchProps & WrappedComponentProps;
//#endregion

//#region COMPONENT
export class NotifyView extends React.Component<Props> {
  render(): React.ReactElement {
    const { intl, message, isShowAlert, isSignedIn, type, HideAlert } = this.props;

    let startAutoClose;

    if (isShowAlert) {
      clearTimeout(startAutoClose);
      startAutoClose = setTimeout(() => {
        HideAlert();
      }, 12000);
    } else {
      clearTimeout(startAutoClose);
    }

    const classes = classnames(
      'alert',
      `alert--${type}`,
      { 'alert--signed': isSignedIn },
      { 'alert--showed': isShowAlert }
    );

    return (
      <div className={classes}>
        <div className="alert__content">{message && intl.formatMessage({ id: message, defaultMessage: message })}</div>
        <Button type="inline" icon="close" onClick={(): void => HideAlert()} />
      </div>
    );
  }
}
//#endregion

export default injectIntl(NotifyView);
