//#region IMPORTS

// PACKAGE IMPORTS
import { createStore, applyMiddleware, Middleware, Store } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { composeWithDevTools as composeReduxDevtools } from 'redux-devtools-extension/developmentOnly';
import { composeWithDevTools as composeRemoteReduxDevtools } from 'remote-redux-devtools';
import { persistStore, persistReducer } from 'redux-persist';
import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';

// LOCAL IMPORTS
import reducers from './ReduxReducer';
import reduxSaga from './ReduxSaga';

//#endregion

export const history = createBrowserHistory();

let middlewares: Middleware[] = [];
let composedEnhancers;

const sagaMiddleware = createSagaMiddleware();

if (process.env.REACT_APP_PLATFORM === 'XAMARIN' && process.env.NODE_ENV === 'development') {
  middlewares = [sagaMiddleware, routerMiddleware(history), logger];
  composedEnhancers = composeRemoteReduxDevtools(applyMiddleware(...middlewares));
} else {
  middlewares = [sagaMiddleware, routerMiddleware(history)];
  const composeEnhancer = composeReduxDevtools({
    trace: true,
  });
  composedEnhancers = composeEnhancer(applyMiddleware(...middlewares));
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const persistedReducer = persistReducer(persistConfig, reducers(history));

const initialState = {};

const reduxStore = createStore(persistedReducer, initialState, composedEnhancers);
sagaMiddleware.run(reduxSaga);

export type AppState = ReturnType<typeof persistedReducer>;
export const store = reduxStore;
export const persistor = persistStore((store as unknown) as Store);
