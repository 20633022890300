//#region IMPORTS

// PACKAGE IMPORTS
import React from 'react';
import API, { graphqlOperation } from '@aws-amplify/api';
import Observable from 'zen-observable';
import { injectIntl, IntlShape } from 'react-intl';
import lodash from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

// LOCAL CONFIG IMPORTS
import {
  MEAL_ORDER_CREATE_SUCCESS,
  MEAL_ORDER_LIST_SUCCESS,
  MealOrder,
  MealOrderActionTypes,
  MealOrderCreateParam,
  MealOrderCreateUpdateType,
  MealOrderFilterType,
  MealOrderListMultiStatusResponse,
  MealOrderListPayload,
  MealOrderListResponse,
  MealOrderStatusType,
  MealOrderUpdateParam,
  MenuSelectItem,
  ON_CREATE_UPDATE_MEAL_ORDER_SUCCESS,
  OnCompleteMealOrderPayload,
  OnCreateUpdateMealOrderPayload,
  OnRejectMealOrderPayload,
  OnStartMealOrderPayload,
  OnUpdateMealOrderItemsPayload,
  OrderForm,
  subscription,
  SubscriptionRegistrationStatus,
  FailureType,
  GraphQLSubscriptionResult,
  LatestOrderCounter,
} from '../../constants';
import { PATH } from '../../../navigation/constants';

// LOCAL COMPONENT IMPORTS
import { Header, Button, Modal } from '../../../../components';
import Column, { ColumnColor, ColumnProps } from '../../../../components/Column';
import MealsForm from '../MealsForm';
import MealsListItem from '../MealsListItem';
import MealsDetail from '../MealsDetail';
import './Meals.scss';

//#endregion

//#region STATE & PROPS
export interface DispatchProps {
  MealOrderSubscriptionCreateSuccess(): MealOrderActionTypes;
  MealOrderSubscriptionCreateFailed(payload: FailureType): MealOrderActionTypes;
  MealOrderSubscriptionRemoveSuccess(): MealOrderActionTypes;
  MealOrderSubscriptionRemoveFailed(payload: FailureType): MealOrderActionTypes;
  OnCreateUpdateMealOrder(payload: OnCreateUpdateMealOrderPayload, muted: boolean, airportId: string, loungeId: string): MealOrderActionTypes;
  MealOrderListFetch(payload: MealOrderListPayload): MealOrderActionTypes;
  MealOrderCreateFetch(payload: MealOrderCreateParam): MealOrderActionTypes;
  MealOrderUpdateFetch(payload: MealOrderUpdateParam): MealOrderActionTypes;
  MealOrderSubscriptionCreateFetch(): MealOrderActionTypes;
  MealOrderSubscriptionRemoveFetch(): MealOrderActionTypes;
  SetLatestOrderCounter(payload: LatestOrderCounter): MealOrderActionTypes;
  ToggleSortedAction(payload: boolean): MealOrderActionTypes;
}

export interface StateProps {
  action: string;
  mealOrderListResponse?: MealOrderListResponse;
  mealOrderListParam?: MealOrderListPayload;
  onCreateUpdateSubscriptionParam?: OnCreateUpdateMealOrderPayload;
  onCreateUpdateMealOrderBookingResponse?: OnCreateUpdateMealOrderPayload | MealOrder;
  mealOrderCreateParam?: MealOrderCreateParam;
  subscriptionRegistrationStatus: SubscriptionRegistrationStatus;
  mealOrderListLoading: boolean;
  airportId: string;
  loungeId: string;
  isMuted: boolean;
  latestOrderCounter: LatestOrderCounter;
  isAscSorted: boolean;
}

export interface OwnProps extends RouteComponentProps {
  intl: IntlShape;
  isArchive?: boolean;
}

type Props = DispatchProps & OwnProps & StateProps;

interface State {
  mealsData: MealOrderListMultiStatusResponse;
  menuItems: MenuSelectItem[];
  modalVisible: boolean;
  modalAddVisible: boolean;
  detailMealOrder?: MealOrder;
  selectedDate?: Date | null;
}

//#endregion

//#region COMPONENT
export class Meals extends React.Component<Props, State> {
  //#region CLASS PROPERTIES
  private interval: number | undefined;

  private OnCreateMealOrder?: ZenObservable.Subscription;
  private OnRejectMealOrder?: ZenObservable.Subscription;
  private OnStartMealOrder?: ZenObservable.Subscription;
  private OnCompleteMealOrder?: ZenObservable.Subscription;
  private OnUpdateMealOrderItems?: ZenObservable.Subscription;

  private readonly createOrderModalRef: React.RefObject<Modal>;
  private readonly createOrderDetailModalRef: React.RefObject<Modal>;
  //#endregion

  //#region CONSTRUCTOR
  constructor(props: Props) {
    super(props);
    this.state = {
      mealsData: {
        items: {
          [MealOrderStatusType.SUBMITTED]: [],
          [MealOrderStatusType.IN_PROGRESS]: [],
        },
      },
      menuItems: [],
      modalVisible: false,
      modalAddVisible: false,
      detailMealOrder: undefined,
      selectedDate: moment().toDate(),
    };

    // noinspection DuplicatedCode
    this.interval = undefined;
    this.createOrderModalRef = React.createRef<Modal>();
    this.createOrderDetailModalRef = React.createRef<Modal>();
    this.fetchMealOrders = this.fetchMealOrders.bind(this);
    this.handleMealOrderListSuccess = this.handleMealOrderListSuccess.bind(this);
    this.handleSubscriptionUpdate = this.handleSubscriptionUpdate.bind(this);
    this.handleMealOrderSubmit = this.handleMealOrderSubmit.bind(this);
    this.handleMealOrderUpdate = this.handleMealOrderUpdate.bind(this);
    this.handleShowDetail = this.handleShowDetail.bind(this);
    this.handleShowAddModal = this.handleShowAddModal.bind(this);
    this.handleReduxActions = this.handleReduxActions.bind(this);
    this.handleReload = this.handleReload.bind(this);
    this.handleToggleSort = this.handleToggleSort.bind(this);
    
  }
  //#endregion

  //#region LC
  async componentDidMount(): Promise<void> {
    const { isArchive } = this.props;
    this.createSubscription();
    this.interval = window.setInterval(() => {
      //this.unsubscribeSubscription();
      //this.createSubscription();
      console.log("called meal interval")
      if(!isArchive){
        this.fetchMealOrders(this.props.airportId, this.props.loungeId, isArchive ? moment().format('YYYY-MM-DD') : undefined);
      }
    }, 1 * 7 * 1000);
    this.fetchMealOrders(this.props.airportId, this.props.loungeId, isArchive ? moment().format('YYYY-MM-DD') : undefined);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (prevProps.action !== this.props.action) {
      this.handleReduxActions(this.props.action);
    }

    if (prevState.modalVisible !== this.state.modalVisible) {
      if (this.state.modalVisible) {
        this.createOrderDetailModalRef.current && this.createOrderDetailModalRef.current.openModal();
      } else {
        this.createOrderDetailModalRef.current && this.createOrderDetailModalRef.current.closeModal();
      }
    }

    if (prevState.modalAddVisible !== this.state.modalAddVisible) {
      if (this.state.modalAddVisible) {
        this.createOrderModalRef.current && this.createOrderModalRef.current.openModal();
      } else {
        this.createOrderModalRef.current && this.createOrderModalRef.current.closeModal();
      }
    }


  }

  componentWillUnmount(): void {
    clearInterval(this.interval);
    this.unsubscribeSubscription();
  }

  //#endregion

  //#region CLASS METHODS
  handleReduxActions(action: string): void {
    const actions = {
      [MEAL_ORDER_LIST_SUCCESS as string]: (): void => {
        this.props.mealOrderListResponse && this.handleMealOrderListSuccess();
      },
      [MEAL_ORDER_CREATE_SUCCESS as string]: (): void => {
        this.createOrderModalRef.current && this.createOrderModalRef.current.closeModal();
      },
      [ON_CREATE_UPDATE_MEAL_ORDER_SUCCESS as string]: (): void => this.fetchMealOrders(this.props.airportId, this.props.loungeId),
      DEFAULT: (): void => {},
    };

    return (actions[action] || actions.DEFAULT)();
  }

  async createSubscription(): Promise<void> {
    if (
      this.props.subscriptionRegistrationStatus === SubscriptionRegistrationStatus.UNREGISTERED ||
      this.props.subscriptionRegistrationStatus === SubscriptionRegistrationStatus.REGISTRATION_FAILED
    ) {
      try {
        //#region SUBSCRIPTION CREATION
        this.OnCreateMealOrder = ((await API.graphql(graphqlOperation(subscription.OnCreateMealOrder))) as Observable<
          GraphQLSubscriptionResult
        >).subscribe({
          next: (data: GraphQLSubscriptionResult) =>
            this.handleSubscriptionUpdate({
              onCreateMealOrder: data.value.data.onCreateMealOrder,
              mutationType: MealOrderCreateUpdateType.CREATE,
            }),
          error: (error: string) => {
            console.log("disconnect subscription:", error);
            this.reconnectSubscription()
          }
        });

        this.OnRejectMealOrder = ((await API.graphql(graphqlOperation(subscription.OnRejectMealOrder))) as Observable<
          GraphQLSubscriptionResult
        >).subscribe({
          next: (data: GraphQLSubscriptionResult) =>
            this.handleSubscriptionUpdate({
              onRejectMealOrder: data.value.data.onRejectMealOrder,
              mutationType: MealOrderCreateUpdateType.REJECT,
            }),
          error: (error: string) => {
            console.log("disconnect subscription:", error);
            this.reconnectSubscription()
          }
        });

        this.OnStartMealOrder = ((await API.graphql(graphqlOperation(subscription.OnStartMealOrder))) as Observable<
          GraphQLSubscriptionResult
        >).subscribe({
          next: (data: GraphQLSubscriptionResult) =>
            this.handleSubscriptionUpdate({
              onStartMealOrder: data.value.data.onStartMealOrder,
              mutationType: MealOrderCreateUpdateType.START,
            }),
          error: (error: string) => {
            console.log("disconnect subscription:", error);
            this.reconnectSubscription()
          }
        });

        this.OnCompleteMealOrder = ((await API.graphql(
          graphqlOperation(subscription.OnCompleteMealOrder)
        )) as Observable<GraphQLSubscriptionResult>).subscribe({
          next: (data: GraphQLSubscriptionResult) =>
            this.handleSubscriptionUpdate({
              onCompleteMealOrder: data.value.data.onCompleteMealOrder,
              mutationType: MealOrderCreateUpdateType.COMPLETE,
            }),
          error: (error: string) => {
            console.log("disconnect subscription:", error);
            this.reconnectSubscription()
          }
        });

        this.OnUpdateMealOrderItems = ((await API.graphql(
          graphqlOperation(subscription.OnUpdateMealOrderItems)
        )) as Observable<GraphQLSubscriptionResult>).subscribe({
          next: (data: GraphQLSubscriptionResult) =>
            this.handleSubscriptionUpdate({
              onUpdateMealOrderItems: data.value.data.onUpdateMealOrderItems,
              mutationType: MealOrderCreateUpdateType.UPDATE,
            }),
          error: (error: string) => {
            console.log("disconnect subscription:", error);
            this.reconnectSubscription()
          }
        });
        //#endregion

        this.props.MealOrderSubscriptionCreateSuccess();
      } catch (e) {
        this.props.MealOrderSubscriptionCreateFailed(e);
      }
    }
  }

  unsubscribeSubscription(): void {
    try {
      this.props.MealOrderSubscriptionRemoveFetch();

      this.OnCreateMealOrder && this.OnCreateMealOrder.unsubscribe();
      this.OnRejectMealOrder && this.OnRejectMealOrder.unsubscribe();
      this.OnStartMealOrder && this.OnStartMealOrder.unsubscribe();
      this.OnCompleteMealOrder && this.OnCompleteMealOrder.unsubscribe();
      this.OnUpdateMealOrderItems && this.OnUpdateMealOrderItems.unsubscribe();

      this.props.MealOrderSubscriptionRemoveSuccess();
    } catch (e) {
      this.props.MealOrderSubscriptionRemoveFailed(e);
    }
  }
  
  reconnectSubscription(): void {
    this.unsubscribeSubscription();
    this.createSubscription();
  }

  fetchMealOrders(airport_id: string, lounge_id: string, date?: string): void {
    const { isArchive } = this.props;
    this.props.MealOrderListFetch({
      limit: 1000,
      filterType: isArchive ? MealOrderFilterType.STATUS_DATE : MealOrderFilterType.STATUS,
      statusTypes: isArchive
        ? [MealOrderStatusType.COMPLETED, MealOrderStatusType.REJECTED]
        : [MealOrderStatusType.SUBMITTED, MealOrderStatusType.IN_PROGRESS],
      airport_id,
      lounge_id,
      date,
    });
  }

  checkIfMealOrderListFilterByStatus(response: MealOrderListResponse): boolean {
    return 'statusTypes' in response;
  }

  handleMealOrderSubmit(mealOrder: OrderForm): void {
    this.props.MealOrderCreateFetch({
      ...mealOrder,
      table_id: "staff",
      locale: "ja",
      use_app: false,
    });
  }

  handleMealOrderListSuccess(): void {
    const { mealOrderListResponse, mealOrderListParam } = this.props;
    if (!this.checkIfMealOrderListFilterByStatus(mealOrderListParam as MealOrderListResponse)) return;

    const orderSubmitted = this.descSortByTimestamp((mealOrderListResponse as MealOrderListMultiStatusResponse).items[MealOrderStatusType.SUBMITTED]);
    if(orderSubmitted !== undefined && orderSubmitted.length === 0){
      this.props.SetLatestOrderCounter({date: (new Date()).getDate(), counter: 0});
    }else if(orderSubmitted !== undefined && orderSubmitted.length > 0){
      // First time fetch since app was loaded.
      if(this.props.latestOrderCounter.counter < 0){
        if(this.props.latestOrderCounter.counter < orderSubmitted[0].counter){
          this.props.SetLatestOrderCounter({date: (new Date(orderSubmitted[0].timestamp)).getDate(), counter: orderSubmitted[0].counter});
        }
      }else{
        if( this.props.latestOrderCounter.date === (new Date(orderSubmitted[0].timestamp)).getDate() ){
          if(this.props.latestOrderCounter.counter < orderSubmitted[0].counter){
            this.props.SetLatestOrderCounter({date: (new Date(orderSubmitted[0].timestamp)).getDate(), counter: orderSubmitted[0].counter});
            const audio = document.getElementById('notify-se') as HTMLAudioElement;
            if(audio && !this.props.isMuted){
              audio.muted = false;
              audio.play();
            }
          }
        }else if(this.props.latestOrderCounter.date < (new Date(orderSubmitted[0].timestamp)).getDate()){
          this.props.SetLatestOrderCounter({date: (new Date(orderSubmitted[0].timestamp)).getDate(), counter: orderSubmitted[0].counter});
          const audio = document.getElementById('notify-se') as HTMLAudioElement;
          if(audio && !this.props.isMuted){
            audio.muted = false;
            audio.play();
          }
        }
      }
    }
    this.setState({
      mealsData: mealOrderListResponse as MealOrderListMultiStatusResponse,
    });
  }

  handleSubscriptionUpdate(payload: OnCreateUpdateMealOrderPayload): void {
    const prevPayload = this.props.onCreateUpdateSubscriptionParam;
    const updates = {
      [MealOrderCreateUpdateType.COMPLETE as string]: (): MealOrder => {
        return (payload as OnCompleteMealOrderPayload).onCompleteMealOrder;
      },
      [MealOrderCreateUpdateType.START]: (): MealOrder => {
        return (payload as OnStartMealOrderPayload).onStartMealOrder;
      },
      [MealOrderCreateUpdateType.REJECT]: (): MealOrder => {
        return (payload as OnRejectMealOrderPayload).onRejectMealOrder;
      },
      [MealOrderCreateUpdateType.UPDATE]: (): MealOrder => {
        return (payload as OnUpdateMealOrderItemsPayload).onUpdateMealOrderItems;
      },
      DEFAULT: (): string => 'invalid',
    };

    const currentUpdate = (updates[payload.mutationType] || updates.DEFAULT)();
    if (
      this.state.detailMealOrder &&
      typeof currentUpdate !== 'string' &&
      this.state.detailMealOrder.device_id === currentUpdate.device_id
    ) {
      this.setState({ detailMealOrder: currentUpdate });
    }

    if (lodash.isEqual(prevPayload, payload)) return;
    this.props.OnCreateUpdateMealOrder(payload, this.props.isMuted, this.props.airportId, this.props.loungeId);
  }

  handleMealOrderUpdate(mealOrder: MealOrder, updateType: MealOrderCreateUpdateType): void {
    const { device_id, timestamp, items, airport_id, lounge_id } = mealOrder;
    this.props.MealOrderUpdateFetch({
      updateType,
      device_id,
      timestamp,
      airport_id,
      lounge_id,
      ...(updateType === MealOrderCreateUpdateType.UPDATE && {
        items,
      }),
    });
  }

  handleShowAddModal(): void {
    this.setState({ modalAddVisible: true });
  }

  handleReload(): void {
    this.unsubscribeSubscription();
    this.createSubscription();
    this.fetchMealOrders(this.props.airportId, this.props.loungeId, this.props.isArchive ? moment().format('YYYY-MM-DD') : undefined);
  }

  handleToggleSort(): void {
    this.props.ToggleSortedAction(!this.props.isAscSorted);
  }

  handleShowDetail(data: MealOrder): void {
    this.setState({ detailMealOrder: data, modalVisible: true });
  }

  ascSortByTimestamp(data: MealOrder[]|undefined): MealOrder[]|undefined {
    if(data !== undefined){
      return data.sort((a: MealOrder, b: MealOrder) => {
        return moment(a.timestamp).isBefore(b.timestamp) ? -1 : 1;
      });
    }else{
      return undefined;
    }
  }

  descSortByTimestamp(data: MealOrder[]|undefined): MealOrder[]|undefined {
    if(data !== undefined){
      return data.sort((a: MealOrder, b: MealOrder) => {
        return moment(a.timestamp).isBefore(b.timestamp) ? 1 : -1;
      });
    }else{
      return undefined;
    }
  }

  //#region RENDERS

  renderDetailModal(): React.ReactElement {
    if (!this.state.modalVisible || this.state.detailMealOrder == null) return <></>;
    return (
      <Modal
        mode={4}
        ref={this.createOrderDetailModalRef}
        title="labelOrderDetail"
        content={<MealsDetail data={this.state.detailMealOrder} onClickActionButton={this.handleMealOrderUpdate} />}
        onClosed={(): void => this.setState({ modalVisible: false })}
      />
    );
  }

  renderAddModal(): React.ReactElement {
    if (!this.state.modalAddVisible) return <></>;
    return (
      <Modal
        mode={4}
        title="titleCreateOrder"
        content={<MealsForm onSubmit={this.handleMealOrderSubmit} />}
        onClosed={(): void => this.setState({ modalAddVisible: false })}
        ref={this.createOrderModalRef}
      />
    );
  }

  renderColumn(): ColumnProps[] {
    const { isArchive, isAscSorted } = this.props;
    const { mealsData } = this.state;
    let dataSubmitted: MealOrder[]|undefined;
    let dataInProgress: MealOrder[]|undefined;
    let dataCompleted: MealOrder[]|undefined;
    let dataRejected: MealOrder[]|undefined;
    if(isAscSorted){
      dataSubmitted = this.ascSortByTimestamp(mealsData.items[MealOrderStatusType.SUBMITTED]);
      dataInProgress = this.ascSortByTimestamp(mealsData.items[MealOrderStatusType.IN_PROGRESS]);
      dataCompleted = this.ascSortByTimestamp(mealsData.items[MealOrderStatusType.COMPLETED]);
      dataRejected = this.ascSortByTimestamp(mealsData.items[MealOrderStatusType.REJECTED]);
    }else{
      dataSubmitted = this.descSortByTimestamp(mealsData.items[MealOrderStatusType.SUBMITTED]);
      dataInProgress = this.descSortByTimestamp(mealsData.items[MealOrderStatusType.IN_PROGRESS]);
      dataCompleted = this.descSortByTimestamp(mealsData.items[MealOrderStatusType.COMPLETED]);
      dataRejected = this.descSortByTimestamp(mealsData.items[MealOrderStatusType.REJECTED]);
    }
    if (isArchive) {
      return [
        {
          title: 'mealsStatusCompleted',
          count: dataCompleted ? dataCompleted.length : 0,
          color: ColumnColor.SECONDARY,
          content: dataCompleted ? dataCompleted.map((mealOrder: MealOrder) => (
            <MealsListItem
              data={mealOrder}
              key={`${mealOrder.device_id}-${mealOrder.timestamp}`}
              onClickActionButton={this.handleMealOrderUpdate}
              onClickDetail={this.handleShowDetail}
              isArchive={isArchive}
            />
          )) : <></>,
        },
        {
          title: 'mealsStatusRejected',
          count: dataRejected ? dataRejected.length : 0,
          color: ColumnColor.PRIMARY,
          content: dataRejected ? dataRejected.map((mealOrder: MealOrder) => (
            <MealsListItem
              data={mealOrder}
              key={`${mealOrder.device_id}-${mealOrder.timestamp}`}
              onClickActionButton={this.handleMealOrderUpdate}
              onClickDetail={this.handleShowDetail}
              isArchive={isArchive}
            />
          )) : <></>,
        },
      ];
    }

    return [
      {
        title: 'mealsColumnOrder',
        count: dataSubmitted ? dataSubmitted.length : 0,
        color: ColumnColor.SECONDARY,
        content: dataSubmitted ? dataSubmitted.map((mealOrder: MealOrder) => (
          <MealsListItem
            data={mealOrder}
            key={`${mealOrder.device_id}-${mealOrder.timestamp}`}
            onClickActionButton={this.handleMealOrderUpdate}
            onClickDetail={this.handleShowDetail}
          />
        )) : <></>,
      },
      {
        title: 'mealsColumnInProgress',
        count: dataInProgress ? dataInProgress.length : 0,
        color: ColumnColor.PRIMARY,
        content: dataInProgress ? dataInProgress.map((mealOrder: MealOrder) => (
          <MealsListItem
            data={mealOrder}
            key={`${mealOrder.device_id}-${mealOrder.timestamp}`}
            onClickActionButton={this.handleMealOrderUpdate}
            onClickDetail={this.handleShowDetail}
          />
        )) : <></>,
      },
    ];
  }

  //#endregion

  //#endregion

  //#region MAIN RENDER
  render(): React.ReactElement {
    const { isArchive, history, isAscSorted } = this.props;
    return (
      <div className="meals">
        <Header
          title={isArchive ? 'mealsArchiveTitle' : 'mealsTitle'}
          backButton={isArchive}
          action={
            !isArchive
              ? [
                  <Button
                    key="add"
                    label="mealsActionAdd"
                    color="primary"
                    icon="add"
                    onClick={this.handleShowAddModal}
                  />,
                  <Button
                    key="reload"
                    label="mealsActionReload"
                    color="secondary"
                    icon="loop"
                    className="meals__header__reloadButton"
                    onClick={this.handleReload}
                  />,
                  <Button
                    key="sort"
                    label={isAscSorted ? "mealsActionAscSort" : "mealsActionDescSort"}
                    color="secondary"
                    icon={isAscSorted ? "arrow_upward" : "arrow_downward"}
                    className="meals__header__reloadButton"
                    onClick={this.handleToggleSort}
                  />,
                  <Button
                    key="archive"
                    iconOnly
                    icon="history"
                    className="archive"
                    onClick={(): void => {
                      history.push(PATH.MEALS_ORDER_ARCHIVE);
                    }}
                  />,
                ]
              : [
                  <ReactDatePicker
                    dateFormat="dd MMM yyyy"
                    key="date-picker-key"
                    selected={this.state.selectedDate}
                    locale="ja"
                    popperPlacement="top-end"
                    showPopperArrow={false}
                    onChange={(date: Date): void => {
                      this.fetchMealOrders(this.props.airportId, this.props.loungeId, moment(date).format('YYYY-MM-DD'));
                      this.setState({ selectedDate: date });
                    }}
                    className="staff-call__archive__input"
                  />,
                ]
          }
        />
        <div className="meals__content">
          <div className="meals__content__list-group">
            <Column data={this.renderColumn()} />
          </div>
        </div>
        {this.renderAddModal()}
        {this.renderDetailModal()}
      </div>
    );
  }

  //#endregion
}

//#endregion

export default withRouter(injectIntl(Meals));
