//#region IMPORTS
// LOCAL CONFIG
import SagaUser from '../modules/user/SagaUser';
import SagaWrapper from '../modules/wrapper/SagaWrapper';
import SagaShower from '../modules/shower/SagaShower';
import SagaMeals from '../modules/meals/SagaMeals';
import SagaCongestion from '../modules/congestion/SagaCongestion';
import SagaStaffCall from '../modules/staffcall/SagaStaffCall';

//#endregion

export default [...SagaUser, ...SagaWrapper, ...SagaShower, ...SagaMeals, ...SagaCongestion, ...SagaStaffCall];
