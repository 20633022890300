//#region ACTION NAMES

// Shower Booking List
export const SHOWER_BOOKING_LIST_FETCH = 'SHOWER_BOOKING_LIST_FETCH';
export const SHOWER_BOOKING_LIST_SUCCESS = 'SHOWER_BOOKING_LIST_SUCCESS';
export const SHOWER_BOOKING_LIST_FAILED = 'SHOWER_BOOKING_LIST_FAILED';
export const SHOWER_BOOKING_LIST_CLEAR = 'SHOWER_BOOKING_LIST_CLEAR';

// Shower Booking Create
export const SHOWER_BOOKING_CREATE_FETCH = 'SHOWER_BOOKING_CREATE_FETCH';
export const SHOWER_BOOKING_CREATE_SUCCESS = 'SHOWER_BOOKING_CREATE_SUCCESS';
export const SHOWER_BOOKING_CREATE_FAILED = 'SHOWER_BOOKING_CREATE_FAILED';
export const SHOWER_BOOKING_CREATE_CLEAR = 'SHOWER_BOOKING_CREATE_CLEAR';

// Shower Booking Update
export const SHOWER_BOOKING_UPDATE_FETCH = 'SHOWER_BOOKING_UPDATE_FETCH';
export const SHOWER_BOOKING_UPDATE_SUCCESS = 'SHOWER_BOOKING_UPDATE_SUCCESS';
export const SHOWER_BOOKING_UPDATE_FAILED = 'SHOWER_BOOKING_UPDATE_FAILED';
export const SHOWER_BOOKING_UPDATE_CLEAR = 'SHOWER_BOOKING_UPDATE_CLEAR';

// Shower Room List
export const SHOWER_ROOM_LIST_FETCH = 'SHOWER_ROOM_LIST_FETCH';
export const SHOWER_ROOM_LIST_SUCCESS = 'SHOWER_ROOM_LIST_SUCCESS';
export const SHOWER_ROOM_LIST_FAILED = 'SHOWER_ROOM_LIST_FAILED';
export const SHOWER_ROOM_LIST_CLEAR = 'SHOWER_ROOM_LIST_CLEAR';

// Shower Subscription
export const SHOWER_BOOKING_SUBSCRIPTION_CREATE_FETCH = 'SHOWER_BOOKING_SUBSCRIPTION_CREATE_FETCH';
export const SHOWER_BOOKING_SUBSCRIPTION_CREATE_SUCCESS = 'SHOWER_BOOKING_SUBSCRIPTION_CREATE_SUCCESS';
export const SHOWER_BOOKING_SUBSCRIPTION_CREATE_FAILED = 'SHOWER_BOOKING_SUBSCRIPTION_CREATE_FAILED';
export const SHOWER_BOOKING_SUBSCRIPTION_REMOVE_FETCH = 'SHOWER_BOOKING_SUBSCRIPTION_REMOVE_FETCH';
export const SHOWER_BOOKING_SUBSCRIPTION_REMOVE_SUCCESS = 'SHOWER_BOOKING_SUBSCRIPTION_REMOVE_SUCCESS';
export const SHOWER_BOOKING_SUBSCRIPTION_REMOVE_FAILED = 'SHOWER_BOOKING_SUBSCRIPTION_REMOVE_FAILED';
export const SHOWER_BOOKING_SUBSCRIPTION_UPDATE_FETCH = 'SHOWER_BOOKING_SUBSCRIPTION_UPDATE_FETCH';
export const SHOWER_BOOKING_SUBSCRIPTION_UPDATE_SUCCESS = 'SHOWER_BOOKING_SUBSCRIPTION_UPDATE_SUCCESS';
export const SHOWER_BOOKING_SUBSCRIPTION_UPDATE_FAILED = 'SHOWER_BOOKING_SUBSCRIPTION_UPDATE_FAILED';
export const ON_CREATE_UPDATE_SHOWER_BOOKING = 'ON_CREATE_UPDATE_SHOWER_BOOKING';
export const ON_CREATE_UPDATE_SHOWER_BOOKING_SUCCESS = 'ON_CREATE_UPDATE_SHOWER_BOOKING_SUCCESS';
export const ON_CREATE_UPDATE_SHOWER_BOOKING_FAILED = 'ON_CREATE_UPDATE_SHOWER_BOOKING_FAILED';

//#endregion
