//#region IMPORTS
// PACKAGE IMPORT
import { connect } from 'react-redux';

// LOCAL CONFIG
import LoginView, { StateProps } from './Login';
import { Login } from '../../ActionUser';
import { AppState } from '../../../../bootstrap/ReduxStore';
//#endregion

//#region REDUX BINDINGS
const mapStateToProps = (state: AppState): StateProps => ({
  isLoadingLogin: state.user.isLoadingLogin,
});

const mapDispatchToProps = {
  Login,
};
//#endregion

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginView);
