import env from '../../../config/environment';

// GET DEVICE TOKEN
import { SatoAuthParam } from './TypesUser';

//#region ACTION STRINGS

// USER
export const USER = {
  GET_USER: 'GET_USER',
  SET_USER: 'SET_USER',
  LOADING_USER: 'LOADING_USER',
  LOGIN: 'LOGIN',
  LOADING_LOGIN: 'LOADING_LOGIN',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  SET_DEVICE_TOKEN: 'SET_DEVICE_TOKEN',
  GET_DEVICE_PLATFORM: 'GET_DEVICE_PLATFORM',
  SET_DEVICE_PLATFORM: 'SET_DEVICE_PLATFORM',
  SET_SATO_TOKEN: 'SET_SATO_TOKEN',
};

// PUSH NOTIFICATIONS
export const PUSH_NOTIFICATION = {
  REGISTER: {
    PUSH_NOTIFICATION_REGISTER_FETCH: 'PUSH_NOTIFICATION_REGISTER_FETCH',
    PUSH_NOTIFICATION_REGISTER_SUCCESS: 'PUSH_NOTIFICATION_REGISTER_SUCCESS',
    PUSH_NOTIFICATION_REGISTER_FAILED: 'PUSH_NOTIFICATION_REGISTER_FAILED',
    PUSH_NOTIFICATION_REGISTER_CLEAR: 'PUSH_NOTIFICATION_REGISTER_CLEAR',
  },
};

// GET USERS INSIDE AREA
export const GET_AREA_USERS_FETCH = 'GET_AREA_USERS_FETCH';
export const GET_AREA_USERS_SUCCESS = 'GET_AREA_USERS_SUCCESS';
export const GET_AREA_USERS_FAILED = 'GET_AREA_USERS_FAILED';
export const GET_AREA_USERS_CLEAR = 'GET_AREA_USERS_CLEAR';

// GET USER'S CURRENT LOCATION
export const GET_USER_LOCATION_FETCH = 'GET_USER_LOCATION_FETCH';
export const GET_USER_LOCATION_SUCCESS = 'GET_USER_LOCATION_SUCCESS';
export const GET_USER_LOCATION_FAILED = 'GET_USER_LOCATION_FAILED';
export const GET_USER_LOCATION_CLEAR = 'GET_USER_LOCATION_CLEAR';

// SATO AUTH
export const SATO_AUTH_FETCH = 'SATO_AUTH_FETCH';
export const SATO_AUTH_SUCCESS = 'SATO_AUTH_SUCCESS';
export const SATO_AUTH_FAILED = 'SATO_AUTH_FAILED';
export const SATO_AUTH_CLEAR = 'SATO_AUTH_CLEAR';

//#region API

// SATO
export const SATO_API_URI = env.satoApiUrl;
export const GET_AREA_USERS_API = '/whois';
export const GET_USER_LOCATION_API = '/whereis';

// SATO AUTH
export const SATO_API_AUTH_URI = env.satoAuthUrl;
export const SATO_AUTH_PAYLOAD: SatoAuthParam = {
  client_id: 'jal-client',
  username: 'jaltok1015',
  password: 'JalTok1015',
  grant_type: 'password',
};

//#endregion
