//#region IMPORTS
// PACKAGE IMPORTS
import React from 'react';
import API, { graphqlOperation } from '@aws-amplify/api';
import Observable from 'zen-observable';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import lodash from 'lodash';

// LOCAL CONFIG
import { PATH } from '../../../navigation/constants';
import {
  StaffCallActions,
  subscription,
  StaffCallStatusTypes,
  StaffCallListParam,
  StaffCall,
  StaffCallMultiStatusResponse,
  STAFF_CALL_LIST_SUCCESS,
  ON_STAFF_CALL_SUBSCRIPTION,
  StaffCallUpdateParam,
  StaffCallMutationTypes,
  StaffCallItem,
  OnCreateUpdateStaffCallPayload,
  OnAnswerStaffCall,
  StaffCallUpdateResponse,
  STAFF_CALL_UPDATE_FAILED,
  STAFF_CALL_UPDATE_SUCCESS,
  FailureType,
  GraphQLSubscriptionResult,
  SubscriptionStatus,
} from '../../constants';
import { ALERTTYPE } from '../../../alert/constants';

// LOCAL COMPONENT IMPORTS
import { Button, Header, Modal } from '../../../../components';
import Column, { ColumnColor, ColumnProps } from '../../../../components/Column';
import StaffCallListItem from '../StaffCallListItem';
import StaffCallDetail from '../StaffCallDetail';
import './StaffCall.scss';

//#endregion

//#region INTERFACE
interface OwnProps extends WrappedComponentProps, RouteComponentProps {
  isArchive?: boolean;
}

interface OwnState {
  staffCallData: StaffCallMultiStatusResponse;
  modalVisible: boolean;
  detailStaffCall?: StaffCallItem;
  selectedDate?: Date | null;
}

export interface DispatchProps {
  OnStaffCallSubscription(): StaffCallActions;
  StaffCallSubscriptionCreateFetch(): StaffCallActions;
  StaffCallSubscriptionCreateFailed(payload: FailureType): StaffCallActions;
  StaffCallSubscriptionCreateSuccess(): StaffCallActions;
  StaffCallSubscriptionCreateClear(): StaffCallActions;
  StaffCallListFetch(payload: StaffCallListParam): StaffCallActions;
  StaffCallUpdateFetch(payload: StaffCallUpdateParam): StaffCallActions;
  ShowAlert(type: ALERTTYPE, message: string): void;
}

export interface StateProps {
  onCreateUpdateSubscriptionParam?: OnCreateUpdateStaffCallPayload;
  staffCallSubscriptionStatus: SubscriptionStatus;
  staffCallListResponse?: StaffCallMultiStatusResponse;
  staffCallListParams?: StaffCallListParam;
  staffCallUpdateResponse?: StaffCallUpdateResponse;
  staffCallUpdateParam?: StaffCallUpdateParam;
  staffCallCreateUpdateError?: FailureType;
  action: string;
}

type Props = OwnProps & DispatchProps & StateProps;
//#endregion

//#region COMPONENT
class Staff extends React.Component<Props, OwnState> {
  //#region CLASS PROPERTIES
  private OnCreateStaffCall?: ZenObservable.Subscription;
  private OnAnswerStaffCall?: ZenObservable.Subscription;
  private detailModalRef: React.RefObject<Modal> = React.createRef();
  //#endregion

  //#region CONSTRUCTOR & LIFECYCLE METHODS
  constructor(props: Props) {
    super(props);

    this.state = {
      detailStaffCall: undefined,
      modalVisible: false,
      staffCallData: {
        items: {
          [StaffCallStatusTypes.CALLING]: [],
          [StaffCallStatusTypes.ANSWERED]: [],
          [StaffCallStatusTypes.CANCELLED]: [],
        },
      },
      selectedDate: moment().toDate(),
    };

    this.handleReduxActions = this.handleReduxActions.bind(this);
    this.fetchStaffCall = this.fetchStaffCall.bind(this);
    this.handleStaffCallUpdate = this.handleStaffCallUpdate.bind(this);
    this.handleShowDetail = this.handleShowDetail.bind(this);
    this.handleSubscriptionUpdate = this.handleSubscriptionUpdate.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const { staffCallSubscriptionStatus, isArchive } = this.props;
    const { UNREGISTERED, REGISTRATION_FAILURE } = SubscriptionStatus;
    this.fetchStaffCall(isArchive ? moment().format('YYYY-MM-DD') : undefined);
    if (staffCallSubscriptionStatus === UNREGISTERED || staffCallSubscriptionStatus === REGISTRATION_FAILURE) {
      await this.createSubscription();
    }
  }

  handleSubscriptionUpdate(payload: OnCreateUpdateStaffCallPayload): void {
    const { detailStaffCall } = this.state;

    const prevPayload = this.props.onCreateUpdateSubscriptionParam;
    const updates = {
      [StaffCallMutationTypes.ANSWER as string]: (): StaffCall => {
        return (payload as OnAnswerStaffCall).onAnswerStaffCall;
      },
      DEFAULT: (): string => 'invalid',
    };

    const currentUpdate = (updates[payload.mutationType] || updates.DEFAULT)();

    if (detailStaffCall && typeof currentUpdate !== 'string' && detailStaffCall.device_id === currentUpdate.device_id) {
      this.setState({ detailStaffCall: currentUpdate });
    }

    if (lodash.isEqual(prevPayload, payload)) return;
    this.fetchStaffCall();
  }

  componentDidUpdate(prevProps: Props, prevState: OwnState): void {
    if (prevProps.action !== this.props.action) {
      this.handleReduxActions(this.props.action);
    }

    if (prevState.modalVisible !== this.state.modalVisible) {
      if (this.state.modalVisible) {
        this.detailModalRef.current && this.detailModalRef.current.openModal();
      } else {
        this.detailModalRef.current && this.detailModalRef.current.closeModal();
      }
    }
  }

  fetchStaffCall(date?: string): void {
    this.props.StaffCallListFetch({
      limit: 1000,
      statusTypes: this.props.isArchive
        ? [StaffCallStatusTypes.ANSWERED, StaffCallStatusTypes.CALLING]
        : [StaffCallStatusTypes.CALLING],
      date,
    });
  }

  componentWillUnmount(): void {
    this.unsubscribeSubscription();
  }
  //#endregion

  //#region CLASS METHODS
  handleReduxActions(action: string): void {
    const actions = {
      [STAFF_CALL_LIST_SUCCESS as string]: (): void => {
        this.props.staffCallListResponse && this.handleStaffCallListSuccess(this.props.staffCallListResponse);
      },
      [ON_STAFF_CALL_SUBSCRIPTION as string]: (): void => {
        this.fetchStaffCall();
      },
      [STAFF_CALL_UPDATE_SUCCESS as string]: (): void => {},
      [STAFF_CALL_UPDATE_FAILED as string]: (): void => {
        this.fetchStaffCall();
      },
      DEFAULT: (): void => {},
    };

    return (actions[action] || actions.DEFAULT)();
  }

  handleStaffCallUpdate(data: StaffCallItem, updateType: StaffCallMutationTypes): void {
    const { StaffCallUpdateFetch } = this.props;
    switch (updateType) {
      case StaffCallMutationTypes.ANSWER:
        StaffCallUpdateFetch({ input: { device_id: data.device_id }, updateType });
        break;
      default:
        this.fetchStaffCall();
        break;
    }
  }

  async createSubscription(): Promise<void> {
    this.props.StaffCallSubscriptionCreateFetch();

    try {
      this.OnCreateStaffCall = ((await API.graphql(graphqlOperation(subscription.OnCreateStaffCall))) as Observable<
        GraphQLSubscriptionResult
      >).subscribe({
        next: () => this.props.OnStaffCallSubscription(),
      });

      this.OnAnswerStaffCall = ((await API.graphql(graphqlOperation(subscription.OnAnswerStaffCall))) as Observable<
        GraphQLSubscriptionResult
      >).subscribe({
        next: (data: GraphQLSubscriptionResult) =>
          this.handleSubscriptionUpdate({
            onAnswerStaffCall: data.value.data.onAnswerStaffCall,
            mutationType: StaffCallMutationTypes.ANSWER,
          }),
      });

      this.props.StaffCallSubscriptionCreateSuccess();
    } catch (e) {
      this.props.StaffCallSubscriptionCreateFailed(e);
    }
  }

  unsubscribeSubscription(): void {
    try {
      this.OnCreateStaffCall && this.OnCreateStaffCall.unsubscribe();
      this.OnAnswerStaffCall && this.OnAnswerStaffCall.unsubscribe();
      this.props.StaffCallSubscriptionCreateClear();
    } catch (e) {
      this.props.StaffCallSubscriptionCreateFailed(e);
    }
  }

  handleStaffCallListSuccess(response: StaffCallMultiStatusResponse): void {
    this.setState({
      staffCallData: response,
    });
  }

  handleShowDetail(data: StaffCallItem): void {
    this.setState({ detailStaffCall: data, modalVisible: true });
  }

  //#endregion

  //#region RENDER
  renderDetail(): React.ReactNode {
    const { detailStaffCall, modalVisible } = this.state;
    if (!modalVisible || detailStaffCall == null) return <></>;

    return (
      <Modal
        mode={4}
        ref={this.detailModalRef}
        title="labelCallDetail"
        onClosed={(): void => this.setState({ modalVisible: false })}
        content={<StaffCallDetail data={detailStaffCall} onClickActionButton={this.handleStaffCallUpdate} />}
      />
    );
  }

  renderColumn(): ColumnProps[] {
    const { isArchive } = this.props;
    const { staffCallData } = this.state;
    const dataCalling = staffCallData.items[StaffCallStatusTypes.CALLING] || [];
    const dataAnswered = staffCallData.items[StaffCallStatusTypes.ANSWERED] || [];
    if (isArchive) {
      return [
        {
          color: ColumnColor.SECONDARY,
          title: 'labelAnswered',
          count: dataAnswered.length,
          content: dataAnswered.map((staffCall: StaffCall) => (
            <StaffCallListItem
              key={`${staffCall.device_id}-${staffCall.timestamp}`}
              data={staffCall}
              onClickDetail={this.handleShowDetail}
              onClickActionButton={this.handleStaffCallUpdate}
            />
          )),
        },
      ];
    } else {
      return [
        {
          color: ColumnColor.PRIMARY,
          title: 'labelCalling',
          count: dataCalling.length,
          content: dataCalling.map((staffCall: StaffCall) => (
            <StaffCallListItem
              key={`${staffCall.device_id}-${staffCall.timestamp}`}
              data={staffCall}
              onClickDetail={this.handleShowDetail}
              onClickActionButton={this.handleStaffCallUpdate}
            />
          )),
        },
      ];
    }
  }

  render(): React.ReactElement {
    const { isArchive, history } = this.props;

    return (
      <div className="staff-call">
        <Header
          backButton={isArchive}
          title={isArchive ? 'staffCallArchiveTitle' : 'staffCallDetailTitle'}
          action={
            isArchive
              ? [
                  <DatePicker
                    dateFormat="dd MMM yyyy"
                    key="date-picker-key"
                    selected={this.state.selectedDate}
                    locale="ja"
                    popperPlacement="top-end"
                    showPopperArrow={false}
                    onChange={(date: Date): void => {
                      this.fetchStaffCall(moment(date).format('YYYY-MM-DD'));
                      this.setState({ selectedDate: date });
                    }}
                    className="staff-call__archive__input"
                  />,
                ]
              : [
                  <Button
                    className="archive"
                    key="archive"
                    type="button"
                    color="primary-transparent"
                    iconOnly={true}
                    icon="history"
                    onClick={(): void => history.push(PATH.STAFF_CALLING_ARCHIVE)}
                  />,
                ]
          }
        />
        <div className="staff-call__content">
          <div className="staff-call__content__list-group">
            <Column data={this.renderColumn()} />
          </div>
        </div>
        {this.renderDetail()}
      </div>
    );
  }
  //#endregion
}

export default withRouter(injectIntl(Staff));
