//#region ACTION STRINGS

export const GET_DEVICE_TOKEN_FETCH = 'GET_DEVICE_TOKEN_FETCH';
export const GET_DEVICE_TOKEN_RECEIVED = 'GET_DEVICE_TOKEN_RECEIVED';
export const NEW_DEVICE_TOKEN = 'NEW_DEVICE_TOKEN';
export const UNKNOWN_ACTION = 'UNKNOWN_ACTION';

// GEENRAL XAMARIN ACTIONS
export const SEND_ACTION_TO_NATIVE = 'SEND_ACTION_TO_NATIVE';
export const RECEIVED_ACTION_FROM_NATIVE = 'RECEIVED_ACTION_FROM_NATIVE';
export const SEND_ACTION_TO_NATIVE_CLEAR = 'SEND_ACTION_TO_NATIVE_CLEAR';
export const RECEIVED_ACTION_FROM_NATIVE_CLEAR = 'RECEIVED_ACTION_FROM_NATIVE_CLEAR';
export const SEND_ACTION_TO_NATIVE_ERROR = 'SEND_ACTION_TO_NATIVE_ERROR';
export const RECEIVED_ACTION_FROM_NATIVE_ERROR = 'RECEIVED_ACTION_FROM_NATIVE_ERROR';
export const SEND_ACTION_TO_NATIVE_ERROR_CLEAR = 'SEND_ACTION_TO_NATIVE_ERROR_CLEAR';
export const RECEIVED_ACTION_FROM_NATIVE_ERROR_CLEAR = 'RECEIVED_ACTION_FROM_NATIVE_ERROR_CLEAR';

// MISC
export const CONSOLE_LOG = 'CONSOLE_LOG';
export const TOGGLE_MUTED_ACTION = 'TOGGLE_MUTED_ACTION';

// REGISTER ENDPOINTS
export const REGISTER_ENDPOINTS_FETCH = 'REGISTER_ENDPOINTS';
export const REGISTER_ENDPOINTS_RESPONSE = 'REGISTER_ENDPOINTS_RESPONSE';
export const UNREGISTER_ENDPOINTS = 'UNREGISTER_ENDPOINTS';

// DEVICE PLATFORM
export const GET_PLATFORM_FETCH = 'GET_PLATFORM_FETCH';
export const GET_PLATFORM_RESPONSE = 'GET_PLATFORM_RESPONSE';

export const GET_USER_LOCATION = 'GET_USER_LOCATION';
export const GET_USER_LOCATION_RESPONSE = 'GET_USER_LOCATION_RESPONSE';

//#endregion
