import React from 'react';
import { connect } from 'react-redux';
import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import { AppState } from '../../../../bootstrap/ReduxStore';
import Unauthorized from '../../views/Unauthorized';
import { UserRoles } from '../../../user/constants';

interface OwnProps extends RouteComponentProps {
  history: History;
}

interface StateProps {
  roles: UserRoles[];
  isSignedIn: boolean;
}

interface OwnState {
  allowed: boolean;
}

type Props = OwnProps & StateProps;

const isKitchenStaff = (WrappedComponent: any): any => {
  class Index extends React.Component<Props, OwnState> {
    constructor(props: any) {
      super(props);
      this.state = {
        allowed: false,
      };
    }

    UNSAFE_componentWillMount(): void {
      if (this.props.roles.includes(UserRoles.KITCHEN_STAFF)) this.setState({ allowed: true });
    }

    render(): React.ReactElement {
      return this.state.allowed ? <WrappedComponent {...this.props} /> : <Unauthorized />;
    }
  }

  const mapStateToProps = (state: AppState): StateProps => ({
    roles: state.user && state.user.user ? state.user.user.roles : [],
    isSignedIn: state.user.isSignedIn,
  });

  return connect(mapStateToProps)(Index);
};

export default isKitchenStaff;
