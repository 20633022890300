//#region IMPORTS
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import './Unauthorized.scss';
//#endregion

//#region HEADER COMPONENT
interface OwnProps {
  intl: IntlShape;
}

type Props = OwnProps;

function Unauthorized({ intl }: Props): React.ReactElement {
  return (
    <div className="unauthorized">
      <div className="unauthorized__content">
        <h2>{intl.formatMessage({ id: 'unauthorizedTitle', defaultMessage: 'unauthorizedTitle' })}</h2>
        <p>{intl.formatMessage({ id: 'unauthorizedDesc', defaultMessage: 'unauthorizedDesc' })}</p>
      </div>
    </div>
  );
}

export default injectIntl(Unauthorized);
