//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import classnames from 'classnames';
import moment from 'moment';

// LOCAL CONFIG
import { MealOrder, MealOrderItem, MealOrderCreateUpdateType, MealOrderStatusType } from '../../constants';

// LOCAL COMPONENT
import { TimeCounter } from '../../components';
import { IconBell } from '../../../../config/icon';
import MealsAction from '../MealsAction';
import { tagMaster } from '../../../user/views/UserLocationSearch/TagMaster';
import { Icon, Checkbox } from '../../../../components';
import './MealsListItem.scss';

//#endregion

//#region INTERFACE
export interface OwnProps {
  intl: IntlShape;
  data: MealOrder;
  onClickActionButton(mealOrder: MealOrder, updateType: MealOrderCreateUpdateType): void;
  onClickDetail(mealOrder: MealOrder): void;
  isArchive?: boolean;
}

const InitialState = {
  isCollapse: false,
  statusType: MealOrderStatusType.SUBMITTED,
  items: {},
  itemIsEmpty: true,
};

type State = typeof InitialState;

type Props = OwnProps;
//#endregion

//#region COMPONENT
class MealListItem extends React.Component<Props, State> {
  //#region CONSTRUCTOR
  constructor(props: OwnProps) {
    super(props);

    this.state = {
      ...InitialState,
      statusType: props.data.status,
    };

    this.onToggleCollapse = this.onToggleCollapse.bind(this);
    this.getMealsOrderStatusLabel = this.getMealsOrderStatusLabel.bind(this);
    this.handleCookedItem = this.handleCookedItem.bind(this);
    this.handleDeliveredItem = this.handleDeliveredItem.bind(this);
  }
  //#endregion

  //#region METHOD
  onToggleCollapse(): void {
    this.setState({ isCollapse: !this.state.isCollapse });
  }

  getMealsOrderStatusLabel(status: MealOrderStatusType): string {
    const labels = {
      [MealOrderStatusType.SUBMITTED as string]: 'mealsStatusSubmitted',
      [MealOrderStatusType.REJECTED as string]: 'mealsStatusRejected',
      [MealOrderStatusType.IN_PROGRESS as string]: 'mealsStatusInProgress',
      [MealOrderStatusType.COMPLETED as string]: 'mealsStatusCompleted',
    };
    const label = labels[status as string];

    return this.props.intl.formatMessage({ id: label, defaultMessage: label });
  }

  handleCookedItem(index: number, is_cooked_value: boolean): void {
    const { data, onClickActionButton } = this.props;
    const items = JSON.parse(data.items);
    if (!(Array.isArray(items) && items.length > 0)) return;

    items.splice(index, 1, {
      ...items[index],
      is_cooked: is_cooked_value,
    });

    const newData = {
      ...data,
      items: JSON.stringify(items),
    };

    onClickActionButton(newData, MealOrderCreateUpdateType.UPDATE);
  }

  handleDeliveredItem(index: number, is_delivered_value: boolean): void {
    const { data, onClickActionButton } = this.props;
    const items = JSON.parse(data.items);
    if (!(Array.isArray(items) && items.length > 0)) return;

    items.splice(index, 1, {
      ...items[index],
      is_delivered: is_delivered_value,
    });

    const newData = {
      ...data,
      items: JSON.stringify(items),
    };

    onClickActionButton(newData, MealOrderCreateUpdateType.UPDATE);
  }

  //#endregion

  //#region RENDER
  renderMealOrderItems(): JSX.Element[] | null {
    const { intl, data } = this.props;

    const items = JSON.parse(data.items);
    return (items as MealOrderItem[]).map((item: MealOrderItem, index: number) => {
      return (
        <div key={index} className="order-item">
          <div className="order-item__qty">
            {item.qty}
            {intl.formatMessage({
                      id: 'labelQtyUnit',
                      defaultMessage: 'labelQtyUnit',
                    })}
          </div>
          <div className={item.name.startsWith("【鮨 鶴亭】") || item.name.startsWith("Sushi Tsurutei") ? "order-item__name__sushi" : "order-item__name"}>{item.name}</div>
          {data.status === MealOrderStatusType.IN_PROGRESS && (
            <>
              <div className="order-item__cooking">
                <Checkbox
                  id={`is_cooked-${data.device_id}-${data.timestamp}-${index}`}
                  checked={item.is_cooked}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    this.handleCookedItem(index, e.target.checked)
                  }
                />
              </div>
              <div className="order-item__deliver">
                <Checkbox
                  id={`is_delivered-${data.device_id}-${data.timestamp}-${index}`}
                  checked={item.is_delivered}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    this.handleDeliveredItem(index, e.target.checked)
                  }
                />
              </div>
            </>
          )}
        </div>
      );
    });
  }

  render(): JSX.Element {
    const { data, intl, isArchive } = this.props;
    const { isCollapse } = this.state;

    // Status check constant for easier comparison
    const submitted = data.status === MealOrderStatusType.SUBMITTED;
    const inProgress = data.status === MealOrderStatusType.IN_PROGRESS;
    const completed = data.status === MealOrderStatusType.COMPLETED;
    const rejected = data.status === MealOrderStatusType.REJECTED;

    // Set which status for which columns
    const orders = submitted;

    const classess = classnames('meals-list-item', `meals-list-item--${data.status}`);

    return (
      <div className={classess}>
        <div className="meals-list-item__header" onClick={this.onToggleCollapse}>
          <div className="meals-list-item__header__status">
            <span
              className={`meals-list-item__header__icon meals-list-item__header__icon--${orders ? 'orders' : 'ready'}`}
            >
              <Icon
                data={
                  /*eslint no-nested-ternary: "off"*/
                  completed ? 'check' : inProgress ? 'whatshot' : rejected ? 'priority_high' : IconBell
                }
              />
            </span>

            {this.getMealsOrderStatusLabel(data.status)}
          </div>
          <div className="meals-list-item__header__collapsable">
            <Icon data={isCollapse ? 'keyboard_arrow_down' : 'keyboard_arrow_down'} />
          </div>
        </div>
        {!isCollapse && (
          // Only show content if is not collapse
          <>
            <div className="meals-list-item__content">
              <div className="meals-list-item__content__group">
                <div className="meals-list-item__content__item">
                  <div className="meals-list-item__content__item__label">
                    {intl.formatMessage({
                      id: data.use_app ? 'labelOrderTableId' : 'labelBeaconId',
                      defaultMessage: data.use_app ? 'labelOrderTableId' : 'labelBeaconId',
                    })}
                  </div>
                  <div className="meals-list-item__content__item__value">
                    {!data.use_app && (
                      <span className="icon-wrapper">
                        <Icon data="label" />
                      </span>
                    )}
                    {data.use_app ? data.table_id : tagMaster.filter(tag => tag.code === data.device_id)[0].id}
                  </div>
                </div>
                <div className="meals-list-item__content__item">
                  <div className="meals-list-item__content__item__label">
                    {intl.formatMessage({
                      id: 'labelOrderId',
                      defaultMessage: 'labelOrderId',
                    })}
                  </div>
                  <div className="meals-list-item__content__item__value">{data.counter}</div>
                </div>

                <div className="meals-list-item__content__item order-date">
                  <div className="meals-list-item__content__item__label">
                    {!isArchive && intl.formatMessage({ id: 'mealsElapsedTime', defaultMessage: 'mealsElapsedTime' })}
                    {isArchive && intl.formatMessage({ id: 'mealsOrderTime', defaultMessage: 'mealsOrderTime' })}
                  </div>
                  <div className="meals-list-item__content__item__value">
                    {!isArchive && (
                      <>
                        <span className="icon-wrapper">
                          <Icon data="timer" />
                        </span>
                        <TimeCounter startTime={data.timestamp} />
                      </>
                    )}
                    {isArchive && moment(data.timestamp).format('hh:mm a')}
                  </div>
                </div>
              </div>

              <div className="meals-list-item__content__group meals-list-item__content__group--items">
                <div className="meals-list-item__content__item">
                  <div className="meals-list-item__content__item__label">
                    <div className="meals-list-item__content__item__label__order-detail">
                      {intl.formatMessage({ id: 'mealsOrderItems', defaultMessage: 'mealsOrderItems' })}
                    </div>
                    {data.status === MealOrderStatusType.IN_PROGRESS && (
                      <>
                        <div className="meals-list-item__content__item__label__cooked">
                          {intl.formatMessage({ id: 'mealsOrderItemsCooking', defaultMessage: 'mealsOrderItemsCooking' })}
                        </div>
                        <div className="meals-list-item__content__item__label__delivered">
                          {intl.formatMessage({ id: 'mealsOrderItemsDeliver', defaultMessage: 'mealsOrderItemsDeliver' })}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="meals-list-item__content__item__value">{this.renderMealOrderItems()}</div>
                </div>
              </div>
            </div>
            <MealsAction {...this.props}  />
          </>
        )}
      </div>
    );
  }

  //#endregion
}

//#endregion

export default injectIntl(MealListItem);
